import { t } from "@lingui/macro";
import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  type FieldFields,
  type FieldInstanceFields,
  FieldInstanceValueInputType,
  type PropertyDataType,
  type SpectrumFieldVersionFields,
} from "@regrello/graphql-api";
import { RegrelloChip, RegrelloTypography } from "@regrello/ui-core";
import React, { useCallback, useMemo } from "react";
import type { UseFormReturn } from "react-hook-form";

import { ValidationRules } from "../../../../../../constants/globalConstants";
import { isFromSplitterTask } from "../../../../../../utils/customFields/splitFieldUtils";
import { CurrencyFieldPlugin } from "../../../../../molecules/customFields/plugins/CurrencyFieldPlugin";
import { MultiSelectFieldPlugin } from "../../../../../molecules/customFields/plugins/MultiSelectFieldPlugin";
import { CustomFieldPluginRegistrar } from "../../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { RegrelloObjectFieldPlugin } from "../../../../../molecules/customFields/plugins/RegrelloObjectFieldPlugin";
import { SelectFieldPlugin } from "../../../../../molecules/customFields/plugins/SelectFieldPlugin";
import {
  type ControlledCustomFieldInstanceSelectFieldOptionsConfig,
  RegrelloControlledFormFieldCustomFieldInstanceSelect,
} from "../../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import type {
  CustomFieldInstanceSelectConfigureFieldProps,
  RegrelloFormFieldCustomFieldInstanceSelectWorkflowContext,
} from "../../../../../molecules/formFields/RegrelloFormFieldCustomFieldInstanceSelect";
import { RegrelloFormSection } from "../../../../../molecules/formSection/RegrelloFormSection";
import { SpectrumFieldPluginRegistrar } from "../../../../../molecules/spectrumFields/registry/spectrumFieldPluginRegistrar";
import LongArrowHeadRight from "../_internal/svgs/long-arrow-head-right.svg?react";

enum OptionDisabledReason {
  INVALID_CARDINALITY = "invalidCardinality",
  RESTRICTED_FIELD_TYPE = "restrictedFieldType",
}

/** Represents a mapping between a source field instance and a destination input. */
export type CustomFieldsInputMappingRow = {
  /** The source field instance mapped to the destination input field. */
  fieldInstance: FieldInstanceFields | null;

  /** The destination input field the source field instance is mapped to. */
  input: {
    /** Display name for the chip representing the destination field. */
    displayName: string;

    /** The field instance, if one exists, represented by the destination field. */
    fieldInstance?: FieldInstanceFields | null;

    /** Whether the destination field is hidden. */
    isHidden: boolean;

    /**
     * Whether the destination field is required. Affects the validation rules applied to the
     * source field instance input.
     */
    isRequired: boolean;

    /** The destination field's property data type. */
    valueType: PropertyDataType;
  };
};

/** Represents a group of mappings between source field instances and destination inputs. */
export type CustomFieldsGroupedInputsMappingRow = {
  /** Name for the group of mappings. */
  groupName: string;

  /** The input mappings included in the group. */
  inputs: CustomFieldsInputMappingRow[];
};

export interface RegrelloConfigureCustomFieldsInputMappingFormSectionProps {
  /** ID of the action item template this task depends on, if one exists. */
  dependingOnActionItemTemplateId?: number;

  /** Description for this form. Rendered under the title. */
  description?: React.ReactNode;

  /** Placeholder to use in the source field instance input. */
  fieldSelectPlaceholder?: string;

  /**
   * Configuration for selecting fields options in the field instance select input. Selecting fields
   * is enabled iff linked workflows are enabled and this prop is defined.
   */
  fieldOptionsConfig?: {
    /**
     * If defined, the user will be prompted to provide a value when a field option is selected.
     * The form will be configured with the provided props.
     */
    configureFieldProps?: Omit<CustomFieldInstanceSelectConfigureFieldProps, "isMultiValued" | "projection">;

    /** Whether to allow creating a new field from the field instance select dropdown. */
    isCreatingFieldsAllowed: boolean;

    /**
     * Callback invoked when an option representing a field is selected, in order to convert it to a
     * field instance to populate the select field.
     */
    onFieldOptionSelected: (
      field: FieldFields,
      isMultiValued: boolean | undefined,
      projection:
        | {
            selectedRegrelloObjectPropertyIds: number[];
          }
        | undefined,
      value: unknown,
      onFieldInstanceCreated: (fieldInstance: FieldInstanceFields) => void,
      fieldVersion?: SpectrumFieldVersionFields,
    ) => void;

    /**
     * A list of options that should not be displayed in the suggestions menu. Useful for preventing
     * already-selected fields from being selected again, for example.
     */
    omittedFieldInstances?: FieldInstanceFields[];

    /** Name of the current workflow or blueprint. Used in the field options group label. */
    workflowOrBlueprintName?: string;
  };

  /**
   * The form to use for configuring the input controller props in this form section. Attempting to
   * fully enumerate the necessary form fields here using generic types triggered the frustrating
   * `react-hook-form` bug of the type "type instantiation is excessively deep and possibly
   * infinite". All that's really necessary though is that the form passed in has at least `inputs`
   * and `groupInputs` defined so the correct controller props are passed to the custom field select
   * inputs. The input mapping types above are adequate for asserting the actual shape of the values
   * used throughout this component.
   */
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  form: UseFormReturn<any>;

  /** The grouped inputs to render in the form section. */
  groupedInputRows?: CustomFieldsGroupedInputsMappingRow[];

  /** The standalone inputs to render in the form section. */
  inputRows: CustomFieldsInputMappingRow[];

  /**
   * Whether the current task is an automated task.
   *
   * @default false
   */
  isAutomatedTask?: boolean;

  /**
   * Whether the form is disabled.
   *
   * @default false
   */
  isDisabled?: boolean;

  /**
   * Whether the form values are loading.
   *
   * @default false
   */
  isLoading?: boolean;

  /**
   * Whether the current mapping is for a spectrum form
   *
   * @default false
   */
  isSpectrumForm?: boolean;

  /** Headers for the source field and destination field columns. */
  mappingContainerHeaders?: {
    sourceFieldsHeader: string;
    destinationFieldsHeader: string;
  };

  /** Callback invoked when the user changes a field instance option. */
  onChange?: (fieldInstance: FieldInstanceFields | null, fieldIndex: number) => void;

  /** Title for the form. */
  title: string;

  /**
   * The greater workflow context this task exists in. Affects which fields are presented for the
   * user to select from.
   */
  workflowContext: RegrelloFormFieldCustomFieldInstanceSelectWorkflowContext;

  /**
   * Whether to render the component without default spacing and border.
   */
  omitSectionStyling?: boolean;
}

/**
 * Renders a form section where a user can select field instances from a blueprint or workflow to
 * map to destination fields.
 */
export const RegrelloConfigureCustomFieldsInputMappingFormSection = React.memo(
  function RegrelloConfigureCustomFieldsInputMappingFormSectionFn({
    dependingOnActionItemTemplateId,
    description,
    fieldOptionsConfig,
    fieldSelectPlaceholder,
    form,
    groupedInputRows,
    inputRows,
    isAutomatedTask = false,
    isSpectrumForm = false,
    isDisabled = false,
    isLoading = false,
    mappingContainerHeaders,
    onChange,
    title,
    workflowContext,
    omitSectionStyling = false,
  }: RegrelloConfigureCustomFieldsInputMappingFormSectionProps) {
    // (zstanik): These field types have metadata associated with the fields that make it dangerous
    // to allow users to select any field of the same type to map to input fields. For these field
    // types, we have to restrict the user to only select an instance of the same field.
    const unmappableFieldPlugins = useMemo(() => {
      return [SelectFieldPlugin, MultiSelectFieldPlugin, RegrelloObjectFieldPlugin, CurrencyFieldPlugin];
    }, []);

    const getIsInputFieldInstanceRestricted = useCallback(
      (inputFieldInstance: FieldInstanceFields) => {
        return unmappableFieldPlugins.some((fieldPlugin) => fieldPlugin.canProcessField(inputFieldInstance.field));
      },
      [unmappableFieldPlugins],
    );

    const getOptionDisabledReason = useCallback(
      (option: FieldInstanceFields, inputFieldInstance: FieldInstanceFields) => {
        // (zstanik): Disallow passing multi-valued fields into single-valued fields.
        const isInvalidCardinality =
          (option.field.isMultiValued && !inputFieldInstance.field.isMultiValued) ||
          (RegrelloObjectFieldPlugin.canProcessField(inputFieldInstance.field) &&
            option.isMultiValued &&
            !inputFieldInstance.isMultiValued);
        return getIsInputFieldInstanceRestricted(inputFieldInstance) && option.field.id !== inputFieldInstance.field.id
          ? OptionDisabledReason.RESTRICTED_FIELD_TYPE
          : isInvalidCardinality
            ? OptionDisabledReason.INVALID_CARDINALITY
            : undefined;
      },
      [getIsInputFieldInstanceRestricted],
    );

    const getOptionDisabled = useCallback(
      (option: FieldInstanceFields, inputFieldInstance: FieldInstanceFields) => {
        return getOptionDisabledReason(option, inputFieldInstance) != null;
      },
      [getOptionDisabledReason],
    );

    const getOptionTooltip = useCallback(
      (option: FieldInstanceFields, inputFieldInstance: FieldInstanceFields) => {
        const optionDisabledReason = getOptionDisabledReason(option, inputFieldInstance);
        switch (optionDisabledReason) {
          case OptionDisabledReason.INVALID_CARDINALITY:
            return t`Multi-valued fields cannot be mapped to single-valued fields.`;
          case OptionDisabledReason.RESTRICTED_FIELD_TYPE: {
            const fieldDisplayName =
              CustomFieldPluginRegistrar.getPluginForFieldInstance(inputFieldInstance).getFieldDisplayName();
            return t`This ${fieldDisplayName} field cannot be selected as it has a different name and values. Please only select the field with the same name it is being mapped to.`;
          }
          default:
            return EMPTY_STRING;
        }
      },
      [getOptionDisabledReason],
    );

    const getInputRowFieldOptionsConfig: (
      inputFieldInstance: FieldInstanceFields,
    ) => ControlledCustomFieldInstanceSelectFieldOptionsConfig | undefined = useCallback(
      (inputFieldInstance: FieldInstanceFields) => {
        if (fieldOptionsConfig == null) {
          return undefined;
        }

        const isInputFieldInstanceRestricted = getIsInputFieldInstanceRestricted(inputFieldInstance);
        const isInputFieldInstanceMultiValued = inputFieldInstance.isMultiValued ?? undefined;
        const isInputFieldMultiValued = inputFieldInstance.field.isMultiValued;
        const projection = inputFieldInstance.projection ?? undefined;
        const spectrumFieldVersion = inputFieldInstance.spectrumFieldVersion ?? undefined;
        const spectrumFieldValidationType = inputFieldInstance.spectrumFieldVersion?.validationType.validationType;

        // If the input field instance is of a restricted field type, only allow the user to select
        // that field to add to the workflow or blueprint. Additionally, if the input field is not
        // multi-valued, don't allow multi-valued fields to be selected.
        const filterOptions = isInputFieldInstanceRestricted
          ? (option: FieldFields) => option.id === inputFieldInstance.field.id
          : !isInputFieldMultiValued
            ? (option: FieldFields) => !option.isMultiValued
            : undefined;
        return {
          allowMultipleSwitchDisabledHelperText: !isInputFieldMultiValued
            ? t`Multi-valued fields cannot be mapped to single-valued fields.`
            : undefined,
          configureFieldProps:
            fieldOptionsConfig.configureFieldProps != null
              ? {
                  ...fieldOptionsConfig.configureFieldProps,
                  isMultiValued: isInputFieldInstanceMultiValued,
                  projection,
                }
              : undefined,
          defaultAllowMultiple: isInputFieldMultiValued,
          filterOptions,
          isCreatingFieldsAllowed: !isInputFieldInstanceRestricted && fieldOptionsConfig.isCreatingFieldsAllowed,
          onFieldOptionSelected: (
            field: FieldFields,
            value: unknown,
            onFieldInstanceCreated: (fieldInstance: FieldInstanceFields) => void,
          ) => {
            fieldOptionsConfig.onFieldOptionSelected(
              field,
              isInputFieldInstanceMultiValued,
              projection,
              value,
              onFieldInstanceCreated,
              spectrumFieldVersion,
            );
          },
          workflowOrBlueprintName: fieldOptionsConfig.workflowOrBlueprintName,
          spectrumFieldValidationType,
        };
      },
      [fieldOptionsConfig, getIsInputFieldInstanceRestricted],
    );

    const genericCustomFieldMapping = useCallback(
      (fieldRow: CustomFieldsInputMappingRow, fieldIndex: number, groupIndex?: number) => {
        const customFieldPluginForInputFieldInstance =
          fieldRow.input?.fieldInstance != null
            ? CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldRow.input.fieldInstance)
            : undefined;
        const allowedFieldPlugins =
          customFieldPluginForInputFieldInstance != null
            ? [customFieldPluginForInputFieldInstance]
            : fieldRow.input?.valueType != null
              ? CustomFieldPluginRegistrar.getPluginsForPropertyDataType(fieldRow.input.valueType)
              : EMPTY_ARRAY;

        // HACKHACK: because the form has values of type `any` (see comment above in props for
        // explanation as to why), we need to typecast the return value of `form.watch()`.
        // Additionally, ignore this warning check if a `groupIndex` is defined, because it's
        // unclear if this form is ever rendered in the context of grouping inputs, and this warning
        // is only being added for the purposes of linked workflow input mapping.
        const formValues = groupIndex == null ? (form.watch() as { inputs: CustomFieldsInputMappingRow[] }) : undefined;
        const selectedFieldInstance = formValues?.inputs[fieldIndex].fieldInstance;
        const isSelectedFieldInstanceOptional =
          selectedFieldInstance?.inputType === FieldInstanceValueInputType.OPTIONAL;

        const fieldInstanceSelectWarning =
          fieldRow.input?.isRequired && isSelectedFieldInstanceOptional
            ? isSpectrumForm
              ? t`Field is optional but required by the form`
              : t`Field is optional but required to start the linked workflow`
            : undefined;

        let fieldInstanceIconName =
          customFieldPluginForInputFieldInstance?.getIconName(
            fieldRow.input.fieldInstance?.field.fieldType,
            fieldRow.input.fieldInstance?.field,
          ) ?? "text-field";

        if (fieldRow.input.fieldInstance?.spectrumFieldVersion != null) {
          const spectrumFieldPlugin = SpectrumFieldPluginRegistrar.getPluginForSpectrumField(
            fieldRow.input.fieldInstance.spectrumFieldVersion,
          );
          fieldInstanceIconName = spectrumFieldPlugin.getIconName(
            fieldRow.input.fieldInstance?.field.fieldType,
            fieldRow.input.fieldInstance?.field,
          );
        }

        return (
          <div className="flex" data-testid={DataTestIds.CUSTOM_FIELD_INPUT_MAPPING_ROW}>
            <RegrelloControlledFormFieldCustomFieldInstanceSelect
              allowedFieldPlugins={allowedFieldPlugins}
              className="grow-0 shrink-0 basis-50"
              controllerProps={{
                control: form.control,
                name:
                  groupIndex != null
                    ? `groupedInputs.${groupIndex}.inputs.${fieldIndex}.fieldInstance`
                    : `inputs.${fieldIndex}.fieldInstance`,
                rules: fieldRow.input?.isRequired ? ValidationRules.REQUIRED : ValidationRules.NOT_REQUIRED,
              }}
              dataTestId={DataTestIds.CUSTOM_FIELD_INPUT_MAPPING_FIELD_SELECT}
              disabled={isDisabled}
              fieldOptionsConfig={
                fieldRow.input.fieldInstance != null
                  ? getInputRowFieldOptionsConfig(fieldRow.input.fieldInstance)
                  : undefined
              }
              filterCondition={(fieldInstance) => !isFromSplitterTask(fieldInstance)}
              getOptionDisabled={(option) =>
                fieldRow.input?.fieldInstance != null ? getOptionDisabled(option, fieldRow.input.fieldInstance) : false
              }
              getOptionTooltip={(option) =>
                fieldRow.input?.fieldInstance != null
                  ? getOptionTooltip(option, fieldRow.input.fieldInstance)
                  : EMPTY_STRING
              }
              isRequiredAsteriskShown={fieldRow.input?.isRequired}
              isRetainOptionalFieldInstanceInputType={true}
              omittedOptions={fieldOptionsConfig?.omittedFieldInstances}
              onClearClick={() => onChange?.(null, fieldIndex)}
              onValueChange={(_, fieldInstance) => onChange?.(fieldInstance, fieldIndex)}
              optionsConfig={{
                type: "asyncLoaded",
                dependingOnActionItemTemplateId: dependingOnActionItemTemplateId,
                workflowContext,
                isAutomatedTask,
                fieldIds:
                  fieldRow.input.fieldInstance?.field.fieldRestriction != null
                    ? [fieldRow.input.fieldInstance?.field.id]
                    : undefined,
              }}
              placeholder={fieldSelectPlaceholder}
              warning={fieldInstanceSelectWarning}
            />
            <LongArrowHeadRight className="mt-3.5 grow-0 shrink-0 basis-30 text-neutral-icon" />
            <RegrelloChip
              // HACKHACK: give this element a bit of top margin instead of centering it with the
              // right arrow and field instance select because there could be errors underneath the
              // select field of arbitrary length, making the centering look off in such cases.
              className="mt-1"
              icon={{
                type: "iconName",
                iconName: fieldInstanceIconName,
              }}
            >
              {fieldRow.input?.displayName} {fieldRow.input?.isRequired && <span className="text-danger-icon">*</span>}
            </RegrelloChip>
          </div>
        );
      },
      [
        dependingOnActionItemTemplateId,
        fieldOptionsConfig?.omittedFieldInstances,
        fieldSelectPlaceholder,
        form,
        getInputRowFieldOptionsConfig,
        getOptionDisabled,
        getOptionTooltip,
        isAutomatedTask,
        isDisabled,
        isSpectrumForm,
        onChange,
        workflowContext,
      ],
    );

    const fieldMappingComponent = useMemo(() => {
      return (
        <>
          {mappingContainerHeaders != null && (
            <div className="flex">
              <RegrelloTypography className="grow-0 shrink-0 basis-50" variant="h6">
                {mappingContainerHeaders.sourceFieldsHeader}
              </RegrelloTypography>
              <div className="mt-3.5 grow-0 shrink-0 basis-30" />
              <RegrelloTypography variant="h6">{mappingContainerHeaders.destinationFieldsHeader}</RegrelloTypography>
            </div>
          )}

          {inputRows
            .filter((inputRow) => !inputRow.input?.isHidden)
            .map((fieldRow, fieldIndex) => genericCustomFieldMapping(fieldRow, fieldIndex))}
          {groupedInputRows?.map((groupRow, groupIndex) =>
            groupRow.inputs
              .filter((inputRow) => !inputRow.input?.isHidden)
              .map((fieldRow, fieldIndex) => genericCustomFieldMapping(fieldRow, fieldIndex, groupIndex)),
          )}
        </>
      );
    }, [genericCustomFieldMapping, groupedInputRows, inputRows, mappingContainerHeaders]);

    return (
      <RegrelloFormSection
        description={description}
        loading={isLoading}
        omitSectionStyling={omitSectionStyling}
        title={title}
      >
        {fieldMappingComponent}
      </RegrelloFormSection>
    );
  },
);
