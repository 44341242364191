import { EMPTY_STRING, mergeRefs } from "@regrello/core-utils";
import type {
  DocumentFields,
  FieldFields,
  FieldInstanceFields,
  RegrelloObjectInstanceFields,
  WorkflowTemplateForSelectFieldFields,
} from "@regrello/graphql-api";
import React, { useCallback, useState } from "react";
import { type FieldPath, type FieldValues, useController } from "react-hook-form";
import { useDebounce } from "react-use";

import { DEBOUNCE_TIMEOUT } from "../../../../constants/globalConstants";
import type { PartyTypeUnion } from "../../../../utils/parties/PartyTypeUnion";
import type { SpectrumFieldValidationType } from "../../spectrumFields/types/SpectrumFieldValidationType";
import {
  RegrelloFormFieldPartySelect,
  type RegrelloFormFieldPartySelectProps,
} from "../partySelect/RegrelloFormFieldPartySelect";
import {
  RegrelloFormFieldBlueprintSelect,
  type RegrelloFormFieldBlueprintSelectProps,
} from "../RegrelloFormFieldBlueprintSelect";
import { RegrelloFormFieldCheckbox, type RegrelloFormFieldCheckboxProps } from "../RegrelloFormFieldCheckbox";
import {
  type CustomFieldInstanceSelectFieldOptionCallback,
  type CustomFieldInstanceSelectFieldOptionsConfigBase,
  RegrelloFormFieldCustomFieldInstanceSelect,
  type RegrelloFormFieldCustomFieldInstanceSelectProps,
} from "../RegrelloFormFieldCustomFieldInstanceSelect";
import {
  RegrelloFormFieldCustomFieldSelect,
  type RegrelloFormFieldCustomFieldSelectProps,
} from "../RegrelloFormFieldCustomFieldSelect";
import { RegrelloFormFieldDate, type RegrelloFormFieldDatePropsV2 } from "../RegrelloFormFieldDate";
import { RegrelloFormFieldDocument, type RegrelloFormFieldDocumentProps } from "../RegrelloFormFieldDocument";
import { RegrelloFormFieldMultiSelect, type RegrelloFormFieldMultiSelectProps } from "../RegrelloFormFieldMultiSelect";
import { RegrelloFormFieldNumber, type RegrelloFormFieldNumberProps } from "../RegrelloFormFieldNumber";
import { RegrelloFormFieldRadioGroup, type RegrelloFormFieldRadioGroupProps } from "../RegrelloFormFieldRadioGroup";
import {
  RegrelloFormFieldRegrelloObject,
  type RegrelloFormFieldRegrelloObjectProps,
} from "../RegrelloFormFieldRegrelloObject";
import { type RegrelloFormFieldSelectPropsV2, RegrelloFormFieldSelectV2 } from "../RegrelloFormFieldSelectV2";
import {
  type FormSelectFormVersionFields,
  RegrelloFormFieldSpectrumFormSelect,
  type RegrelloFormFieldSpectrumFormSelectProps,
} from "../RegrelloFormFieldSpectrumFormSelect";
import { RegrelloFormFieldSwitch, type RegrelloFormFieldSwitchProps } from "../RegrelloFormFieldSwitch";
import { RegrelloFormFieldTagInput, type RegrelloFormFieldTagInputProps } from "../RegrelloFormFieldTagInput";
import { RegrelloFormFieldTagSelect, type RegrelloFormFieldTagSelectProps } from "../RegrelloFormFieldTagSelect";
import {
  RegrelloFormFieldTagTypeSelect,
  type RegrelloFormFieldTagTypeSelectProps,
} from "../RegrelloFormFieldTagTypeSelect";
import {
  RegrelloFormFieldTextMultiline,
  type RegrelloFormFieldTextMultilineProps,
} from "../RegrelloFormFieldTextMultiline";
import { getErrorProp, type RegrelloControlledFormFieldProps, useInvokeOnChangeOnRequiredStateChange } from "./utils";

// This file exports form fields wrapped with additional functionality that makes them compatible
// with react-hook-form.
//
// TODO (clewis): There's probably a more generic way to express all of these, but the typings got
// convoluted pretty quickly. Note that we don't currently guarantee type safety between the
// provided `name` and the value type in the provided `TFieldValues` - if you can figure that out,
// go for it!

export type FormFieldPropsToOmit = "name" | "onChange" | "value";

export const RegrelloControlledFormFieldCheckboxInternal = function RegrelloControlledFormFieldCheckboxFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  ...checkboxFieldProps
}: RegrelloControlledFormFieldProps<TFieldValues, TName, Omit<RegrelloFormFieldCheckboxProps, FormFieldPropsToOmit>> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: boolean | null) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  // (max): This isn't really necessary as checkboxes are always optional, but including for consistency.
  useInvokeOnChangeOnRequiredStateChange(field, checkboxFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldCheckbox
      {...fieldProps}
      {...checkboxFieldProps}
      {...getErrorProp(fieldState)}
      ref={checkboxFieldProps.ref != null ? mergeRefs(ref, checkboxFieldProps.ref) : ref}
      onChange={(newValue) => {
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
    />
  );
};

export const RegrelloControlledFormFieldCheckbox = React.memo(
  RegrelloControlledFormFieldCheckboxInternal,
) as typeof RegrelloControlledFormFieldCheckboxInternal;

export const RegrelloControlledFormFieldDateInternal = function RegrelloControlledFormFieldDateFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  isDefaultTimeStartOfDay = false,
  onValueChange,
  showTimePicker = false,
  ...dateFieldProps
}: RegrelloControlledFormFieldProps<TFieldValues, TName, Omit<RegrelloFormFieldDatePropsV2, FormFieldPropsToOmit>> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: Date | string | null) => void;
  /**
   * Whether to show a time-picker with the date-picker.
   *
   * @default false
   */
  showTimePicker?: boolean;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, dateFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldDate
      {...fieldProps}
      {...dateFieldProps}
      {...getErrorProp(fieldState)}
      firstSegmentRef={ref} // (clewis): This is the element that needs to be programmatically focusable when this field is missing a value when the form is submitted.
      inputRef={dateFieldProps.inputRef}
      isDefaultTimeStartOfDay={isDefaultTimeStartOfDay}
      onChange={(newDate) => {
        onValueChange?.(field.name, newDate);
        fieldProps.onChange(newDate);
      }}
      showTimePicker={showTimePicker}
    />
  );
};

export const RegrelloControlledFormFieldDate = React.memo(
  RegrelloControlledFormFieldDateInternal,
) as typeof RegrelloControlledFormFieldDateInternal;

const RegrelloControlledFormFieldBlueprintSelectInternal = function RegrelloControlledFormFieldBlueprintSelectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  placeholder,
  ...customFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldBlueprintSelectProps, FormFieldPropsToOmit> & {
    onValueChange?: (name: string, newValue: WorkflowTemplateForSelectFieldFields | null) => void;
  }
>) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, customFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldBlueprintSelect
      {...fieldProps}
      {...customFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={customFieldProps.inputRef != null ? mergeRefs(ref, customFieldProps.inputRef) : ref}
      onChange={(newValue) => {
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
      placeholder={placeholder}
    />
  );
};

export const RegrelloControlledFormFieldBlueprintSelect = React.memo(
  RegrelloControlledFormFieldBlueprintSelectInternal,
) as typeof RegrelloControlledFormFieldBlueprintSelectInternal;

const RegrelloControlledFormFieldCustomFieldSelectV2Internal =
  function RegrelloControlledFormFieldCustomFieldSelectV2Fn<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>,
  >({
    controllerProps,
    onValueChange,
    placeholder,
    ...customFieldProps
  }: RegrelloControlledFormFieldProps<
    TFieldValues,
    TName,
    Omit<RegrelloFormFieldCustomFieldSelectProps, FormFieldPropsToOmit> & {
      onValueChange?: (name: string, newValue: FieldFields | null) => void;
    }
  >) {
    const { field, fieldState } = useController(controllerProps);
    const { ref, ...fieldProps } = field;
    useInvokeOnChangeOnRequiredStateChange(field, customFieldProps.isRequiredAsteriskShown ?? false);

    return (
      <RegrelloFormFieldCustomFieldSelect
        {...fieldProps}
        {...customFieldProps}
        {...getErrorProp(fieldState)}
        inputRef={customFieldProps.inputRef != null ? mergeRefs(ref, customFieldProps.inputRef) : ref}
        onChange={(newValue) => {
          onValueChange?.(field.name, newValue);
          fieldProps.onChange(newValue);
        }}
        onClearClick={() => {
          onValueChange?.(field.name, null);
          fieldProps.onChange(null);
        }}
        placeholder={placeholder}
      />
    );
  };

export const RegrelloControlledFormFieldCustomFieldSelectV2 = React.memo(
  RegrelloControlledFormFieldCustomFieldSelectV2Internal,
) as typeof RegrelloControlledFormFieldCustomFieldSelectV2Internal;

/**
 * Callback invoked when a field option is selected in order to create a field instance from it
 * (necessary to set the value for the select field).
 */
export type ControlledCustomFieldInstanceSelectFieldOptionCallback = (
  field: FieldFields,
  value: unknown,
  onFieldInstanceCreated: (fieldInstance: FieldInstanceFields) => void,
) => void;

/**
 * Configuration for supporting field options in the select dropdown. If defined, the provided or
 * loaded field options will be rendered (wrapped in a mock field instance) after any field
 * instance options. It's up to the caller to properly handle a selected field option.
 */
export type ControlledCustomFieldInstanceSelectFieldOptionsConfig = CustomFieldInstanceSelectFieldOptionsConfigBase & {
  onFieldOptionSelected: ControlledCustomFieldInstanceSelectFieldOptionCallback;
  // Filter spectrum fields, by validation type for performance reasons.
  spectrumFieldValidationType?: SpectrumFieldValidationType;
};

const RegrelloControlledFormFieldCustomFieldInstanceSelectInternal =
  function RegrelloControlledFormFieldCustomFieldInstanceSelectFn<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>,
  >({
    controllerProps,
    onValueChange,
    fieldOptionsConfig,
    placeholder,
    ...customFieldProps
  }: RegrelloControlledFormFieldProps<
    TFieldValues,
    TName,
    Omit<RegrelloFormFieldCustomFieldInstanceSelectProps, FormFieldPropsToOmit | "fieldOptionsConfig"> & {
      /**
       * Configuration for supporting field options in the select dropdown. If defined, the provided or
       * loaded field options will be rendered (wrapped in a mock field instance) after any field
       * instance options. It's up to the caller to properly handle a selected field option.
       */
      fieldOptionsConfig?: ControlledCustomFieldInstanceSelectFieldOptionsConfig;

      /**
       * Whether to hide the form field error icon and message. Added (potentially temporarily
       * depending on Design System updates) for Conditional Branching to support the new error
       * state for stage start date forms.
       */
      isErrorMessageHidden?: boolean;

      /**
       * Whether to hide inactive field instances (i.e., their respective fields have been deleted)
       * in the field instance select dropdown.
       */
      isInactiveFieldInstancesHidden?: boolean;

      onValueChange?: (name: string, newValue: FieldInstanceFields | null) => void;
    }
  >) {
    const { field, fieldState } = useController(controllerProps);
    const { ref, ...fieldProps } = field;

    // Intercept the callback invoked when a field option is selected in order to pass it the
    // callback for manually setting the value on the controlled field.
    const handleFieldOptionSelected: CustomFieldInstanceSelectFieldOptionCallback = useCallback(
      (selectedField: FieldFields, value: unknown) => {
        fieldOptionsConfig?.onFieldOptionSelected(selectedField, value, fieldProps.onChange);
      },
      [fieldProps.onChange, fieldOptionsConfig],
    );

    useInvokeOnChangeOnRequiredStateChange(field, customFieldProps.isRequiredAsteriskShown ?? false);

    return (
      <RegrelloFormFieldCustomFieldInstanceSelect
        {...fieldProps}
        {...customFieldProps}
        {...getErrorProp(fieldState)}
        fieldOptionsConfig={
          fieldOptionsConfig != null
            ? { ...fieldOptionsConfig, onFieldOptionSelected: handleFieldOptionSelected }
            : undefined
        }
        inputRef={customFieldProps.inputRef != null ? mergeRefs(ref, customFieldProps.inputRef) : ref}
        onChange={(newValue) => {
          onValueChange?.(field.name, newValue);
          fieldProps.onChange(newValue);
        }}
        placeholder={placeholder}
      />
    );
  };

export const RegrelloControlledFormFieldCustomFieldInstanceSelect = React.memo(
  RegrelloControlledFormFieldCustomFieldInstanceSelectInternal,
) as typeof RegrelloControlledFormFieldCustomFieldInstanceSelectInternal;

export const RegrelloControlledFormFieldDocumentInternal = function RegrelloControlledFormFieldDocumentFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  ...documentFieldProps
}: RegrelloControlledFormFieldProps<TFieldValues, TName, Omit<RegrelloFormFieldDocumentProps, FormFieldPropsToOmit>> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: DocumentFields[]) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;

  const internalOnBlur = fieldProps.onBlur;
  const propsOnBlur = documentFieldProps.onBlur;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      internalOnBlur();
      propsOnBlur?.(event);
    },
    [internalOnBlur, propsOnBlur],
  );

  useInvokeOnChangeOnRequiredStateChange(field, documentFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldDocument
      {...fieldProps}
      {...documentFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={ref}
      onBlur={handleBlur}
      onChange={(newDocuments) => {
        onValueChange?.(field.name, newDocuments);
        fieldProps.onChange(newDocuments);
      }}
    />
  );
};

export const RegrelloControlledFormFieldDocument = React.memo(
  RegrelloControlledFormFieldDocumentInternal,
) as typeof RegrelloControlledFormFieldDocumentInternal;

export const RegrelloControlledFormFieldMultiSelectInternal = function RegrelloControlledFormFieldMultiSelectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue,
>({
  controllerProps,
  onValueChange,
  ...multiSelectFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldMultiSelectProps<TValue>, FormFieldPropsToOmit>
> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: TValue[]) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;

  const internalOnBlur = fieldProps.onBlur;
  const propsOnBlur = multiSelectFieldProps.onBlur;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      internalOnBlur();
      propsOnBlur?.(event);
    },
    [internalOnBlur, propsOnBlur],
  );

  useInvokeOnChangeOnRequiredStateChange(field, multiSelectFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldMultiSelect
      {...fieldProps}
      {...multiSelectFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={multiSelectFieldProps.inputRef != null ? mergeRefs(ref, multiSelectFieldProps.inputRef) : ref}
      onBlur={handleBlur}
      onChange={(newValue) => {
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
    />
  );
};

export const RegrelloControlledFormFieldMultiSelect = React.memo(
  RegrelloControlledFormFieldMultiSelectInternal,
) as typeof RegrelloControlledFormFieldMultiSelectInternal;

export const RegrelloControlledFormFieldNumberInternal = function RegrelloControlledFormFieldNumberFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  allowUndefined = false,
  controllerProps,
  onValueChange,
  ...numberFieldProps
}: RegrelloControlledFormFieldProps<TFieldValues, TName, Omit<RegrelloFormFieldNumberProps, FormFieldPropsToOmit>> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue?: string) => void;
  allowUndefined?: boolean;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;

  const internalOnBlur = fieldProps.onBlur;
  const propsOnBlur = numberFieldProps.onBlur;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      internalOnBlur();
      propsOnBlur?.(event);
    },
    [internalOnBlur, propsOnBlur],
  );

  useInvokeOnChangeOnRequiredStateChange(field, numberFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldNumber
      {...fieldProps}
      {...numberFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={numberFieldProps.inputRef != null ? mergeRefs(ref, numberFieldProps.inputRef) : ref}
      onBlur={handleBlur}
      onChange={(event) => {
        const newValue = allowUndefined ? event.target.value || undefined : event.target.value;
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
    />
  );
};

export const RegrelloControlledFormFieldNumber = React.memo(
  RegrelloControlledFormFieldNumberInternal,
) as typeof RegrelloControlledFormFieldNumberInternal;

export const RegrelloControlledFormFieldPartySelectInternal = function RegrelloControlledFormFieldPartySelectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  ...partySelectFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldPartySelectProps, FormFieldPropsToOmit>
> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: PartyTypeUnion[]) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;

  const internalOnBlur = fieldProps.onBlur;
  const propsOnBlur = partySelectFieldProps.onBlur;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      internalOnBlur();
      propsOnBlur?.(event);
    },
    [internalOnBlur, propsOnBlur],
  );

  useInvokeOnChangeOnRequiredStateChange(field, partySelectFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldPartySelect
      {...fieldProps}
      {...partySelectFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={partySelectFieldProps.inputRef != null ? mergeRefs(ref, partySelectFieldProps.inputRef) : ref}
      onBlur={handleBlur}
      onChange={(newValue) => {
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
    />
  );
};

export const RegrelloControlledFormFieldPartySelect = React.memo(
  RegrelloControlledFormFieldPartySelectInternal,
) as typeof RegrelloControlledFormFieldPartySelectInternal;

export const RegrelloControlledFormFieldPartySingleSelectInternal = function RegrelloControlledFormFieldPartySelectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  ...partySelectFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldPartySelectProps, FormFieldPropsToOmit>
> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: PartyTypeUnion[]) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;

  const internalOnBlur = fieldProps.onBlur;
  const propsOnBlur = partySelectFieldProps.onBlur;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      internalOnBlur();
      propsOnBlur?.(event);
    },
    [internalOnBlur, propsOnBlur],
  );

  useInvokeOnChangeOnRequiredStateChange(field, partySelectFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldPartySelect
      {...fieldProps}
      {...partySelectFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={partySelectFieldProps.inputRef != null ? mergeRefs(ref, partySelectFieldProps.inputRef) : ref}
      isSingleParty={true}
      onBlur={handleBlur}
      onChange={(newValue) => {
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
    />
  );
};

export const RegrelloControlledFormFieldPartySingleSelect = React.memo(
  RegrelloControlledFormFieldPartySingleSelectInternal,
) as typeof RegrelloControlledFormFieldPartySingleSelectInternal;

export const RegrelloControlledFormFieldRegrelloObjectInternal = function RegrelloControlledFormFieldRegrelloObjectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  ...regrelloObjectFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldRegrelloObjectProps, FormFieldPropsToOmit>
> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: RegrelloObjectInstanceFields[] | undefined) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, regrelloObjectFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldRegrelloObject
      {...fieldProps}
      {...regrelloObjectFieldProps}
      {...getErrorProp(fieldState)}
      onChange={(newValue) => {
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
    />
  );
};

const RegrelloControlledFormFieldRadioGroupInternal = function RegrelloControlledFormFieldRadioGroupFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  ...radioGroupFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldRadioGroupProps, FormFieldPropsToOmit>
> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: string | undefined) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, radioGroupFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldRadioGroup
      {...fieldProps}
      {...radioGroupFieldProps}
      {...getErrorProp(fieldState)}
      onChange={(newValue) => {
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
    />
  );
};

export const RegrelloControlledFormFieldRadioGroup = React.memo(
  RegrelloControlledFormFieldRadioGroupInternal,
) as typeof RegrelloControlledFormFieldRadioGroupInternal;

export const RegrelloControlledFormFieldRegrelloObject = React.memo(
  RegrelloControlledFormFieldRegrelloObjectInternal,
) as typeof RegrelloControlledFormFieldRegrelloObjectInternal;

export type RegrelloControlledFormFieldSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue,
> = RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldSelectPropsV2<TValue>, "name" | "value" | "onChange"> & {
    /**
     * Capture the change event before the rerender happens. This is useful for setting the
     * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
     * different type. This avoids passing in a incorrect data type to the component.
     */
    onValueChange?: (name: string, newValue: TValue | null, oldValue: TValue | null) => void;
  }
>;

const RegrelloControlledFormFieldSelectInternal = function RegrelloControlledFormFieldSelectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue,
>({
  controllerProps,
  onValueChange,
  ...selectFieldProps
}: RegrelloControlledFormFieldSelectProps<TFieldValues, TName, TValue>) {
  const { field, fieldState } = useController(controllerProps);

  const internalOnBlur = field.onBlur;
  const propsOnBlur = selectFieldProps.onBlur;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLButtonElement>) => {
      internalOnBlur();
      propsOnBlur?.(event);
    },
    [internalOnBlur, propsOnBlur],
  );

  const onChange = useCallback(
    (newValue: TValue | null) => {
      onValueChange?.(field.name, newValue, field.value);
      field.onChange(newValue);
    },
    [field, onValueChange],
  );

  useInvokeOnChangeOnRequiredStateChange(field, selectFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldSelectV2
      {...field}
      {...selectFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={selectFieldProps.inputRef}
      onBlur={handleBlur}
      onChange={onChange}
      onClearClick={() => {
        field.onChange(null);
      }}
      selectRef={mergeRefs(field.ref, selectFieldProps.selectRef)}
    />
  );
};

export const RegrelloControlledFormFieldSelect = React.memo(
  RegrelloControlledFormFieldSelectInternal,
) as typeof RegrelloControlledFormFieldSelectInternal;

export const RegrelloControlledFormFieldSwitchInternal = function RegrelloControlledFormFieldSwitchFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  ...switchFieldProps
}: RegrelloControlledFormFieldProps<TFieldValues, TName, Omit<RegrelloFormFieldSwitchProps, FormFieldPropsToOmit>>) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, switchFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldSwitch
      {...fieldProps}
      {...switchFieldProps}
      {...getErrorProp(fieldState)}
      ref={switchFieldProps.ref != null ? mergeRefs(ref, switchFieldProps.ref) : ref}
      checked={field.value}
    />
  );
};

export const RegrelloControlledFormFieldSwitch = React.memo(
  RegrelloControlledFormFieldSwitchInternal,
) as typeof RegrelloControlledFormFieldSwitchInternal;

const RegrelloControlledFormFieldTagSelectInternal = function RegrelloControlledFormFieldTagSelectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  ...tagSelectFieldProps
}: RegrelloControlledFormFieldProps<TFieldValues, TName, Omit<RegrelloFormFieldTagSelectProps, FormFieldPropsToOmit>>) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, tagSelectFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldTagSelect
      {...fieldProps}
      {...tagSelectFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={tagSelectFieldProps.inputRef != null ? mergeRefs(ref, tagSelectFieldProps.inputRef) : ref}
    />
  );
};

export const RegrelloControlledFormFieldTagSelect = React.memo(
  RegrelloControlledFormFieldTagSelectInternal,
) as typeof RegrelloControlledFormFieldTagSelectInternal;

const RegrelloControlledFormFieldTagInputInternal = function RegrelloControlledFormFieldTagInputFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  ...tagInputFieldProps
}: RegrelloControlledFormFieldProps<TFieldValues, TName, Omit<RegrelloFormFieldTagInputProps, FormFieldPropsToOmit>>) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, tagInputFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldTagInput
      {...fieldProps}
      {...tagInputFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={tagInputFieldProps.inputRef != null ? mergeRefs(ref, tagInputFieldProps.inputRef) : ref}
    />
  );
};

export const RegrelloControlledFormFieldTagInput = React.memo(
  RegrelloControlledFormFieldTagInputInternal,
) as typeof RegrelloControlledFormFieldTagInputInternal;

const RegrelloControlledFormFieldTagTypeSelectInternal = function RegrelloControlledFormFieldTagTypeSelectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  ...tagSelectFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldTagTypeSelectProps, FormFieldPropsToOmit>
>) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, tagSelectFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldTagTypeSelect
      {...fieldProps}
      {...tagSelectFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={tagSelectFieldProps.inputRef != null ? mergeRefs(ref, tagSelectFieldProps.inputRef) : ref}
    />
  );
};

export const RegrelloControlledFormFieldTagTypeSelect = React.memo(
  RegrelloControlledFormFieldTagTypeSelectInternal,
) as typeof RegrelloControlledFormFieldTagTypeSelectInternal;

export const RegrelloControlledFormFieldTextMultilineInternal = function RegrelloControlledFormFieldTextMultilineFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  shouldDebounceOnChangeCallbacks = false,
  onValueChange,
  ...textMultilineFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldTextMultilineProps, "name" | "onChange" | "value">
> & {
  /**
   * Whether to debounce the `onValueChange` and `field.onChange` callbacks. This is a performance
   * optimization to prevent the entire form from having to rerender on every keystroke when this
   * input is used within a large, complex form. It internalizes the value of the field, and only
   * propagates that value up on debounce.
   *
   * @default false
   */
  shouldDebounceOnChangeCallbacks?: boolean;

  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: string) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  const [value, setValue] = useState(field.value ?? EMPTY_STRING);

  const internalOnBlur = fieldProps.onBlur;
  const propsOnBlur = textMultilineFieldProps.onBlur;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      internalOnBlur();
      propsOnBlur?.(event);
    },
    [internalOnBlur, propsOnBlur],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);

      if (!shouldDebounceOnChangeCallbacks) {
        onValueChange?.(field.name, event.target.value);
        field.onChange(event.target.value);
      }
    },
    [field, onValueChange, shouldDebounceOnChangeCallbacks],
  );

  const debouncedFormUpdateCallback = useCallback(() => {
    if (!shouldDebounceOnChangeCallbacks) {
      return;
    }

    onValueChange?.(field.name, value);
    field.onChange(value);
  }, [field, onValueChange, shouldDebounceOnChangeCallbacks, value]);

  useDebounce(debouncedFormUpdateCallback, DEBOUNCE_TIMEOUT, [value]);
  useInvokeOnChangeOnRequiredStateChange(field, textMultilineFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldTextMultiline
      {...fieldProps}
      {...textMultilineFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={textMultilineFieldProps.inputRef != null ? mergeRefs(ref, textMultilineFieldProps.inputRef) : ref}
      onBlur={handleBlur}
      onChange={handleChange}
      value={shouldDebounceOnChangeCallbacks ? value : fieldProps.value}
    />
  );
};

export const RegrelloControlledFormFieldTextMultiline = React.memo(
  RegrelloControlledFormFieldTextMultilineInternal,
) as typeof RegrelloControlledFormFieldTextMultilineInternal;

const RegrelloControlledFormFieldSpectrumFormSelectInternal = function RegrelloControlledFormFieldSpectrumFormSelectFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  placeholder,
  onValueChange,
  ...customFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldSpectrumFormSelectProps, FormFieldPropsToOmit> & {
    onValueChange?: (name: string, newValue: FormSelectFormVersionFields | null) => void;
  }
>) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;
  useInvokeOnChangeOnRequiredStateChange(field, customFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldSpectrumFormSelect
      {...fieldProps}
      {...customFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={customFieldProps.inputRef != null ? mergeRefs(ref, customFieldProps.inputRef) : ref}
      onChange={(newValue) => {
        onValueChange?.(field.name, newValue);
        fieldProps.onChange(newValue);
      }}
      placeholder={placeholder}
    />
  );
};

export const RegrelloControlledFormFieldSpectrumFormSelect = React.memo(
  RegrelloControlledFormFieldSpectrumFormSelectInternal,
) as typeof RegrelloControlledFormFieldSpectrumFormSelectInternal;
