import { t } from "@lingui/macro";
import { clsx, EMPTY_ARRAY, EMPTY_STRING, formatDateTimeString, mergeRefs, queueMacrotask } from "@regrello/core-utils";
import {
  type DocumentFields,
  useCreateDocumentMutation,
  useRetrieveDocumentDownloadUrlQueryLazyQuery,
} from "@regrello/graphql-api";
import { RegrelloPartyAvatar } from "@regrello/ui-app-molecules";
import {
  RegrelloCheckbox,
  RegrelloChip,
  RegrelloControlWithLabel,
  RegrelloField,
  RegrelloIcon,
  RegrelloSignatureInput,
  type RegrelloSignatureInputProps,
  type RegrelloSize,
  RegrelloSpinner,
  RegrelloTypography,
} from "@regrello/ui-core";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useMount } from "react-use";

import { ResponseStatus } from "../../../constants/globalConstants";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { getBasePartyTypeUnion } from "../../../utils/parties/partyUtils";
import { toFlatParty } from "../../../utils/parties/toFlatParty";
import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";

export interface RegrelloFormFieldSignatureProps
  extends Pick<
    RegrelloFormFieldBaseProps<DocumentFields[]>,
    | "className"
    | "dataTestId"
    | "disabled"
    | "error"
    | "helperText"
    | "infoTooltipText"
    | "infoTooltipIconName"
    | "infoTooltipVariant"
    | "isDeleted"
    | "isRequiredAsteriskShown"
    | "labelPlacement"
    | "label"
    | "labelWidth"
    | "name"
    | "variant"
  > {
  inputRef?: React.Ref<HTMLInputElement>;
  isEmphasized?: boolean;
  isRequiredAsteriskShown?: boolean;
  onChange: (newValue: DocumentFields[]) => void;
  onUploadStart?: () => void;
  onUploadFinish?: () => void;
  showLegalNotice?: boolean;
  showPreviewOnly?: boolean;
  size?: RegrelloSize;
  value: DocumentFields[];
}

export const RegrelloFormFieldSignature = React.memo(function RegrelloFormFieldSignatureFn({
  className,
  dataTestId,
  disabled,
  error,
  helperText,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  inputRef,
  isDeleted,
  isEmphasized,
  isRequiredAsteriskShown,
  labelPlacement,
  label,
  labelWidth,
  name,
  onChange,
  onUploadStart,
  onUploadFinish,
  showLegalNotice = false,
  showPreviewOnly = false,
  size = "large",
  value,
  variant,
}: RegrelloFormFieldSignatureProps) {
  const { handleError } = useErrorHandler();
  const [signature, setSignature] = useState<string | null>();
  const [consent, setConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputRefInternal = useRef<HTMLInputElement>(null);
  const checkboxRef = useRef<HTMLButtonElement>(null);

  const intent: RegrelloSignatureInputProps["intent"] = error != null ? "danger" : isEmphasized ? "warning" : undefined;

  const [createAttachmentAsync] = useCreateDocumentMutation({
    onError: () => {
      handleError(
        t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
        {
          toastMessage: t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
        },
      );
    },
  });

  const [retrieveDocumentDownloadUrlAsyncLazy] = useRetrieveDocumentDownloadUrlQueryLazyQuery({
    onError: (queryError) => {
      handleError(queryError, {
        toastMessage: t`Failed to download document. Please try again, or contact a Regrello admin if you continue to see this error.`,
      });
    },
    // We always need a fresh link
    fetchPolicy: "no-cache",
  });

  const fetchSignedUrl = (signedUrl: string) => {
    void fetch(signedUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setSignature(e.target?.result as string);
          setConsent(true);
          setIsLoading(false);
        };
        reader.readAsDataURL(blob);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // On mount try to fetch the signature for the preview
  // (dosipiuk): we want this to only run once on mount in case there is already a file
  useMount(() => {
    if (value[0] != null) {
      setIsLoading(true);
      if (value[0]?.currentVersion.externalLink != null) {
        fetchSignedUrl(value[0].currentVersion.externalLink);
      } else if (value[0] != null) {
        void retrieveDocumentDownloadUrlAsyncLazy({
          variables: {
            documentVersionId: value[0].currentVersion.id,
          },
          onError: () => {
            setIsLoading(false);
          },
          onCompleted: (data) => {
            if (data.retrieveDocumentDownloadUrl?.signedUrl != null) {
              fetchSignedUrl(data.retrieveDocumentDownloadUrl?.signedUrl);
            }
          },
        });
      }
    }
  });

  const onUncheck = useCallback(async () => {
    onUploadStart?.();

    onChange(EMPTY_ARRAY);
    onUploadFinish?.();

    // Re-focus so the user can Tab to the next thing in the form. Wait a cycle so the checkbox can
    // be rendered again after the loading spinner disappears.
    queueMacrotask(() => checkboxRef.current?.focus());
  }, [onChange, onUploadFinish, onUploadStart]);

  const onUpload = useCallback(
    async (shouldUpload: boolean) => {
      if (!shouldUpload) {
        // Noop for the case when we `uncheck` consent
        return;
      }

      if (signature == null) {
        handleError(t`Upload triggered without a signature`, {
          toastMessage: t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
        });
        return;
      }

      setIsLoading(true);

      const signatureResponse = await fetch(signature);
      const signatureBlob = await signatureResponse.blob();
      // WARNING (clewis): This prefix and suffix are currently hackily used on the backend to
      // identify signature fields for form PDF generation. We're working on a better way, but for
      // now, please don't change these.
      const signatureFile = new File([signatureBlob], `signature-${crypto.randomUUID()}.png`, {
        type: "image/png",
        lastModified: Date.now(),
      });

      if (signatureFile == null) {
        handleError(t`Could not convert signature dataUrl to file`, {
          toastMessage: t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
        });
      } else {
        onUploadStart?.();

        const response = await createAttachmentAsync({
          variables: {
            filename: signatureFile.name,
          },
        });

        if (response.data?.createDocument?.document == null || response.data.createDocument.signedUrl == null) {
          handleError(t`Could not create signature document attachment`, {
            toastMessage: t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
          });
        } else {
          const { document, signedUrl } = response.data.createDocument;

          const fetchResponse = await fetch(signedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: signatureFile,
          });

          if (fetchResponse.status !== ResponseStatus.SUCCESS) {
            handleError(fetchResponse.statusText, {
              toastMessage: t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
            });
          }

          onChange([document]);
          onUploadFinish?.();
        }
      }
      setIsLoading(false);

      // Re-focus so the user can Tab to the next thing in the form. Wait a cycle so the checkbox
      // can be rendered again after the loading spinner disappears.
      queueMacrotask(() => checkboxRef.current?.focus());
    },
    [createAttachmentAsync, handleError, onChange, onUploadFinish, onUploadStart, signature],
  );

  const signatureFieldElement = useMemo(() => {
    return (
      <div className="flex flex-col gap-1">
        {showLegalNotice ? (
          <div
            className={clsx(
              "bg-background border-warning-solid border rounded py-1.5 px-2 flex items-start gap-1.5 text-warning-textMuted",
              {
                "opacity-30": disabled,
              },
            )}
          >
            <RegrelloIcon className="pt-0.5" iconName="alert" size="x-small" />
            <RegrelloTypography>
              {t`This signature may not be legally binding depending on your jurisdiction.`}{" "}
              <a
                className="text-primary-textMuted hover:underline"
                href={t`https://www.notion.so/regrello/Signature-Fields-a80486d054944472a4695fdb0cc7d62e`}
                rel="noreferrer"
                target="_blank"
              >
                {t`Learn more`}
              </a>
            </RegrelloTypography>
          </div>
        ) : null}
        <RegrelloSignatureInput
          allowClearWhenDisabled={!disabled}
          dataUrl={signature || undefined}
          disabled={disabled || isLoading || consent}
          hideClearButton={showPreviewOnly}
          inputRef={mergeRefs(inputRef, inputRefInternal)}
          intent={intent}
          onEnd={(dataUrl) => {
            if (dataUrl == null) {
              setConsent(false);
              onChange([]);
            }
            setSignature(dataUrl);

            // Re-focus so the user can Tab to the checkbkox.
            inputRefInternal.current?.focus();
          }}
          size={size}
        />
        {showPreviewOnly && value[0] != null ? (
          <div className="flex items-center gap-2">
            {value[0].currentVersion.createdBy ? (
              <RegrelloChip size="small">
                <RegrelloPartyAvatar
                  party={toFlatParty(getBasePartyTypeUnion(value[0].currentVersion.createdBy))}
                  size="small"
                />
              </RegrelloChip>
            ) : null}
            {formatDateTimeString(value[0].currentVersion.createdAt)}
          </div>
        ) : (
          <RegrelloControlWithLabel
            control={
              isLoading ? (
                <div className="w-4.5 flex items-center">
                  <RegrelloSpinner size="small" />
                </div>
              ) : (
                <RegrelloCheckbox
                  ref={checkboxRef}
                  checked={consent}
                  disabled={disabled || signature == null}
                  hasError={error != null}
                  onCheckedChange={(isChecked) => {
                    setConsent(isChecked);
                    if (isChecked) {
                      void onUpload(isChecked);
                    } else {
                      void onUncheck();
                    }
                  }}
                />
              )
            }
            disabled={disabled || signature == null}
            label={t`By checking this box, I agree that this electronic signature will be as valid as handwritten signatures to the extent allowed by local law.`}
          />
        )}
      </div>
    );
  }, [
    consent,
    disabled,
    error,
    inputRef,
    intent,
    isLoading,
    onChange,
    onUncheck,
    onUpload,
    showLegalNotice,
    showPreviewOnly,
    signature,
    size,
    value,
  ]);

  if (variant === "spectrum") {
    return (
      <RegrelloField
        dataTestId={dataTestId}
        deleted={isDeleted}
        description={infoTooltipText}
        errorMessage={error}
        helperText={typeof helperText === "string" ? helperText : undefined}
        label={typeof label === "string" ? label : EMPTY_STRING}
        name={name ?? EMPTY_STRING}
        required={isRequiredAsteriskShown}
      >
        {() => {
          return signatureFieldElement;
        }}
      </RegrelloField>
    );
  }

  return (
    <RegrelloFormFieldLayout
      className={className}
      dataTestId={dataTestId}
      error={error}
      infoTooltipIconName={infoTooltipIconName}
      infoTooltipText={infoTooltipText}
      infoTooltipVariant={infoTooltipVariant}
      isDeleted={isDeleted}
      isRequiredAsteriskShown={isRequiredAsteriskShown}
      label={label}
      labelPlacement={labelPlacement}
      labelWidth={labelWidth}
      variant={variant}
    >
      {signatureFieldElement}
    </RegrelloFormFieldLayout>
  );
});
