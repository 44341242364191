import { clsx, type WithClassName } from "@regrello/core-utils";
import React from "react";

export interface RegrelloChipListProps extends WithClassName {
  /** An array of `RegrelloChip` elements to display. */
  children: React.ReactNodeArray;

  /**
   * Whether to disable wrapping, forcing all chips onto one line. If setting this to `true`, you
   * must also set `disableShrinking` to `true` on each chip to prevent chips from shrinking.
   *
   * @default false
   */
  disableWrapping?: boolean;
}

/**
 * A standard component for displaying lists of `RegrelloChip` elements. Elegantly handles
 * wrapping and shrinking of long chips.
 */
export const RegrelloChipList = React.memo<RegrelloChipListProps>(function RegrelloChipListFn({
  children,
  className,
  disableWrapping = false,
}) {
  return (
    <div
      className={clsx(
        "flex gap-1",
        {
          // (clewis): min-w-0 forces long chips to shrink to the width of the container. When
          // wrapping is disabled, though, we exclude it to avoid shrinking chips.
          "flex-wrap min-w-0": !disableWrapping,
          "overflow-hidden": disableWrapping,
        },
        className,
      )}
    >
      {children}
    </div>
  );
});
