const MINIMUM_CHARACTER_TYPE_REQUIRED = 3;

export interface PasswordRules {
  minLength: boolean;
  hasValidCharacterCombination: boolean;
  hasLowercaseLetter: boolean;
  hasUppercaseLetter: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  hasNoConsecutiveCharacters: boolean;
}
export function validatePassword(password: string): PasswordRules {
  const hasLowercaseLetter = passwordRuleHasLowerCaseLetter(password);
  const hasUppercaseLetter = passwordRuleHasUpperCaseLetter(password);
  const hasNumber = passwordRuleHasNumber(password);
  const hasSpecialCharacter = passwordRuleHasSpecialCharacter(password);
  let usedCharacterType = 0;
  if (hasLowercaseLetter) {
    usedCharacterType += 1;
  }
  if (hasUppercaseLetter) {
    usedCharacterType += 1;
  }
  if (hasNumber) {
    usedCharacterType += 1;
  }
  if (hasSpecialCharacter) {
    usedCharacterType += 1;
  }
  return {
    minLength: passwordRuleMinLength(password),
    hasValidCharacterCombination: usedCharacterType >= MINIMUM_CHARACTER_TYPE_REQUIRED,
    hasLowercaseLetter,
    hasUppercaseLetter,
    hasNumber,
    hasSpecialCharacter,
    hasNoConsecutiveCharacters: passwordRuleNoConsecutiveCharacter(password),
  };
}

export function passwordRuleMinLength(password: string) {
  return password.length >= 15;
}

export function passwordRuleHasLowerCaseLetter(password: string) {
  return /[a-z]/.test(password);
}

export function passwordRuleHasUpperCaseLetter(password: string) {
  return /[A-Z]/.test(password);
}

export function passwordRuleHasNumber(password: string) {
  return /\d/.test(password);
}

export function passwordRuleHasSpecialCharacter(password: string) {
  return /[!@#$%^&*]/.test(password);
}

export function passwordRuleNoConsecutiveCharacter(password: string) {
  return password.length > 0 && !/([a-z0-9!@#$%^&*])\1\1/i.test(password);
}
