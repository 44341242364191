import { t } from "@lingui/macro";
import type { WithClassName } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { ConditionConnective } from "@regrello/graphql-api";
import type { UseFormReturn } from "react-hook-form";

import { ValidationRules } from "../../../../../constants/globalConstants";
import { RegrelloControlledFormFieldSelect } from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import type { RegrelloScheduleTimeFormSectionBase, ScheduleTimeKeys } from "./RegrelloScheduleTimeFormSectionBase";
import { START_CONDITION_CONNECTIVES } from "./scheduleTimeConstants";

type ConnectiveComponentProps = WithClassName & {
  /**
   * connective path points to the and/or being configured in the form.
   */
  connectivePath: `${ScheduleTimeKeys.CONDITIONS}.connective`;
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;
  isDisabled: boolean;
};

/**
 * ConnectiveComponent is a piece of the form used to configure condition groups. This component simply lets
 * the user decide between and/or connectives.
 */
export function ConnectiveComponent({ form, connectivePath, isDisabled, className }: ConnectiveComponentProps) {
  return (
    <RegrelloControlledFormFieldSelect
      className={className}
      controllerProps={{
        control: form.control,
        name: connectivePath,
        rules: ValidationRules.REQUIRED,
      }}
      dataTestId={DataTestIds.START_DEPENDENCY_CONDITION_CONNECTIVE_SELECT}
      disabled={isDisabled}
      getOptionLabel={(option) => (option === ConditionConnective.AND ? t`and` : t`or`)}
      isFilterable={false}
      options={START_CONDITION_CONNECTIVES}
    />
  );
}
