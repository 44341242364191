import { t } from "@lingui/macro";
import { clsx } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  RegrelloButton,
  RegrelloLinkify,
  RegrelloTooltip,
  RegrelloTooltippedText,
  RegrelloTypography,
} from "@regrello/ui-core";
import React, { useCallback } from "react";

export interface RegrelloFormFieldSelfContainedFormDisplayValueProps {
  /** The read-only summary of the current field value. */
  children: React.ReactNode;
  editButtonRef: React.Ref<HTMLButtonElement>;

  /** @default false */
  isEditingDisabled?: boolean;
  isEditButtonInline?: boolean;
  isEditSaving?: boolean;

  /**
   * The maximum number of lines to permit for the display value. Display values longer than this
   * will be accessible in full via a tooltip on hover.
   */
  maxLines?: number;

  onEditButtonClick?: React.MouseEventHandler<HTMLElement>;
  typography?: "h3" | "h5" | "body";

  /**
   * Whether the form field is rendered by a spectrum form.
   *
   * @default "default"
   */
  variant?: "default" | "spectrum";
}

/**
 * Displays a read-only display value for a given form field's self-contained form, along with an
 * 'Edit' button at the end.
 */
export const RegrelloFormFieldSelfContainedFormDisplayValue = React.memo(
  function RegrelloFormFieldSelfContainedFormDisplayValueFn({
    children,
    editButtonRef,
    isEditingDisabled = false,
    isEditButtonInline = false,
    isEditSaving = false,
    onEditButtonClick,
    maxLines,
    typography,
    variant = "default",
  }: RegrelloFormFieldSelfContainedFormDisplayValueProps) {
    const editButton = (
      <RegrelloButton
        ref={editButtonRef}
        className={clsx(
          "min-w-0 py-0 px-1.5",
          "mt-1.5", // (clewis): Align with value baseline, but stick to top if value wraps to multiple lines.
          {
            "px-1": isEditButtonInline,
            "mt-2": typography === "h5", // (clewis): Vertically center with a single-line value, but stay top-aligned when there is a multi-line value.
            "mt-3": typography === "h3", // (clewis): Vertically center with a single-line value, but stay top-aligned when there is a multi-line value.
            "mt-0 p-0": typography === "h3" && variant === "spectrum",
            "h-6": variant === "default",
          },
        )}
        dataTestId={DataTestIds.DETAIL_DIALOG_FIELD_GENERIC_EDIT_BUTTON}
        iconOnly={true}
        loading={isEditSaving}
        onClick={onEditButtonClick}
        size={variant === "spectrum" && typography === "h3" ? "small" : "x-small"}
        startIcon="edit-outline"
        variant="ghost"
      />
    );

    const maybeApplyMaxLines = useCallback(
      (childrenInternal: React.ReactNode) => {
        return maxLines != null ? (
          <RegrelloTooltippedText lines={maxLines}>{childrenInternal}</RegrelloTooltippedText>
        ) : (
          childrenInternal
        );
      },
      [maxLines],
    );

    return (
      <div className={clsx("flex items-start grow")}>
        {typography === "body"
          ? maybeApplyMaxLines(
              <RegrelloTypography
                // (clewis): Apply min-w-0 to ensure that flex children shrink to fit the available
                // width on small viewports.
                className={clsx("grow break-words min-h-9 pt-2.25 pb-1.75 px-1.5 min-w-0", {
                  "grow-0": isEditButtonInline,
                })}
                component="div"
                dataTestId={DataTestIds.DETAIL_DIALOG_FIELD_GENERIC_DISPLAY_VALUE}
                variant="body"
              >
                <RegrelloLinkify>{children}</RegrelloLinkify>
              </RegrelloTypography>,
            )
          : maybeApplyMaxLines(
              <RegrelloTypography
                className={clsx("grow break-words min-h-9 pt-2.25 pb-1.75 px-1.5 min-w-0", {
                  "grow-0": isEditButtonInline,
                  "min-h-0 p-0 pr-1.5": variant === "spectrum" && typography === "h3",
                })}
                component="div"
                dataTestId={DataTestIds.DETAIL_DIALOG_FIELD_GENERIC_DISPLAY_VALUE}
                variant={typography}
              >
                <RegrelloLinkify>{children}</RegrelloLinkify>
              </RegrelloTypography>,
            )}
        {!isEditingDisabled && (
          <div className="flex-none">
            <RegrelloTooltip content={t`Edit`} side="top">
              {/* We need a wrapper element here because the edit button
              could be disabled and a disabled element does not fire events.
              The RegrelloTooltip component needs to listen to the child element's
              events to display the title */}
              <div>{editButton}</div>
            </RegrelloTooltip>
          </div>
        )}
      </div>
    );
  },
);
