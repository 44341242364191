import { createNonNullableContext } from "../../../utils/createNonNullableContext";
import type { RegrelloAuthenticationContext } from "./RegrelloAuthenticationProvider";
import type { UserContextType } from "./UserContext";

const [useRegrelloAuthV2, RegrelloAuthenticationProviderInternal] =
  createNonNullableContext<RegrelloAuthenticationContext>();

const [useUser, Provider] = createNonNullableContext<UserContextType>();

export { Provider, RegrelloAuthenticationProviderInternal, useRegrelloAuthV2, useUser };
