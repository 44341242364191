import type { WithClassName, WithDataTestId } from "@regrello/core-utils";
import type { RegrelloIconName, RegrelloTooltipProps } from "@regrello/ui-core";

import type { RegrelloFormFieldSelfContainedFormProps } from "./RegrelloFormFieldLayout";

export enum RegrelloFormFieldLabelPlacement {
  START = "start",
  TOP = "top",
}

export interface RegrelloFormFieldBaseProps<T> extends WithClassName, WithDataTestId {
  /** Whether the field is non-interactive. */
  disabled?: boolean;

  /** An error message to display. If provided, the field will render with an "error" style. */
  error?: string;

  /** Explanatory text to display beneath the field. */
  helperText?: React.ReactNode;

  /**
   * If defined, an info icon will be rendered to the right of the field label with this text as
   * tooltip content.
   */
  infoTooltipText?: string;

  /**
   * The name of the tooltip triggering icon
   */
  infoTooltipIconName?: RegrelloIconName;

  /**
   * The visual treatment to give to the tooltip.
   * - `"default"`: A standard dark-background tooltip.
   * - `"popover"`: A light-background tooltip with a border.
   *
   * @default "default"
   */
  infoTooltipVariant?: RegrelloTooltipProps["variant"];

  /** Excludes the default margin around the field. Useful if rendering a stack of checkboxes. */
  isDefaultMarginsOmitted?: boolean;

  /** Whether to show a tooltiped deleted badge besides the label. */
  isDeleted?: boolean;

  /**
   * Whether to visually emphasize this field with a yellow bar on the start side. This yellow bar
   * is meant to attract user attention and indicate that the user needs to enter a value.
   */
  isEmphasized?: boolean;

  /**
   * Whether the label is vertically center-aligned with the input. If not provided, the input is
   * aligned with the top of the input by default.
   */
  isLabelVerticallyCentered?: boolean;

  /**
   * Whether the field should display a visual indicator that it is required. Actual validation is
   * left to the caller.
   */
  isRequiredAsteriskShown?: boolean;

  /**
   * Whether the label is stacked above the input field or is in the same line with the input
   */
  labelPlacement?: RegrelloFormFieldLabelPlacement;

  /** The display label of the form field. */
  label?: React.ReactNode;

  /** The width to allot to the form field's label. */
  labelWidth?: number | string;

  /**
   * The name of this form field, used for internal tracking. Should be unique within this
   * `<form>`.
   */
  name?: string;

  /**
   * Whether to display an explicit 'Edit' button. If this object is provided, the field value will
   * be read-only and non-interactive until the 'Edit' button is clicked. The editable form field
   * will then be shown in a popover.
   *
   * **Note:** This object does not need to be memoized.
   */
  selfContainedForm?: Omit<RegrelloFormFieldSelfContainedFormProps<T>, "getDisplayValue">;

  /** Whether the form field is rendered by a spectrum form. */
  variant?: "default" | "spectrum";

  /**
   * A warning message to display. Errors take priority, and if one is defined that will be rendered
   * instead.
   */
  warning?: string;
}
