/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable react-refresh/only-export-components */
import "@unocss/reset/tailwind.css";
import "virtual:uno.css";

import env from "@env";
import { RegrelloNonIdealStateWithRefresh } from "@regrello/ui-core";
import { browserTracingIntegration, init, replayIntegration } from "@sentry/react";
import { createRoot } from "react-dom/client";

import { App } from "./App";
import { RegrelloErrorBoundary } from "./ui/molecules/errorBoundary/RegrelloErrorBoundary";
import { onBeforeSentryCaptureOnAppRoot } from "./utils/sentryScopeUtils";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;
const root = createRoot(container);

function RootComponent() {
  return (
    <RegrelloErrorBoundary
      beforeCapture={onBeforeSentryCaptureOnAppRoot}
      fallback={
        <RegrelloNonIdealStateWithRefresh
          // (swerner) Note: this is outside of the Lingui Provider for i18n and cannot be translated.
          description="Please refresh, or contact a Regrello admin if you continue to see this error."
          title="Something went wrong"
        />
      }
    >
      <App />
    </RegrelloErrorBoundary>
  );
}

init({
  dsn: env.ReactAppSentryDSN,
  environment: env.Environment,
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: env.SentryReplayEnableMasking,
      blockAllMedia: env.SentryReplayEnableMasking,
      // (hchen): After some sampling with manually clicking around the app, looks like it can
      // get up to 3000+ mutation with in the same event loop when rendering opening a task on
      // home page on top of the table. In most cases this number is below 100.
      mutationBreadcrumbLimit: 1000,
      mutationLimit: 5000,
    }),
  ],
  // biome-ignore lint/correctness/noUndeclaredVariables: Injected by Vite - workspace/apps/regrello-app/vite.config.mts
  release: __INJECTED_APP_VERSION__.replace(/[() ]/g, "-"),
  tracesSampleRate: Number.isNaN(Number.parseFloat(env.SentrySampleRate))
    ? 0.0
    : Number.parseFloat(env.SentrySampleRate),

  replaysSessionSampleRate: Number.isNaN(Number.parseFloat(env.SentryReplaySessionSampleRate))
    ? 0.0
    : Number.parseFloat(env.SentryReplaySessionSampleRate),
  replaysOnErrorSampleRate: Number.isNaN(Number.parseFloat(env.SentryReplayOnErrorSampleRate))
    ? 0.0
    : Number.parseFloat(env.SentryReplayOnErrorSampleRate),
});

root.render(<RootComponent />);
