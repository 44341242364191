import { clsx } from "@regrello/core-utils";
import type { RegrelloIconName } from "@regrello/ui-core";
import React from "react";

import { RegrelloContentChip } from "../../../atoms/contentChips/_internal/RegrelloContentChip";
import type {
  InteractiveChipAttributesSpec,
  InteractiveChipProps,
} from "../../textEditors/_internal/types/interactiveChipTypes";

export interface TextEditorFieldChipAttributesSpec extends InteractiveChipAttributesSpec {
  fieldName: { default: string };
  fieldId: { default: number };
  regrelloObjectPropertyName: { default: string | undefined };
  regrelloObjectPropertyId: { default: number | undefined };
  iconName: { default: RegrelloIconName | undefined };
}

export type RegrelloTextEditorFieldChipProps = InteractiveChipProps<TextEditorFieldChipAttributesSpec> & {
  /**
   * An optional display value to render in place of the field name.
   */
  displayValue?: string;

  /**
   * If defined, the chip will be rendered with a faint gold background and this tooltip text to
   * indicate it's a "special" chip in an info state.
   */
  infoStateTooltipText?: string;

  /**
   * Whether the field chip is being rendered as a drag preview. Affects styling of the underlying
   * `RegrelloContentChip`.
   *
   * @default false
   */
  isDragPreview?: boolean;

  /**
   * Whether the content chip icon is hidden.
   *
   * @default false
   */
  isIconHidden?: boolean;
};

/**
 * An interactive chip representing a field, intended to be used as field placeholders in
 * `RegrelloTextEditor-` components.
 */
export const RegrelloTextEditorFieldChip = React.memo<RegrelloTextEditorFieldChipProps>(
  function RegrelloTextEditorFieldChipFn(props: RegrelloTextEditorFieldChipProps) {
    return (
      <RegrelloContentChip
        classNames={{
          root: clsx("my-0.5 mx-0 min-w-0", { "bg-background": props.displayValue != null }),
        }}
        dndProps={props.dndProps}
        icon={props.isIconHidden ? undefined : props.iconName}
        infoStateTooltipText={props.infoStateTooltipText}
        isDragPreview={props.isDragPreview}
        isPreview={props.isPreview}
        isSelected={props.isSelected}
        name={props.displayValue ?? props.fieldName}
        onDelete={props.onDelete}
      />
    );
  },
);
