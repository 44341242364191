import { t } from "@lingui/macro";
import { ComparatorResult, EMPTY_ARRAY } from "@regrello/core-utils";
import { type TagTypeFields, useTagTypesQueryLazyQuery } from "@regrello/graphql-api";
import React, { useCallback, useEffect, useState } from "react";

import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { CreateTagTypeDialog } from "../../views/modals/formDialogs/tags/CreateTagTypeDialog";
import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloSelectV2AddOption } from "./_internal/selectOptions/RegrelloSelectV2AddOption";
import { RegrelloSelectV2ErrorOption } from "./_internal/selectOptions/RegrelloSelectV2ErrorOption";
import {
  type RegrelloFormFieldSelectPropsV2,
  RegrelloFormFieldSelectV2,
  type RegrelloSelectChangeReason,
} from "./RegrelloFormFieldSelectV2";

export interface RegrelloFormFieldTagTypeSelectProps
  extends RegrelloFormFieldBaseProps<TagTypeFields | null>,
    Pick<
      RegrelloFormFieldSelectPropsV2<TagTypeFields>,
      "dataTestIdForMenuInput" | "inputRef" | "onChange" | "onClearClick" | "size" | "value"
    > {
  /**
   * Whether to allow creating new tags and tag types.
   *
   * @default false
   */
  allowCreateTagTypes?: boolean;
}

/**
 * A form field that allows the user to select from all tag types in the system, or create a new tag
 * type in the system.
 */
export const RegrelloFormFieldTagTypeSelect = React.memo(function RegrelloFormFieldTagTypeSelectFn({
  allowCreateTagTypes,
  className,
  onChange, // (clewis): Pull this out because we need to override it.
  ...selectProps
}: RegrelloFormFieldTagTypeSelectProps) {
  // (clewis): As a UX nicety, we want to pre-populate the typed value in the add dialog. This is
  // tricky because the inputValue is cleared as soon as we select an option, so we have to track
  // the dialog's defaultValue separately.
  const [defaultValueForAddDialog, setDefaultValueForAddDialog] = useState<string>("");
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [loadedOptions, setLoadedOptions] = useState<TagTypeFields[]>(EMPTY_ARRAY);

  const { handleError } = useErrorHandler();

  const [getTagTypesAsync, tagTypesQueryResult] = useTagTypesQueryLazyQuery({
    onError: (error) => {
      handleError(error);
    },
    fetchPolicy: "no-cache",
  });

  // Update and sort the locally stored tag types when the query finishes loading.
  useEffect(() => {
    if (tagTypesQueryResult.loading) {
      return;
    }

    if (tagTypesQueryResult.error != null || tagTypesQueryResult.data?.tagTypes == null) {
      setLoadedOptions([]);
      return;
    }

    // (clewis): Need to spread before sorting, because the loaded array is readonly. Also need to
    // include the "Add" option in order for it to emit successfully via onChange.
    setLoadedOptions(sortOptions([...tagTypesQueryResult.data.tagTypes]));
  }, [tagTypesQueryResult]);

  const handleAutocompleteOpen = useCallback(() => {
    // Reload the tag types when the autocomplete opens.
    void getTagTypesAsync();
  }, [getTagTypesAsync]);

  const handleChange = useCallback(
    (nextValue: TagTypeFields | null, reason: RegrelloSelectChangeReason) => {
      onChange(nextValue, reason);
    },
    [onChange],
  );

  const handleAddDialogClose = useCallback(() => {
    setIsAddDialogOpen(false);
  }, []);

  const handleTagTypeAdded = useCallback(
    (newTagType: TagTypeFields) => {
      setLoadedOptions(sortOptions([...loadedOptions, newTagType]));
      onChange(newTagType, "create-option");
    },
    [loadedOptions, onChange],
  );

  return (
    <>
      <RegrelloFormFieldSelectV2
        className={className}
        extraEndOptions={[
          tagTypesQueryResult.error != null ? (
            <RegrelloSelectV2ErrorOption key="option-error" />
          ) : (
            <RegrelloSelectV2AddOption
              key="option-add"
              allowCreateDisabledText={t`Please contact an admin if you would like to add a new homepage category`}
              allowCreateOptions={allowCreateTagTypes}
              iconName="add"
              message={t`Add tag category`}
              onSelect={(inputValue) => {
                setIsAddDialogOpen(true);
                setDefaultValueForAddDialog(inputValue);
              }}
            />
          ),
        ]}
        getOptionLabel={getTagTypeLabel}
        isLoading={tagTypesQueryResult.loading && tagTypesQueryResult.data == null}
        onChange={handleChange}
        onOpen={handleAutocompleteOpen}
        options={loadedOptions}
        {...selectProps}
      />

      <CreateTagTypeDialog
        defaultValue={defaultValueForAddDialog}
        isOpen={isAddDialogOpen}
        onClose={handleAddDialogClose}
        onTagTypeAdded={handleTagTypeAdded}
      />
    </>
  );
});

function getTagTypeLabel(tagType: TagTypeFields): string {
  return tagType.name;
}

function sortComparator(tagTypeA: TagTypeFields, tagTypeB: TagTypeFields) {
  if (tagTypeA.name < tagTypeB.name) {
    return ComparatorResult.BEFORE;
  }
  if (tagTypeA.name > tagTypeB.name) {
    return ComparatorResult.AFTER;
  }
  return ComparatorResult.EQUAL;
}

function sortOptions(options: TagTypeFields[]): TagTypeFields[] {
  return options.sort(sortComparator);
}
