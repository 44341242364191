import { t } from "@lingui/macro";
import { ComparatorResult, EMPTY_ARRAY, isArraysEqual, stringComparator } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  ConditionOperator,
  type CreateFieldInstanceValueInputs,
  type FieldAllowedValueString,
  type FieldFields,
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  type FieldInstanceValueInputType,
  PropertyDataType,
  type PropertyTypeFields,
  type UpdateFieldInstanceValueInputs,
  type UpdateStartingConditionsInputs,
  type ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloIcon } from "@regrello/ui-core";
import type { FieldPath, FieldValues } from "react-hook-form";

import { FeatureFlagService } from "../../../../services/FeatureFlagService";
import type { FieldInstanceBaseFields } from "../../../../types";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import {
  getRegrelloDefaultFilterDefinitionMultiSelectValue,
  getRegrelloFilterDefinitionMultiSelectValue,
} from "../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import { RegrelloControlledFormFieldMultiSelect } from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloInactiveBadge } from "../../inactiveBadge/RegrelloInactiveBadge";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";
import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import {
  type CustomFieldFrontendSelectableOption,
  INACTIVE_SELECTABLE_OPTION_ID,
} from "./types/CustomFieldFrontendSelectableOption";
import type {
  CustomFieldPlugin,
  CustomFieldPluginV2RenderFormFieldProps,
  GetCreateFieldInstanceValueInputsFromFormValueParams,
} from "./types/CustomFieldPlugin";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { DEFAULT_INPUT_TYPE_IF_NO_VALUE, getConditionOperatorsByType } from "./utils/customFieldConstants";
import {
  extractAllowedValuesFromFieldForFrontend,
  extractAllowedValuesFromFieldVersionForFrontend,
} from "./utils/extractAllowedValuesForFrontend";
import { extractSelectedValuesOrThrow } from "./utils/extractSelectedValuesOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { getUpdateStartingConditionsInputsForEmptyOperators } from "./utils/getUpdateStartingConditionsInputsForEmptyOperators";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'multiSelect' field is invalid";
const WARNING_INVALID_FORM_VALUE = "Provided 'multiSelect'-field form value is not a valid array of selectable options";
const ERROR_INVALID_VALUE_TYPE =
  "Provided 'multiSelect' field instance value must have type 'FieldInstanceValueString'";
const WARNING_INVALID_OPERATOR_TYPE = "Provided operator type is invalid for 'multiselect' fields";
const WARNING_UNEXPECTED_EMPTY_FORM_VALUE =
  "Provided 'multiselect' field form value must not be empty given the provided operator type";
const WARNING_UNEXPECTED_DEFINED_FORM_VALUE =
  "Provided 'multiselect' field form value must not be defined given the provided operator type";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.STRING;
}

function canProcessField(field: FieldFields): boolean {
  return (
    field.isMultiValued && field.allowedValues.length > 0 && canProcessPropertyDataType(field.propertyType.dataType)
  );
}

type MultiSelectPluginFrontendValue = CustomFieldFrontendSelectableOption[];
type MultiSelectFieldPluginType = CustomFieldPlugin<MultiSelectPluginFrontendValue>;

const getConditionOperators: MultiSelectFieldPluginType["getConditionOperators"] = () =>
  getConditionOperatorsByType("MULTISELECT");

const renderDisplayValue: MultiSelectFieldPluginType["renderDisplayValue"] = (fieldInstance, options = {}) => {
  const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance, {
    includeInactiveOptions: true,
  });

  if (options?.context === "table" || options?.context === "inlineTruncate" || options?.context === "dataTab") {
    return (
      <span>
        {frontendFieldInstance.values.map((allowedValue, index) =>
          getOptionFrontendDisplayValue(
            allowedValue,
            index === frontendFieldInstance.values.length - 1
              ? ""
              : index === frontendFieldInstance.values.length - 2 && options?.lastStringSeparator != null
                ? ` ${options.lastStringSeparator} `
                : ", ",
          ),
        )}
      </span>
    );
  }

  return (
    // Wrap all the multiselect values in an element to ensure they do not become flex children.
    <div>
      {frontendFieldInstance.values.map(({ id, value }, index) => {
        return (
          <div
            // (zstanik): Not ideal to use `index` here, but the selected options aren't guaranteed
            // to have unique IDs and values.
            key={`${id}-${value}-${index}`}
            data-testid={DataTestIds.CUSTOM_FIELD_MULTISELECT_VALUE}
          >
            {`• ${value}`}
            {id === INACTIVE_SELECTABLE_OPTION_ID && <RegrelloInactiveBadge />}
          </div>
        );
      })}
    </div>
  );
};

const sortComparator: MultiSelectFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,
  direction = "asc",
): ComparatorResult => {
  if (direction === "desc") {
    return MultiSelectFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(fieldInstance1.field);

  const value1 = fieldPlugin.getValueForFrontend(fieldInstance1) as CustomFieldFrontendSelectableOption[];
  const value2 = fieldPlugin.getValueForFrontend(fieldInstance2) as CustomFieldFrontendSelectableOption[];

  const normalizedValue1 = value1.map(({ value }) => value.toLocaleLowerCase()).join("-");
  const normalizedValue2 = value2.map(({ value }) => value.toLocaleLowerCase()).join("-");

  return stringComparator(normalizedValue1, normalizedValue2);
};

/**
 * Describes a custom field that allows the user to select multiple values from an approved list.
 */
export const MultiSelectFieldPlugin: MultiSelectFieldPluginType = {
  uri: "com.regrello.customField.multiSelect",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (loadedPropertyTypes: PropertyTypeFields[]): number | undefined => {
    // (clewis): 'Multi-select' fields always hold text/string values.
    return loadedPropertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators,

  getCreateFieldInstanceValueInputsFromFormValue: (
    inputs: GetCreateFieldInstanceValueInputsFromFormValueParams,
  ): CreateFieldInstanceValueInputs => {
    const { displayOrder, field, inputType, spectrumFieldVersion, value } = inputs;
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(WARNING_INVALID_FORM_VALUE, { field, inputType, value }));
    }
    if (value == null) {
      return {
        fieldId: field.id,
        inputType,
        displayOrder,
        spectrumFieldVersionId: spectrumFieldVersion?.id,
      };
    }

    const isAllowedValuesVersioningEnabled = FeatureFlagService.isEnabled(
      FeatureFlagKey.SPECTRUM_ALLOWED_VALUES_2024_12,
    );
    const allowedValues =
      isAllowedValuesVersioningEnabled && spectrumFieldVersion != null
        ? extractAllowedValuesFromFieldVersionForFrontend(spectrumFieldVersion)
        : extractAllowedValuesFromFieldForFrontend(field);

    // (clewis): O(N^2) but this method will be called rarely, so no big deal.
    const validOptions = value.filter((selectedOption) =>
      allowedValues.some(
        (allowedValue) => allowedValue.id === selectedOption.id && allowedValue.value === selectedOption.value,
      ),
    );
    if (validOptions.length !== value.length) {
      console.warn(
        "[MultiSelectFieldPlugin] Received one or more values that do not appear in allowedValues. Silently ignoring them.",
        { field, value: value, validOptions },
      );
    }

    return {
      displayOrder: displayOrder,
      fieldId: field.id,
      stringMultiValue: validOptions.map((element) => element.value),
      inputType,
      spectrumFieldVersionId: spectrumFieldVersion?.id,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return t`Multi-select`;
  },

  getFilterDefinition: (field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionMultiSelectValue({
      type: "default",
      values: field.allowedValues.filter(isFieldAllowedValueString).map((value) => ({
        id: value.id,
        label: value.stringValue,
        value: value.stringValue,
      })),
    });
  },

  getFilterDefinitionWithValues: (field: FieldFields, filter: ViewFilterFields) => {
    const values = filter.values ?? EMPTY_ARRAY;
    const allowedValues = field.allowedValues.filter(isFieldAllowedValueString).map((value) => ({
      id: value.id,
      label: value.stringValue,
      value: value.stringValue,
    }));
    return getRegrelloFilterDefinitionMultiSelectValue(filter.operator, values, allowedValues);
  },

  getEmptyValueForFrontend: (): CustomFieldFrontendSelectableOption[] => {
    return EMPTY_ARRAY;
  },

  getHelperText: (): string => {
    return t`Multi-select allows you to select multiple options from predefined options in a dropdown.`;
  },

  getIconName: () => {
    return "multi-select-field";
  },

  getNameTemplateDisplayValueFromFormValue: (value) => {
    if (!isValueValid(value) || value == null || value.length === 0) {
      return undefined;
    }
    return value.map((option) => option.value).join(", ");
  },

  getSourceFieldInstance: (_fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    return undefined;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    if (!isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        leftFieldInstance,
        operator,
      });
      return undefined;
    }

    if (operator === ConditionOperator.EMPTY || operator === ConditionOperator.NOT_EMPTY) {
      if (value != null) {
        console.warn(WARNING_UNEXPECTED_DEFINED_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
      }
      return getUpdateStartingConditionsInputsForEmptyOperators(getFieldInstanceId(leftFieldInstance), operator);
    }

    if (value == null || (Array.isArray(value) && value.length === 0)) {
      console.warn(WARNING_UNEXPECTED_EMPTY_FORM_VALUE, { leftFieldInstance, value });
      return undefined;
    }

    if (
      !Array.isArray(value) ||
      value.some((element) => typeof element.id !== "number" || typeof element.value !== "string")
    ) {
      console.warn(getErrorMessageWithPayload(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value }));
      return undefined;
    }

    // (clewis): O(N^2) but this method will be called rarely, so no big deal.
    const validOptions = value.filter((selectedOption) =>
      leftFieldInstance.field.allowedValues.some((allowedValue) =>
        allowedValue.id === selectedOption.id && allowedValue.__typename === "FieldAllowedValueString"
          ? allowedValue.stringValue === selectedOption.value
          : false,
      ),
    );
    if (validOptions.length !== value.length) {
      console.warn(
        "[MultiSelectFieldPlugin] Received one or more values that do not appear in allowedValues. Silently ignoring them.",
        { leftFieldInstance, value },
      );
    }

    return {
      leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
      operatorV2: operator,
      rightStringMultiValue: value.map((element) => element.value),
      rightFloatMultiValue: EMPTY_ARRAY,
      rightIntMultiValue: EMPTY_ARRAY,
      rightPartyIDMultiValue: EMPTY_ARRAY,
      rightTimeMultiValue: EMPTY_ARRAY,
    };
  },

  // (hchen): Currently this is only used for handling the edge case when flipping the field
  // instance input type from OPTIONAL to REQUESTED (i.e. required) when the a previously OPTIONAL
  // field is set as a native field on a future task. It's not necessary to implement for field
  // types other than date and user, since we only support those as native field now.
  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    _fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    throw new Error("not implmented yet");
  },

  getValueForFrontend: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
    options?: { includeInactiveValues: boolean },
  ): CustomFieldFrontendSelectableOption[] => {
    return translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance, {
      includeInactiveOptions: options?.includeInactiveValues ?? false,
    }).values;
  },

  hasAllowedValues: (): boolean => {
    return true;
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    options?: { includeInactiveOptions: boolean },
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance, {
      includeInactiveOptions: options?.includeInactiveOptions ?? false,
    });
    return frontendFieldInstance.values.length === 0;
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    if (
      frontendFieldInstance.values.length === 0 &&
      (proposedChange.stringMultiValue == null || proposedChange.stringMultiValue.length === 0)
    ) {
      return true;
    }

    if (
      frontendFieldInstance.values.length === 0 ||
      proposedChange.stringMultiValue == null ||
      proposedChange.stringMultiValue.length === 0
    ) {
      return false;
    }

    return isArraysEqual(
      frontendFieldInstance.values.map((value) => value.value),
      proposedChange.stringMultiValue,
      (a, b) => a === b,
    );
  },

  isMultiValued: (): boolean => {
    return true;
  },

  renderDisplayValue: renderDisplayValue,

  renderFormField: <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactNode => {
    if (!canProcessField(field)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
    }
    const { operator, fieldInstance } = props;
    const isDeleted = fieldInstance?.field.deletedAt != null;
    if (operator != null && !isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        field,
        operator,
      });
      return undefined;
    }

    const isAllowedValuesVersioningEnabled = FeatureFlagService.isEnabled(
      FeatureFlagKey.SPECTRUM_ALLOWED_VALUES_2024_12,
    );

    return (
      <RegrelloControlledFormFieldMultiSelect
        {...props}
        key={props.controllerProps.name}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        getSelectedItemProps={getSelectedItemProps}
        helperText={t`Select 1 or more.`}
        infoTooltipText={props.description}
        isDeleted={isDeleted}
        isItemsEqual={isItemsEqual}
        itemPredicate={itemPredicate}
        items={
          isAllowedValuesVersioningEnabled && fieldInstance?.spectrumFieldVersion != null
            ? extractAllowedValuesFromFieldVersionForFrontend(fieldInstance?.spectrumFieldVersion)
            : extractAllowedValuesFromFieldForFrontend(field)
        }
        renderItem={renderItem}
      />
    );
  },

  renderIcon: (props) => {
    return <RegrelloIcon {...props} iconName="multi-select-field" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }
    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: "inlineTruncate" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: renderDisplayValue(fieldInstance, { context: options?.context ?? "table" }),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName,
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  shouldDisplayMultiValuedToggle: () => false,

  sortComparator,
};

interface FrontendMultiSelectFieldInstance {
  name: string;
  allowedValues: CustomFieldFrontendSelectableOption[];
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  values: CustomFieldFrontendSelectableOption[];
}

function getOptionFrontendDisplayValue(allowedValue: CustomFieldFrontendSelectableOption, suffix: string) {
  return (
    <>
      {allowedValue.value}
      {allowedValue.id === INACTIVE_SELECTABLE_OPTION_ID && <RegrelloInactiveBadge />}
      {suffix}
    </>
  );
}

function getSelectedItemProps(allowedValue: CustomFieldFrontendSelectableOption) {
  return {
    children: allowedValue.value,
  };
}

function renderItem(allowedValue: CustomFieldFrontendSelectableOption) {
  return {
    key: allowedValue.id,
    text: allowedValue.value,
  };
}

function isItemsEqual(a: CustomFieldFrontendSelectableOption, b: CustomFieldFrontendSelectableOption) {
  return a.id === b.id;
}

function itemPredicate(query: string, allowedValue: CustomFieldFrontendSelectableOption) {
  return allowedValue.value.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  options?: {
    includeInactiveOptions: boolean;
  },
): FrontendMultiSelectFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractSelectedValuesOrThrow<FrontendMultiSelectFieldInstance>({
    fieldInstance,
    errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
    getterIfNoValue: (allowedValues) => ({
      name: field.name,
      allowedValues,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstanceInputType: undefined,
      values: EMPTY_ARRAY,
    }),
    getterIfValue: (fieldInstanceValues, frontendSelectedOptions, allowedValues) => ({
      name: field.name,
      allowedValues,
      inputType: fieldInstanceValues[0].inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValues[0]) &&
        fieldInstanceValues[0].crossWorkflowSinksFieldInstanceMultiValueString != null
          ? fieldInstanceValues[0].crossWorkflowSinksFieldInstanceMultiValueString.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValues[0])
        ? fieldInstanceValues[0].crossWorkflowSourceFieldInstanceMultiValueString?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValues[0].sinksFieldInstanceMultiValueString?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: isFieldInstanceFields
        ? (fieldInstanceValues[0].sourceFieldInstanceMultiValueString?.id ?? undefined)
        : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValues[0].sourceFieldInstanceMultiValueString?.inputType
        : undefined,
      values: frontendSelectedOptions,
    }),
    options: {
      includeInactiveOptions: options?.includeInactiveOptions ?? false,
    },
  });
}

function isOperatorValid(
  operator: ConditionOperator,
): operator is
  | ConditionOperator.EMPTY
  | ConditionOperator.NOT_EMPTY
  | ConditionOperator.CONTAINS_ALL_OF
  | ConditionOperator.CONTAINS_ANY_OF
  | ConditionOperator.CONTAINS_NONE_OF {
  return getConditionOperators().find((stageStartOperator) => stageStartOperator.operator === operator) != null;
}

function isFieldAllowedValueString(
  value: FieldFields["allowedValues"][0],
): value is FieldAllowedValueString & { __typename: "FieldAllowedValueString" } {
  return value.__typename === "FieldAllowedValueString";
}

function isValueValid(value: unknown): value is MultiSelectPluginFrontendValue | null {
  return (
    value == null ||
    (Array.isArray(value) && value.length === 0) ||
    (Array.isArray(value) &&
      value.every((element) => typeof element.id === "number" && typeof element.value === "string"))
  );
}
