import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type SpectrumFieldAllowedValueFields = {
  id: number;
  uuid: string;
  displayOrder: number;
  createdAt: string;
  stringValue?: string | null;
  intValue?: number | null;
  floatValue?: number | null;
  documentIDValue?: number | null;
  partyValue?: PartyBaseFields | null;
};

export const SpectrumFieldAllowedValueFields = gql`
    fragment SpectrumFieldAllowedValueFields on SpectrumFieldAllowedValue {
  id
  uuid
  displayOrder
  createdAt
  stringValue
  intValue
  floatValue
  partyValue {
    ...PartyBaseFields
  }
  documentIDValue
}
    `;
