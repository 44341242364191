import { ComparatorResult } from "@regrello/core-utils";

/** Sorts boolean values. False will come before true. */
export function booleanComparator(value1: boolean, value2: boolean): ComparatorResult {
  if (!value1 && value2) {
    return ComparatorResult.BEFORE;
  }

  if (!value2 && value1) {
    return ComparatorResult.AFTER;
  }

  return ComparatorResult.EQUAL;
}
