import type * as Types from "../../../generated/graphqlBaseTypes";

import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateSpectrumFieldVersionMutationVariables = Types.Exact<{
  input: Types.CreateSpectrumFieldVersionInput;
}>;

export type CreateSpectrumFieldVersionMutation = { createSpectrumFieldVersion: SpectrumFieldVersionFields };

export const CreateSpectrumFieldVersionMutationDocument = gql`
    mutation CreateSpectrumFieldVersionMutation($input: CreateSpectrumFieldVersionInput!) {
  createSpectrumFieldVersion(input: $input) {
    ...SpectrumFieldVersionFields
  }
}
    ${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${PropertyTypeFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${FieldUnitFields}
${FieldFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}`;
export type CreateSpectrumFieldVersionMutationFn = Apollo.MutationFunction<
  CreateSpectrumFieldVersionMutation,
  CreateSpectrumFieldVersionMutationVariables
>;

/**
 * __useCreateSpectrumFieldVersionMutation__
 *
 * To run a mutation, you first call `useCreateSpectrumFieldVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpectrumFieldVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpectrumFieldVersionMutation, { data, loading, error }] = useCreateSpectrumFieldVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpectrumFieldVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSpectrumFieldVersionMutation,
    CreateSpectrumFieldVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSpectrumFieldVersionMutation, CreateSpectrumFieldVersionMutationVariables>(
    CreateSpectrumFieldVersionMutationDocument,
    options,
  );
}
export type CreateSpectrumFieldVersionMutationHookResult = ReturnType<typeof useCreateSpectrumFieldVersionMutation>;
export type CreateSpectrumFieldVersionMutationResult = Apollo.MutationResult<CreateSpectrumFieldVersionMutation>;
export type CreateSpectrumFieldVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateSpectrumFieldVersionMutation,
  CreateSpectrumFieldVersionMutationVariables
>;
