import { t } from "@lingui/macro";
import { clsx, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

import {
  RegrelloFormFieldLayout,
  type RegrelloFormFieldLayoutProps,
} from "../formFields/_internal/RegrelloFormFieldLayout";
import {
  type NameTemplateFieldDisplayValue,
  RegrelloNameTemplatePreviewWrapper,
} from "./RegrelloNameTemplatePreviewWrapper";
import type { FrontendNameTemplate, NameTemplateHandle } from "./utils/nameTemplateUtils";

export interface RegrelloNameTemplateSuffixFormFieldProps
  extends Pick<RegrelloFormFieldLayoutProps<unknown>, "label" | "labelPlacement" | "labelWidth"> {
  /** The name template to render a preview of. */
  defaultNameTemplate: FrontendNameTemplate;

  /**
   * Whether the suffix form field is in an error state. Will be rendered with a red bar on the
   * left, a "Field is required" error below and a red border on focus.
   *
   * @default false
   */
  hasError?: boolean;

  /**
   * Whether the suffix field is required in the form.
   *
   * @default false
   */
  isRequired?: boolean;

  /** The display values to inject into the preview field chips. */
  nameTemplateFieldDisplayValues: NameTemplateFieldDisplayValue[];

  /** Callback invoked when the suffix input is blurred. */
  onBlur?: () => void;
}

/**
 * Renders a form field with a preview of the provided name template and an input for the name
 * template suffix. To be used when creating an object that has a naming convention configured.
 */
export const RegrelloNameTemplateSuffixFormField = React.memo(
  React.forwardRef<NameTemplateHandle, RegrelloNameTemplateSuffixFormFieldProps>(
    function RegrelloNameTemplateSuffixFormFieldFn(
      {
        defaultNameTemplate,
        hasError = false,
        isRequired = false,
        nameTemplateFieldDisplayValues,
        label,
        labelPlacement,
        labelWidth,
        onBlur,
      }: RegrelloNameTemplateSuffixFormFieldProps,
      ref,
    ) {
      // (zstanik): Only use the initial suffix value to set the content editable span below, as
      // it's dangerous to treat such content as controlled by React.
      const [suffix] = useState(defaultNameTemplate.suffix ?? EMPTY_STRING);
      const [isFocused, setIsFocused] = useState(false);

      const suffixInputRef = useRef<HTMLSpanElement>(null);
      const handleBlur = useCallback(() => {
        setIsFocused(false);
        onBlur?.();
      }, [onBlur]);

      useEffect(() => {
        if (hasError) {
          suffixInputRef.current?.focus();
          setIsFocused(true);
        }
      }, [hasError]);

      useImperativeHandle(ref, () => {
        return {
          getNameTemplateInputs: () => undefined,
          // (zstanik): An element's text content is safe to use here as it's just all the plaintext
          // stripped of any HTML.
          getNameTemplateSuffix: () => (suffixInputRef.current?.textContent ?? EMPTY_STRING).trimEnd(),
        };
      });

      return (
        <RegrelloFormFieldLayout
          error={hasError ? t`This field is required` : undefined}
          isRequiredAsteriskShown={isRequired}
          label={label}
          labelPlacement={labelPlacement}
          labelWidth={labelWidth}
        >
          <div className="relative">
            <div
              className={clsx("min-h-9 rg-box-border rounded p-2 px-2.25 hover:cursor-text", {
                "outline-[1.5px] outline-solid outline-primary-solid rounded-focused": isFocused,
                "outline-danger-solid": isFocused && hasError,
                "before:absolute before:inset-0 before:content-[''] before:rounded-l before:shadow-[inset_4px_0_0_#C93D4E]":
                  hasError && !isFocused,
              })}
              onClick={() => suffixInputRef.current?.focus()}
            >
              <RegrelloNameTemplatePreviewWrapper
                defaultNameTemplate={defaultNameTemplate}
                isIconsHidden={true}
                isPreviewTextMuted={true}
                nameTemplateFieldDisplayValues={nameTemplateFieldDisplayValues}
              >
                <span
                  ref={suffixInputRef}
                  className="relative inline align-middle border-none min-w-0.5 h-4 focus:outline-none"
                  // (zstanik): Use a contenteditable span so that the input can behave like an inline
                  // element and expand naturally as the user types.
                  contentEditable={true}
                  data-testid={DataTestIds.NAME_TEMPLATE_SUFFIX_INPUT}
                  onBlur={handleBlur}
                  onFocus={() => {
                    setIsFocused(true);
                  }}
                  suppressContentEditableWarning={true}
                >
                  {suffix}
                </span>
              </RegrelloNameTemplatePreviewWrapper>
            </div>
          </div>
        </RegrelloFormFieldLayout>
      );
    },
  ),
);
