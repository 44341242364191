import { clsx, type WithChildren } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React from "react";

import { RegrelloTextEditorFieldChip } from "./components/RegrelloTextEditorFieldChip";
import { type FrontendNameTemplate, getOptionLabel, getPreviewTokensFromNameTemplate } from "./utils/nameTemplateUtils";

/** Utility type for expressing display values for fields as the user inputs values. */
export type NameTemplateFieldDisplayValue = {
  fieldId: number;
  regrelloObjectPropertyId?: number;
  displayValue?: string;
};

export interface RegrelloNameTemplatePreviewWrapperProps extends WithChildren {
  /** The name template to render a preview of. */
  defaultNameTemplate: FrontendNameTemplate;

  /**
   * Callback invoked to get the info state tooltip text for a given field name (the full field
   * object isn't available at this level). No tooltip is rendered if this prop is undefined or
   * returns undefined.
   */
  getFieldChipInfoStateTooltipText?: (fieldName: string) => string | undefined;

  /** The display values to inject into the preview field chips. */
  nameTemplateFieldDisplayValues: NameTemplateFieldDisplayValue[];

  /**
   * Whether the field chips are rendered without their icons.
   *
   * @default false
   */
  isIconsHidden?: boolean;

  /**
   * Whether the plaintext in the name template is muted. Useful when rendering the preview in a
   * dialog where more text may be added, to distinguish between preview text and editable text.
   *
   * @default false
   */
  isPreviewTextMuted?: boolean;
}

/**
 * Renders a preview of the provided name template. The `children` will be rendered inline with the
 * other preview tokens. Useful for rendering an additional input along with the preview.
 */
export const RegrelloNameTemplatePreviewWrapper = React.memo<RegrelloNameTemplatePreviewWrapperProps>(
  function RegrelloNameTemplatePreviewWrapperFn({
    children,
    defaultNameTemplate,
    getFieldChipInfoStateTooltipText,
    nameTemplateFieldDisplayValues,
    isIconsHidden = false,
    isPreviewTextMuted = false,
  }: RegrelloNameTemplatePreviewWrapperProps) {
    const previewTokens = getPreviewTokensFromNameTemplate(defaultNameTemplate);

    return (
      <div data-testid={DataTestIds.NAME_TEMPLATE_PREVIEW}>
        {previewTokens.map((previewToken) => {
          return previewToken.type === "text" ? (
            <span key={previewToken.key} className={clsx("align-middle", { "text-textMuted": isPreviewTextMuted })}>
              {previewToken.text}
            </span>
          ) : (
            <span key={previewToken.key} className="align-middle">
              <RegrelloTextEditorFieldChip
                {...previewToken.props}
                displayValue={
                  nameTemplateFieldDisplayValues.find(
                    (fieldDisplayValue) =>
                      fieldDisplayValue.fieldId === previewToken.props.fieldId &&
                      fieldDisplayValue.regrelloObjectPropertyId === previewToken.props.regrelloObjectPropertyId,
                  )?.displayValue
                }
                fieldName={getOptionLabel(previewToken.props)}
                infoStateTooltipText={getFieldChipInfoStateTooltipText?.(previewToken.props.fieldName)}
                isIconHidden={isIconsHidden}
                isPreview={true}
              />
            </span>
          );
        })}
        {children}
      </div>
    );
  },
);
