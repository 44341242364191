import { clsx, type WithClassName } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React from "react";

import { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloIcon, type RegrelloIconName } from "../icons/RegrelloIcon";
import { RegrelloLinkify } from "../linkify/RegrelloLinkify";
import { RegrelloTooltip, type RegrelloTooltipProps } from "./RegrelloTooltip";

export interface RegrelloTooltippedInfoIconProps extends Pick<RegrelloTooltipProps, "align">, WithClassName {
  /** The name of the tooltip triggering icon. */
  iconName?: RegrelloIconName;

  /**
   * Whether this element should render inline with other text.
   *
   * @default false
   */
  inline?: boolean;

  /**
   * Whether the default left margin of 4px is omitted. The margin is included by default because
   * usually this icon is rendered to the right of some text.
   *
   * @default false
   */
  isDefaultMarginsOmitted?: boolean;

  /** The text to display in the tooltip on hover. */
  tooltipText?: React.ReactNode;

  /**
   * The visual treatment to give to the tooltip.
   * - `"default"`: A standard dark-background tooltip.
   * - `"popover"`: A light-background tooltip with a border.
   *
   * @default "default"
   */
  variant?: "default" | "popover";
}

/**
 * An info icon with an on-hover tooltip displaying some provided text.
 */
export const RegrelloTooltippedInfoIcon = React.memo<RegrelloTooltippedInfoIconProps>(
  function RegrelloTooltipElementFn({
    align = "start",
    className,
    iconName = "info-outline",
    inline: isInline = false,
    isDefaultMarginsOmitted = false,
    tooltipText,
    variant = "default",
  }) {
    const icon = (
      <RegrelloIcon
        className={clsx(
          "cursor-help text-neutral-icon hover:text-neutral-iconHovered",
          { "ml-1": !isDefaultMarginsOmitted },
          className,
        )}
        dataTestId={DataTestIds.INFO_ICON}
        displayInline={true}
        iconName={iconName}
        size={RegrelloSize.X_SMALL}
      />
    );

    return tooltipText == null || (typeof tooltipText === "string" && tooltipText.length === 0) ? null : (
      <RegrelloTooltip
        align={align}
        content={<RegrelloLinkify>{tooltipText}</RegrelloLinkify>}
        enableHoverableContent={variant === "popover"}
        maxWidth={400}
        side="top"
        variant={variant}
      >
        {isInline ? <span>{icon}</span> : <span className="flex items-center">{icon}</span>}
      </RegrelloTooltip>
    );
  },
);
