import { toast } from "sonner";

import { RegrelloToast, type RegrelloToastProps } from "./RegrelloToast";

export namespace useToastMessageQueue {
  export interface Return {
    /** Shows a toast and returns the `toastId` that can be passed to `dismissToast`. */
    showToast: (newToast: RegrelloToastProps) => string | number;
    dismissToast: (toastId: string | number) => void;
  }
}

function showToast(newToast: RegrelloToastProps): string | number {
  return toast.custom(
    (id) => {
      return <RegrelloToast {...newToast} toastId={id} />;
    },
    { id: newToast.id },
  );
}

/**
 * This hook exposes two API for showing and dismissing a global toast message.
 * - showToast: (newToast: Toast) => string: add a new toast to the message queue and display.
 * - dismissToast: (toastId: string) => void: dismiss a toast message with the provided id.
 *
 * Note: Typically you should only need to use showToast. Dismissing a toast message is handled by
 * the <RegrelloToast> component internally.
 */
export function useToastMessageQueue(): useToastMessageQueue.Return {
  return {
    showToast,
    dismissToast: toast.dismiss,
  };
}
