/* eslint-disable lingui/no-unlocalized-strings */

import type { Scope } from "@sentry/react";

export enum RegrelloFormDialogName {
  ADD_COLLABORATION_TO_WORKFLOW = "AddCollaborationToWorkflowDialog",
  ADD_DOCUMENT_LINK_DIALOG = "AddDocumentLinkDialog",
  ADD_RELATION_TO_WORKFLOW = "AddRelationToWorkflowDialog",
  ADD_ROLE = "AddRoleDialog",
  ADD_TAG = "AddTagDialog",
  ADD_USER = "AddUserDialog",
  ADD_WORKFLOW = "AddWorkflowDialog",
  ADD_WORKFLOW_ACTION_ITEM = "AddWorkflowActionItemDialog",
  ADD_WORKFLOW_TEMPLATE_ACTION_ITEM = "AddWorkflowTemplateActionItemDialog",
  DUE_DATE = "DueDateDialog",
  CONFIGURE_BLUEPRINT_CONTACT_EMAIL = "ConfigureBlueprintContactEmail",
  CONFIGURE_CUSTOM_FIELDS = "ConfigureCustomFieldsDialog",
  CREATE_BLUEPRINT_VARIANT = "CreateBlueprintVariantDialog",
  CREATE_FIELD = "CreateFieldDialog",
  CREATE_TENANT = "CreateTenantDialog",
  CONFIGURE_TEAM = "ConfigureTeamDialog",
  EDIT_ASSIGNED_TO = "EditAssignedToDialog",
  EDIT_CCD = "EditCcd",
  EDIT_USER = "EditUser",
  EDIT_VIEW = "EditView",
  EDIT_SHAREPOINT_FOLDER = "EditSharepointFolderDialog",
  EDIT_WORKFLOW_AUTOMATED_ACTION_ITEM_TEMPLATE = "EditWorkflowAutomationActionItemTemplateDialog",
  EDIT_WORKFLOW_NAME = "EditWorkflowNameDialog",
  EDIT_WORKFLOW_OWNER = "EditWorkflowOwnerDialog",
  EDIT_WORKFLOW_STAGE = "EditWorkflowStageDialog",
  EDIT_WORKFLOW_TEMPLATE_ACTION_ITEM = "EditWorkflowTemplateActionItemDialog",
  EDIT_WORKFLOW_TEMPLATE_STAGE = "EditWorkflowTemplateStage",
  IMPORT_BLUEPRINT = "ImportBlueprint",
  INBOX_MINI_VIEW_SETTINGS = "InboxMiniViewSettings",
  GRANT_ACCESS_TO_BLUEPRINT = "GrantAccessToBlueprintDialog",
  PROFILE = "Profile",
  REPORT_EXCEPTION = "ReportException",
  SELECT_FIELD = "SelectFieldDialog",
  SPECTRUM_RULE_BUILDER = "SpectrumRuleBuilder",
  UPDATE_TENANT_DISPLAY_NAME = "UpdateTenantDisplayNameDialog",
  UPDATE_CALENDAR_SETTINGS = "UpdateCalendarSettings",
  USE_TEMPLATE = "UseTemplateDialog",
  WORKSPACE_PERSONALIZATION = "WorkspacePersonalization",
  WORKFLOW_TEMPLATE_AUTO_ADJUST_DUE_ON = "WorkflowTemplateAutoAdjustDueOn",
}

export function onBeforeSentryCaptureOnAppRoot(scope: Scope) {
  setErrorBoundaryScope(scope, "AppRoot");
}

export function onBeforeSentryCaptureOnInboxTable(scope: Scope) {
  setErrorBoundaryScope(scope, "InboxTable");
}

export function onBeforeSentryCaptureOnCustomFields(scope: Scope) {
  setErrorBoundaryScope(scope, "Custom fields");
}

export function onBeforeSentryCaptureOnBlueprintsPage(scope: Scope) {
  setErrorBoundaryScope(scope, "Blueprints page");
}

export function onBeforeSentryCaptureOnSpectrumForms(scope: Scope) {
  setErrorBoundaryScope(scope, "Spectrum forms");
}

export function onBeforeSentryCaptureOnAdminPage(scope: Scope) {
  setErrorBoundaryScope(scope, "Admin");
}

export function onBeforeSentryCaptureOnGenerateBlueprintPage(scope: Scope) {
  setErrorBoundaryScope(scope, "GenerateBlueprint");
}

export function onBeforeSentryCaptureOnGenerateFormPage(scope: Scope) {
  setErrorBoundaryScope(scope, "GenerateForm");
}

export function onBeforeSentryCaptureOnHomePage(scope: Scope) {
  setErrorBoundaryScope(scope, "HomePage");
}

export function onBeforeSentryCaptureOnImportBlueprintPage(scope: Scope) {
  setErrorBoundaryScope(scope, "ImportBlueprint");
}

export function onBeforeSentryCaptureOnInboxPage(scope: Scope) {
  setErrorBoundaryScope(scope, "InboxPage");
}

export function onBeforeSentryCaptureOnInboxV2Page(scope: Scope) {
  setErrorBoundaryScope(scope, "InboxV2Page");
}

export function onBeforeSentryCaptureOnNotificationsPage(scope: Scope) {
  setErrorBoundaryScope(scope, "Notifications");
}

export function onBeforeSentryCaptureOnPeoplePage(scope: Scope) {
  setErrorBoundaryScope(scope, "People");
}

export function onBeforeSentryCaptureOnPersonDetailViewPage(scope: Scope) {
  setErrorBoundaryScope(scope, "PersonDetailView");
}

export function onBeforeSentryCaptureOnTeamDetailViewPage(scope: Scope) {
  setErrorBoundaryScope(scope, "TeamDetailView");
}

export function onBeforeSentryCaptureOnTagsPage(scope: Scope) {
  setErrorBoundaryScope(scope, "TagsPage");
}

export function onBeforeSentryCaptureOnWorkflow(scope: Scope) {
  setErrorBoundaryScope(scope, "Workflow");
}

export function onBeforeSentryCaptureOnDocumentsPage(scope: Scope) {
  setErrorBoundaryScope(scope, "MyDocuments");
}

export function onBeforeSentryCaptureOnStarred(scope: Scope) {
  setErrorBoundaryScope(scope, "Starred");
}

export function onBeforeSentryCaptureOnRegrelloFormDialog(scope: Scope, dialogName: RegrelloFormDialogName) {
  setErrorBoundaryScope(scope, dialogName);
}

export function onBeforeSentryCaptureOnBulkStartWorkflowsDialog(scope: Scope) {
  setErrorBoundaryScope(scope, "BulkStartWorkflows");
}

function setErrorBoundaryScope(scope: Scope, componentName: string) {
  scope.setTag("component", componentName);
}
