/*
  Based upon code from date-fns, but heavily modified to use Intl and our own translation
  system.
  date-fns license is MIT.
*/
import { i18nRelativeTimeFormat } from "@regrello/i18n-llm-translator";

import { isValidDate, parseDate } from "./dateUtils";
import { type DateType, getTimezoneOffsetInMilliseconds } from "./formatDistance";

const minutesInDay = 1440;
const minutesInMonth = 43200;
const millisecondsInMinute = 60000;
const minutesInYear = 525600;

/**
 * Formats the distance between two dates in a human-readable, relative time format.
 * Returns precise numbers as return value, e.g. "in 2 hours" or "2 hours ago".
 *
 * @param date - The target date to compare.
 * @param baseDate - The base date to compare against.
 * @param options - Optional configuration for the relative time format.
 * @returns A string representing the relative time difference between the two dates.
 * @throws {RangeError} If either of the provided dates is invalid.
 */
export const formatDistanceStrict = (date: DateType, baseDate: DateType, options?: Intl.RelativeTimeFormatOptions) => {
  const currentDate = parseDate(date);
  const currentBaseDate = parseDate(baseDate);

  if (!isValidDate(currentDate) || !isValidDate(currentBaseDate)) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    throw new RangeError("Invalid time value");
  }

  const direction = currentBaseDate.getTime() - currentDate.getTime() > 0 ? -1 : 1;

  const milliseconds = Math.abs(currentDate.getTime() - currentBaseDate.getTime());
  const minutes = milliseconds / millisecondsInMinute;

  const timezoneOffset = Math.abs(
    getTimezoneOffsetInMilliseconds(currentBaseDate) - getTimezoneOffsetInMilliseconds(currentDate),
  );

  // Use DST-normalized difference in minutes for years, months and days;
  // use regular difference in minutes for hours, minutes and seconds.
  const dstNormalizedMinutes = (milliseconds - timezoneOffset) / millisecondsInMinute;

  // 0 up to 60 seconds
  if (minutes < 1) {
    const seconds = Math.round(milliseconds / 1000);
    return i18nRelativeTimeFormat(seconds * direction, "second", options);
  }
  // 1 up to 60 mins
  if (minutes < 60) {
    const roundedMinutes = Math.round(minutes);
    return i18nRelativeTimeFormat(roundedMinutes * direction, "minute", options);
  }
  // 1 up to 24 hours
  if (minutes < minutesInDay) {
    const hours = Math.round(minutes / 60);
    return i18nRelativeTimeFormat(hours * direction, "hour", options);
  }
  // 1 up to 30 days
  if (dstNormalizedMinutes < minutesInMonth) {
    const days = Math.round(dstNormalizedMinutes / minutesInDay);
    return i18nRelativeTimeFormat(days * direction, "day", options);
  }
  // 1 up to 12 months
  const months = Math.round(dstNormalizedMinutes / minutesInMonth);
  if (dstNormalizedMinutes < minutesInYear && months < 12) {
    return i18nRelativeTimeFormat(months * direction, "month", options);
  }

  // 1 year up to max Date
  const years = Math.round(dstNormalizedMinutes / minutesInYear);
  return i18nRelativeTimeFormat(years * direction, "year", options);
};
