import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { clsx } from "@regrello/core-utils";
import React from "react";

import { RegrelloTypography } from "../typography/RegrelloTypography";

const RegrelloTooltipProvider = TooltipPrimitive.Provider;

const RegrelloTooltipRoot = TooltipPrimitive.Root;

const RegrelloTooltipTrigger = TooltipPrimitive.Trigger;

const RegrelloTooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    /**
     * A custom maximum width in pixels for the tooltip.
     *
     * @default 200
     */
    maxWidth?: number | "100%";

    /**
     * The visual treatment to give to the tooltip.
     * - `"default"`: A standard dark-background tooltip.
     * - `"popover"`: A light-background tooltip with a border.
     *
     * @default "default"
     */
    variant?: "default" | "popover";
  }
>(({ children, className, maxWidth = 200, sideOffset = 4, variant = "default", ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    className={clsx(
      // (clewis): This is the same z-index that Material UI uses. This ensures that tooltips in
      // popovers appear over the popover.
      `
      z-tooltip
      overflow-hidden

      rounded

      px-2.5
      py-1.5

      animate-in
      fade-in-0
      zoom-in-95
      break-words

      data-[state=closed]:animate-out
      data-[state=closed]:fade-out-0
      data-[state=closed]:zoom-out-95

      data-[side=bottom]:slide-in-from-top-2
      data-[side=left]:slide-in-from-right-2
      data-[side=right]:slide-in-from-left-2
      data-[side=top]:slide-in-from-bottom-2
      `,
      {
        "bg-backgroundTooltip text-textTooltip": variant === "default",
        "border bg-background text-textDefault shadow-md": variant === "popover",
      },
      className,
    )}
    sideOffset={sideOffset}
    style={{
      maxHeight: "var(--radix-tooltip-content-available-height)",
      maxWidth,
    }}
    {...props}
  >
    <RegrelloTypography variant="body-xs">{children}</RegrelloTypography>
  </TooltipPrimitive.Content>
));
RegrelloTooltipContent.displayName = TooltipPrimitive.Content.displayName;

export interface RegrelloTooltipProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root> {
  /**
   * Where along the specified {@link side} of the target to align the menu.
   *
   * @default "center"
   */
  align?: TooltipPrimitive.TooltipContentProps["align"];

  /** The element that should open the menu when clicked. */
  children: React.ReactNode;

  /** The content to display in the popover. If `null` or `""`, the tooltip will be disabled. */
  content: React.ReactNode;

  /** Props to pass to the `content` element. */
  contentProps?: Omit<React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>, "align" | "side">;

  /**
   * Whether the tooltip is disabled and should not open when the user hovers the trigger. The
   * tooltip will also be automatically disabled if the `content` is `null` or `""`.
   */
  disabled?: boolean;

  /**
   * Whether the tooltip should remain open if the cursor or focus moves into the tooltip itself.
   * Setting to `true` enables this behavior; this allows the user to select text and interact with
   * buttons and other elements within the tooltip.
   *
   * By default (`false`), the tooltip will close as soon as the cursor or focus moves out of the
   * trigger element.
   *
   * __Warning:__ Setting this to `true` can cause E2E tests to flake because the tooltip may remain
   * open and block clicks on neighboring elements. Before using this prop, consider whether a
   * `RegrelloPopover` would be a more appropriate choice for your use case.
   *
   * @default false
   * @deprecated As of 30 Aug 2023. We should really use `RegrelloPopover` instead of this.
   */
  enableHoverableContent?: boolean;

  /**
   * A custom maximum width in pixels for the tooltip. You can also provide this via
   * `contentProps.maxWidth`.
   *
   * @default 200
   */
  maxWidth?: number | "100%";

  /** Callback invoked when the tooltip opens or closes. */
  onOpenChange?: (nextIsOpen: boolean) => void;

  /**
   * How long to wait before the tooltip appears after hovering the trigger.
   *
   * @default "default"
   */
  openDelay?: "long" | "default" | "none";

  /**
   * The side of the target on which to align the menu content.
   *
   * @default "bottom"
   */
  side?: TooltipPrimitive.TooltipContentProps["side"];

  /** Props to pass to the trigger element. */
  triggerProps?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>;

  /**
   * The visual treatment to give to the tooltip.
   * - `"default"`: A standard dark-background tooltip.
   * - `"popover"`: A light-background tooltip with a border.
   *
   * @default "default"
   */
  variant?: "default" | "popover";
}

/**
 * A popup that displays information related to an element when the element receives keyboard focus
 * or the mouse hovers over it.
 */
const RegrelloTooltip = React.memo<RegrelloTooltipProps>(
  ({
    align = "center",
    children,
    content,
    contentProps,
    disabled = false,
    enableHoverableContent = false,
    maxWidth,
    onOpenChange,
    openDelay = "default",
    side = "bottom",
    triggerProps,
    variant,
    ...props
  }) => {
    return (
      <RegrelloTooltipProvider
        delayDuration={openDelay === "long" ? 500 : openDelay === "default" ? 250 : 0}
        disableHoverableContent={!enableHoverableContent}
      >
        <RegrelloTooltipRoot
          {...props}
          onOpenChange={onOpenChange}
          open={disabled || content == null || content === "" ? false : props.open}
        >
          <RegrelloTooltipTrigger asChild={true} {...triggerProps}>
            {children}
          </RegrelloTooltipTrigger>

          <TooltipPrimitive.Portal>
            <RegrelloTooltipContent
              align={align}
              className="whitespace-pre-wrap"
              maxWidth={maxWidth}
              side={side}
              variant={variant}
              {...contentProps}
            >
              {content}
            </RegrelloTooltipContent>
          </TooltipPrimitive.Portal>
        </RegrelloTooltipRoot>
      </RegrelloTooltipProvider>
    );
  },
);
RegrelloTooltip.displayName = "RegrelloTooltip";

export {
  RegrelloTooltip,
  RegrelloTooltipContent,
  RegrelloTooltipProvider,
  RegrelloTooltipRoot,
  RegrelloTooltipTrigger,
};
