import type { DocumentFields } from "@regrello/graphql-api";
import React from "react";
import { type FieldPath, type FieldValues, useController } from "react-hook-form";

import { RegrelloFormFieldSignature, type RegrelloFormFieldSignatureProps } from "../RegrelloFormFieldSignature";
import type { FormFieldPropsToOmit } from "./regrelloControlledFormFields";
import { getErrorProp, type RegrelloControlledFormFieldProps, useInvokeOnChangeOnRequiredStateChange } from "./utils";

export const RegrelloControlledFormFieldSignatureInternal = function RegrelloControlledFormFieldSignatureFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  ...documentFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldSignatureProps, FormFieldPropsToOmit>
> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: DocumentFields[]) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;

  useInvokeOnChangeOnRequiredStateChange(field, documentFieldProps.isRequiredAsteriskShown ?? false);

  return (
    <RegrelloFormFieldSignature
      {...fieldProps}
      {...documentFieldProps}
      {...getErrorProp(fieldState)}
      inputRef={ref}
      onChange={(newDocuments) => {
        onValueChange?.(field.name, newDocuments);
        fieldProps.onChange(newDocuments);
      }}
    />
  );
};

export const RegrelloControlledFormFieldSignature = React.memo(
  RegrelloControlledFormFieldSignatureInternal,
) as typeof RegrelloControlledFormFieldSignatureInternal;
