import { t } from "@lingui/macro";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloDialogV2 } from "@regrello/ui-core";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { RegrelloControlledFormFieldCheckbox } from "../../controlled/regrelloControlledFormFields";

export interface ObjectColumn {
  id: string;
  name: string;
  disabled?: boolean;
}

interface RegrelloObjectSearchColumnFilterProps {
  defaultColumns: ObjectColumn[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (visibility: string[]) => void;
}

function getDefaultVisibility(defaultColumns: ObjectColumn[]) {
  return defaultColumns.reduce((acc: Record<string, boolean>, column) => {
    acc[column.id] = true;
    return acc;
  }, {});
}

export function RegrelloObjectSearchColumnFilter({
  defaultColumns,
  isOpen,
  onClose,
  onSubmit,
}: RegrelloObjectSearchColumnFilterProps) {
  const form = useForm({
    defaultValues: getDefaultVisibility(defaultColumns),
  });

  const onCloseWithReset = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmitInternal = useCallback(() => {
    const values = form.getValues();
    const visible = Object.entries(values)
      .map(([key, value]) => (value ? key : undefined))
      .filter(Boolean) as string[];
    onSubmit(visible);
    onClose();
  }, [form, onClose, onSubmit]);

  return (
    <RegrelloDialogV2
      actions={[
        { text: t`Cancel`, buttonProps: { onClick: onCloseWithReset, variant: "outline" } },
        {
          text: t`Apply`,
          buttonProps: {
            variant: "solid",
            intent: "primary",
            onClick: onSubmitInternal,
          },
        },
      ]}
      contentClassName="max-w-100"
      onClose={onCloseWithReset}
      open={isOpen}
      title={t`Select fields`}
    >
      <div>
        {defaultColumns?.map((column) => (
          <RegrelloControlledFormFieldCheckbox
            key={column.id}
            controllerProps={{
              name: column.id,
              control: form.control,
            }}
            dataTestId={DataTestIds.SYNCED_OBJECTS_COLUMN_VISIBILITY_SWITCH}
            disabled={column.disabled}
            label={<div className="flex items-center gap-1 w-fit p-1">{column.name}</div>}
          />
        ))}
      </div>
    </RegrelloDialogV2>
  );
}
