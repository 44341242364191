import { t } from "@lingui/macro";
import { ComparatorResult, EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  ConditionOperator,
  type CreateFieldInstanceValueInputs,
  type FieldFields,
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  type FieldInstanceValueInputType,
  type FieldUnit,
  FieldUnitType,
  PropertyDataType,
  type PropertyTypeFields,
  type UpdateFieldInstanceValueInputs,
  type UpdateStartingConditionsInputs,
  type ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloIcon } from "@regrello/ui-core";
import type { FieldPath, FieldValues } from "react-hook-form";

import { ValidationRules } from "../../../../constants/globalConstants";
import type { FieldInstanceBaseFields } from "../../../../types";
import { numberComparator } from "../../../../utils/comparators/numberComparator";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import { TableCellDefaultWidths } from "../../../../utils/tableCellWidthUtils";
import {
  getRegrelloDefaultFilterDefinitionNumberValue,
  getRegrelloFilterDefinitionNumberValue,
} from "../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import { RegrelloControlledFormFieldNumber } from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";
import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import type {
  CustomFieldPlugin,
  CustomFieldPluginV2RenderFormFieldProps,
  GetCreateFieldInstanceValueInputsFromFormValueParams,
} from "./types/CustomFieldPlugin";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { DEFAULT_INPUT_TYPE_IF_NO_VALUE, getConditionOperatorsByType } from "./utils/customFieldConstants";
import { extractAtMostOneValueOrThrow } from "./utils/extractAtMostOneValueOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { getUpdateStartingConditionsInputsForEmptyOperators } from "./utils/getUpdateStartingConditionsInputsForEmptyOperators";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'currency' field is invalid";
const WARNING_INVALID_FORM_VALUE = "Provided 'currency'-field form value is not a number string";
const ERROR_INVALID_VALUE_COUNT = "Provided 'currency' field instance cannot have multiple values";
const ERROR_INVALID_VALUE_TYPE = "Provided 'currency' field instance value must have type 'FieldInstanceValueFloat'";
const WARNING_UNEXPECTED_EMPTY_FORM_VALUE =
  "Provided 'currency' field form value must not be empty given the provided operator type";
const WARNING_UNEXPECTED_DEFINED_FORM_VALUE =
  "Provided 'currency' field form value must not be defined given the provided operator type";
const WARNING_INVALID_OPERATOR_TYPE = "Provided operator type is invalid for 'currency' fields";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.FLOAT;
}

function canProcessField(field: FieldFields): boolean {
  return (
    !field.isMultiValued &&
    field.allowedValues.length === 0 &&
    canProcessPropertyDataType(field.propertyType.dataType) &&
    field.fieldUnit != null
  );
}

type CurrencyFieldPluginFrontendValue = string;
type CurrencyFieldPluginType = CustomFieldPlugin<CurrencyFieldPluginFrontendValue>;

const getConditionOperators: CurrencyFieldPluginType["getConditionOperators"] = () =>
  getConditionOperatorsByType("CURRENCY");

const renderDisplayValue: CurrencyFieldPluginType["renderDisplayValue"] = (fieldInstance) => {
  const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
  const valueAsFloat = Number.parseFloat(frontendFieldInstance.value);
  const fieldUnitDisplayValue = getFieldUnitDisplayValue(
    { ...frontendFieldInstance.fieldUnit, type: FieldUnitType.CURRENCY },
    !Number.isNaN(valueAsFloat) ? valueAsFloat : null,
  );

  return fieldUnitDisplayValue;
};

const sortComparator: CurrencyFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,
  direction = "asc",
): ComparatorResult => {
  if (direction === "desc") {
    return CurrencyFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(fieldInstance1.field);

  const value1 = Number(fieldPlugin.getValueForFrontend(fieldInstance1));
  const value2 = Number(fieldPlugin.getValueForFrontend(fieldInstance2));

  return numberComparator(value1, value2);
};

/**
 * Describes a custom field that holds a currency value consisting of a number and a symbol.
 */
export const CurrencyFieldPlugin: CurrencyFieldPluginType = {
  uri: "com.regrello.customField.currency",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (propertyTypes: PropertyTypeFields[]): number | undefined => {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.FLOAT)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators,

  getCreateFieldInstanceValueInputsFromFormValue: (
    inputs: GetCreateFieldInstanceValueInputsFromFormValueParams,
  ): CreateFieldInstanceValueInputs => {
    const { displayOrder, field, inputType, spectrumFieldVersion, value } = inputs;
    // Expect the value to be passed in as a stringified number.
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(WARNING_INVALID_FORM_VALUE, { field, inputType, value }));
    }
    return {
      fieldId: field.id,
      floatValue:
        value == null || value === EMPTY_STRING
          ? undefined
          : !Number.isNaN(Number.parseFloat(value))
            ? Number.parseFloat(value)
            : undefined,
      inputType,
      displayOrder,
      spectrumFieldVersionId: spectrumFieldVersion?.id,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return t`Currency`;
  },

  getFilterDefinition: (_field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionNumberValue();
  },

  getFilterDefinitionWithValues: (_field: FieldFields, filter: ViewFilterFields) => {
    if (filter.value == null) {
      return undefined;
    }

    const value = Number.parseFloat(filter.value);
    return getRegrelloFilterDefinitionNumberValue(filter.operator, value);
  },

  getIconName: () => {
    return "currency-field";
  },

  getNameTemplateDisplayValueFromFormValue: (value, options) => {
    if (!isValueValid(value) || value == null || value === EMPTY_STRING) {
      return undefined;
    }

    const valueAsFloat = Number.parseFloat(value);
    const fieldUnitDisplayValue = getFieldUnitDisplayValue(
      options?.fieldUnit,
      !Number.isNaN(valueAsFloat) ? valueAsFloat : null,
    );

    return fieldUnitDisplayValue.toString();
  },

  getEmptyValueForFrontend: (_, defaultValue): string => {
    return defaultValue ? Number.parseFloat(defaultValue).toString() : EMPTY_STRING;
  },

  getPreferredHomeTableColumnWidth: () => {
    return TableCellDefaultWidths.NUMBER_CELL;
  },

  getSourceFieldInstance: (_fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    return undefined;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    if (!isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        leftFieldInstance,
        operator,
      });
      return undefined;
    }

    if (operator === ConditionOperator.EMPTY || operator === ConditionOperator.NOT_EMPTY) {
      if (value != null) {
        console.warn(WARNING_UNEXPECTED_DEFINED_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
      }
      return getUpdateStartingConditionsInputsForEmptyOperators(getFieldInstanceId(leftFieldInstance), operator);
    }

    if (value == null || value === EMPTY_STRING || (Array.isArray(value) && value.length === 0)) {
      console.warn(WARNING_UNEXPECTED_EMPTY_FORM_VALUE, {
        leftFieldInstance,
        value,
      });
      return undefined;
    }

    if (operator === ConditionOperator.BETWEEN) {
      // (zstanik): For the `BETWEEN` operator, the value is expected to be an array of 2
      // stringified numbers: a lower bound and upper bound.
      if (!Array.isArray(value) || value.length !== 2 || value.some((str) => Number.isNaN(Number.parseFloat(str)))) {
        console.warn(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value });
        return undefined;
      }

      return {
        leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
        operatorV2: operator,
        rightFloatMultiValue: value.map((str) => Number.parseFloat(str)),
        rightIntMultiValue: EMPTY_ARRAY,
        rightPartyIDMultiValue: EMPTY_ARRAY,
        rightStringMultiValue: EMPTY_ARRAY,
        rightTimeMultiValue: EMPTY_ARRAY,
      };
    }

    // (zstanik): For all other operators, the value is expected to be a stringified number.
    if (typeof value !== "string" || Number.isNaN(Number.parseFloat(value))) {
      console.warn(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value });
      return undefined;
    }

    return {
      leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
      operatorV2: operator,
      rightFloatValue: Number.parseFloat(value),
      rightFloatMultiValue: EMPTY_ARRAY,
      rightIntMultiValue: EMPTY_ARRAY,
      rightPartyIDMultiValue: EMPTY_ARRAY,
      rightStringMultiValue: EMPTY_ARRAY,
      rightTimeMultiValue: EMPTY_ARRAY,
    };
  },

  // (hchen): Currently this is only used for handling the edge case when flipping the field
  // instance input type from OPTIONAL to REQUESTED (i.e. required) when the a previously OPTIONAL
  // field is set as a native field on a future task. It's not necessary to implement for field
  // types other than date and user, since we only support those as native field now.
  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    _fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    throw new Error("not implmented yet");
  },

  getValueForFrontend: (fieldInstance: FieldInstanceFields | FieldInstanceBaseFields): string => {
    return extractAtMostOneValueOrThrow({
      fieldInstance,
      fieldInstanceValueTypeName: "FieldInstanceValueFloat",
      errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
      errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
      getterIfNoValue: () => EMPTY_STRING,
      getterIfValue: (fieldInstanceValue) =>
        fieldInstanceValue.floatValue == null || Number.isNaN(fieldInstanceValue.floatValue)
          ? EMPTY_STRING
          : fieldInstanceValue.floatValue.toString(),
    });
  },

  hasFieldUnit: (): boolean => {
    return true;
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.value.length === 0;
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    return (
      (frontendFieldInstance.value == null && proposedChange.floatValue == null) ||
      frontendFieldInstance.value === (proposedChange.floatValue?.toString() ?? EMPTY_STRING)
    );
  },

  isNeedsFieldUnit: (): boolean => {
    return true;
  },

  renderDisplayValue: renderDisplayValue,

  renderFormField: <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactNode => {
    if (!canProcessField(field)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
    }
    const isDeleted = props.fieldInstance?.field.deletedAt != null;
    const { operator } = props;
    if (operator != null && !isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        field,
        operator,
      });
      return undefined;
    }

    return (
      <RegrelloControlledFormFieldNumber
        {...props}
        key={props.controllerProps.name}
        controllerProps={{
          ...props.controllerProps,
          rules: {
            ...props.controllerProps.rules,
            ...ValidationRules.NUMBER,
          },
        }}
        currencySymbol={props.fieldInstance?.spectrumFieldVersion?.fieldUnit?.symbol ?? field.fieldUnit?.symbol}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        infoTooltipText={props.description}
        isDeleted={isDeleted}
      />
    );
  },

  renderIcon: (props) => {
    return <RegrelloIcon {...props} iconName="currency-field" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }
    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: options?.context ?? "table" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: renderDisplayValue(fieldInstance, { context: options?.context ?? "table" }),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName,
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  sortComparator,
};

interface FrontendCurrencyFieldInstance {
  name: string;
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  value: string;

  /** The denomination in which the currency value is expressed (e.g., US dollars, Euros). */
  fieldUnit: {
    /** The currency denomination's unique ID. */
    id: number;

    /** The currency denomination's abbreviated display name (e.g., USD, EUR). */
    name: string;

    /** The currency denomination's symbol (e.g., $, £). */
    symbol: string;
  };
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): FrontendCurrencyFieldInstance {
  const { field, spectrumFieldVersion } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const fieldUnit = spectrumFieldVersion?.fieldUnit ?? field.fieldUnit;
  const { name } = field;

  // (clewis: We already verified this in canProcessField, but best to have a redundant check here:
  if (fieldUnit == null) {
    throw new Error(ERROR_INVALID_FIELD);
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractAtMostOneValueOrThrow({
    fieldInstance,
    fieldInstanceValueTypeName: "FieldInstanceValueFloat",
    errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
    errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
    getterIfNoValue: () => ({
      name,
      fieldUnit,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstanceInputType: undefined,
      value: EMPTY_STRING,
    }),
    getterIfValue: (fieldInstanceValue) => ({
      name,
      fieldUnit,
      inputType: fieldInstanceValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue) &&
        fieldInstanceValue.crossWorkflowSinksFieldInstanceValueFloat != null
          ? fieldInstanceValue.crossWorkflowSinksFieldInstanceValueFloat.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue)
        ? fieldInstanceValue.crossWorkflowSourceFieldInstanceValueFloat?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValue.sinksFieldInstanceValueFloat?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: isFieldInstanceFields ? fieldInstanceValue.sourceFieldInstanceValueFloat?.id : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceValueFloat?.inputType
        : undefined,
      value:
        fieldInstanceValue.floatValue == null || Number.isNaN(fieldInstanceValue.floatValue)
          ? EMPTY_STRING
          : fieldInstanceValue.floatValue.toString(),
    }),
  });
}

function getFieldUnitDisplayValue(fieldUnit: FieldUnit | undefined, value: number | null) {
  if (value == null) {
    return "";
  }

  const symbol = fieldUnit?.symbol;
  return symbol != null ? `${symbol}${value}` : value;
}

function isOperatorValid(
  operator: ConditionOperator,
): operator is
  | ConditionOperator.EMPTY
  | ConditionOperator.NOT_EMPTY
  | ConditionOperator.GREATER_THAN_OR_EQUALS
  | ConditionOperator.LESS_THAN_OR_EQUALS
  | ConditionOperator.EQUALS
  | ConditionOperator.NOT_EQUALS
  | ConditionOperator.BETWEEN {
  return getConditionOperators().find((stageStartOperator) => stageStartOperator.operator === operator) != null;
}

function isValueValid(value: unknown): value is CurrencyFieldPluginFrontendValue | null {
  return (
    value == null || value === EMPTY_STRING || (typeof value === "string" && !Number.isNaN(Number.parseFloat(value)))
  );
}
