import { t } from "@lingui/macro";
import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import type { FieldFields, FieldInstanceFields } from "@regrello/graphql-api";
import { RegrelloButton, RegrelloTooltip } from "@regrello/ui-core";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { type FieldArrayWithId, useFieldArray, type UseFormReturn, useWatch } from "react-hook-form";
import { useUnmount } from "react-use";

import { ValidationRules } from "../../../../../constants/globalConstants";
import { RegrelloControlledFormFieldSpectrumFormSelect } from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import type {
  FormSelectFormVersionFields,
  RegrelloFormFieldSpectrumFormSelectProps,
} from "../../../../molecules/formFields/RegrelloFormFieldSpectrumFormSelect";
import { RegrelloConfigureCustomFieldsInputMappingForm } from "./RegrelloConfigureCustomFieldsInputMappingForm";

export interface RegrelloConfigureSpectrumFormsFormFields {
  forms: FormSelectFormVersionFields[];
}

export namespace useConfigureSpectrumForms {
  export interface Args {
    /**
     * The context of where the forms are being used.
     */
    context?: RegrelloFormFieldSpectrumFormSelectProps["context"];

    /**
     * Whether forms cannot be added to the task.
     *
     * @default false
     */
    disallowAddForms?: boolean;

    /**
     * The tooltip text to display if the add forms button is disabled.
     *
     * @default FormAndFieldsCannotBeProvidedAtTheSameTime
     */
    disallowAddFormsTooltipText?: string;

    /** Callback invoked when the form is deleted. */
    handleFormDelete?: () => void;

    /**
     * The existing spectrum spectrumFormManager that were added to an action-item template at creation time. If
     * provided, the component will prepopulate this data to related Form Fields.
     */
    initialSpectrumForms?: FormSelectFormVersionFields[];

    /**
     * Whether the form-selection is locked and cannot be changed. This functions similarly to
     * disable, but will display the lock-associated messages/icons.
     *
     * @default false
     */
    isLocked?: boolean;

    /**
     * The role fields that are already used in the workflow or blueprint. If a form uses a role
     * field, it may not be added.
     */
    roleFields?: FieldFields[];

    /**
     * The spectrum form configuration form. Must include a `customFields` key with an array value
     * that conforms to the structure in `AddCustomFieldFormSectionFields`.
     */
    spectrumFormManager: UseFormReturn<RegrelloConfigureSpectrumFormsFormFields>;

    /**
     * The field instances that are selected in the `Share Information` section. If a form has a
     * field with the same spectrum field id as one of these field instances, it will be disabled in
     * the form select dropdown.
     */
    selectedInheritableFieldInstances: FieldInstanceFields[];

    inputMappingFormProps?: RegrelloConfigureCustomFieldsInputMappingForm.Props;
  }

  export interface Return {
    spectrumForms: Array<FieldArrayWithId<RegrelloConfigureSpectrumFormsFormFields, "forms">>;
    renderAddFormButton: () => React.ReactElement;
    renderFormRows: () => React.ReactElement;
    renderInputMappingForm: () => React.ReactNode;
  }
}

export function useConfigureSpectrumForms({
  context,
  disallowAddForms,
  disallowAddFormsTooltipText = t`Form and fields cannot be provided at the same time.`,
  isLocked = false,
  handleFormDelete,
  initialSpectrumForms,
  roleFields,
  inputMappingFormProps,
  selectedInheritableFieldInstances,
  spectrumFormManager,
}: useConfigureSpectrumForms.Args): useConfigureSpectrumForms.Return {
  const initialSpectrumFormUUIDsRef = useRef(new Set());

  const {
    fields: spectrumForms,
    append: appendForm,
    remove: removeForm,
    update: updateForm,
  } = useFieldArray({ control: spectrumFormManager.control, name: "forms" });
  const selectedSpectrumForm = useWatch({ control: spectrumFormManager.control, name: "forms.0" });

  const handleAddFormClick = useCallback(() => {
    // (hchen): Add this declaration to explicitly type the field array entry because `appendForm`
    // doesn't do that for us.
    const mockForm: FormSelectFormVersionFields = {
      form: {
        id: -1,
        uuid: EMPTY_STRING,
        createdAt: EMPTY_STRING,
        createdBy: {
          id: -1,
        },
      },
      id: -3,
      uuid: EMPTY_STRING,
      description: EMPTY_STRING,
      name: EMPTY_STRING,
      spectrumFieldNamesById: new Map(),
      compositeFieldInstances: EMPTY_ARRAY,
      createdBy: {
        id: -1,
      },
      isPublic: false,
    };
    appendForm(mockForm, { shouldFocus: false });
  }, [appendForm]);

  const handleDeleteFormClick = useCallback(
    (index: number | undefined) => {
      if (spectrumFormManager == null) {
        return true;
      }
      if (index != null) {
        removeForm(index);
      }
      handleFormDelete?.();
      return true;
    },
    [spectrumFormManager, handleFormDelete, removeForm],
  );

  const handleSpectrumFormChange = useCallback(
    async (index: number, newValue: FormSelectFormVersionFields | null) => {
      if (spectrumFormManager == null) {
        return;
      }

      if (newValue == null) {
        return;
      }

      updateForm(index, newValue);
    },
    [spectrumFormManager, updateForm],
  );

  // (hchen): This effect is used to prepopulate the form fields with the initial spectrum forms. We
  // use a set to keep track of the form version uuid being used to prevent excessive calls to the
  // effect because useDeepCompareEffect doesn't work.
  useEffect(() => {
    if (initialSpectrumForms == null || initialSpectrumForms.length === 0) {
      return;
    }

    initialSpectrumForms.forEach((spectrumForm) => {
      if (initialSpectrumFormUUIDsRef.current.has(spectrumForm.uuid)) {
        return;
      }
      if (initialSpectrumFormUUIDsRef.current.size > 0) {
        // (hchen): Handles the edge case that if the user has a running task with the form
        // currently being used. And he has another tab open creating a new version of the form. In
        // this case the form version used by the task template will be bumped in the background.
        // Causing the hook to think that the updated form version is a new form and attempts to
        // append it to the list. So we need to clear to old form and before adding the new one for
        // rendering.
        initialSpectrumFormUUIDsRef.current.clear();
        removeForm();
      }

      initialSpectrumFormUUIDsRef.current.add(spectrumForm.uuid);
      appendForm(spectrumForm, { shouldFocus: false });
      void handleSpectrumFormChange(0, spectrumForm);
    });
  }, [appendForm, handleSpectrumFormChange, initialSpectrumForms, removeForm]);

  useUnmount(() => {
    removeForm();
    initialSpectrumFormUUIDsRef.current.clear();
  });

  const renderAddFormButton = useCallback(() => {
    return (
      <RegrelloTooltip content={disallowAddForms ? disallowAddFormsTooltipText : EMPTY_STRING}>
        {/* (hchen): This <span> is required for the tooltip to work with a disabled button. */}
        <span>
          <RegrelloButton
            className="ml-2"
            dataTestId={DataTestIds.ADD_ACTION_ITEM_DIALOG_ADD_FORM_BUTTON}
            disabled={disallowAddForms}
            intent="primary"
            onClick={handleAddFormClick}
            startIcon="add"
            variant="ghost"
          >
            {t`Add form`}
          </RegrelloButton>
        </span>
      </RegrelloTooltip>
    );
  }, [disallowAddForms, disallowAddFormsTooltipText, handleAddFormClick]);

  const spectrumFieldIdsOccupiedByCustomFields = useMemo(() => {
    return selectedInheritableFieldInstances.reduce<number[]>((acc, fieldInstance) => {
      const fieldId = fieldInstance.spectrumFieldVersion?.spectrumField?.id;
      if (fieldId != null) {
        acc.push(fieldId);
      }
      return acc;
    }, []);
  }, [selectedInheritableFieldInstances]);

  const renderFormRows = useCallback(() => {
    return (
      <>
        {spectrumForms.map((spectrumForm, index) => {
          return (
            spectrumForm != null && (
              <div>
                <div className="w-full flex">
                  <RegrelloControlledFormFieldSpectrumFormSelect
                    className="flex-1 mr-2"
                    context={context}
                    controllerProps={{
                      control: spectrumFormManager.control,
                      name: `forms.${index}`,
                      rules: ValidationRules.REQUIRED,
                    }}
                    dataTestId={DataTestIds.ADD_ACTION_ITEM_DIALOG_SPECTRUM_FORM_SELECT}
                    excludedFieldIds={roleFields?.map((r) => r.id)}
                    isLocked={isLocked}
                    label={t`Form`}
                    labelWidth={148}
                    occupiedSpectrumFieldIds={spectrumFieldIdsOccupiedByCustomFields}
                    onValueChange={async (_name, newValue) => handleSpectrumFormChange(index, newValue)}
                  />

                  <RegrelloTooltip content={isLocked ? t`This form cannot be edited` : t`Remove form`} side="right">
                    <div>
                      <RegrelloButton
                        dataTestId={DataTestIds.ADD_ACTION_ITEM_DIALOG_DELETE_FORM_BUTTON}
                        disabled={isLocked}
                        iconOnly={true}
                        onClick={() => handleDeleteFormClick(index)}
                        shape="circle"
                        startIcon={isLocked ? "locked" : "delete"}
                        variant="ghost"
                      />
                    </div>
                  </RegrelloTooltip>
                </div>
              </div>
            )
          );
        })}
      </>
    );
  }, [
    context,
    isLocked,
    handleDeleteFormClick,
    handleSpectrumFormChange,
    roleFields,
    spectrumFieldIdsOccupiedByCustomFields,
    spectrumFormManager.control,
    spectrumForms,
  ]);

  const renderInputMappingForm = useCallback(() => {
    return (
      selectedSpectrumForm != null &&
      inputMappingFormProps != null &&
      inputMappingFormProps?.defaultValues.destinationFieldInstances.length > 0 && (
        <div className="pb-2 pt-1">
          <RegrelloConfigureCustomFieldsInputMappingForm.Component
            {...inputMappingFormProps}
            omitSectionStyling={true}
            selectedSpectrumForm={selectedSpectrumForm}
          />
        </div>
      )
    );
  }, [inputMappingFormProps, selectedSpectrumForm]);

  return {
    spectrumForms,
    renderAddFormButton,
    renderFormRows,
    renderInputMappingForm,
  };
}
