import { assertNever, isDefined } from "@regrello/core-utils";
import { type FieldFields, PropertyDataType, type SpectrumFieldVersionFields } from "@regrello/graphql-api";

import { getBasePartyTypeUnion, getPartyFullNameAndOrganization } from "../../../../../utils/parties/partyUtils";
import type { CustomFieldFrontendSelectableOption } from "../types/CustomFieldFrontendSelectableOption";

/**
 *  @deprecated Prefer the use of `extractAllowedValuesFromFieldVersionForFrontend()` to avoid unversioned allowed values.
 */
export function extractAllowedValuesFromFieldForFrontend(
  field: Pick<FieldFields, "allowedValues">,
): CustomFieldFrontendSelectableOption[] {
  return field.allowedValues
    .map((allowedValue) => {
      switch (allowedValue.__typename) {
        case "FieldAllowedValueString":
          return {
            id: allowedValue.id,
            value: allowedValue.stringValue,
          };
        case "FieldAllowedValueParty":
          return {
            id: allowedValue.id,
            value: getPartyFullNameAndOrganization(getBasePartyTypeUnion(allowedValue.partyValue)),
          };
        default:
          assertNever(allowedValue);
      }
    })
    .filter(isDefined);
}

export function extractAllowedValuesFromFieldVersionForFrontend(
  fieldVersion: Pick<SpectrumFieldVersionFields, "allowedValues" | "propertyType" | "id">,
): CustomFieldFrontendSelectableOption[] {
  return fieldVersion.allowedValues
    .map((allowedValue) => {
      switch (fieldVersion.propertyType.dataType) {
        case PropertyDataType.STRING:
          if (allowedValue.stringValue == null) {
            return;
          }
          return {
            id: allowedValue.id,
            value: allowedValue.stringValue,
          };
        case PropertyDataType.PARTY_ID:
          if (allowedValue.partyValue == null) {
            return;
          }
          return {
            id: allowedValue.id,
            value: getPartyFullNameAndOrganization(getBasePartyTypeUnion(allowedValue.partyValue)),
          };
        default:
          throw new Error(
            `Received unhandled property data type: ${fieldVersion.propertyType} for fieldVersionId: ${fieldVersion.id}`,
          );
      }
    })
    .filter(isDefined);
}
