import type { ValuesUnion, WithClassName, WithDataTestId } from "@regrello/core-utils";
import React from "react";

import type { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloIconStyler } from "./RegrelloIconStyler";
import DocusignFullColorIcon from "./svgs/brand/docusign-full-color-icon.svg?react";
import GoogleFullColorIcon from "./svgs/brand/google-full-color-icon.svg?react";
import SharepointSquareLogo from "./svgs/brand/sharepoint-full-color-icon.svg?react";

export interface RegrelloBrandIconProps extends WithDataTestId, WithClassName {
  /** The icon to render. */
  brand: RegrelloBrandIconName;

  /**
   * The size of the rendered icon. If unspecified, the SVG is rendered at its maximum size.
   *
   * @default "medium"
   */
  size?: RegrelloSize;
}

export const RegrelloBrandIconName = {
  DOCUSIGN: "docusign",
  GOOGLE: "google",
  SHAREPOINT: "sharepoint",
} as const;

export type RegrelloBrandIconName = ValuesUnion<typeof RegrelloBrandIconName>;

const brandIcons: Record<RegrelloBrandIconName, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  [RegrelloBrandIconName.DOCUSIGN]: DocusignFullColorIcon,
  [RegrelloBrandIconName.GOOGLE]: GoogleFullColorIcon,
  [RegrelloBrandIconName.SHAREPOINT]: SharepointSquareLogo,
};

function getBrandIconSvgComponentByName(
  iconName: RegrelloBrandIconName,
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> {
  return brandIcons[iconName];
}

/** A standalone icon in Regrello. Renders an SVG that can be rendered anywhere in the UI. */
export const RegrelloBrandIcon = React.memo<RegrelloBrandIconProps>(function RegrelloIconFn({
  brand,
  className,
  size = "medium",
  dataTestId,
}: RegrelloBrandIconProps) {
  const IconSvgComponent = getBrandIconSvgComponentByName(brand);

  const icon = <IconSvgComponent data-testid={dataTestId} />;

  return (
    <span className={className}>
      <RegrelloIconStyler size={size}>{icon}</RegrelloIconStyler>
    </span>
  );
});
