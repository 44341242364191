import type { KeyNames } from "@regrello/core-utils";
import { RegrelloChipInput, type RegrelloChipInputProps, type RegrelloSize } from "@regrello/ui-core";
import React, { useCallback } from "react";

import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";

/**
 * This component renders a text input component that adds values as tags when the user presses a
 * specified delimiter.
 */
export interface RegrelloFormFieldTagInputProps extends RegrelloFormFieldBaseProps<string[]> {
  /** Whether to auto-focus this input on mount. */
  autoFocus?: boolean;

  /**
   * An array of event.key names that control when an input is registered as a tag.
   *
   * @default [KeyNames.ENTER]
   */
  delimiters?: KeyNames[];

  /**
   * Disables only the ability of the user to input new values by passing `disabled` specifically to
   * the `Autocomplete` component behind the chips. Still allows chips to be clicked for deletion.
   */
  disableUserInput?: boolean;

  /** A ref to pass to this input element. */
  inputRef?: React.Ref<HTMLInputElement>;

  /** Callback invoked when the field loses focus. */
  onBlur?: React.FocusEventHandler<HTMLElement>;

  /** Callback invoked when the array of selected values changes.  */
  onChange: (nextSelection: string[]) => void;

  /** Placeholder text to display when no value has been typed. */
  placeholder?: string;

  /**
   * A separator pattern to split input text into multiple values.
   *
   * @default /[,\n\r]+/g
   */
  separator?: string | RegExp;

  /** The display size of this input. */
  size?: RegrelloSize;

  /** An array of selected values. */
  value: string[];
}

export const RegrelloFormFieldTagInput = React.memo(function RegrelloFormFieldTagInputFn({
  delimiters,
  disabled,
  disableUserInput,
  error,
  helperText,
  inputRef,
  isEmphasized,
  onChange,
  separator,
  size = "medium",
  value: tags,
  ...layoutProps
}: RegrelloFormFieldTagInputProps) {
  const onChipAdd = useCallback(
    (newValues: string[]) => {
      onChange([...tags, ...newValues]);
    },
    [onChange, tags],
  );

  const onChipRemove = useCallback(
    (removedValue: string) => {
      onChange(tags.filter((tag) => tag !== removedValue));
    },
    [onChange, tags],
  );

  const onChipClear = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const intent: RegrelloChipInputProps<unknown>["intent"] =
    error != null ? "danger" : isEmphasized ? "warning" : "neutral";

  return (
    <RegrelloFormFieldLayout {...layoutProps} error={error} helperText={helperText}>
      <RegrelloChipInput
        addOnBlur={true}
        delimiters={delimiters}
        disabled={disableUserInput || disabled}
        fullWidth={true}
        inputRef={inputRef}
        intent={intent}
        onAdd={onChipAdd}
        onClear={onChipClear}
        onRemove={onChipRemove}
        separator={separator}
        size={size}
        values={tags}
      />
    </RegrelloFormFieldLayout>
  );
});
