import env from "@env";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { I18nProvider } from "@lingui/react";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { RegrelloIcon, RegrelloNonIdealState } from "@regrello/ui-core";
import { withProfiler } from "@sentry/react";
import { useAtom, useAtomValue } from "jotai";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { useAsync, useMount } from "react-use";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { initTranslation } from "./i18n";
import { FeatureFlagService } from "./services/FeatureFlagService";
import { RegrelloRestApiService } from "./services/RegrelloRestApiService";
import { isAuthenticatedAtom, isWaitingForAuth0AuthenticationAtom, userThemeAtom } from "./state/applicationState";
import { RegrelloAuthenticationProvider } from "./ui/app/authentication/RegrelloAuthenticationProvider";
import { RoutePaths } from "./ui/app/routes/consts";
import { getWorkspaceRouteTo } from "./ui/app/routes/routeCreatorUtils";
import { UnauthenticatedCredentialsErrorPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedCredentialsErrorPage";
import { UnauthenticatedEmailSentPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedEmailSentPage";
import { UnauthenticatedRequestForEmailPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedRequestForEmailPage";
import { UnauthenticatedSignInPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedSignInPage";
import { UnauthenticatedSignInPageV2 } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedSignInPageV2";
import { UnauthenticatedSignInSsoPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedSignInSsoPage";
import { UnauthenticatedSignUpPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedSignUpPage";
import { UnsubscribePage } from "./ui/views/pages/auth/unauthenticatedPages/UnsubscribePage";
import { DeviceVerificationEmailSentPage } from "./ui/views/pages/regrelloLite/DeviceVerificationEmailSentPage";
import { RegrelloSessionStorageKey, sessionStorageSetTyped } from "./utils/getFromSessionStorage";
import { useErrorLogger } from "./utils/hooks/useErrorLogger";
import { useIframeThemeChangeListener } from "./utils/hooks/useIframeThemeChangeListener";
import { MonitorAndReportFpsPerformance } from "./utils/MonitorAndReportFpsPerformance";

const LazyAppWithAuthProvider = React.lazy(() =>
  // eslint-disable-next-line lingui/no-unlocalized-strings
  import("./AppWithAuthProvider").then((module) => ({
    default: module.AppWithAuthProvider,
  })),
);

const LazyNonLoggedInActionItemDetailPage = React.lazy(() =>
  // eslint-disable-next-line lingui/no-unlocalized-strings
  import("./ui/views/pages/regrelloLite/NonLoggedInActionItemDetailPage").then((module) => ({
    default: module.NonLoggedInActionItemDetailPage,
  })),
);

// TODO (clewis): Use Sentry.useProfiler hook.
export const App = withProfiler(function AppFn() {
  const { logErrorMessage } = useErrorLogger();

  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const [isWaitingForAuth0Authentication, setIsWaitingForAuth0Authentication] = useAtom(
    isWaitingForAuth0AuthenticationAtom,
  );

  // get FeatureFlag based on IP
  const { value: isRevisedSignInFeatureFlagEnabledResponse } = useAsync(async () => {
    return RegrelloRestApiService.getFeatureFlagBasedOnIP(FeatureFlagKey.REVISED_SIGN_IN_2024_11);
  }, []);
  const isRevisedSignInFeatureFlagEnabled = isRevisedSignInFeatureFlagEnabledResponse?.json.enabled;

  const userTheme = useAtomValue(userThemeAtom);
  useEffect(() => {
    document.documentElement.dataset.theme = userTheme;
  }, [userTheme]);

  // If we're in an iframe, listen for changes to the parent page's theme so we stay in sync.
  useIframeThemeChangeListener();

  // If true, allow login spoofing (dev only).
  const [isLoginSpoofingEnabled, setIsLoginSpoofingEnabled] = useState(false);

  const handleLoginSpoofingChange = useCallback(() => {
    setIsLoginSpoofingEnabled(!isLoginSpoofingEnabled);
  }, [isLoginSpoofingEnabled]);

  useMount(() => {
    if (!isAuthenticated) {
      if (
        window.location.pathname !== RoutePaths.INTERNAL_GRANULAR_ACCESS &&
        window.location.pathname !== RoutePaths.LOGIN &&
        window.location.pathname !== RoutePaths.HOME
      ) {
        sessionStorageSetTyped(RegrelloSessionStorageKey.REDIRECT_URL_AFTER_LOGIN, window.location.href);
      }
    }
  });

  useEffect(() => {
    if (isAuthenticated && isWaitingForAuth0Authentication) {
      setIsWaitingForAuth0Authentication(false);
    }
  }, [isAuthenticated, isWaitingForAuth0Authentication, setIsWaitingForAuth0Authentication]);

  const envState = useAsync(async () => {
    FeatureFlagService.registerFeatureFlagOverrides(env.FeatureFlagOverrides);
    await FeatureFlagService.initializeWithDefaultUserInNonAuthenticatedContext(env.LaunchDarklyClientId);

    await initTranslation();
  }, []);

  if (
    envState.loading ||
    (!FeatureFlagService.isInitialized() &&
      !FeatureFlagService.hasInitializeError() &&
      !FeatureFlagService.hasIdentifyUserError())
  ) {
    return null;
  }

  if (FeatureFlagService.hasInitializeError()) {
    logErrorMessage(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      "[Unexpected error] FeatureFlagsService did not initialize successfully, and the FeatureFlagService didn't 'catch' this error during initialization.",
    );
    return (
      <RegrelloNonIdealState
        actionProps={{
          text: t`Home`,
          onClick: () => {
            window.location.reload();
          },
        }}
        description={t`There was an error loading the Regrello platform. Check to ensure that your browser is not blocking requests to https://app.launchdarkly.com. Please contact us if you continue to have trouble.`}
        size="large"
        title={t`Something went wrong`}
        visual={<RegrelloIcon iconName="alert" size="x-large" />}
      />
    );
  }

  if (FeatureFlagService.hasIdentifyUserError()) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    logErrorMessage("Launch Darkly failed to switch to current user.");
    return (
      <RegrelloNonIdealState
        actionProps={{
          text: t`Home`,
          onClick: () => {
            window.location.reload();
          },
        }}
        description={t`There was an error loading the Regrello platform. Check to ensure that your browser is not blocking requests to https://app.launchdarkly.com. Please contact us if you continue to have trouble.`}
        size="large"
        title={t`Something went wrong`}
        visual={<RegrelloIcon iconName="alert" size="x-large" />}
      />
    );
  }

  return (
    <I18nProvider i18n={i18n}>
      <RegrelloAuthenticationProvider
        auth0Audience={env.Auth0Audience}
        auth0ClientID={env.Auth0ClientId}
        auth0Domain={env.Auth0Domain}
        isLoginSpoofingEnabled={isLoginSpoofingEnabled}
      >
        <MonitorAndReportFpsPerformance />
        {isAuthenticated ? (
          <Suspense fallback={null}>
            <LazyAppWithAuthProvider
              enableSentryPiiCollection={env.SentryEnablePII}
              launchDarklyClientId={env.LaunchDarklyClientId}
            />
          </Suspense>
        ) : isWaitingForAuth0Authentication ? null : (
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <Routes>
                {/* feature flag to enable new login page */}
                <Route
                  element={
                    isRevisedSignInFeatureFlagEnabled ? (
                      <UnauthenticatedSignInPageV2
                        isLoginSpoofingEnabled={isLoginSpoofingEnabled}
                        onLoginSpoofingChange={handleLoginSpoofingChange}
                      />
                    ) : (
                      <UnauthenticatedSignInPage
                        isLoginSpoofingEnabled={isLoginSpoofingEnabled}
                        onLoginSpoofingChange={handleLoginSpoofingChange}
                      />
                    )
                  }
                  path={RoutePaths.LOGIN}
                />
                <Route element={<UnauthenticatedCredentialsErrorPage />} path={RoutePaths.CREDENTIALS_ERROR} />
                <Route element={<UnauthenticatedSignInSsoPage />} path={RoutePaths.LOGIN_SSO} />
                <Route element={<UnauthenticatedSignUpPage />} path={RoutePaths.SIGN_UP} />
                <Route
                  element={<UnauthenticatedRequestForEmailPage mode="lostInvite" />}
                  path={getWorkspaceRouteTo(RoutePaths.INVITE_REQUEST)}
                />
                <Route element={<UnauthenticatedEmailSentPage mode="lostInvite" />} path={RoutePaths.INVITE_SENT} />
                <Route
                  element={<UnauthenticatedEmailSentPage mode="verificationSent" />}
                  path={RoutePaths.VERIFICATION_SENT}
                />
                <Route
                  element={<UnauthenticatedRequestForEmailPage mode="passwordReset" />}
                  path={RoutePaths.PASSWORD_RESET_REQUEST}
                />
                <Route
                  element={<UnauthenticatedEmailSentPage mode="passwordReset" />}
                  path={RoutePaths.PASSWORD_RESET_SENT}
                />
                <Route
                  element={
                    <Suspense fallback={null}>
                      <LazyNonLoggedInActionItemDetailPage
                        enableSentryPiiCollection={env.SentryEnablePII}
                        launchDarklyClientId={env.LaunchDarklyClientId}
                      />
                    </Suspense>
                  }
                  path={RoutePaths.SUBMISSION}
                />
                <Route element={<DeviceVerificationEmailSentPage />} path={RoutePaths.REQUEST_VERIFICATION} />
                <Route element={<UnsubscribePage />} path={RoutePaths.UNSUBSCRIBE} />

                <Route
                  element={
                    <Navigate
                      replace={true}
                      to={{
                        pathname: RoutePaths.LOGIN,
                        search: window.location.search,
                      }}
                    />
                  }
                  path="*"
                />
              </Routes>
            </QueryParamProvider>
          </BrowserRouter>
        )}
      </RegrelloAuthenticationProvider>
    </I18nProvider>
  );
});
