import env from "@env";
import { t, Trans } from "@lingui/macro";
/* eslint-disable react-hooks/exhaustive-deps */
import { clsx, EMPTY_STRING, getEmailDomain } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  RegrelloBrandIcon,
  RegrelloButton,
  RegrelloFullColorLogoMarkIcon,
  RegrelloFullColorReceiveInviteIcon,
  RegrelloIcon,
  RegrelloIconStyler,
  RegrelloLinkV2,
  RegrelloTypography,
} from "@regrello/ui-core";
import throttle from "lodash/throttle";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMount } from "react-use";

import { AuthenticationConnectionName } from "../../../../../constants/auth";
import { REGRELLO_PRIVACY_POLICY_URL, ValidationRules } from "../../../../../constants/globalConstants";
import { PendoMetricName } from "../../../../../constants/PendoMetricName";
import { RegrelloRestApiService } from "../../../../../services/RegrelloRestApiService";
import { isDevEnvironment } from "../../../../../utils/environmentUtils";
import { RegrelloSessionStorageKey, sessionStorageSetTyped } from "../../../../../utils/getFromSessionStorage";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { useRegrelloHistory } from "../../../../../utils/hooks/useRegrelloHistory";
import { useRegrelloAuthV2 } from "../../../../app/authentication/userContextUtils";
import { RoutePaths, RouteQueryStringKeys } from "../../../../app/routes/consts";
import { RegrelloCopyright } from "../../../../atoms/copyright/RegrelloCopyright";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloFormFieldCheckbox } from "../../../../molecules/formFields/RegrelloFormFieldCheckbox";
import { RegrelloRedirectingSpinner } from "./_internal/RegrelloRedirectingSpinner";

const ARTIFICIAL_DELAY = 1500;

export interface UnauthenticatedSignInPageProps {
  isLoginSpoofingEnabled: boolean;
  onLoginSpoofingChange: () => void;
}

export interface UnauthenticatedSignInPageFormFields {
  email: string;
  password: string;
}

const SUBMIT_THROTTLE_IN_MILLISECONDS = 1000;
const DOMAIN_PREFIX_MATCH_MIN_LENGTH = 4;
const TIMES_TRIED_BEFORE_REDIRECT_THRESHOLD = 10;

export const UnauthenticatedSignInPage = React.memo(function UnauthenticatedSignInPageFn({
  isLoginSpoofingEnabled,
  onLoginSpoofingChange,
}: UnauthenticatedSignInPageProps) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const { getQueryParam, push } = useRegrelloHistory();

  // (krashanoff): Pulls the error message as returned from Auth0, and makes it presentable.
  const { [RouteQueryStringKeys.AUTH0_ERROR_DESCRIPTION]: errorDescriptionUnformatted } = useQueryMap();
  const errorDescription = useMemo(() => {
    if (errorDescriptionUnformatted == null || errorDescriptionUnformatted.length === 0) {
      return null;
    }
    if (errorDescriptionUnformatted.length < 2) {
      return errorDescriptionUnformatted;
    }
    return (
      errorDescriptionUnformatted.charAt(0).toUpperCase() +
      errorDescriptionUnformatted.slice(1) +
      (!errorDescriptionUnformatted.endsWith(".") && ".")
    );
  }, [errorDescriptionUnformatted]);

  const { login, authError, loading: isLoading, clearError } = useRegrelloAuthV2();
  const { supplier: supplierQueryValue } = useQueryMap();
  const isSupplierMode = supplierQueryValue != null;

  const email = getQueryParam(RouteQueryStringKeys.EMAIL) ?? EMPTY_STRING;
  const userVerificationUUID = getQueryParam(RouteQueryStringKeys.USER_VERIFICATION_UUID);
  if (userVerificationUUID != null) {
    sessionStorageSetTyped(RegrelloSessionStorageKey.USER_VERIFICATION_UUID, userVerificationUUID);
  }

  const defaultValues = useMemo<UnauthenticatedSignInPageFormFields>(
    () => ({
      email,
      password: EMPTY_STRING,
    }),
    [email],
  );

  const form = useForm<UnauthenticatedSignInPageFormFields>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: defaultValues,
  });

  // eslint-disable-next-line lingui/no-unlocalized-strings
  const [whichLoginButtonPressed, setWhichLoginButtonPressed] = useState<"default" | "sso" | "google">("default");

  const [ssoConnectionName, setSsoConnectionName] = useState<string | null>(null);
  const [isSsoOptional, setIsSsoOptional] = useState<boolean>(false);

  const isPendoEnabled = env.IsPendoEnabled;

  const shouldUseSso = ssoConnectionName != null && !isSsoOptional;

  const onContinueWithSso = useCallback(
    async (connectionName?: string) => {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      setWhichLoginButtonPressed("sso");
      if (connectionName) {
        await login(
          {
            connection: connectionName,
            email: form.getValues().email,
            password: form.getValues().password,
          },
          isSupplierMode ? ARTIFICIAL_DELAY : undefined,
        );
      } else {
        await login({
          connection: AuthenticationConnectionName.SSO,
        });
      }
    },
    [form, isSupplierMode, login],
  );

  const throttledOnContinueWithSso = useMemo(
    () => throttle(onContinueWithSso, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [onContinueWithSso],
  );

  const updateSsoConnectionName = useCallback(
    async (newEmail?: string): Promise<string | null> => {
      const domain = getEmailDomain(newEmail ?? "");

      // (krashanoff): Don't start searching on keystroke until it's a
      // sufficiently long domain. This is an arbitrary value.
      if (domain.length < DOMAIN_PREFIX_MATCH_MIN_LENGTH) {
        setSsoConnectionName(null);
        return null;
      }

      const result = await RegrelloRestApiService.isSso(domain);
      if (result.status === 200 && result.json.isSso) {
        setSsoConnectionName(result.json.connectionName);
        setIsSsoOptional(result.json.isSsoOptional);
        if (isSupplierMode && isPendoEnabled) {
          window.pendo?.track(PendoMetricName.SUPPLIER_AUTO_SIGN_IN, { connectionName: result.json.connectionName });
        }
        await throttledOnContinueWithSso(result.json.connectionName);
        return result.json.connectionName;
      }
      setSsoConnectionName(null);
      return null;
    },
    [isSupplierMode, throttledOnContinueWithSso, isPendoEnabled],
  );

  // (krashanoff): Some of the dependencies here should be picked up automatically
  // by React.
  //
  // biome-ignore lint/correctness/useExhaustiveDependencies: This throttle setup is a bit confusing for the rules - refactor to get rid of lodash
  const onEmailChange = useCallback(throttle(updateSsoConnectionName, SUBMIT_THROTTLE_IN_MILLISECONDS), [
    throttledOnContinueWithSso,
  ]);

  // (krashanoff): When the component is mounted, check the user's email for SSO enablement. Similarly,
  // if the environment is on-prem, then force use of the SSO provider.

  useMount(() => {
    if (email.length !== 0) {
      void onEmailChange(email);
    }
  });
  const continueWithForcedSso = useCallback(async () => {
    if (env.ForceProviderName != null && env.ForceProviderName !== "") {
      await onContinueWithSso(env.ForceProviderName);
    }
  }, [onContinueWithSso]);

  // (krashanoff): For FS' on-prem deployment, we need to redirect to their SSO provider immediately
  // to pass an InfoSec scan.
  useEffect(() => {
    if (errorDescription != null) {
      return;
    }
    void continueWithForcedSso();
  }, [continueWithForcedSso, errorDescription]);

  const [timesTried, setTimesTried] = useState<Record<string, number>>({});
  const throttledOnSubmit = useMemo(
    () =>
      throttle(async (data: UnauthenticatedSignInPageFormFields) => {
        // (krashanoff): Catch too many attempts with same email
        if (timesTried[data.email] && timesTried[data.email] >= TIMES_TRIED_BEFORE_REDIRECT_THRESHOLD) {
          setTimesTried((oldTimesTried) => ({ ...oldTimesTried, [data.email]: 0 }));
          push(RoutePaths.CREDENTIALS_ERROR, { [RouteQueryStringKeys.REDIRECT]: RoutePaths.LOGIN });
          return;
        }

        // (krashanoff): Regardless how the user submitted, we should check if their domain name
        // requires SSO.
        const updatedSsoConnectionName = await updateSsoConnectionName(data.email);
        if (updatedSsoConnectionName != null) {
          clearError();
          await onContinueWithSso(updatedSsoConnectionName);
          setTimesTried((oldTimesTried) => ({ ...oldTimesTried, [data.email]: (oldTimesTried[data.email] ?? 0) + 1 }));
          return;
        }

        clearError();
        await login({ connection: AuthenticationConnectionName.DEFAULT, email: data.email, password: data.password });
        setTimesTried((oldTimesTried) => ({ ...oldTimesTried, [data.email]: (oldTimesTried[data.email] ?? 0) + 1 }));
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [timesTried, updateSsoConnectionName, clearError, login, push, onContinueWithSso],
  );

  const submit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      // (hchen): Prevent refreshing.
      event.preventDefault();
      // (clewis): Be sure to include an extra '()', since formHandleSubmit(...) returns a function.
      void form.handleSubmit(throttledOnSubmit)();
    },
    [form, throttledOnSubmit],
  );

  const throttledOnContinueWithGoogle = useMemo(
    () =>
      throttle(async () => {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        setWhichLoginButtonPressed("google");
        if (isPendoEnabled) {
          window.pendo?.track(PendoMetricName.SIGN_IN_WITH_GOOGLE);
        }
        await login({
          connection: AuthenticationConnectionName.GOOGLE,
          email: form.getValues().email,
          password: form.getValues().password,
        });
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [login, form, isPendoEnabled],
  );

  const throttledOnContinueWithSsoNoArgs = useCallback(async () => {
    // If no email, direct to SSO sign-in page
    if (form.getValues().email == null || form.getValues().email === "") {
      push(RoutePaths.LOGIN_SSO);
      return;
    }
    if (isPendoEnabled) {
      window.pendo?.track(PendoMetricName.SIGN_IN_WITH_SSO);
    }
    await throttledOnContinueWithSso(ssoConnectionName ?? undefined);
  }, [throttledOnContinueWithSso, ssoConnectionName, form, push, isPendoEnabled]);

  const onClickReceiveAnInvite = useCallback(() => {
    clearError();
    push(RoutePaths.INVITE_REQUEST, isSupplierMode ? { [RouteQueryStringKeys.SUPPLIER]: "1" } : undefined);
  }, [clearError, push, isSupplierMode]);

  const onClickForgotPassword = useCallback(() => {
    clearError();
    if (isPendoEnabled) {
      window.pendo?.track(PendoMetricName.FORGOT_PASSWORD);
    }
  }, [clearError, isPendoEnabled]);

  const onCheckboxChange = useCallback((isChecked: boolean) => {
    setIsPasswordShown(isChecked);
  }, []);

  const renderPill = useCallback((text: string) => {
    return (
      <div className="relative w-full py-2 mb-3">
        <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 border-t -z-1" />
        <div className="bg-background px-4 m-auto w-min">
          <RegrelloTypography
            className={`
                bg-background

                border
                rounded-circular

                w-min
                whitespace-nowrap

                px-1
                m-auto
              `}
            uppercase={true}
            variant="body-xs"
          >
            {text}
          </RegrelloTypography>
        </div>
      </div>
    );
  }, []);

  const newUsersBanner = renderPill(isSupplierMode ? t`New Users`.toUpperCase() : t`New Suppliers`.toUpperCase());

  const loginInfoFields = useMemo(
    () => (
      <>
        <RegrelloControlledFormFieldText
          autoFocus={true}
          className="w-full mb-2"
          controllerProps={{
            control: form.control,
            name: "email",
            rules: {
              ...ValidationRules.VALID_EMAIL,

              // (krashanoff): Ensures we fire this function with the new email value
              // (onChange is fired before updating the object returned by form.getValues()).
              onChange: async (e) => {
                await onEmailChange(e?.target?.value);
              },
            },
          }}
          disabled={isLoading}
          isDefaultMarginsOmitted={true}
          placeholder={t`Email`}
        />
        <RegrelloControlledFormFieldText
          className="w-full mb-2"
          controllerProps={{
            control: form.control,
            name: "password",
            rules: { ...ValidationRules.REQUIRED },
          }}
          disabled={isLoading || shouldUseSso}
          isDefaultMarginsOmitted={true}
          placeholder={t`Password`}
          type={isPasswordShown ? "text" : "password"}
        />

        {
          /** Toggle controlling login spoofing in dev environments. */
          isDevEnvironment() && (
            <div className="w-full">
              <RegrelloFormFieldCheckbox
                dataTestId={DataTestIds.ENABLE_LOGIN_SPOOFING_TOGGLE}
                disabled={isLoading}
                label={t`Enable login spoofing`}
                onChange={onLoginSpoofingChange}
                value={isLoginSpoofingEnabled}
              />
            </div>
          )
        }

        <div className={clsx("w-full flex items-center justify-between mb-4")}>
          <RegrelloFormFieldCheckbox
            disabled={isLoading || shouldUseSso}
            isDefaultMarginsOmitted={true}
            label={t`Show password`}
            onChange={onCheckboxChange}
            value={isPasswordShown}
          />
          {shouldUseSso ? null : (
            <RegrelloLinkV2
              dataTestId={DataTestIds.FORGOT_PASSWORD_LINK}
              onClick={onClickForgotPassword}
              to={{
                pathname: RoutePaths.PASSWORD_RESET_REQUEST,
                search: isSupplierMode ? "?supplier=1" : undefined,
              }}
            >
              {t`Forgot password`}
            </RegrelloLinkV2>
          )}
        </div>

        {(authError != null || errorDescription != null) && (
          <RegrelloTypography className="flex justify-center items-center mb-8 text-danger-textMuted w-[200%] max-w-[100vw]">
            <RegrelloIcon className="text-danger-icon pr-1" iconName="alert" />
            {authError || errorDescription}
          </RegrelloTypography>
        )}

        <RegrelloButton
          className="w-full mb-6"
          dataTestId={DataTestIds.SIGN_IN_BUTTON}
          disabled={shouldUseSso || isLoading}
          intent="primary"
          loading={isLoading && whichLoginButtonPressed === "default"}
          size="large"
          type="submit"
        >
          {t`Sign in`}
        </RegrelloButton>
      </>
    ),
    [
      form.control,
      isLoading,
      shouldUseSso,
      isPasswordShown,
      onLoginSpoofingChange,
      isLoginSpoofingEnabled,
      onCheckboxChange,
      onClickForgotPassword,
      isSupplierMode,
      authError,
      errorDescription,
      whichLoginButtonPressed,
      onEmailChange,
    ],
  );

  const receiveAnEmailInvite = (
    <>
      {newUsersBanner}

      <RegrelloTypography className="text-center mb-3" variant="h5">
        {t`Regrello is invite only`}
      </RegrelloTypography>
      <RegrelloTypography className="text-center mb-4">
        {isSupplierMode ? (
          <Trans>
            Been assigned tasks in Regrello? If you would like to sign in to complete your work, please click send email
            invite. You must click/tap <strong>Accept invite</strong> in your email invite in order to create your
            account password.
          </Trans>
        ) : (
          <Trans>
            You must click/tap <strong>Accept invite</strong> in your email invite in order to set your password.
          </Trans>
        )}
      </RegrelloTypography>

      <RegrelloButton
        className="w-full"
        dataTestId={DataTestIds.RESEND_EMAIL_INVITE_BUTTON}
        intent="primary"
        onClick={onClickReceiveAnInvite}
        startIcon={<RegrelloFullColorReceiveInviteIcon />}
        variant="outline"
      >
        {isSupplierMode ? t`Send email invite` : t`Resend email invite`}
      </RegrelloButton>
    </>
  );

  const ssoButton = useMemo(
    () => (
      <RegrelloButton
        className="w-full mb-2"
        dataTestId={DataTestIds.SIGN_IN_WITH_SSO_BUTTON}
        disabled={isLoading}
        loading={isLoading && whichLoginButtonPressed === "sso"}
        onClick={throttledOnContinueWithSsoNoArgs}
        startIcon={<RegrelloIcon iconName="sso" />}
        variant="outline"
      >
        {t`Sign in with SSO`}
      </RegrelloButton>
    ),
    [isLoading, whichLoginButtonPressed, throttledOnContinueWithSsoNoArgs],
  );

  const googleButton = useMemo(
    () => (
      <RegrelloButton
        className="w-full mb-6"
        dataTestId={DataTestIds.CONTINUE_WITH_GOOGLE_BUTTON}
        disabled={shouldUseSso || isLoading}
        loading={isLoading && whichLoginButtonPressed === "google"}
        onClick={throttledOnContinueWithGoogle}
        startIcon={<RegrelloBrandIcon brand="google" size="x-small" />}
        variant="outline"
      >
        {t`Continue with Google`}
      </RegrelloButton>
    ),
    [shouldUseSso, isLoading, whichLoginButtonPressed, throttledOnContinueWithGoogle],
  );

  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col w-97.5 px-12 pb-4">
        <form className="flex flex-col flex-auto items-center justify-center" onSubmit={submit}>
          <div className="mb-8">
            <RegrelloIconStyler size="x-large">
              <RegrelloFullColorLogoMarkIcon />
            </RegrelloIconStyler>
          </div>
          <RegrelloTypography className="mb-8" variant="h2">
            {isSupplierMode ? t`Supplier sign in` : t`Welcome to Regrello`}
          </RegrelloTypography>

          {isSupplierMode && shouldUseSso && isLoading && (
            <RegrelloRedirectingSpinner message={t`Redirecting to your company's sign in page`} />
          )}

          {isSupplierMode ? (
            <>
              {loginInfoFields}
              {receiveAnEmailInvite}
            </>
          ) : (
            <>
              {loginInfoFields}
              {renderPill(t`Or`)}

              {ssoButton}
              {googleButton}
              {receiveAnEmailInvite}
            </>
          )}
        </form>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {t`Privacy Policy`}
          </RegrelloLinkV2>
          <RegrelloCopyright />
        </RegrelloTypography>
      </div>
    </div>
  );
});
