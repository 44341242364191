import { EMPTY_ARRAY, isDefined } from "@regrello/core-utils";
import {
  type ConditionalExpressionGroupFields,
  ConditionConnective,
  type FieldInstanceConditionalExpressionFields,
  type Maybe,
  type UpdateConditionGroupInputs,
} from "@regrello/graphql-api";
import { startOfDay } from "date-fns";
import type { UseFormReturn } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import {
  type FieldInstanceCondition,
  type RegrelloScheduleTimeFormSectionBase,
  ScheduleTimeKeys,
  ScheduleTimeValues,
} from "./RegrelloScheduleTimeFormSectionBase";

export const MAX_CONDITION_GROUP_NESTING_LEVEL = 3;

export const getStartAfterActionItemTemplate = (
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>,
): number | undefined => {
  const startAfterDependency = form.getValues(ScheduleTimeKeys.DEPENDENCY);
  // Do not return null here because null is a valid value to clear start after dependency.
  return startAfterDependency != null ? startAfterDependency?.id : undefined;
};

export const getStartAtV2 = (form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>): string | undefined => {
  const startOnDate = form.getValues(ScheduleTimeKeys.DATE);
  // Do not return null here because null is a valid value to clear start after dependency.
  return startOnDate != null ? startOfDay(startOnDate).toISOString() : undefined;
};

export type Condition = {
  // (zstanik): TypeScript kept erroring out if the correct types are provided here, so declare
  // as `any` for now and typecast to the appropriate type when reading values? from the form.
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  fieldInstance: any;
  fieldInstancePropertyId?: Maybe<number>;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  operator: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  value1: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  value2: any;

  displayOrder: number;
};

export type ConditionGroup = {
  conditions: Condition[];
  conditionGroups: ConditionGroup[];
  connective: ConditionConnective;
  displayOrder: number;
};

export function CleanUpConditionGroup(
  cg: Maybe<conditionalExpressionGroupFieldsRecursive> | undefined,
): ConditionalExpressionGroupFields | undefined {
  if (cg == null) {
    return undefined;
  }
  const conditions = cg.conditions.filter((condition) => condition.left.field.deletedAt == null) ?? EMPTY_ARRAY;
  const groups = cg.conditionGroups?.map(CleanUpConditionGroup) || EMPTY_ARRAY;

  return {
    id: cg.id,
    connective: cg.connective,
    conditions: conditions,
    conditionGroups: groups
      .filter((group) => group != null)
      .filter((group) => group.conditions.length > 0 || group.conditionGroups.length > 0),
  };
}

type conditionalExpressionGroupFieldsRecursive = {
  id: number;
  connective: ConditionConnective;
  conditions: FieldInstanceConditionalExpressionFields[];
  conditionGroups?: conditionalExpressionGroupFieldsRecursive[];
};

export function getFormStartingConditions(cg: conditionalExpressionGroupFieldsRecursive | undefined): ConditionGroup {
  if (cg === undefined) {
    return makeEmptyConditionGroup(0);
  }

  const out: FieldInstanceCondition[] = [];
  cg.conditions.forEach((condition, index) => {
    const fieldInstance = condition.left;
    const fieldInstancePropertyId = condition.leftFieldInstancePropertyID;
    const operator =
      CustomFieldPluginRegistrar.getPluginForFieldInstance(condition.left)
        .getConditionOperators()
        .find((startConditionOperator) => startConditionOperator.operator === condition.operatorV2) ?? null;
    const value1 =
      operator != null && operator.numFields > 0 && condition.right.length > 0
        ? CustomFieldPluginRegistrar.getPluginForFieldInstance(condition.right[0]).getValueForFrontend(
            condition.right[0],
          )
        : null;
    const value2 =
      operator != null && operator.numFields > 1 && condition.right.length > 1
        ? CustomFieldPluginRegistrar.getPluginForFieldInstance(condition.right[1]).getValueForFrontend(
            condition.right[1],
          )
        : null;
    // (zstanik): The operator is the only value here that could be null AND shouldn't be null in
    // a valid start condition. Silently warning to avoid crashing the app on invalid data.
    if (operator != null) {
      out.push({
        fieldInstance,
        fieldInstancePropertyId,
        operator,
        value1,
        value2,
        displayOrder: index,
      });
    } else {
      console.warn("Invalid condition operator for field instance", {
        operator: condition.operatorV2,
        fieldInstance,
      });
    }
  });

  const groups = cg.conditionGroups?.map(getFormStartingConditions) || EMPTY_ARRAY;
  groups.forEach((_, i) => {
    groups[i].displayOrder = out.length + i;
  });

  return {
    conditions: out,
    connective: cg.connective,
    conditionGroups: groups,
    // displayOrder will be overwritten by parent if necessary.
    displayOrder: 0,
  };
}

// (zstanik): Placeholder start condition values. Note: this shouldn't be declared as an array here
// because then values may be added to it as the user interacts with the form and introduce form
// state bugs when empty values need to be populated.
export function makeEmptyConditionGroup(displayOrder: number): ConditionGroup {
  return {
    connective: ConditionConnective.AND,
    conditions: [
      {
        fieldInstance: null,
        operator: null,
        value1: null,
        value2: null,
        displayOrder: 0,
      },
    ],
    conditionGroups: [],
    displayOrder,
  };
}

export function conditionGroupToUpdateConditionGroupInputs(data: ConditionGroup): UpdateConditionGroupInputs | null {
  const conditions = data.conditions
    ?.filter((startingCondition) => {
      return isDefined(startingCondition.fieldInstance) && isDefined(startingCondition.operator);
    })
    .map((startingCondition) => {
      return CustomFieldPluginRegistrar.getPluginForFieldInstance(
        startingCondition.fieldInstance,
      ).getUpdateStartingConditionsInputsFromFormValues(
        startingCondition.fieldInstance,
        startingCondition.operator.numFields > 1
          ? [startingCondition.value1, startingCondition.value2]
          : startingCondition.value1,
        startingCondition.operator.operator,
        startingCondition.fieldInstancePropertyId,
      );
    })
    .filter(isDefined);
  const groups = data.conditionGroups?.map(conditionGroupToUpdateConditionGroupInputs) || EMPTY_ARRAY;
  const conditionGroups = groups.filter((v) => v !== null);
  if (conditionGroups.length + conditions.length === 0) {
    return null;
  }

  return {
    connective: data.connective,
    conditions,
    conditionGroups,
  };
}

export function hasConditions({ conditions, conditionGroups }: UpdateConditionGroupInputs): boolean {
  if (conditions != null) {
    if (conditions.length > 0) {
      return true;
    }
  }

  if (conditionGroups != null) {
    return conditionGroups.some(hasConditions);
  }

  return false;
}

export function getStartingConditions(
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>,
  startTime: ScheduleTimeValues,
): UpdateConditionGroupInputs | null {
  const startingConditions = form.getValues(ScheduleTimeKeys.CONDITIONS) as ConditionGroup | null;
  if (startingConditions == null) {
    return null;
  }

  if (
    startTime !== ScheduleTimeValues.START_CONDITION_STAGE &&
    startTime !== ScheduleTimeValues.START_CONDITION_WORKFLOW
  ) {
    return null;
  }

  return conditionGroupToUpdateConditionGroupInputs(startingConditions);
}
