import { useCallback, useState } from "react";

export namespace useSimpleHover {
  export interface Return {
    isHovered: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  }
}

/** Provides basic on-hover watching via "mouseenter" and "mouseleave" event listeners. */
export function useSimpleHover(): useSimpleHover.Return {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return {
    isHovered,
    onMouseEnter,
    onMouseLeave,
  };
}
