import { t } from "@lingui/macro";
import { EMPTY_ARRAY, isDefined } from "@regrello/core-utils";
import { ConditionConnective, type UpdateConditionGroupInputs } from "@regrello/graphql-api";
import React from "react";
import type { UseFormReturn } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "../../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { SelectFieldPlugin } from "../../../../../molecules/customFields/plugins/SelectFieldPlugin";
import { RegrelloFormSection } from "../../../../../molecules/formSection/RegrelloFormSection";
import { RegrelloConfigureConditionsFormSection } from "../../schedule/RegrelloConfigureConditionsFormSection";
import {
  type RegrelloScheduleTimeFormSectionBase,
  ScheduleTimeKeys,
} from "../../schedule/RegrelloScheduleTimeFormSectionBase";

export namespace ConfigureVariantBlueprintConditionsFormSection {
  export function getConditionGroupMutationInputs(
    form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>,
  ): UpdateConditionGroupInputs {
    const variantConditions = form.getValues(ScheduleTimeKeys.CONDITIONS);
    if (variantConditions == null) {
      return { connective: ConditionConnective.AND, conditions: [], conditionGroups: [] };
    }
    const variantConditionsInput = variantConditions.conditions
      .filter((condition) => condition.fieldInstance != null && condition.operator != null)
      .map((condition) => {
        return CustomFieldPluginRegistrar.getPluginForFieldInstance(
          condition.fieldInstance,
        ).getUpdateStartingConditionsInputsFromFormValues(
          condition.fieldInstance,
          condition.operator.numFields > 1 ? [condition.value1, condition.value2] : condition.value1,
          condition.operator.operator,
          condition.fieldInstancePropertyId,
        );
      })
      .filter(isDefined);

    return {
      connective: variantConditions.connective,
      conditions: variantConditionsInput,
      conditionGroups: EMPTY_ARRAY,
    };
  }

  export type Props = {
    /**
     * The form for configuring the variant conditions. Should have `mode` set to 'all' for form
     * validation to work properly.
     */
    // (zstanik) blueprint variations TODO: Do a more thorough refactor of
    // `RegrelloConfigureConditionsFormSection` such that we don't need to use a form with more
    // fields than necessary here.
    form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;

    /**
     * Whether the form section is disabled.
     *
     * @default false
     */
    isDisabled?: boolean;

    /**
     * ID of the standard blueprint if creating a variant and ID of the variant blueprint if
     * updating a variant.
     */
    blueprintId: number;
  };

  /** A form section for configuring the conditions for selecting a blueprint variant. */
  export const Component = React.memo<ConfigureVariantBlueprintConditionsFormSection.Props>(
    function ConfigureVariantBlueprintConditionsFormSectionFn({
      form,
      isDisabled = false,
      blueprintId,
    }: ConfigureVariantBlueprintConditionsFormSection.Props) {
      return (
        <RegrelloFormSection
          description={t`Set the conditions for selecting this variant.`}
          isRequiredAsteriskShown={true}
          title={t`Variant conditions`}
        >
          <RegrelloConfigureConditionsFormSection
            allowedFieldPlugins={[SelectFieldPlugin]}
            form={form}
            isDisabled={isDisabled}
            // Variants do not support recursive groups.
            isRecursiveGroupsEnabled={false}
            workflowContext={{
              type: "workflowTemplate",
              workflowTemplateId: blueprintId,
              // (zstanik): Create via email can't be enabled for standard or variant workflow
              // templates.
              workflowTemplateIsCreateViaEmailEnabled: false,
            }}
          />
        </RegrelloFormSection>
      );
    },
  );
}
