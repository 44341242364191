import { localStorageGetTyped, RegrelloLocalStorageKey } from "@regrello/core-utils";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

import { userThemeAtom } from "../../state/applicationState";

/**
 * This hook listens for changes to the user's theme in local storage and updates the userThemeAtom.
 * This is useful in iframe'd pages that wish to listen for changes to the parent page's theme.
 *
 * This hook is a no-op if the page is not in an iframe.
 */
export function useIframeThemeChangeListener() {
  const setUserTheme = useSetAtom(userThemeAtom);

  const isInIframe = (() => {
    try {
      return window.self !== window.parent;
    } catch (_error) {
      return true;
    }
  })();

  useEffect(() => {
    if (!isInIframe) {
      return;
    }

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === RegrelloLocalStorageKey.USER_THEME) {
        const newUserTheme = localStorageGetTyped(RegrelloLocalStorageKey.USER_THEME);
        if (newUserTheme != null) {
          setUserTheme(newUserTheme);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [isInIframe, setUserTheme]);
}
