import { t } from "@lingui/macro";
/* eslint-disable class-methods-use-this */
import { noop } from "@regrello/core-utils";
import {
  FormConstraintConditionOperator,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import type { ReactNode } from "react";
import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import { ValidationRules } from "../../../constants/globalConstants";
import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import type { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloControlledFormFieldNumber } from "../formFields/controlled/regrelloControlledFormFields";
import { RegrelloFormFieldNumber } from "../formFields/RegrelloFormFieldNumber";
import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import { FrontendValueConstraintRuleName, getValueConstraintDisplayName } from "./utils/spectrumFieldConstraintUtils";

type CurrencyFieldPluginFrontendValue = string;

export class SpectrumCurrencyFieldPluginDecorator extends SpectrumFieldPluginDecorator<CurrencyFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<CurrencyFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.currency";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.CURRENCY;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.FLOAT);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.CURRENCY,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(valueConstraints: SpectrumValueConstraintFields[]) {
    return valueConstraints.filter(
      ({ valueConstraintRule }) =>
        valueConstraintRule === FrontendValueConstraintRuleName.MAX_FLOAT ||
        valueConstraintRule === FrontendValueConstraintRuleName.MIN_FLOAT,
    );
  }

  public isDataFormatToggleVisible() {
    return true;
  }

  public isValueConstraintEnabled() {
    return true;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.GT]: {
        label: t`Is greater than`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.GTE]: {
        label: t`Is greater than or equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.LT]: {
        label: t`Is less than`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.LTE]: {
        label: t`Is less than or equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.EQ]: {
        label: t`Is equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.NOT]: {
        label: t`Is not equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onBlur" as const;
  }

  public renderPreviewFormField(spectrumField: SpectrumFieldVersionFields) {
    return (
      <RegrelloFormFieldNumber
        currencySymbol={spectrumField.fieldUnit?.symbol || "$"}
        disabled={true}
        onChange={noop}
        size="medium"
        value=""
      />
    );
  }

  public renderValueConstraints(props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
  }): ReactNode {
    const { constraints, disabled, form } = props;
    const getFieldKey = (index: number) => `valueConstraints.${index}.args.0` as const;

    const minFloatConstraintIndex = constraints.findIndex(
      (constraint) => constraint.constraint.valueConstraintRule === FrontendValueConstraintRuleName.MIN_FLOAT,
    );
    const maxFloatConstraintIndex = constraints.findIndex(
      (constraint) => constraint.constraint.valueConstraintRule === FrontendValueConstraintRuleName.MAX_FLOAT,
    );
    if (minFloatConstraintIndex < 0 || maxFloatConstraintIndex < 0) {
      return null;
    }
    const minFloatConstraintArg = form.getValues(`valueConstraints.${minFloatConstraintIndex}.args.0`);
    const maxFloatConstraintArg = form.getValues(`valueConstraints.${maxFloatConstraintIndex}.args.0`);

    const typedMinFloatConstraintArg = minFloatConstraintArg
      ? Number.parseFloat(minFloatConstraintArg)
      : Number.MIN_SAFE_INTEGER;
    const typedMaxFloatConstraintArg = maxFloatConstraintArg
      ? Number.parseFloat(maxFloatConstraintArg)
      : Number.MAX_SAFE_INTEGER;

    return (
      <>
        {constraints.map((row, constraintIndex) => {
          const fieldKey = getFieldKey(constraintIndex);
          return (
            <RegrelloControlledFormFieldNumber
              key={row.constraint.uuid}
              className="w-60"
              controllerProps={{
                control: form.control,
                name: fieldKey,
                rules:
                  constraintIndex === minFloatConstraintIndex && maxFloatConstraintArg != null
                    ? {
                        ...ValidationRules.NUMBER,
                        ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(typedMaxFloatConstraintArg),
                        ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number.MIN_SAFE_INTEGER),
                      }
                    : constraintIndex === maxFloatConstraintIndex && minFloatConstraintArg != null
                      ? {
                          ...ValidationRules.NUMBER,
                          ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number.MAX_SAFE_INTEGER),
                          ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(typedMinFloatConstraintArg),
                        }
                      : {
                          ...ValidationRules.NUMBER,
                          ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number.MAX_SAFE_INTEGER),
                          ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number.MIN_SAFE_INTEGER),
                        },
              }}
              disabled={disabled}
              label={getValueConstraintDisplayName(row.constraint)}
            />
          );
        })}
      </>
    );
  }
}
