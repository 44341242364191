import { useCallback, useState } from "react";

export namespace useSimpleDialog {
  export interface Return {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  }
}

/** A simple hook that can track and manipulate the "open" state of a dialog. */
export function useSimpleDialog(): useSimpleDialog.Return {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    open: handleOpen,
    close: handleClose,
  };
}
