import { t } from "@lingui/macro";
import { clsx, type WithClassName, type WithDataTestId } from "@regrello/core-utils";
import type { Column, RowData } from "@tanstack/react-table";
import React from "react";

import { RegrelloButton } from "../../../atoms/button/RegrelloButton";
import { RegrelloIcon } from "../../../atoms/icons/RegrelloIcon";
import { RegrelloInput } from "../../../atoms/input/RegrelloInput";
import { REGRELLO_DATA_GRID_BULK_EDIT_COLUMN_ID } from "./regrelloDataGridConstants";

export interface RegrelloDataGridColumnHeaderFilterProps<TData extends RowData, TValue = unknown>
  extends WithClassName,
    WithDataTestId {
  /** react-table column definition */
  column: Column<TData, TValue>;
}

function RegrelloDataGridColumnHeaderDisplayFn<TData, TValue>({
  className,
  column,
}: RegrelloDataGridColumnHeaderFilterProps<TData, TValue>) {
  const isFilterable = column.getCanFilter();

  if (column.id === REGRELLO_DATA_GRID_BULK_EDIT_COLUMN_ID) {
    return (
      <RegrelloButton
        className={clsx(
          "px-2 border-t rounded-none disabled:opacity-100 [&_svg]:text-textContrastMuted",
          {
            "[&_svg]:text-textDefault": column.getIsFiltered(),
            "[&_svg]:text-textContrastMuted/40": !isFilterable,
          },
          className,
        )}
        disabled={!isFilterable}
        onClick={() => {
          column.setFilterValue(column.getFilterValue() ? undefined : true);
        }}
        variant="ghost"
      >
        <RegrelloIcon iconName="filter" />
      </RegrelloButton>
    );
  }

  return (
    <RegrelloInput
      className={clsx(
        "px-2 border-t bg-backgroundSoft hover:brightness-95 ring-0 rounded-none text-textDefault [&_svg]:text-textContrastMuted",
        { "[&_svg]:text-textDefault": column.getIsFiltered() },
        className,
      )}
      disabled={!isFilterable}
      onChange={(event) => {
        column.setFilterValue(event.target.value);
      }}
      startElement={{
        type: "icon",
        iconName: "filter",
      }}
      title={isFilterable ? undefined : t`Filtering is disabled for this column`}
    />
  );
}

/**
 * Standard display content for a RegrelloDataGrid header. Renders the display name and/or
 * displayIcon if provided.
 */
export const RegrelloDataGridColumnHeaderFilter = React.memo(
  RegrelloDataGridColumnHeaderDisplayFn,
) as typeof RegrelloDataGridColumnHeaderDisplayFn;
