import { useCallback, useState } from "react";

export namespace useSimpleFocus {
  export interface Return {
    isFocused: boolean;
    onFocus: () => void;
    onBlur: () => void;
  }
}

/** Provides basic on-focus watching via "focus" and "blur" event listeners. */
export function useSimpleFocus(): useSimpleFocus.Return {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return {
    isFocused,
    onFocus,
    onBlur,
  };
}
