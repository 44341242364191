/* eslint-disable lingui/no-unlocalized-strings */
import { t, Trans } from "@lingui/macro";
import { assertNever, EMPTY_STRING } from "@regrello/core-utils";
import { RegrelloButton, RegrelloInput, RegrelloLinkV2, RegrelloSpinner } from "@regrello/ui-core";
import React, { type FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useCounter } from "react-use";

import { REGRELLO_PRIVACY_POLICY_URL, REGRELLO_SUPPORT_EMAIL } from "../../../../constants/globalConstants";
import { RegrelloRestApiService } from "../../../../services/RegrelloRestApiService";
import { useQueryMap } from "../../../../utils/hooks/useQueryStrings";
import { RoutePaths, RouteQueryStringKeys } from "../../../app/routes/consts";
import { RegrelloCopyright } from "../../../atoms/copyright/RegrelloCopyright";
import RegrelloLogo from "./_internal/regrelloLogoFull.svg?react";

type EmailSentState = "waitingForUser" | "emailSending" | "emailSentSuccessfully" | "needsEmail" | "error";

const STATUS_BAD_REQUEST = 400;
const STATUS_NEED_TEAM_EMAIL = 432;
const STATUS_OK = 200;

/**
 * Displays a page for the user to submit their email for verification with Regrello Lite.
 *
 * (krashanoff): This component may require further changes for custom domain support at
 * a later time.
 */
export const DeviceVerificationEmailSentPage = React.memo(function DeviceVerificationEmailSentPageFn() {
  const { supplier: supplierQueryValue } = useQueryMap();
  const isSupplierMode = supplierQueryValue != null;
  const { [RouteQueryStringKeys.TOKEN]: granularAccessToken } = useQueryMap();
  const signInButtonHref = `${RoutePaths.LOGIN}/${
    isSupplierMode ? { [RouteQueryStringKeys.SUPPLIER]: supplierQueryValue } : ""
  }`;

  // If the state is not accounted for in the provided union
  const [consecutiveErrors, { inc: consecutiveErrorOccurred, reset: resetErrorCount }] = useCounter();
  const [likelyErrorReason, setLikelyErrorReason] = useState<string | null>(null);
  const [emailSentStatus, setEmailSentStatus] = useState<EmailSentState>("waitingForUser");

  const [email, setEmail] = useState<string | null>(null);

  // (zstanik): Request the workspace's personalization settings so the customized logo can be
  // rendered below.
  const [logoBase64, setLogoBase64] = useState<string | undefined>(undefined);
  const [isWorkspacePersonalizationLoading, setIsWorkspacePersonalizationLoading] = useState(true);
  useEffect(() => {
    if (!isWorkspacePersonalizationLoading) {
      return;
    }

    void RegrelloRestApiService.getWorkspacePersonalization(granularAccessToken).then((resp) => {
      if (resp.status === STATUS_OK) {
        setLogoBase64(resp.json.Logo?.value ?? undefined);
      } else {
        setLogoBase64(undefined);
      }
      setIsWorkspacePersonalizationLoading(false);
    });
  }, [granularAccessToken, isWorkspacePersonalizationLoading]);

  const requestVerification = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setEmailSentStatus("emailSending");
      const { status } = await RegrelloRestApiService.requestVerificationLink(granularAccessToken, email);
      switch (status) {
        case STATUS_OK:
          resetErrorCount();
          setEmailSentStatus("emailSentSuccessfully");
          break;
        case STATUS_BAD_REQUEST:
          setLikelyErrorReason(
            t`Something went wrong sending a request to our backend. Please report this to support@regrello.com.`,
          );
          setEmailSentStatus("error");
          break;
        case STATUS_NEED_TEAM_EMAIL:
          resetErrorCount();
          setEmailSentStatus("needsEmail");
          break;
        default:
          consecutiveErrorOccurred(1);
          setEmailSentStatus("error");
          setLikelyErrorReason(getLikelyErrorFromStatus(status));
      }
    },
    [consecutiveErrorOccurred, email, granularAccessToken, resetErrorCount],
  );

  const waitForConfirmationContent = useMemo(
    () => (
      <>
        <h1 className="flex items-center text-4xl md:text-5xl">{t`Verify your device`}</h1>

        <form className="flex flex-col gap-10 items-center" onSubmit={requestVerification}>
          <div>
            <p className="font-semibold text-base">{t`We need to verify your device for use with the Regrello webform. Click the button below to proceed with email verification.`}</p>
            <ul className="leading-6 list-disc ml-4 mt-4">
              <li>
                <Trans>If you have access to the task, you will receive an email to verify your device.</Trans>
              </li>
              <li>
                <Trans>
                  <strong>How do I access this task?</strong> Only people who have been assigned to or CC’ed to this
                  task will be able access the task.
                </Trans>
              </li>
            </ul>
          </div>

          {emailSentStatus === "emailSending" ? (
            <RegrelloSpinner />
          ) : (
            <RegrelloButton className="w-full p-3.5" intent="primary" type="submit">
              {t`Verify`}
            </RegrelloButton>
          )}
        </form>
      </>
    ),
    [emailSentStatus, requestVerification],
  );

  const emailSentSuccessfullyContent = useMemo(() => {
    const supportLink = (
      <RegrelloLinkV2 className="font-normal" to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>
        {REGRELLO_SUPPORT_EMAIL}
      </RegrelloLinkV2>
    );

    return (
      <>
        <h1 className="text-5xl">{t`Check your email`}</h1>

        <div className="flex flex-col gap-10">
          <p className="leading-6">
            <strong>{t`We have sent you an email. You'll need to verify your device to access the task.`}</strong>
            <ul className="list-disc ml-4 mt-4">
              <li>
                <Trans>
                  Check your inbox for the email with the subject <strong>Verify device</strong>
                </Trans>
              </li>
              <li>
                <Trans>
                  <strong>Didn&apos;t get it?</strong> Only people who have been assigned to or CC&apos;ed to this task
                  will be able access the task.
                </Trans>
              </li>
            </ul>
          </p>

          <div>
            <Trans>Having trouble? Contact us at {supportLink}.</Trans>
          </div>
        </div>
      </>
    );
  }, []);

  const needsEmailContent = useMemo(
    () => (
      <>
        <h1 className="text-5xl">{t`Verify your device`}</h1>

        <form className="flex flex-col gap-10 items-center" onSubmit={requestVerification}>
          <div>
            <p className="font-semibold text-base">{t`We need to verify your device for use with the Regrello webform. Click the button below to proceed with email verification.`}</p>
            <ul className="leading-6 list-disc ml-4 mt-4">
              <li>
                <Trans>If you have access to the task, you will receive an email to verify your device.</Trans>
              </li>
              <li>
                <Trans>
                  <strong>How do I access this task?</strong> Only people who have been assigned to or CC’ed to this
                  task will be able access the task.
                </Trans>
              </li>
            </ul>
          </div>

          <RegrelloInput
            className="w-full"
            // (krashanoff): One character username, one `@`, one character hostname. Extremely
            // unrealistic, but could happen in theory.
            minLength={3}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
            placeholder={t`example@example.com`}
            title={t`Enter your email`}
            value={email ?? EMPTY_STRING}
          />

          {emailSentStatus === "emailSending" ? (
            <RegrelloSpinner />
          ) : (
            <RegrelloButton className="w-full p-3.5" intent="primary" type="submit">
              {t`Verify`}
            </RegrelloButton>
          )}
        </form>
      </>
    ),
    [email, emailSentStatus, requestVerification],
  );

  const errorContent = useMemo(
    () => (
      <>
        <h1 className="text-5xl">{t`An error occurred`}</h1>

        <form className="flex flex-col gap-10" onSubmit={requestVerification}>
          <p className="leading-6">
            {t`An email could not be sent.`} {likelyErrorReason}
          </p>

          {emailSentStatus === "emailSending" ? (
            <RegrelloSpinner />
          ) : (
            <RegrelloButton className="max-w-xs" intent="primary" type="submit">
              {t`Try again`}
            </RegrelloButton>
          )}

          {consecutiveErrors > 2 && (
            <div>
              {t`Having trouble? Contact us at `}{" "}
              <RegrelloLinkV2 to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>{REGRELLO_SUPPORT_EMAIL}</RegrelloLinkV2>
            </div>
          )}
        </form>
      </>
    ),
    [consecutiveErrors, emailSentStatus, likelyErrorReason, requestVerification],
  );

  const currentContent = useMemo(() => {
    switch (emailSentStatus) {
      case "waitingForUser":
      case "emailSending":
        return waitForConfirmationContent;
      case "emailSentSuccessfully":
        return emailSentSuccessfullyContent;
      case "needsEmail":
        return needsEmailContent;
      case "error":
        return errorContent;
      default:
        assertNever(emailSentStatus);
    }
  }, [emailSentStatus, emailSentSuccessfullyContent, errorContent, needsEmailContent, waitForConfirmationContent]);

  return (
    <div className="w-full h-full flex flex-col justify-between">
      {/*
        (krashanoff): A negative margin is used here since relying on flexbox
        alone would lead to the box being technically centered, but visually askew.
        */}
      <div className="px-4 mx-auto mt-2 lg:mt-25 w-full max-w-168">
        <header className="flex justify-between items-center p-4">
          <div>
            {isWorkspacePersonalizationLoading ? (
              <RegrelloSpinner />
            ) : logoBase64 != null ? (
              <img alt="logo" className="max-w-35 max-h-10 object-contain" src={logoBase64} />
            ) : (
              <RegrelloLogo height={40} width={164} />
            )}
          </div>
          {!isWorkspacePersonalizationLoading && (
            <RegrelloLinkV2 className="font-bold text-primary-textMuted" to={signInButtonHref}>
              {t`Sign in`}
            </RegrelloLinkV2>
          )}
        </header>
        <hr />

        <div className="flex flex-col mt-10 gap-10">{currentContent}</div>
      </div>

      <footer className="w-full flex flex-row justify-around py-4">
        <div className="flex flex-row items-center justify-center gap-6 font-medium text-textMuted">
          <RegrelloLinkV2 className="text-textMuted" to={REGRELLO_PRIVACY_POLICY_URL}>
            {t`Privacy Policy`}
          </RegrelloLinkV2>
          <p>
            <RegrelloCopyright />
          </p>
        </div>
      </footer>
    </div>
  );
});

function getLikelyErrorFromStatus(status: number) {
  if (status >= 400 && status < 500) {
    return t`Something seemed to go wrong while connecting to our servers.`;
  }
  if (status >= 500) {
    return t`Our servers appear to be having trouble.`;
  }
  return null;
}
