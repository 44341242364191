import { clsx, mergeRefs, type WithClassName, type WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { useMemo, useRef } from "react";
import { usePhoneInput } from "react-international-phone";

import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import type { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloButton } from "../button/RegrelloButton";
import { getInputV2ButtonSizeForInputSize } from "../input/inputV2Utils";
import { RegrelloInput } from "../input/RegrelloInput";
import { RegrelloPopover } from "../popover/RegrelloPopover";
import { RegrelloPhoneInputCountrySelector } from "./RegrelloPhoneInputCountrySelector";

export interface RegrelloPhoneInputProps
  extends WithDataTestId,
    WithClassName,
    Omit<React.HTMLProps<HTMLInputElement>, "onChange" | "size"> {
  /**
   * Whether input is disabled.
   */
  disabled?: boolean;

  /**
   * Whether the input should take up the full width of its parent.
   */
  fullWidth?: boolean;

  /**
   * The semantic intent of this input.
   *
   * @default RegrelloIntent.NEUTRAL
   */
  intent?: Extract<RegrelloIntent, "neutral" | "warning" | "danger">;

  /** The callback to invoke when the input value changes. */
  onChange?: (value: string) => void;

  /**
   * The size of the input.
   *
   * @default "large"
   */
  size?: RegrelloSize;

  /**
   * Controlled value of the input.
   */
  value?: string;
}

function getFlagUrl(countryCode: string) {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return `/flags/${countryCode}.svg`;
}

const RegrelloPhoneInput = React.forwardRef<HTMLInputElement, RegrelloPhoneInputProps>(
  ({ disabled, fullWidth, id, intent, onBlur, onChange, size = "large", value }, ref) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
      disableDialCodePrefill: true,
      value,
      onChange: (data) => {
        onChange?.(data.phone);
      },
    });

    const countrySelector = useMemo(
      () => (
        <RegrelloPopover
          align="start"
          content={
            <RegrelloPhoneInputCountrySelector
              buttonRef={buttonRef}
              country={country}
              inputRef={inputRef}
              setCountry={setCountry}
            />
          }
          // Disabled animations due to whacky interop with material-ui focus traps.
          // Remove this after material-ui dialog replacement with radix equivalent.
          contentProps={{
            className: "p-0 w-auto",
            disableAnimations: true,
            style: { minWidth: "var(--radix-popover-trigger-width)" },
          }}
        >
          <div className="flex items-center">
            <RegrelloButton
              ref={buttonRef}
              className="p-0 hover:bg-transparent active:bg-transparent"
              dataTestId={DataTestIds.PHONE_INPUT_COUNTRY_BUTTON}
              disabled={disabled}
              size={getInputV2ButtonSizeForInputSize(size)}
              variant="ghost"
            >
              <img
                alt={country.name}
                className={clsx("w-7 aspect-4/3 border rounded", {
                  "w-5": size === "x-small",
                  "w-6": size === "small",
                  "w-7": size === "medium",
                })}
                data-country={country.iso2}
                data-testid={DataTestIds.PHONE_INPUT_COUNTRY_FLAG}
                draggable={false}
                src={getFlagUrl(country.iso2)}
              />
            </RegrelloButton>
          </div>
        </RegrelloPopover>
      ),
      [country, disabled, inputRef, setCountry, size],
    );

    return (
      <RegrelloInput
        dataTestId={DataTestIds.PHONE_INPUT_INPUT}
        disabled={disabled}
        fullWidth={fullWidth}
        id={id}
        inputRef={mergeRefs(inputRef, ref)}
        intent={intent}
        onBlur={onBlur}
        onChange={handlePhoneValueChange}
        size={size}
        startElement={{
          type: "interactive",
          element: countrySelector,
        }}
        value={inputValue}
      />
    );
  },
);

RegrelloPhoneInput.displayName = "RegrelloPhoneInput";

export { RegrelloPhoneInput };
