import { clsx } from "@regrello/core-utils";
import { Command as CommandPrimitive } from "cmdk";
import React from "react";

import { RegrelloIcon } from "../icons/RegrelloIcon";
import {
  RegrelloMenuV2ItemContent,
  type RegrelloMenuV2ItemContentProps,
} from "../menuV2/_internal/RegrelloMenuV2ItemContent";
import { menuItemSeparatorClasses, menuItemVariants } from "../menuV2/_internal/regrelloMenuV2Utils";
import { RegrelloTypography } from "../typography/RegrelloTypography";

/**
 * A command menu that allows the user to input a query to filter through a list of items and select
 * one.
 */
const RegrelloCommandRoot = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(function RegrelloCommandRootFn({ className, ...props }, ref) {
  return (
    <CommandPrimitive
      ref={ref}
      className={clsx(
        ["flex", "h-full", "w-full", "flex-col", "overflow-hidden", "rounded", "bg-background", "text-textDefault"],
        className,
      )}
      {...props}
    />
  );
});

const RegrelloCommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    hideFilter?: boolean;
  }
>(function RegrelloCommandInputFn({ className, hideFilter, ...props }, ref) {
  return (
    // (clewis): This prop is necessary for cmdk styling.
    // eslint-disable-next-line react/no-unknown-property
    <div className="flex items-center gap-1.5 border-b px-2" cmdk-input-wrapper="">
      {/* (clewis): Nudge icon downward to optically vertically center. */}
      {hideFilter ? null : <RegrelloIcon className="-mb-0.5 shrink-0 opacity-50" iconName="search" />}
      <CommandPrimitive.Input
        ref={ref}
        className={clsx(
          [
            "flex",
            "h-9", // (clewis): Same height as normal inputs.
            "w-full",
            "rounded",
            "bg-transparent",
            "text-sm",
            "outline-none",
            "placeholder:text-textPlaceholder",
            "disabled:cursor-not-allowed",
            "disabled:opacity-30",
          ],
          { "w-0 h-0": hideFilter },
          className,
        )}
        {...props}
      />
    </div>
  );
});

const RegrelloCommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(function RegrelloCommandListFn({ className, ...props }, ref) {
  return (
    <CommandPrimitive.List
      ref={ref}
      className={clsx("max-h-75 overflow-y-auto overflow-x-hidden p-1", className)}
      {...props}
    />
  );
});

const RegrelloCommandLoading = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Loading>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Loading>
>(function RegrelloCommandLoadingFn({ children, ...restProps }, ref) {
  return (
    <CommandPrimitive.Loading ref={ref} className="py-6 text-center text-base" {...restProps}>
      <RegrelloTypography component="div" muted={true}>
        {children}
      </RegrelloTypography>
    </CommandPrimitive.Loading>
  );
});

const RegrelloCommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(function RegrelloCommandEmptyFn({ children, ...restProps }, ref) {
  return (
    <CommandPrimitive.Empty ref={ref} className="py-6 text-center text-base" {...restProps}>
      <RegrelloTypography component="div" muted={true}>
        {children}
      </RegrelloTypography>
    </CommandPrimitive.Empty>
  );
});

const RegrelloCommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group> & {
    /**
     * Whether to show a separator above this group.
     *
     * @default false
     */
    showSeparator?: boolean;

    /** A title to show at the top of this group. */
    title?: string;
  }
>(function RegrelloCommandGroupFn({ className, showSeparator = false, title, ...props }, ref) {
  return (
    <>
      {showSeparator ? <RegrelloCommandSeparator /> : null}
      <CommandPrimitive.Group
        ref={ref}
        className={clsx(
          [
            "[&_[cmdk-group-heading]]:p-2",
            "[&_[cmdk-group-heading]]:text-sm",
            "[&_[cmdk-group-heading]]:font-semibold",
          ],
          className,
        )}
        title={title}
        {...props}
      />
    </>
  );
});

const RegrelloCommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(function RegrelloCommandSeparatorFn({ className, ...props }, ref) {
  return <CommandPrimitive.Separator ref={ref} className={clsx(menuItemSeparatorClasses, className)} {...props} />;
});

export type RegrelloCommandItemProps = Omit<RegrelloMenuV2ItemContentProps, "endElement"> &
  Omit<React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>, "children">;

const RegrelloCommandItem = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Item>, RegrelloCommandItemProps>(
  function RegrelloCommandItemFn(
    { className, icon, inset, intent, secondaryText, selected, text, tooltip, ...props },
    ref,
  ) {
    return (
      <CommandPrimitive.Item
        key={props.value}
        ref={ref}
        className={clsx(
          menuItemVariants({
            intent,
            isDisabled: props.disabled,
            isSelected: selected,
          }),
          className,
        )}
        {...props}
      >
        <RegrelloMenuV2ItemContent
          icon={icon}
          inset={inset}
          intent={intent}
          secondaryText={secondaryText}
          selected={selected}
          text={text}
          tooltip={tooltip}
        />
      </CommandPrimitive.Item>
    );
  },
);

export {
  RegrelloCommandEmpty,
  RegrelloCommandGroup,
  RegrelloCommandInput,
  RegrelloCommandItem,
  RegrelloCommandList,
  RegrelloCommandLoading,
  RegrelloCommandRoot,
  RegrelloCommandSeparator,
};
