export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: number; output: number };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Time: { input: string; output: string };
  UUID: { input: string; output: string };
}

export interface AiAgentLearningSimilarActionItemsInput {
  /**
   * ID of the action item to retrieve similar action items for. Only action items that have been
   * copied from the exact same template as the provided action item will be considered when searching
   * for matches.
   */
  actionItemID: Scalars["ID"]["input"];
  /**
   * Maximum vector distance threshold for results, between 0 and 2. where 0 is a perfect match. To
   * quote Typesense docs: "Every matching hit in the response will contain a vector_distance field
   * that indicates how "close" the document's vector value is to the query vector. Typesense uses the
   * cosine similarity, so this distance will be a value between 0 and 2.
   *
   * If the document's vector perfectly matches the query vector, the distance will be 0.
   * If the document's vector is extremely different from the query vector, then the distance will be
   * 2.
   */
  maxDistance?: InputMaybe<Scalars["Float"]["input"]>;
  /** Maximum number of results to return. */
  maxResults?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AiAgentLearningSimilarActionItemsResponse {
  similarActionItemIDs: Array<Scalars["ID"]["output"]>;
}

export interface ApiHealthCheckConfig {
  healthCheckRequest: AutomationRequest;
  id: Scalars["ID"]["output"];
  isPaused: Scalars["Boolean"]["output"];
  lastHealthCheckResult?: Maybe<ApiHealthCheckResult>;
  scheduledJob?: Maybe<ScheduledJob>;
}

export interface ApiHealthCheckResult {
  createdAt: Scalars["Time"]["output"];
  details: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  statusCode: Scalars["Int"]["output"];
}

export interface ApiHealthCheckResultsResponse {
  apiHealthCheckResults: Array<ApiHealthCheckResult>;
}

/**
 * An AccessRole is a set of permissions that are granted to a user or team. AccessRoles are used to
 * determine whether a user or team is allowed to view or edit a resource.
 *
 * This type is deprecated, and will no longer be in use after the launch of permissions V2.
 */
export interface AccessRole {
  /** A human-readable name for the AccessRole that may change over time. The name field will never change. */
  displayName: Scalars["String"]["output"];
  /** A unique identifer for each AccessRole. */
  id: Scalars["ID"]["output"];
  /**
   * The level field is used to prevent privilege escalation. Each AccessRole is assigned a level that
   * indicates the sensitivity of the AccessRole. It will limit the access roles that the
   * current user is allowed to apply to other users. For instance, while an external user can create
   * another external user in some circumstances, they cannot create an internal admin or owner. A
   * higher level means a more powerful role.
   */
  level: Scalars["Int"]["output"];
  /** One of a pre-defined set of names, see AccessRoleName for more information. */
  name: AccessRoleName;
  /** Whether the access role applies to the Workspace, a Workflow, or a View. */
  scope: AccessRoleScope;
  /** Indicates whether the access role applies to internal users or external users. */
  userScope?: Maybe<AccessRoleUserScope>;
}

/**
 * Enumerates every AccessRole that exists in Regrello. AccessRoles are used to determine whether a
 *   user or team is allowed to view or edit a resource.
 */
export enum AccessRoleName {
  ADMIN = "ADMIN",
  BLUEPRINT_PROCESS_COORDINATOR = "BLUEPRINT_PROCESS_COORDINATOR",
  EXTERNAL_CAN_VIEW_AND_COMMENT = "EXTERNAL_CAN_VIEW_AND_COMMENT",
  EXTERNAL_TEAM_ADMIN = "EXTERNAL_TEAM_ADMIN",
  INTERNAL_CAN_MAKE = "INTERNAL_CAN_MAKE",
  INTERNAL_CAN_PUBLISH = "INTERNAL_CAN_PUBLISH",
  INTERNAL_CAN_VIEW_AND_COMMENT = "INTERNAL_CAN_VIEW_AND_COMMENT",
  INTERNAL_TEAM_ADMIN = "INTERNAL_TEAM_ADMIN",
  OWNER = "OWNER",
  VIEW_CAN_EDIT = "VIEW_CAN_EDIT",
  VIEW_CAN_VIEW = "VIEW_CAN_VIEW",
  VIEW_OWNER = "VIEW_OWNER",
  WORKFLOW_CAN_EDIT = "WORKFLOW_CAN_EDIT",
  WORKFLOW_CAN_VIEW_AND_COMMENT = "WORKFLOW_CAN_VIEW_AND_COMMENT",
  WORKFLOW_CREATOR = "WORKFLOW_CREATOR",
  WORKFLOW_OWNER = "WORKFLOW_OWNER",
}

/**
 * The area that the AccessRole applies to. See the AccessRole model for more information. Team means Workspace, Workflow means Workflow, and View
 * refers to Home Views.
 */
export enum AccessRoleScope {
  BLUEPRINT = "BLUEPRINT",
  TEAM = "TEAM",
  VIEW = "VIEW",
  WORKFLOW = "WORKFLOW",
}

/**
 * AccessRoleUserScope is a property of an AccessRole. It is used to determine whether an access role
 * comes with INTERNAL (employee) or EXTERNAL (supplier) assumptions.
 */
export enum AccessRoleUserScope {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export interface AcknowledgeActionItemResponse {
  actionItem?: Maybe<ActionItem>;
  error?: Maybe<Scalars["String"]["output"]>;
}

/**
 * The core object. The ActionItem is the "thing to do". It has one or more assignees,
 * zero or more reviewers, zero or more ccList entries, one or more tags, zero or more comments,
 * zero or more documents.
 */
export interface ActionItem {
  /** The difference between completedAt and actualStartAt in working seconds. */
  actualDurationSeconds?: Maybe<Scalars["Int"]["output"]>;
  /**
   * This is the first moment within the working hours of the assignee whose working hours will result
   * in the task being completed soonest.
   */
  actualStartAt?: Maybe<Scalars["Time"]["output"]>;
  approvalActionItemTemplates: Array<ActionItemTemplate>;
  /**
   * Reference to the parent (OG) task of the Approval task.
   * Needed to retrieve the data from the OG task when
   * opening the Approval task by the direct link.
   */
  approvalForActionItem?: Maybe<ActionItem>;
  approvalStatus?: Maybe<ApprovalStatus>;
  assignedBy: Party;
  assignees: Array<Party>;
  auditHistory: Array<ActionItemAuditHistory>;
  automationRequests: Array<AutomationRequest>;
  cc: Array<Party>;
  comments: Array<Comment>;
  completedAt?: Maybe<Scalars["Time"]["output"]>;
  /**
   * The party that completed the action item. Null if the status is not "COMPLETED", or if the action
   * item is of type "AUTOMATION" or "LINKED_WORKFLOW".
   */
  completedBy?: Maybe<Party>;
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  /** Holds information about whether and when the current user may have starred this action item. */
  currentUserStar?: Maybe<Star>;
  /** Holds all delegation instance event information. Should only be selected from actionItemDetailsQuery. Not optimized for return value of slice of tasks */
  delegations: Array<OutOfOfficeDelegationEvent>;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder: Scalars["Float"]["output"];
  documents: Array<Document>;
  dueOn?: Maybe<Scalars["Time"]["output"]>;
  dueOnClass: DueOnClass;
  fieldInstanceControllingAssignees?: Maybe<FieldInstance>;
  fieldInstanceControllingDueOn?: Maybe<FieldInstance>;
  fieldInstances: Array<FieldInstance>;
  fieldInstancesControllingAssignees?: Maybe<Array<FieldInstance>>;
  fieldInstancesControllingCc?: Maybe<Array<FieldInstance>>;
  frozeForReason?: Maybe<ActionItemFrozeForReason>;
  /**
   * Whether any documents are attached to this action-item template, whether via the native
   * "Documents" fields or via any Document field instances.
   */
  hasDocuments: Scalars["Boolean"]["output"];
  hasException: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  integrationAutomationInstance?: Maybe<IntegrationAutomationInstance>;
  integrationType?: Maybe<IntegrationType>;
  /**
   * Whether the action item is locked, which means that certain actions (configuration dependent) are
   * restricted from being performed on the action item. The actual restrictions are reflected in the
   * action item's `permissions` object.
   */
  isLocked: Scalars["Boolean"]["output"];
  isMuted: Scalars["Boolean"]["output"];
  /** If the action item is a splitter task */
  isSplitter?: Maybe<Scalars["Boolean"]["output"]>;
  lines: Array<Line>;
  linesAuditHistory: Array<RegrelloObjectInstancesAuditHistory>;
  /**
   * The linked workflow that this action item created when it ran. The workflow record could be
   * deleted in which case the UI can still show details about it but should not attempt to link to it.
   */
  linkedWorkflowId?: Maybe<Scalars["ID"]["output"]>;
  linkedWorkflowReference?: Maybe<LinkedWorkflowReference>;
  name: Scalars["String"]["output"];
  numberOfUnreadComments: Scalars["Int"]["output"];
  /**
   * The party IDs that were originally assigned to this ActionItem's ActionItemTemplate, if one
   * exists, when the template was created. Primarily useful for knowing which parties to restrict from
   * removing from a locked ActionItem.
   */
  originalTemplateAssigneePartyIDs?: Maybe<Array<Scalars["ID"]["output"]>>;
  permissions?: Maybe<PermissionsToActionItem>;
  /** The post-reject action to take with necessary data to perform the action. */
  rejectAction?: Maybe<RejectAction>;
  /** Holds audit history for related sync object instances. Should only be selected from actionItemDetailsQuery. Not optimized for return value of slice of tasks */
  relatedRegrelloObjectAuditHistory: Array<RegrelloObjectChangeEvent>;
  requiresAck: Scalars["Boolean"]["output"];
  requiresReview: Scalars["Boolean"]["output"];
  reviewers: Array<Party>;
  /** If this task is a split child, the ID of the splitter action item that created it. */
  splitterParentActionItemId?: Maybe<Scalars["ID"]["output"]>;
  status: ActionItemStatus;
  statusHistory: Array<ActionItemStatusHistory>;
  tags: Array<Tag>;
  /** The action item template that this action item belongs to, if any. */
  templateId?: Maybe<Scalars["ID"]["output"]>;
  /**
   * This is the timezone as determined by the task assignees. Regrello picks the timezone that will
   * result in the task being completed soonest, according to working hours.
   */
  timezone?: Maybe<Scalars["String"]["output"]>;
  type: ActionItemType;
  updatedAt: Scalars["Time"]["output"];
  uuid: Scalars["String"]["output"];
  uuidLong: Scalars["String"]["output"];
  uuidShort: Scalars["String"]["output"];
  workflowReference?: Maybe<WorkflowReference>;
}

/**
 * The core object. The ActionItem is the "thing to do". It has one or more assignees,
 * zero or more reviewers, zero or more ccList entries, one or more tags, zero or more comments,
 * zero or more documents.
 */
export interface ActionItemCommentsArgs {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  unreadOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}

/**
 * Represents Audit History of an Action Item.
 * When records are edited in an ActionItem, we edit in place and record the field(s) changed and the user who did it
 * Arbitrary context can be added here like IP address, User-Agent, any other useful device information, etc.
 */
export interface ActionItemAuditHistory {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  createdByUnknownEmail?: Maybe<Scalars["String"]["output"]>;
  event: AuditHistoryEvent;
  fields: Array<ActionItemAuditHistoryDelta>;
  id: Scalars["ID"]["output"];
  /**
   * Currently only applicable for changes including status, has exception, workflow status change,
   * stage status change
   */
  reason?: Maybe<TaskStatusChangeReason>;
}

export interface ActionItemAuditHistoryDelta {
  addedRecords: Array<AuditHistoryAddedRecord>;
  addedRecordsV2: Array<AuditHistoryRecord>;
  collectionAdd?: Maybe<Scalars["String"]["output"]>;
  collectionRemove?: Maybe<Scalars["String"]["output"]>;
  /**
   * This field can be used when we need to represent multiple parties per one audit record.
   *
   * For example, to produce the following system message:
   * "Party A (Company) submitted this task for approval to Party B (Company) and Party C (Company)"
   *
   * we store the IDs of Party B and Party C in the <collectionAdd> field and implement the
   * <destinationParties> resolver to return these parties for the particular <fieldName>.
   */
  destinationParties: Array<Party>;
  field?: Maybe<Field>;
  fieldName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /**
   * A common field to return data related to a delta entry in a different use cases.
   *
   * Example use case: SharePoint "Upload files to site folder" automated task.
   * Documents that failed to be uploaded to a site folder should be displayed in a task activity.
   * In this case 2 audit record deltas are created:
   *
   * Delta record 1:
   *   fieldName="FailedToUploadDocumentErrorCode"
   *   valueBefore=""
   *   valueAfter="<error code returned from Workato recipe>"
   *
   * Delta record 2:
   *   fieldName="FailedToUploadDocumentErrorMessage"
   *   valueBefore="<id of a not uploaded document>"
   *   valueAfter="<error returned from Workato recipe>"
   *   relatedRecords - contains a Document object.
   */
  relatedRecords: Array<AuditHistoryRecord>;
  /**
   * Currently supported only for removed Documents,
   * but we may want to extend it for Tags, Parties, etc.
   * and adding addedRecords: [AuditHistoryRemovedRecord!]! as well.
   * This will allow us to avoid the extra api calls that retrieve added/removed tags, parties, etc.
   * to show up them in the message history of an Action Item
   */
  removedRecords: Array<AuditHistoryRemovedRecord>;
  removedRecordsV2: Array<AuditHistoryRecord>;
  spectrumFieldVersion?: Maybe<SpectrumFieldVersion>;
  valueAfter?: Maybe<Scalars["String"]["output"]>;
  valueBefore?: Maybe<Scalars["String"]["output"]>;
}

export enum ActionItemCheckboxFilter {
  ASSIGNED_BY = "ASSIGNED_BY",
  ASSIGNED_DIRECTLY_TO = "ASSIGNED_DIRECTLY_TO",
  CC = "CC",
  EVERYONE_ELSE = "EVERYONE_ELSE",
  NEED_APPROVAL_BY = "NEED_APPROVAL_BY",
}

export enum ActionItemDueOnIntervalTrigger {
  ON_ACTION_ITEM_ACKNOWLEDGED = "ON_ACTION_ITEM_ACKNOWLEDGED",
  ON_PREVIOUS_STAGE_COMPLETED = "ON_PREVIOUS_STAGE_COMPLETED",
  ON_WORKFLOW_STARTED = "ON_WORKFLOW_STARTED",
}

export enum ActionItemFrozeForReason {
  ENDED = "ENDED",
  PAUSED = "PAUSED",
}

/**
 * ActionItemInput defines a tagged union of either an action item's
 * ID (numerical identifier) or its UUID (long-form UUIDv4).
 *
 * When used in the query `actionItem`, exactly one field must be
 * specified.
 */
export interface ActionItemInput {
  /** ID of the action item to retrieve. */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** UUID of the action item to retrieve. */
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}

export enum ActionItemOrActionItemTemplateField {
  ASSIGNEE = "ASSIGNEE",
  CC = "CC",
  DUE_ON = "DUE_ON",
}

/**
 * A reference to an action item, containing the minimum viable data about a
 * particular action item.
 */
export interface ActionItemReference {
  dueOn?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  uuid: Scalars["UUID"]["output"];
}

export enum ActionItemStatus {
  /** Any completed, approved, or rejected task has a status of completed. */
  COMPLETED = "COMPLETED",
  /**
   * Those that belonged to a start-after dependency chain in which one task upstream was reopened.
   * Currently, an action item can only leave this state when its direct parent task has been
   * completed, equivalent to when it would have become materialized from a template in the first
   * place.
   */
  INACTIVE = "INACTIVE",
  /** @deprecated unused */
  PENDING_ACKNOWLEDGEMENT = "PENDING_ACKNOWLEDGEMENT",
  /**
   * Base status for approval tasks, before they have been approved or rejected. Not used in standalone
   * approvals.
   */
  PENDING_REVIEW = "PENDING_REVIEW",
  /**
   * Base status for normal tasks and standalone approvals, before they have been completed, approved,
   * or rejected.
   */
  PENDING_SUBMISSION = "PENDING_SUBMISSION",
  /** @deprecated use ActionItem.ApprovalStatus and COMPLETED instead of REJECTED */
  REJECTED = "REJECTED",
}

export interface ActionItemStatusHistory {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  id: Scalars["ID"]["output"];
  nextStatus: ActionItemStatus;
  previousStatus?: Maybe<ActionItemStatus>;
  reason?: Maybe<TaskStatusChangeReason>;
}

export interface ActionItemTemplate {
  /**
   * If an actionItems do exist it means that part of the WorkflowStage hasn't started yet.
   * One ActionItemTemplate can reference to multiple ActionItems.
   * We may want to have a "recurring action item" or an action item that spawns itself
   * into 10 action items (one per supplier, one per document in some list, etc),
   * so it's reasonable to assume that we might want to have ActionItemTemplates that
   * print themselves into many ActionItems and one to many is the right relationship here.
   */
  actionItems: Array<ActionItem>;
  /** The difference between the action item template's dueOn and adjustedStartAt in working seconds. */
  adjustedDurationSeconds?: Maybe<Scalars["Int"]["output"]>;
  /**
   * The time when the actionItemTemplate is currently predicted to start, taking into account
   * previous tasks and stages.
   */
  adjustedStartAt?: Maybe<Scalars["Time"]["output"]>;
  /** The assignees that are used as a backup if the AI Agent fails to fill all fields on the task. */
  aiAgentBackupAssignees: Array<Party>;
  approvalActionItemTemplates: Array<ActionItemTemplate>;
  /** Reference to the parent (OG) task template of the Approval task template. */
  approvalForActionItemTemplate?: Maybe<ActionItemTemplate>;
  assignedBy: Party;
  assignees: Array<Party>;
  automationRequests: Array<AutomationRequest>;
  /** A convenience aggregation indicating the number of WorkflowTemplates/Blueprints that use this Automation. */
  automationUsedInWorkflowTemplatesCount: Scalars["Int"]["output"];
  cc: Array<Party>;
  /**
   * The time when the actionItemTemplate was initially supposed to be due, taking into account
   * previous tasks and stages. ConditionalDueOn can change if conditions attached to stage start conditions
   * cause stages to skip. Behaves similarly to ConditionalStartAt.
   */
  conditionalDueOn?: Maybe<Scalars["Time"]["output"]>;
  /** The difference between conditionalDueOn and conditionalStartAt in working seconds. */
  conditionalDurationSeconds?: Maybe<Scalars["Int"]["output"]>;
  /**
   * The time when the actionItemTemplate was initially supposed to start, taking into account previous
   * tasks and stages. ConditionalStartAt can change if conditions attached to stage start conditions
   * cause stages to skip. In these cases, ConditionalStartAt will ignore the skipped stage when
   * determining its value.
   */
  conditionalStartAt?: Maybe<Scalars["Time"]["output"]>;
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  /**
   * When the action item runs, a workflow will be created from the specified template. Leave the
   * `createsWorkflowFromWorkflowTemplateId` here in addition to the
   * `createsWorkflowFromWorkflowTemplate` because we want to be able to query for the ID without
   * preloading the association, and to be able to see when the related record gets deleted.
   */
  createsWorkflowFromWorkflowTemplateId?: Maybe<Scalars["ID"]["output"]>;
  createsWorkflowFromWorkflowTemplateReference?: Maybe<CreatesWorkflowFromWorkflowTemplateReference>;
  /** If the ActionItem is a template, and if cronDefinition is specified, then additional recurring action items are created based on the cron spec. */
  cronDefinition?: Maybe<Scalars["String"]["output"]>;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder: Scalars["Float"]["output"];
  documents: Array<Document>;
  dueOn?: Maybe<Scalars["Time"]["output"]>;
  /** This will be used to calculate dueOn in the event that this is a template action item. */
  dueOnIntervalSeconds?: Maybe<Scalars["Int"]["output"]>;
  dueOnIntervalSecondsAfterTrigger?: Maybe<ActionItemDueOnIntervalTrigger>;
  dueOnIntervalType?: Maybe<ActionItemTemplateDueOnIntervalType>;
  /**
   * Only use this field if you are running blueprint transfer.
   * It provides a simplified form structure that can then be used to import the form into the new blueprint.
   */
  exportFormStructure?: Maybe<ExportFormStructure>;
  fieldInstanceControllingAssignees?: Maybe<FieldInstance>;
  fieldInstanceControllingDueOn?: Maybe<FieldInstance>;
  fieldInstances: Array<FieldInstance>;
  fieldInstancesControllingAssignees?: Maybe<Array<FieldInstance>>;
  fieldInstancesControllingCc?: Maybe<Array<FieldInstance>>;
  frozeForReason?: Maybe<ActionItemTemplateFrozeForReason>;
  /**
   * Whether any documents are attached to this action-item template, whether via the native
   * "Documents" fields or via any Document field instances.
   */
  hasDocuments: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  integrationAutomationInstance?: Maybe<IntegrationAutomationInstance>;
  integrationType?: Maybe<IntegrationType>;
  /**
   * Whether the action item template is locked, which means that certain actions (configuration
   * dependent) are restricted from being performed on the template. The actual restrictions are
   * reflected in the template's `permissions` object.
   */
  isLocked: Scalars["Boolean"]["output"];
  /**
   * Whether this action-item template is a "splitter field" containing multiple "split children". If
   * true, the split children will be queryable via splitChildTemplates, and the splitter field
   * instance will be queryable via splitterFieldInstance.
   */
  isSplitter?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  /**
   * The party IDs that were originally assigned to this ActionItemTemplate when it was created.
   * Primarily useful for knowing which parties to restrict from removing from a locked
   * ActionItemTemplate.
   */
  originalAssigneePartyIDs?: Maybe<Array<Scalars["ID"]["output"]>>;
  permissions: PermissionsToActionItemTemplate;
  /**
   * The time when the actionItemTemplate was initially supposed to be due, taking into account
   * previous tasks and stages. PlannedDueOn never changes once it is set.
   */
  plannedDueOn?: Maybe<Scalars["Time"]["output"]>;
  /** The difference between plannedDueOn and plannedStartAt in working seconds */
  plannedDurationSeconds?: Maybe<Scalars["Int"]["output"]>;
  /**
   * The time when the actionItemTemplate was initially supposed to start, taking into account previous
   * tasks and stages. PlannedStartAt never changes once it is set.
   */
  plannedStartAt?: Maybe<Scalars["Time"]["output"]>;
  /** The post-reject action to take with necessary data to perform the action. */
  rejectAction?: Maybe<RejectAction>;
  requiresAck: Scalars["Boolean"]["output"];
  requiresReview: Scalars["Boolean"]["output"];
  reviewers: Array<Party>;
  /**
   * The child action-item templates that are created when this action-item template is split. Only
   * present if isSplitter is true.
   */
  splitChildTemplates: Array<ActionItemTemplate>;
  /**
   * The field instance that controls the splitting of this action-item template into multiple
   * child tasks. Only present if isSplitter is true.
   */
  splitterFieldInstance?: Maybe<FieldInstance>;
  startAfterActionItemTemplate?: Maybe<ActionItemTemplate>;
  startAt?: Maybe<Scalars["Time"]["output"]>;
  tags: Array<Tag>;
  /**
   * This is the timezone as determined by the task assignees. Regrello picks the timezone that will
   * result in the task being completed soonest, according to working hours.
   */
  timezone?: Maybe<Scalars["String"]["output"]>;
  type: ActionItemType;
  updatedAt: Scalars["Time"]["output"];
  uuid: Scalars["String"]["output"];
  /**
   * A validation error that would prevent this action-item template from materializing. This error can
   * typically be resolved via manual user action.
   */
  validationError?: Maybe<ActionItemTemplateValidationError>;
  /**
   * Name template suffix for the linked workflow created from the specified workflow template. Only
   * defined if the linked workflow template has a naming convention.
   * @deprecated adding a suffix to a linked workflow name is no longer supported
   */
  workflowNameTemplateSuffix?: Maybe<Scalars["String"]["output"]>;
  workflowReference?: Maybe<WorkflowReference>;
  workflowTemplateReference?: Maybe<WorkflowTemplateReference>;
}

/**
 * Determines how DueOnIntervalSeconds is turned into a Due Date. In the case of WORKING_DAYS, the length of
 * the working hours configured in CalendarSettings will be used to determine the due date. In that
 * case, a day will be defined to be the length of the working hours. In the case of INTERVAL_SECONDS, the
 * DueOnIntervalSeconds will be added directly without any adjustments.
 */
export enum ActionItemTemplateDueOnIntervalType {
  INTERVAL_SECONDS = "INTERVAL_SECONDS",
  WORKING_DAYS = "WORKING_DAYS",
}

export enum ActionItemTemplateFrozeForReason {
  ENDED = "ENDED",
  PAUSED = "PAUSED",
}

/**
 * A reference to an action-item template, containing the minimum viable data
 * about a particular action-item template.
 */
export interface ActionItemTemplateReference {
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
}

/**
 * A validation error that prevents an action-item template from materializing. This error can
 * typically be resolved via manual user action.
 */
export enum ActionItemTemplateValidationError {
  /**
   * An error indicating that one or more input fields on the linked workflow template, no longer
   * exists on the current action-item template.
   */
  LINKED_WORKFLOW_FIELD_MAPPING_BROKEN = "LINKED_WORKFLOW_FIELD_MAPPING_BROKEN",
  /** An error indicating that the linked workflow template has been deleted. */
  LINKED_WORKFLOW_TEMPLATE_DELETED = "LINKED_WORKFLOW_TEMPLATE_DELETED",
}

export interface ActionItemTemplateWithFormInstancesResponse {
  actionItemTemplate: ActionItemTemplate;
  formInstances: Array<FormInstance>;
}

export enum ActionItemType {
  APPROVAL = "APPROVAL",
  AUTOMATION = "AUTOMATION",
  DEFAULT = "DEFAULT",
  LINKED_WORKFLOW = "LINKED_WORKFLOW",
}

export interface ActionItemWithAttemptData {
  actionItem?: Maybe<ActionItem>;
  auditHistory: Array<ActionItemAuditHistory>;
  comments: Array<Comment>;
}

export interface ActionItemWithFormInstancesResponse {
  actionItem: ActionItem;
  formInstances: Array<FormInstance>;
}

export interface ActivityAggregation {
  numberOfUsers: Scalars["Int"]["output"];
  numberOfUsersWithActivityDuringPeriod: Scalars["Int"]["output"];
  weeklyUsers: Array<WeeklyUserSummary>;
  weeklyWorkflows: Array<WeeklyWorkflowSummary>;
}

export interface AddAutomationRequestsToActionItemTemplateResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface AddFieldInstancesToActionItemTemplateResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface AddFieldInstancesToWorkflowResponse {
  workflow: Workflow;
}

export interface AddFieldInstancesToWorkflowTemplateResponse {
  workflowTemplate: WorkflowTemplate;
}

export interface AddFieldObjectUsageToActionItemInputs {
  actionItemId?: InputMaybe<Scalars["ID"]["input"]>;
  data?: InputMaybe<Array<CreateFieldObjectDatumInputs>>;
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  fieldNameOverride?: InputMaybe<Scalars["String"]["input"]>;
  isHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOutput?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReadOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  objectId: Scalars["ID"]["input"];
  sourceObjectUsageId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface AddFieldObjectUsageToActionItemResponse {
  objectUsage: FieldObjectUsage;
}

export interface AddFieldObjectUsageToActionItemTemplateInputs {
  actionItemTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  data?: InputMaybe<Array<CreateFieldObjectDatumInputs>>;
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  fieldNameOverride?: InputMaybe<Scalars["String"]["input"]>;
  isHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOutput?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReadOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  objectId: Scalars["ID"]["input"];
  sourceObjectUsageId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface AddFieldObjectUsageToActionItemTemplateResponse {
  objectUsage: FieldObjectUsage;
}

export interface AddFieldObjectUsageToWorkflowInputs {
  data?: InputMaybe<Array<CreateFieldObjectDatumInputs>>;
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  fieldNameOverride?: InputMaybe<Scalars["String"]["input"]>;
  isHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOutput?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReadOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  objectId: Scalars["ID"]["input"];
  sourceObjectUsageId?: InputMaybe<Scalars["ID"]["input"]>;
  workflowId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface AddFieldObjectUsageToWorkflowResponse {
  objectUsage: FieldObjectUsage;
}

export interface AddFieldObjectUsageToWorkflowTemplateInputs {
  data?: InputMaybe<Array<CreateFieldObjectDatumInputs>>;
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  fieldNameOverride?: InputMaybe<Scalars["String"]["input"]>;
  isHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOutput?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReadOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  objectId: Scalars["ID"]["input"];
  sourceObjectUsageId?: InputMaybe<Scalars["ID"]["input"]>;
  workflowTemplate?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface AddFieldObjectUsageToWorkflowTemplateResponse {
  objectUsage: FieldObjectUsage;
}

export interface AddInstanceFieldInstanceValueInput {
  fieldInput: CreateFieldInstanceValueInputs;
  regrelloObjectPropertyId: Scalars["ID"]["input"];
}

export interface AddInstanceInput {
  values?: InputMaybe<Array<AddInstanceFieldInstanceValueInput>>;
}

export interface AddPartiesToRoleInput {
  partyIds: Array<Scalars["ID"]["input"]>;
  roleId: Scalars["ID"]["input"];
}

export interface AddPartiesToRoleResponse {
  success: Scalars["Boolean"]["output"];
}

export interface AddPropertyFieldInstanceValueInput {
  fieldInput: CreateFieldInstanceValueInputs;
  regrelloObjectInstanceId: Scalars["ID"]["input"];
}

export interface AddPropertyInput {
  fieldId: Scalars["ID"]["input"];
  values?: InputMaybe<Array<AddPropertyFieldInstanceValueInput>>;
}

export interface AdminPeopleTableResponse {
  /** The union of custom fields stored across every non-deleted user in the workspace. */
  customFields: Array<Field>;
  totalCount: Scalars["Int"]["output"];
  users: Array<User>;
}

export interface AggregateActionItemsByCategoryResponse {
  /** Count of all Actions within a Tag */
  all: Scalars["Int"]["output"];
  /** Count of all Actions associated with everyone but Me within a Tag */
  allOthers: Scalars["Int"]["output"];
  assignedBy: Scalars["Int"]["output"];
  assignedDirectlyTo: Scalars["Int"]["output"];
  assignedTo: Scalars["Int"]["output"];
  cc: Scalars["Int"]["output"];
  completed: Scalars["Int"]["output"];
  needsApprovalBy: Scalars["Int"]["output"];
  open: Scalars["Int"]["output"];
  /** Union count of Action Items for enabled checkbox filters */
  unionCountForCheckboxFilters: Scalars["Int"]["output"];
}

export interface AggregateHomePageByTeamResponse {
  /** @deprecated not used and had inefficient implementation */
  associatedPartyActions: Scalars["Int"]["output"];
  /** @deprecated not used and had inefficient implementation */
  notAssociatedPartyActions: Scalars["Int"]["output"];
  parties: Array<PartyWithHomePageAggregations>;
}

export interface AggregateHomePageByUserResponse {
  /** @deprecated not used and had inefficient implementation */
  associatedPartyActions: Scalars["Int"]["output"];
  /** @deprecated not used and had inefficient implementation */
  notAssociatedPartyActions: Scalars["Int"]["output"];
  parties: Array<PartyWithHomePageAggregations>;
}

export interface AggregateHomePageResponse {
  associatedPartyActions: Scalars["Int"]["output"];
  individuals: Scalars["Int"]["output"];
  notAssociatedPartyActions: Scalars["Int"]["output"];
  tagTypes: Array<TagTypeWithCount>;
  tags: Array<TagWithHomePageAggregations>;
  teams: Scalars["Int"]["output"];
}

export interface AggregateHomePageTabResponse {
  associatedPartyActions: Scalars["Int"]["output"];
  notAssociatedPartyActions: Scalars["Int"]["output"];
  tags: Array<TagWithHomePageAggregations>;
}

export interface AggregateWorkflowDocumentsResponse {
  documents: Array<Document>;
}

export interface AggregateWorkflowReportForTagResponse {
  exceptionWorkflows: Scalars["Int"]["output"];
  openWorkflows: Scalars["Int"]["output"];
  overdueWorkflows: Scalars["Int"]["output"];
  stageAggregations: Array<WorkflowStageNamesWithAggregations>;
}

export interface AggregateWorkflowTemplateDocumentsResponse {
  documents: Array<Document>;
}

export interface AllowedDomainsInput {
  /** Additional new domains to allow or remove for the tenant. */
  domains?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tenantId: Scalars["ID"]["input"];
}

export enum AppGenJobStatus {
  FAILURE = "FAILURE",
  GENERATED_PROCESS = "GENERATED_PROCESS",
  GENERATING_FORMS = "GENERATING_FORMS",
  GENERATING_PROCESS = "GENERATING_PROCESS",
  GENERATING_SAMPLE_DATA = "GENERATING_SAMPLE_DATA",
  IMPORTING_APP = "IMPORTING_APP",
  PENDING = "PENDING",
  RECEIVED = "RECEIVED",
  RETRY = "RETRY",
  REVOKED = "REVOKED",
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
}

export interface AppGenStatusResponse {
  blueprintID?: Maybe<Scalars["ID"]["output"]>;
  blueprintSkeleton?: Maybe<BlueprintSkeleton>;
  industry: Scalars["String"]["output"];
  jobUUID: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  process: Scalars["String"]["output"];
  startedAt: Scalars["Time"]["output"];
  status: AppGenJobStatus;
  updatedAt?: Maybe<Scalars["Time"]["output"]>;
}

export interface ApprovalActionItemTemplate {
  assigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  dueOn?: InputMaybe<Scalars["Time"]["input"]>;
  dueOnIntervalSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  fieldInstancesControllingAssignees?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface ApprovalAttempt {
  actionItemsWithAttemptData: Array<ActionItemWithAttemptData>;
}

export enum ApprovalStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface ApproveActionItemResponse {
  actionItem?: Maybe<ActionItem>;
  error?: Maybe<Scalars["String"]["output"]>;
}

/** DEPRECATED. Use AuditHistoryAddedRecordV2 instead. */
export interface AuditHistoryAddedRecord {
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface AuditHistoryDocument {
  document: Document;
}

export enum AuditHistoryEvent {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UNDELETE = "UNDELETE",
  UPDATE = "UPDATE",
}

export interface AuditHistoryFieldInstance {
  fieldInstance: FieldInstance;
}

export type AuditHistoryRecord =
  | AuditHistoryDocument
  | AuditHistoryFieldInstance
  | AuditHistoryRegrelloObjectInstance
  | AuditHistorySavedField;

export interface AuditHistoryRegrelloObjectInstance {
  regrelloObjectInstance: RegrelloObjectInstanceV2;
}

/**
 * Represents the brief data of the deleted record for the Audit History log.
 *
 * DEPRECATED. Use AuditHistoryRemovedRecordV2 instead.
 */
export interface AuditHistoryRemovedRecord {
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface AuditHistorySavedField {
  savedField: SavedField;
}

export interface AuthUser {
  createdAt: Scalars["Time"]["output"];
  currentTenant?: Maybe<Tenant>;
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  tenants: Array<Tenant>;
  updatedAt: Scalars["Time"]["output"];
  welcomeEmailSendTimeStamp: Scalars["Time"]["output"];
}

export enum AuthUserRole {
  /**
   * Tenant Admin (globally scoped). Users with this role should have access to any resource in any
   * accessible tenant.
   */
  ADMIN = "ADMIN",
  /**
   * A Regrello engineer. Users with this role should have access to perform engineering specific
   * tasks, such as running custom tenant-level migrations.
   */
  ENGINEER = "ENGINEER",
  /**
   * Users with this role should have access such as creating new workspaces and unarchiving
   * pre-existing workspaces.
   */
  WORKSPACE_CREATOR = "WORKSPACE_CREATOR",
}

export enum AuthorizationType {
  BEARER_TOKEN = "BEARER_TOKEN",
  NO_AUTH = "NO_AUTH",
  OAUTH_2 = "OAUTH_2",
}

export interface AutomationExtractValueFromJsonError {
  code: AutomationExtractValueFromJsonErrorCode;
  message: Scalars["String"]["output"];
  unmatchedValues: Array<Scalars["String"]["output"]>;
}

export enum AutomationExtractValueFromJsonErrorCode {
  AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_BOOLEAN_DUE_TO_TYPE_MISMATCH = "AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_BOOLEAN_DUE_TO_TYPE_MISMATCH",
  AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_COMPLETELY_DUE_TO_ROLE_MISMATCH = "AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_COMPLETELY_DUE_TO_ROLE_MISMATCH",
  AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_DATE_DUE_TO_TYPE_MISMATCH = "AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_DATE_DUE_TO_TYPE_MISMATCH",
  AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_DOCUMENT_DUE_TO_TYPE_MISMATCH = "AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_DOCUMENT_DUE_TO_TYPE_MISMATCH",
  AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_NUMBER_DUE_TO_TYPE_MISMATCH = "AUTOMATION_RESPONSE_PARSER_FAILED_TO_EXTRACT_NUMBER_DUE_TO_TYPE_MISMATCH",
  AUTOMATION_RESPONSE_PARSER_MULTIPLE_VALUES_RECEIVED_FOR_BOOLEAN_FIELD = "AUTOMATION_RESPONSE_PARSER_MULTIPLE_VALUES_RECEIVED_FOR_BOOLEAN_FIELD",
  AUTOMATION_RESPONSE_PARSER_MULTIPLE_VALUES_RECEIVED_FOR_DATE_FIELD = "AUTOMATION_RESPONSE_PARSER_MULTIPLE_VALUES_RECEIVED_FOR_DATE_FIELD",
  AUTOMATION_RESPONSE_PARSER_MULTIPLE_VALUES_RECEIVED_FOR_DOCUMENT_FIELD = "AUTOMATION_RESPONSE_PARSER_MULTIPLE_VALUES_RECEIVED_FOR_DOCUMENT_FIELD",
  AUTOMATION_RESPONSE_PARSER_MULTIPLE_VALUES_RECEIVED_FOR_NUMBER_FIELD = "AUTOMATION_RESPONSE_PARSER_MULTIPLE_VALUES_RECEIVED_FOR_NUMBER_FIELD",
  AUTOMATION_RESPONSE_PARSER_NO_VALUES_EXTRACTED_FROM_RESPONSE = "AUTOMATION_RESPONSE_PARSER_NO_VALUES_EXTRACTED_FROM_RESPONSE",
  AUTOMATION_RESPONSE_PARSER_RECEIVED_VALUE_DOES_NOT_EXIST = "AUTOMATION_RESPONSE_PARSER_RECEIVED_VALUE_DOES_NOT_EXIST",
}

export interface AutomationExtractedJsonResponseData {
  errors: Array<AutomationExtractValueFromJsonError>;
  fieldInstance?: Maybe<FieldInstance>;
  jsonExpression: Scalars["String"]["output"];
}

export interface AutomationOAuth2Config {
  accessTokenUrl: Scalars["String"]["output"];
  clientId: Scalars["String"]["output"];
  clientSecret: Scalars["String"]["output"];
  createdAt: Scalars["Time"]["output"];
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  grantType: OAuthGrantType;
  id: Scalars["ID"]["output"];
  location: OAuthCredentialsLocation;
  scope?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Time"]["output"];
}

export interface AutomationRequest {
  authorizationType: AuthorizationType;
  createNewUsersFromResponse: Scalars["Boolean"]["output"];
  createdAt: Scalars["Time"]["output"];
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  formDataParams: Array<AutomationRequestParam>;
  headerParams: Array<AutomationRequestParam>;
  healthCheckConfig?: Maybe<ApiHealthCheckConfig>;
  id: Scalars["ID"]["output"];
  jsonDataParams: Array<AutomationRequestParam>;
  method: AutomationRequestMethod;
  oauth2Config?: Maybe<AutomationOAuth2Config>;
  paginationConfig?: Maybe<AutomationRequestPaginationConfig>;
  queryParams: Array<AutomationRequestParam>;
  responseJsonExtractorConfigs: Array<AutomationResponseJsonExtractorConfig>;
  token?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Time"]["output"];
  url: Scalars["String"]["output"];
}

export enum AutomationRequestMethod {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

export interface AutomationRequestPaginationConfig {
  createdAt: Scalars["Time"]["output"];
  initialOffset?: Maybe<Scalars["Int"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  paginationType: PaginationType;
  updatedAt: Scalars["Time"]["output"];
}

export interface AutomationRequestParam {
  fieldInstance?: Maybe<FieldInstance>;
  fieldInstanceProperty?: Maybe<RegrelloObjectProperty>;
  id: Scalars["ID"]["output"];
  inputType: AutomationRequestParamInputType;
  key: Scalars["String"]["output"];
  location: AutomationRequestParamLocation;
  /** Deprecated. Use valueV2.valueString instead. */
  value?: Maybe<Scalars["String"]["output"]>;
  valueType: AutomationRequestParamValueType;
  valueV2?: Maybe<AutomationRequestParamValue>;
}

export enum AutomationRequestParamInputType {
  PROVIDED = "PROVIDED",
  REQUESTED = "REQUESTED",
}

export interface AutomationRequestParamInputV1 {
  /**
   * <fieldId> should be provided when the user selected a field.
   * The BE creates a new Workflow-level field instance of this field so the user is able
   * to specify the value when "using" a Workflow Template.
   *
   * UPDATED: this is currently unused. When creating a standalone automated task template
   * we do not provide a filedId and just show "from Worklow" in the input. When a task
   * is added in a Workflow/Template we show the input and the user should provide a field instance
   * from workflow-level/previous task, so at this step fieldInstanceId or fieldInstanceId+fieldInstancePropertyId are supplied.
   */
  fieldId?: InputMaybe<Scalars["Int"]["input"]>;
  /** <fieldInstanceId> should be provided when the user selected an existing field instance in a Workflow/Template. */
  fieldInstanceId?: InputMaybe<Scalars["Int"]["input"]>;
  fieldInstancePropertyId?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  value: AutomationRequestParamValueInput;
}

export enum AutomationRequestParamLocation {
  HEADER = "HEADER",
  QUERY = "QUERY",
}

export interface AutomationRequestParamMappingInput {
  fieldInstanceId: Scalars["Int"]["input"];
  fieldInstancePropertyId?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["ID"]["input"];
}

export type AutomationRequestParamValue =
  | AutomationRequestParamValueFloat
  | AutomationRequestParamValueInt
  | AutomationRequestParamValueString;

export interface AutomationRequestParamValueFloat {
  valueFloat?: Maybe<Scalars["Float"]["output"]>;
}

export interface AutomationRequestParamValueInput {
  /** Deprecated. Use valueString instead. */
  value?: InputMaybe<Scalars["String"]["input"]>;
  valueFloat?: InputMaybe<Scalars["Float"]["input"]>;
  valueInt?: InputMaybe<Scalars["Int"]["input"]>;
  valueString?: InputMaybe<Scalars["String"]["input"]>;
}

export interface AutomationRequestParamValueInt {
  valueInt?: Maybe<Scalars["Int"]["output"]>;
}

export interface AutomationRequestParamValueString {
  valueString?: Maybe<Scalars["String"]["output"]>;
}

export enum AutomationRequestParamValueType {
  FIELD = "FIELD",
  FLOAT = "FLOAT",
  INT = "INT",
  STRING = "STRING",
}

export interface AutomationResponseJsonExtractorConfig {
  createdAt: Scalars["Time"]["output"];
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  field: Field;
  /** <fieldInstance> is empty when config is a part of a standalone automated task template */
  fieldInstance?: Maybe<FieldInstance>;
  id: Scalars["ID"]["output"];
  /**
   * When true, integrations will add any previously unseen values to the "allowed values" list of the
   * mapped field, if it is a select or multiselect.
   */
  isAllowedToAppendAllowedValues?: Maybe<Scalars["Boolean"]["output"]>;
  jsonExpression: Scalars["String"]["output"];
  updatedAt: Scalars["Time"]["output"];
}

export interface AutomationResponseJsonExtractorConfigInput {
  fieldId: Scalars["ID"]["input"];
  /** <id> should be supplied when updating a config */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * When enabled, integrations will add any previously unseen values to the "allowed values" list of
   * the mapped field, if it is a select or multiselect.
   */
  isAllowedToAppendAllowedValues?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonExpression: Scalars["String"]["input"];
}

export interface AutomationTemplatesResponse {
  automationTemplates: Array<ActionItemTemplate>;
  totalCount: Scalars["Int"]["output"];
}

export interface BaseWorkflowReference {
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  permissions: UserPermissionsToWorkflow;
}

export interface BlueprintImportJob {
  createdAt: Scalars["Time"]["output"];
  fileUuid: Scalars["UUID"]["output"];
  id: Scalars["ID"]["output"];
  importUuid: Scalars["UUID"]["output"];
  status: BlueprintImportStatus;
  updatedAt: Scalars["Time"]["output"];
  /** Specify workflowTemplateId when overriding an existing template */
  workflowTemplateID?: Maybe<Scalars["ID"]["output"]>;
}

export enum BlueprintImportStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export interface BlueprintSkeleton {
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  stages: Array<SkeletonStage>;
}

export interface BlueprintSortInput {
  sortBy: BlueprintSortOption;
  sortOrder: SortOrder;
}

export enum BlueprintSortOption {
  NAME = "NAME",
  UPDATED_AT = "UPDATED_AT",
}

export enum BlueprintTemplateMode {
  DOCX = "DOCX",
  PDF = "PDF",
}

/**
 * Base reference for a Blueprint version (WorkflowTemplate). Contains all the data necessary to render
 * relevant version history information.
 */
export interface BlueprintVersionReference {
  /** When the Blueprint version was created. */
  createdAt: Scalars["Time"]["output"];
  /** The party who created the Blueprint version. */
  createdBy: Party;
  /** ID of the Blueprint version. */
  id: Scalars["ID"]["output"];
  /**
   * The current user's permissions to the Blueprint version. Note: these permissions are pulled from
   * the user's permissions to the latest published version of the blueprint.
   */
  permissions: UserPermissionsToWorkflowTemplate;
  /** When the Blueprint version was published. */
  publishedAt: Scalars["Time"]["output"];
  /** The party who published the Blueprint version. */
  publishedBy: Party;
  /** The user provided summary of changes made to the Blueprint in this version. */
  versionNotes?: Maybe<Scalars["String"]["output"]>;
  /** Represents which iteration of the Blueprint this version is. 1-indexed in order of `createdAt`. */
  versionNumber: Scalars["Int"]["output"];
}

export interface BlueprintVersionsInput {
  /**
   * How many Blueprint version records to return. A default value of 10 will be used if null. If not
   * null, a max value of 100 will be enforced.
   */
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** How many Blueprint version records to skip before the records to return. */
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** ID of the latest published version of the blueprint for which to return versions. */
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface BlueprintVersionsResponse {
  /** The versions of the Blueprint specified in the request, limited to the specified limit. */
  blueprintVersions: Array<BlueprintVersionReference>;
  /** The total number of versions of the specified Blueprint. */
  totalCount: Scalars["Int"]["output"];
}

export interface BoxFolder {
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** Contains only 1-level deep subfolders */
  subfolders: Array<BoxFolder>;
}

export interface BulkCreateUsersInput {
  users: Array<CreateUserInputs>;
}

export interface BulkDeleteWorkflowsError {
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
}

export interface BulkDeleteWorkflowsResponse {
  failed: Array<BulkDeleteWorkflowsError>;
  succeeded: Array<Scalars["ID"]["output"]>;
}

export interface BulkStartWorkflowsCsvColumnIdentifier {
  /**
   * The ID of the workflow-template-level field to which this column corresponds, if any. Will be
   * defined if and only if this column corresponds to a field.
   */
  fieldId?: Maybe<Scalars["ID"]["output"]>;
  /**
   * The key of the native column to which this column corresponds, if any. Will be defined if and only
   * if this column is a native column.
   */
  nativeColumnKey?: Maybe<BulkStartWorkflowsCsvNativeColumnKey>;
}

export enum BulkStartWorkflowsCsvNativeColumnKey {
  NAME = "NAME",
  OWNER = "OWNER",
  TAGS = "TAGS",
}

export interface BulkStartWorkflowsInputs {
  filename: Scalars["String"]["input"];
  records: Array<BulkStartWorkflowsJobRecordInputs>;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface BulkStartWorkflowsJob {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  filename: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  records: Array<BulkStartWorkflowsJobRecord>;
  status: BulkStartWorkflowsJobStatus;
  updatedAt: Scalars["Time"]["output"];
  workflowTemplate: WorkflowTemplate;
}

export interface BulkStartWorkflowsJobRecord {
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  status: BulkStartWorkflowsJobStatus;
  tags: Array<Tag>;
}

export interface BulkStartWorkflowsJobRecordInputs {
  description?: InputMaybe<Scalars["String"]["input"]>;
  fieldInstances?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  ownerPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  tagIds: Array<Scalars["ID"]["input"]>;
}

export enum BulkStartWorkflowsJobStatus {
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
}

export interface BulkStartWorkflowsJobStatusResponse {
  createdWorkflowIds: Array<Scalars["ID"]["output"]>;
  job: BulkStartWorkflowsJob;
  numFailed: Scalars["Int"]["output"];
  numSucceeded: Scalars["Int"]["output"];
}

export interface BulkStartWorkflowsResponse {
  createdWorkflowIds: Array<Scalars["ID"]["output"]>;
  errorCsvSignedUrl?: Maybe<Scalars["String"]["output"]>;
  job: BulkStartWorkflowsJob;
  numFailed: Scalars["Int"]["output"];
  numSucceeded: Scalars["Int"]["output"];
  successCsvSignedUrl?: Maybe<Scalars["String"]["output"]>;
  userCreationCsvSignedUrl?: Maybe<Scalars["String"]["output"]>;
}

export interface BulkStartWorkflowsV2Response {
  job: BulkStartWorkflowsJob;
}

export interface BulkUpdateActionItemResponse {
  failed: Array<UpdateActionItemResponse>;
  succeeded: Array<UpdateActionItemResponse>;
}

export enum CsvExportDateTimeFormat {
  /** Standard DateTime format in YYYY-mm-DD HH:MM:SS. Default value */
  DATETIME = "DATETIME",
  /** RFC3339 compliant format of timestamp with timezone offset: YYYY-mm-DDTHH:MM:SS-ZZ:ZZ. Example: 2023-11-16T09:22:39-08:00 */
  RFC3339 = "RFC3339",
}

export interface CalendarSettings {
  /**
   * Whether or not weekends should be removed from the calendar when calculating due dates, adjusting
   * due dates, and calculating durations of tasks.
   */
  isRemoveWeekendsEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The second of the day that the work day ends. This is used to calculate due dates, adjust due
   * dates, and calculate durations of tasks.
   */
  workDayEndSecond?: Maybe<Scalars["Int"]["output"]>;
  /**
   * The second of the day that the work day starts. This is used to calculate due dates, adjust due
   * dates, and calculate durations of tasks.
   */
  workDayStartSecond?: Maybe<Scalars["Int"]["output"]>;
}

export interface ClearOutOfOfficeResponse {
  success: Scalars["Boolean"]["output"];
}

export interface ColumnCustomizationTableSpecifier {
  isCustomizedInbox?: InputMaybe<Scalars["Boolean"]["input"]>;
  partyId?: InputMaybe<Scalars["ID"]["input"]>;
  tagId?: InputMaybe<Scalars["ID"]["input"]>;
}

/**
 * Comments relate to Action items in a many-to-one, and to other comments in a many-to-one. This relation is in one direction.
 * A comment needs either parentActionItem or parentComment
 */
export interface Comment {
  actionItem?: Maybe<ActionItem>;
  auditHistory: Array<CommentAuditHistory>;
  childComments: Array<Comment>;
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  createdByUnknownEmail?: Maybe<Scalars["String"]["output"]>;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  documents: Array<Document>;
  hasCurrentUserRead: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  mentioningActionItemDeepLink?: Maybe<Scalars["String"]["output"]>;
  mentioningActionItemName?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  visibilityType: CommentVisibilityType;
}

export interface CommentAuditHistory {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  event: AuditHistoryEvent;
  fields: Array<CommentAuditHistoryDelta>;
  id: Scalars["ID"]["output"];
}

export interface CommentAuditHistoryDelta {
  collectionAdd?: Maybe<Scalars["String"]["output"]>;
  collectionRemove?: Maybe<Scalars["String"]["output"]>;
  fieldName: Scalars["String"]["output"];
  valueAfter?: Maybe<Scalars["String"]["output"]>;
  valueBefore?: Maybe<Scalars["String"]["output"]>;
}

export interface CommentOnActionItemResponse {
  comment?: Maybe<Comment>;
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface CommentOnCommentResponse {
  comment?: Maybe<Comment>;
  error?: Maybe<Scalars["String"]["output"]>;
}

/** A reference to a comment, containing the minimum viable data about that comment. */
export interface CommentReference {
  id: Scalars["ID"]["output"];
  text: Scalars["String"]["output"];
}

export enum CommentVisibilityType {
  APPROVAL = "APPROVAL",
  DEFAULT = "DEFAULT",
  EXCEPTION = "EXCEPTION",
  REJECTION = "REJECTION",
}

export enum CompareType {
  CONTAINS = "CONTAINS",
  ENDS_WITH = "ENDS_WITH",
  EQUAL_TO = "EQUAL_TO",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  REGEX = "REGEX",
  STARTS_WITH = "STARTS_WITH",
}

export interface Condition {
  compareType: CompareType;
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  isNegated: Scalars["Boolean"]["output"];
  target: ActionItem;
  targetField: Scalars["String"]["output"];
  valueInt?: Maybe<Scalars["Int"]["output"]>;
  valueString?: Maybe<Scalars["String"]["output"]>;
}

export enum ConditionConnective {
  AND = "AND",
  OR = "OR",
}

export enum ConditionOperator {
  BETWEEN = "BETWEEN",
  CONTAINS_ALL_OF = "CONTAINS_ALL_OF",
  CONTAINS_ANY_OF = "CONTAINS_ANY_OF",
  CONTAINS_NONE_OF = "CONTAINS_NONE_OF",
  EMPTY = "EMPTY",
  EQUALS = "EQUALS",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUALS = "GREATER_THAN_OR_EQUALS",
  IS_ANY_OF = "IS_ANY_OF",
  IS_NONE_OF = "IS_NONE_OF",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUALS = "LESS_THAN_OR_EQUALS",
  NOT_EMPTY = "NOT_EMPTY",
  NOT_EQUALS = "NOT_EQUALS",
}

export interface ConditionalExpressionGroup {
  conditionGroups: Array<ConditionalExpressionGroup>;
  conditions: Array<FieldInstanceConditionalExpression>;
  connective: ConditionConnective;
  id: Scalars["ID"]["output"];
}

export enum ControllerBehaviorModifierType {
  /** Controller field instance resolves to managers of parties when used to set native field. */
  MANAGERS_OF = "MANAGERS_OF",
}

export interface CopySharedWorkflowTemplateToMyTemplatesResponse {
  workflowTemplate: WorkflowTemplate;
}

export interface CopyWorkflowTemplateFromCurrentTenantToDestinationTenantResponse {
  success: Scalars["Boolean"]["output"];
}

export interface CreateApiHealthCheckConfigInput {
  healthCheckRequestInput: CreateAutomationRequestInput;
  scheduledJobInput: CreateScheduledJobInput;
}

export interface CreateActionItemInputs {
  actionCreationStartTime?: InputMaybe<Scalars["Time"]["input"]>;
  assigneePartyIds: Array<Scalars["ID"]["input"]>;
  ccPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  dueOn?: InputMaybe<Scalars["Time"]["input"]>;
  fieldInstanceControllingAssignees?: InputMaybe<CreateFieldInstanceValueInputs>;
  fieldInstanceControllingDueOn?: InputMaybe<CreateFieldInstanceValueInputs>;
  fieldInstances?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  fieldInstancesControllingAssignees?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  fieldInstancesControllingCc?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  name: Scalars["String"]["input"];
  requiresAck: Scalars["Boolean"]["input"];
  requiresReview: Scalars["Boolean"]["input"];
  reviewerPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface CreateActionItemResponse extends Response {
  actionItem?: Maybe<ActionItem>;
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface CreateActionItemTemplateInputs {
  actionItemType?: InputMaybe<ActionItemType>;
  aiAgentBackupAssigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  assigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  automationRequests?: InputMaybe<Array<CreateAutomationRequestInput>>;
  ccPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Input to create a linked workflow task template. Required when actionItemType == LINKED_WORKFLOW. */
  createsWorkflowFromWorkflowTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  cronDefinition?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  dueOn?: InputMaybe<Scalars["Time"]["input"]>;
  dueOnIntervalSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  dueOnIntervalSecondsAfterTrigger?: InputMaybe<ActionItemDueOnIntervalTrigger>;
  dueOnIntervalSecondsType?: InputMaybe<ActionItemTemplateDueOnIntervalType>;
  /**
   * The UUIDs of the forms that we expect to be added to this action item template after creation.
   * Used for validation such as enforcing role fields can only be requested once in a workflow.
   */
  expectedFormVersionUUIDs?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  fieldInstanceControllingAssignees?: InputMaybe<CreateFieldInstanceValueInputs>;
  fieldInstanceControllingDueOn?: InputMaybe<CreateFieldInstanceValueInputs>;
  fieldInstances?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  fieldInstancesControllingAssignees?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  fieldInstancesControllingCc?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  integrationAuthenticationId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * To create a new automation task template in a Workflow Template or Worfklow
   * provide the following parameters:
   *   - <integrationAutomationInstanceId>
   *   - <integrationAutomationGroupedInputs>
   *   - <integrationAutomationInputs> (hidden as well)
   *   - <integrationAutomationOutputs>
   */
  integrationAutomationGroupedInputs?: InputMaybe<Array<ParamsToCreateIntegrationAutomationGroupedInput>>;
  /**
   * To create a new standalone automated task template both
   * <integrationAutomationId> and <integrationAuthenticationId> should be provided.
   */
  integrationAutomationId?: InputMaybe<Scalars["ID"]["input"]>;
  integrationAutomationInputs?: InputMaybe<Array<ParamsToCreateIntegrationAutomationInput>>;
  /**
   * An id of an IntegrationAutomationInstance that belongs to a standalone automated task template.
   *
   * To create a new automated task template in a Workflow/Template
   * <integrationAutomationInstanceId> should be provided.
   */
  integrationAutomationInstanceId?: InputMaybe<Scalars["ID"]["input"]>;
  integrationAutomationOutputs?: InputMaybe<Array<ParamsToCreateIntegrationAutomationOutput>>;
  /**
   * Instructs the BE that a task is created based on the pre-filled data from an existing task (i.e. duplicated).
   *
   * When creating a new task from scratch the newly added Documents are attached directly to a task.
   *
   * When duplicating a task the FE provides Document IDs from an existing task.
   * In this case the BE should copy those Documents into a new task, and avoid attaching them directly
   * because this leads to a validation error that Documents already attached to other objects.
   * For now this flag is used to determine whether it should copy given Documents or attach them directly.
   */
  isDuplicating?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  requiresAck: Scalars["Boolean"]["input"];
  requiresReview: Scalars["Boolean"]["input"];
  reviewerPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * HACKHACK
   * This parameter is used to specifically deal with the following issue:
   *   When a task is added to a running stage, if the user adds a spectrum form to it. The assignees
   *   won't always receive the form in the task email.
   *
   * This is because the form is attached in a seperate mutation after the task is created. But because
   * CreateActionItemTemplate handles email sending, when we send the email, it won't know whether the
   * form is attached or not. So we need this parameter to explicilty tell it that there will be a form
   * incoming. With the method, we can take advantage of the exponential retry mechanism in the email
   * sending service to ensure that the email is sent after the form is attached. This hack should be
   * cleaned up when we combine `CreateActionItemTemplate` with `CreateFormInstanceForActionItemTemplate`
   */
  shouldExpectFormInstance?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Input to create a standalone approval task template. Required when actionItemType == APPROVAL */
  standaloneApprovalActionItemInput?: InputMaybe<StandaloneApprovalActionItemInput>;
  standaloneAutomationActionItemTemplateID?: InputMaybe<Scalars["ID"]["input"]>;
  startAfterActionItemTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Name template suffix for the linked workflow created from the specified workflow template. Should
   * only be provided if the workflow template has a naming convention and will be ignored if not.
   * @deprecated adding a suffix to a linked workflow name is no longer supported
   */
  workflowNameTemplateSuffix?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * In case of ActionItemTemplate is added to WorkflowStage
   * workflowStageId must be provided
   */
  workflowStageId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * In case of ActionItemTemplate is added to WorkflowStageTemplate
   * workflowStageTemplateId must be provided
   */
  workflowStageTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface CreateActionItemTemplateResponse {
  actionItemTemplate?: Maybe<ActionItemTemplate>;
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface CreateApprovalActionItemTemplateInputs {
  assigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  dueOn?: InputMaybe<Scalars["Time"]["input"]>;
  dueOnIntervalSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  fieldInstancesControllingAssignees?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  startAfterActionItemTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface CreateAutomationRequestInput {
  authorizationType: AuthorizationType;
  createNewUsersFromResponse?: InputMaybe<Scalars["Boolean"]["input"]>;
  formDataParams?: InputMaybe<Array<AutomationRequestParamInputV1>>;
  headerParams?: InputMaybe<Array<AutomationRequestParamInputV1>>;
  jsonDataParams?: InputMaybe<Array<AutomationRequestParamInputV1>>;
  method: AutomationRequestMethod;
  oauth2Config?: InputMaybe<OAuth2ConfigInput>;
  paginationConfig?: InputMaybe<CreateAutomationRequestPaginationConfigInput>;
  queryParams?: InputMaybe<Array<AutomationRequestParamInputV1>>;
  responseJsonExtractorConfigInputs?: InputMaybe<Array<AutomationResponseJsonExtractorConfigInput>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
}

export interface CreateAutomationRequestPaginationConfigInput {
  initialOffset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  paginationType: PaginationType;
}

export interface CreateBlueprintImportFileResponse {
  /** A unique identifier for the file that can be used to import the blueprint. */
  fileUuid: Scalars["String"]["output"];
  /** A signed URL that can be used to upload the file to the storage location. */
  signedUrl: Scalars["String"]["output"];
}

/** Creates a relation between either a role or a party, and a blueprint. */
export interface CreateBlueprintRelationInput {
  partyOrRoleId: PartyOrRoleId;
  /** If provided, creates a relationship to a workflow template. */
  workflowTemplateRelationParams?: InputMaybe<Array<RelationWorkflowTemplateInput>>;
}

export interface CreateBlueprintVariantInput {
  conditionGroup: UpdateConditionGroupInputs;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface CreateBlueprintVariantResponse {
  variant: WorkflowTemplate;
}

export interface CreateCommentInputs {
  documentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  text: Scalars["String"]["input"];
  visibilityType?: InputMaybe<CommentVisibilityType>;
}

export interface CreateDetachedFieldInstanceResponse {
  fieldInstance: FieldInstance;
}

export interface CreateDocumentInputs {
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface CreateDocumentResponse extends Response {
  document?: Maybe<Document>;
  error?: Maybe<Scalars["String"]["output"]>;
  signedUrl?: Maybe<Scalars["String"]["output"]>;
}

export interface CreateDocumentVersionInputs {
  /**
   * Pass an ID of a Document if uploading a new document version (i.e. file with the same name).
   * So we can link the DocumentVersion to the Document with ID = documentId.
   */
  documentId: Scalars["ID"]["input"];
}

export interface CreateDocumentVersionResponse extends Response {
  documentVersion?: Maybe<DocumentVersion>;
  error?: Maybe<Scalars["String"]["output"]>;
  signedUrl?: Maybe<Scalars["String"]["output"]>;
}

export interface CreateFieldInputs {
  allowedValues?: InputMaybe<Array<FieldAllowedValuesInput>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fieldUnitId?: InputMaybe<Scalars["ID"]["input"]>;
  isCategoryField?: InputMaybe<Scalars["Boolean"]["input"]>;
  isMultiValued?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  propertyTypeId: Scalars["ID"]["input"];
  regrelloObjectId?: InputMaybe<Scalars["ID"]["input"]>;
}

/**
 * CreateFieldInstanceValueInputs is used to supply field values to a WorkflowTemplate/Blueprint when
 * creating a Workflow from it using the createWorkflowFromBlueprint mutation. Note that only one of
 * the *Value fields should be populated, depending on the type of the field. For instance, if the
 * field is a String type, then only the stringValue field should be populated.
 */
export interface CreateFieldInstanceValueInputs {
  /** If the field is a Checkbox, specify whether or not the value is "checked" here. */
  booleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Options that can be used to modify the behavior of a controller fieldInstance. */
  controllerBehaviorModifier?: InputMaybe<ControllerBehaviorModifierType>;
  /**
   * Sometimes a fieldInstance will be used to control a system-level field such as Assignees or
   * Due Date. ControlsActionItemOrActionItemTemplateField is used to indicate which field this
   * fieldInstance is intended to control.
   */
  controlsActionItemOrActionItemTemplateField?: InputMaybe<ActionItemOrActionItemTemplateField>;
  /**
   * Similar to sourceFieldInstanceValueId, but used for cross-workflow fieldInstanceValues. This is
   * relevant only in the case of Linked Workflows.
   */
  crossWorkflowSourceFieldInstanceValueId?: InputMaybe<Scalars["ID"]["input"]>;
  /** Order index in which fields should be displayed in the UI. */
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  /**
   * To store a document in Regrello, first create the document using the createDocument mutation, then
   * create a DocumentVersion using the createDocumentVersion mutation, and then upload your document
   * to the signed upload link returned by createDocumentVersion. After this, you can use the ID of the
   * Document (not the DocumentVersion) here to store a reference to the document in a Field.
   */
  documentMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * The fieldId to use as the basis for this FieldInstanceValue. The fieldId can be found using the
   * fieldsV2 query.
   */
  fieldId: Scalars["ID"]["input"];
  /** If the field holds Float type data, specify it here. */
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  /**
   * Indicates the inputType for this field. InputType is an enum that determines how a field will be
   * used. See FieldInstanceValueInputType for more details.
   */
  inputType: FieldInstanceValueInputType;
  /** If the field holds Int type data, specify it here. */
  intValue?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * Whether or not the fieldInstance will hold multiple values. This is only relevant in the case of
   * RegrelloObjectInstanceV2 fields. It indicates whether multiple objects can be selected, or only one.
   */
  isMultiValued?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The partyMultiValue field is similar to partyValue, but holds multiple. */
  partyMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * The partyValue refers to a Regrello Party, which is a User or a Team. Specify the ID of the Party here
   * if the Field being used has a User-based PropertyType.
   */
  partyValue?: InputMaybe<Scalars["ID"]["input"]>;
  /** If the fieldInstance will hold a full object, specify the External Primary Key(s) here. This is an alternative options for regrelloObjectInstanceV2IdMultiValue */
  regrelloObjectInstanceV2ExternalKeyMultiValue?: InputMaybe<Array<SyncObjectInstanceExternalKey>>;
  /** If the fieldInstance will hold a full object, specify the ID(s) here. */
  regrelloObjectInstanceV2IdMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * If a RegrelloObjectInstanceV2 fieldInstanceValue is used, then this field can be used to control
   * which properties of that object will be made available to the end user. For instance, if a
   * Supplier object is passed into a task, this field can be used to only show the Supplier's Name to
   * the user and not the Supplier's Address.
   */
  selectedRegrelloObjectPropertyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Indicates which fieldInstanceValue this fieldInstanceValue should inherit its value from. When a
   * fieldInstanceValue inherits a value from another fieldInstanceValue, it will be kept in sync with
   * the source and it will not be editable. Updates to the source field will propagate to this one.
   */
  sourceFieldInstanceValueId?: InputMaybe<Scalars["ID"]["input"]>;
  /** Specify a specific field version to update the field instance to point to. */
  spectrumFieldVersionId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Details for configuring a splitter field instance, which when attached to an action item template,
   * creates a split task.
   */
  splitConfigurationInput?: InputMaybe<SplitConfigurationInput>;
  /** If the field will hold multiple String values, specify it here. */
  stringMultiValue?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** If the field holds String type data, specify it here. */
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
  /** If the field is a timeValue, specify it here. */
  timeValue?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface CreateFieldObjectDatumInputs {
  fieldObjectDatumPropertiesInputs?: InputMaybe<Array<CreateFieldObjectDatumPropertyInputs>>;
  fieldObjectUsageId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface CreateFieldObjectDatumPropertyInputs {
  booleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  documentIdValue?: InputMaybe<Scalars["ID"]["input"]>;
  fieldObjectPropertyId: Scalars["ID"]["input"];
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  intValue?: InputMaybe<Scalars["Int"]["input"]>;
  partyIdValue?: InputMaybe<Scalars["ID"]["input"]>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
  timeValue?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface CreateFieldObjectDatumResponse {
  fieldObjectDatum: FieldObjectDatum;
}

export interface CreateFieldObjectInputs {
  adHocAdditionsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fieldObjectPropertyInputs?: InputMaybe<Array<CreateFieldObjectPropertyInputs>>;
  isCategorical?: InputMaybe<Scalars["Boolean"]["input"]>;
  isMultiSelect?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSelect?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
}

export interface CreateFieldObjectPropertyInputs {
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  fieldObjectId?: InputMaybe<Scalars["ID"]["input"]>;
  isRepresentativeField?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  propertyTypeId: Scalars["ID"]["input"];
}

export interface CreateFieldObjectPropertyResponse {
  fieldObjectProperty: FieldObjectProperty;
}

export interface CreateFieldObjectResponse {
  fieldObject: FieldObject;
}

export interface CreateFieldResponse {
  field: Field;
}

export interface CreateFormAndVersionInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  /**
   * FormV2 requires either a workflowID (if a running workflow) or a workflowTemplateID (if a blueprint) to associate the
   * form to.
   *
   * If the FormV2 Feature Flag is off, these fields are ignored.
   */
  workflowID?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * FormV2 requires either a workflowTemplateID (if a blueprint) or a workflowID (if a running workflow) to associate the
   * form to.
   *
   * If the FormV2 Feature Flag is off, these fields are ignored.
   */
  workflowTemplateID?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface CreateFormFieldInput {
  displayColumn?: InputMaybe<Scalars["Int"]["input"]>;
  displayOrder: Scalars["Float"]["input"];
  enableFieldPassing?: InputMaybe<Scalars["Boolean"]["input"]>;
  formSectionUUID: Scalars["String"]["input"];
  isMultiValued?: InputMaybe<Scalars["Boolean"]["input"]>;
  spectrumFieldUUID: Scalars["String"]["input"];
}

export interface CreateFormInstanceForActionItemInput {
  actionItemUUID?: InputMaybe<Scalars["UUID"]["input"]>;
  formVersionUUID: Scalars["String"]["input"];
}

export interface CreateFormInstanceForActionItemTemplateInput {
  actionItemTemplateUUID?: InputMaybe<Scalars["UUID"]["input"]>;
  formVersionUUID: Scalars["String"]["input"];
}

export interface CreateFormSectionInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder: Scalars["Float"]["input"];
  formVersionUUID: Scalars["String"]["input"];
  instructionOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind: FormSectionKind;
  parentSectionFormUUID?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
}

export interface CreateFormVersionInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  formUUID: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}

export interface CreateIntegrationAuthenticationInput {
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  integrationId: Scalars["ID"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
  workatoIntegrationConnectionId?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface CreateIntegrationAuthenticationResponse {
  integrationAuthentication: IntegrationAuthentication;
}

export interface CreateLineInput {
  fieldInstances?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
}

export interface CreateNotificationCardInput {
  actionItemID?: InputMaybe<Scalars["ID"]["input"]>;
  body: Scalars["String"]["input"];
  commentID?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Whether or not user-provided-feedback should be allowed for the notification card. See the
   * `feedbackSentiment` field on the NotificationCard type.
   */
  isFeedbackEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type: NotificationCardType;
  /**
   * Unique identifier for the NotificationCard in the context of the workflow. If a duplicate is
   * found, the existing card will be dismissed when the new one gets created. This is to allow an
   * external system like AI Monitor the ability to deterministically replace cards without creating
   * new ones.
   */
  uniqueIdentifier: Scalars["String"]["input"];
  workflowId: Scalars["ID"]["input"];
}

export interface CreateNotificationCardResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  notificationCard?: Maybe<NotificationCard>;
}

export interface CreateOrUpdateFormFieldConstraintInput {
  condition: FormConstraintConditionGroupInput;
  formFieldConstraintRuleUUID: Scalars["String"]["input"];
  formFieldUUID: Scalars["String"]["input"];
}

export interface CreateOrUpdateFormSectionConstraintInput {
  condition: FormConstraintConditionGroupInput;
  formSectionConstraintRuleUUID: Scalars["String"]["input"];
  formSectionUUID: Scalars["String"]["input"];
}

export interface CreateOrganizationInputs {
  name: Scalars["String"]["input"];
}

export interface CreateOrganizationResponse {
  organization: Organization;
}

export interface CreateRegrelloObjectInputs {
  /** Must be provided for source=REST_API. */
  automationRequestInput?: InputMaybe<CreateAutomationRequestInput>;
  documentId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  properties: Array<RegrelloObjectPropertyInput>;
  /**
   * An optional field because user should have the ability to create a Regrello Object
   * without a scheduled sync when they want to trigger a data sync manually.
   * Currently, this input is relevant only for source=REST_API.
   */
  scheduledJobInput?: InputMaybe<CreateScheduledJobInput>;
  source: RegrelloObjectSource;
}

export interface CreateRegrelloObjectSyncConfigResponse {
  regrelloObjectSyncConfig: RegrelloObjectSyncConfig;
  scheduledJob?: Maybe<ScheduledJob>;
}

export interface CreateRelationResponse {
  ids: Array<Scalars["ID"]["output"]>;
}

export interface CreateRoleInput {
  canInviteUsersToWorkspace?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
}

export interface CreateRoleResponse {
  role: Role;
}

export interface CreateScheduledJobErrorNotificationConfigInput {
  notifyPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  type: ScheduledJobErrorNotificationType;
}

export interface CreateScheduledJobInput {
  errorNotificationConfig?: InputMaybe<CreateScheduledJobErrorNotificationConfigInput>;
  interval: ScheduledJobInterval;
  /** Time with time zone. Format: "13:00:00+03" */
  runAt?: InputMaybe<Scalars["String"]["input"]>;
  type: ScheduledJobType;
}

export interface CreateSearchCollectionForDataSourceInput {
  /** ID of the data source to create a search collection for. */
  dataSourceId: Scalars["ID"]["input"];
  /**
   * Data object keys of the Regrello Object Properties to use for generating the embedding field for
   * semantic search.
   */
  regrelloObjectPropertyDataKeysForEmbedding: Array<Scalars["String"]["input"]>;
}

export interface CreateSearchCollectionForDataSourceResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  job: Job;
}

/**
 * The inputs necessary to create a Service Account type User. Email addresses for Service Account type users
 * are automatically generated by Regrello, although they do not recieve messages. The Email of a
 * Service Account is only used for identification.
 */
export interface CreateServiceAccountUserInputs {
  /** The level of access that the service account will have. Internal or External. See UserAccessLevel. */
  accessLevel: UserAccessLevel;
  /**
   * The access role that will be applied to the Service Account. IDs can be fetched using the
   * accessRoles query.
   */
  accessRoleId?: InputMaybe<Scalars["ID"]["input"]>;
  /** Indicate whether to create scim service account user with access to only SCIM REST endpoints. */
  isScim?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * The name that will show up in the user card for the Service Account whenever it takes actions
   * within Regrello.
   */
  name: Scalars["String"]["input"];
}

/** The response type used for the createServiceAccountUser mutation. */
export interface CreateServiceAccountUserResponse extends Response {
  /** The OAuth Client ID. */
  clientId?: Maybe<Scalars["String"]["output"]>;
  /** The OAuth Client Secret. It will only be shown here, and it will not be shown again. */
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  /** In the case of an unexpected error, information about the error will be returned in this string. */
  error?: Maybe<Scalars["String"]["output"]>;
  /** The user that was created for the Service Account. */
  user?: Maybe<User>;
}

export interface CreateSpectrumFieldAllowedValueInput {
  displayOrder: Scalars["Float"]["input"];
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  intValue?: InputMaybe<Scalars["Int"]["input"]>;
  partyIDValue?: InputMaybe<Scalars["Int"]["input"]>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateSpectrumFieldConstraintInput {
  constraintArgs: Array<Scalars["String"]["input"]>;
  spectrumFieldVersionUUID: Scalars["String"]["input"];
  spectrumValueConstraintUUID: Scalars["String"]["input"];
}

export interface CreateSpectrumFieldInput {
  allowedValues?: InputMaybe<Array<CreateSpectrumFieldAllowedValueInput>>;
  dataType: PropertyDataType;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fieldConstraints?: InputMaybe<Array<CreateSpectrumFieldConstraintInput>>;
  fieldUnitID?: InputMaybe<Scalars["Int"]["input"]>;
  helperText?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  validationTypeUUID: Scalars["String"]["input"];
}

export interface CreateSpectrumFieldVersionInput {
  allowedValues?: InputMaybe<Array<CreateSpectrumFieldAllowedValueInput>>;
  dataType: PropertyDataType;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fieldConstraints?: InputMaybe<Array<CreateSpectrumFieldConstraintInput>>;
  fieldUnitID?: InputMaybe<Scalars["Int"]["input"]>;
  helperText?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  spectrumFieldUUID: Scalars["String"]["input"];
  validationTypeUUID: Scalars["String"]["input"];
}

export interface CreateTagInputs {
  /** @deprecated createdByUserId is ignored by the handler, which attributes the tag to the current user */
  createdByUserId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  tagTypeId: Scalars["ID"]["input"];
}

export interface CreateTagResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  tag?: Maybe<Tag>;
}

export interface CreateTagTypeInputs {
  backgroundColor?: InputMaybe<Scalars["String"]["input"]>;
  borderColor?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
}

export interface CreateTagTypeResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  tagType?: Maybe<TagType>;
}

export interface CreateTeamInput {
  email: Scalars["String"]["input"];
  /** The user ids of the team members */
  memberIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  name: Scalars["String"]["input"];
  /** The user ids of the team admins */
  teamAdminIds: Array<Scalars["ID"]["input"]>;
  type: TeamType;
}

export interface CreateTeamResponse {
  team: Team;
}

export interface CreateTenantResponse {
  tenant: Tenant;
}

export interface CreateUserInputs {
  /** Whether to create an internal or external user. See UserAccessLevel. */
  accessLevel: UserAccessLevel;
  /** The accessRole that will be given to the user upon creation. See AccessRole for more information. */
  accessRoleId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * The user's email address. Regrello will use this address to send email to the user when they are
   * assigned tasks.
   */
  email: Scalars["String"]["input"];
  /** The user's full name. */
  name: Scalars["String"]["input"];
  /** The roles to give the user, if using permissions v2. */
  roles?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** The user's timeZone is used to determine when to send notifications to the user. */
  timeZone?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateUserResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
}

export interface CreateViewInput {
  columns?: InputMaybe<Array<ViewColumnInput>>;
  enabledWorkflowStatusOverviews?: InputMaybe<EnabledWorkflowStatusOverviewsInput>;
  name: Scalars["String"]["input"];
  /** This field is deprecated with RBAC-M6 and does not have an effect. */
  sharedToWorkspace: Scalars["Boolean"]["input"];
  sharedWithPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sharedWithRoleIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  showCompleted: Scalars["Boolean"]["input"];
  showWorkflowStatusOverview: Scalars["Boolean"]["input"];
  sortColumnFieldId?: InputMaybe<Scalars["ID"]["input"]>;
  sortColumnOption?: InputMaybe<WorkflowDefaultColumnOption>;
  sortColumnRegrelloObjectPropertyId?: InputMaybe<Scalars["ID"]["input"]>;
  sortOrder: SortOrder;
  viewBaseFilter: ViewBaseFilter;
}

export interface CreateViewResponse {
  view: View;
}

export interface CreateWorkatoIntegrationConnectionResponse {
  workatoIntegrationConnection: WorkatoIntegrationConnection;
}

export interface CreateWorkatoIntegrationPackageInput {
  type: WorkatoIntegrationPackageType;
  workatoPackageId: Scalars["String"]["input"];
}

export interface CreateWorkatoIntegrationPackageResponse {
  workatoIntegrationPackage: WorkatoIntegrationPackage;
}

/** The inputs necessary to create a Workflow from a WorkflowTemplate/Blueprint. */
export interface CreateWorkflowFromTemplateInputs {
  /** @deprecated creation of workflows from blueprints no longer supports adding collaborators */
  collaboratorPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Intended for Service Account use, this field can be used to specify a different Workflow creator than the service
   * account. This can be used to give another Regrello user ownership of a process. In Regrello,
   * Workflow creators get special access to the Workflow and they also recieve status update emails
   * regarding changes to the execution of the Workflow. If a non-service account attempts to set this
   * input value, it will be ignored.
   * @deprecated to be superseded by ownerPartyId field
   */
  createdByUserId?: InputMaybe<Scalars["ID"]["input"]>;
  /** @deprecated creation of workflows from blueprints no longer supports adding a description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Represents the Workflow level fields necessary to instantiate the Workflow. This input needs to be
   * lined up with the REQUESTED input type fields present on the WorkflowTemplate/Blueprint.
   */
  fieldInstances?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  /** @deprecated creation of multiline workflows is no longer supported */
  lines?: InputMaybe<Array<CreateLineInput>>;
  /** The name of the newly created Workflow. Workflow names must be unique in a Workspace. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * [Experimental] This input is experimental and is a noop while under development.
   * This field can be used to specify the Workflow owner for the created Workflow.
   * If unset the workflow owner is set to the Workflow creator (i.e. the user running this mutation)
   * or the blueprint defined default workflow owner if set.
   * The Workflow owners will be notified via email.
   * In Regrello, Workflow owners get special access to the Workflow and they also recieve status update emails
   * regarding changes to the execution of the Workflow.
   */
  ownerPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * The start condition for the Workflow. See CreateWorkflowFromTemplateStartCondition for more
   * information. By default, workflows are created in an un-started state and must then be started
   * as a second step, which can be done via the startWorkflow mutation. Specifying START_IMMEDIATELY
   * in this parameter will cause the workflow to be launched as soon as it is created.
   */
  startCondition?: InputMaybe<CreateWorkflowFromTemplateStartCondition>;
  /** @deprecated creation of workflows from blueprints no longer supports adding tags */
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** The WorkflowTemplateId (Blueprint) from which to create the new Workflow */
  workflowTemplateId: Scalars["ID"]["input"];
}

/**
 * Which start conditions to apply to a workflow created from a blueprint. Used to start a workflow
 * immediately or to start it at some point in the future after it is created. Starting it will require
 * executing another mutation in that case.
 */
export enum CreateWorkflowFromTemplateStartCondition {
  START_IMMEDIATELY = "START_IMMEDIATELY",
}

export interface CreateWorkflowInputs {
  collaboratorPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fieldInstances?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  name: Scalars["String"]["input"];
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

/** Creates a relation between either a role or a party, and a workflow. */
export interface CreateWorkflowRelationInput {
  partyOrRoleId: PartyOrRoleId;
  /** If provided, creates a relationship to a workflow. */
  workflowRelationParams?: InputMaybe<Array<RelationWorkflowInput>>;
}

export interface CreateWorkflowResponse {
  /**
   * As of Jul 2023, it's possible to start a workflow immediately upon creating it. If the workflow is
   * created successfully but failed to start, we still want to return the workflow but also notify the
   * client of the start error.
   */
  error?: Maybe<Scalars["String"]["output"]>;
  workflow: Workflow;
}

export interface CreateWorkflowStageInputs {
  /** @deprecated previously we would always set this to true */
  autoStart?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  required: Scalars["Boolean"]["input"];
  startAfterWorkflowStageId?: InputMaybe<Scalars["ID"]["input"]>;
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
  startOnWorkflowStart?: InputMaybe<Scalars["Boolean"]["input"]>;
  startingConditions?: InputMaybe<UpdateConditionGroupInputs>;
  workflowId: Scalars["ID"]["input"];
}

export interface CreateWorkflowStageInputsV2 {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  startConfig: StageStartConfig;
  workflowId: Scalars["ID"]["input"];
}

export interface CreateWorkflowStageResponse {
  workflowStage: WorkflowStage;
}

export interface CreateWorkflowStageTemplateInputs {
  /** @deprecated previously we would always set this to true */
  autoStart?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  required: Scalars["Boolean"]["input"];
  startAfterWorkflowStageTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
  startOnWorkflowStart?: InputMaybe<Scalars["Boolean"]["input"]>;
  startingConditions?: InputMaybe<UpdateConditionGroupInputs>;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface CreateWorkflowStageTemplateInputsV2 {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  startConfig: StageStartConfig;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface CreateWorkflowStageTemplateResponse {
  workflowStageTemplate?: Maybe<WorkflowStageTemplate>;
}

export interface CreateWorkflowTemplateInputs {
  /** @deprecated creation of blueprints no longer supports adding collaborators */
  collaboratorPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fieldInstances?: InputMaybe<Array<CreateFieldInstanceValueInputs>>;
  name: Scalars["String"]["input"];
  nameTemplate?: InputMaybe<UpdateNameTemplateInputs>;
  /** @deprecated creation of blueprints no longer supports adding tags */
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  versionedWorkflowTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface CreateWorkflowTemplateResponse {
  workflowTemplate: WorkflowTemplate;
}

/**
 * A CreatesWorkflowFromWorkflowTemplateReference is a reference to a WorkflowTemplate/Blueprint that
 * contains the fields that need to be accessed from its parent actionItemTemplate.
 */
export interface CreatesWorkflowFromWorkflowTemplateReference {
  /** A list of all of the Workflow level fields that will be created when the WorkflowTemplate/Blueprint is used to create a Workflow. */
  fieldInstances: Array<FieldInstance>;
  id: Scalars["ID"]["output"];
  /** The name of the WorkflowTemplate/Blueprint. */
  name: Scalars["String"]["output"];
  /** The naming convention/template with which the WorkflowTemplate/Blueprint's name is crafted. */
  nameTemplate?: Maybe<NameTemplate>;
  /** A sum of the durations of all tasks, taking into account thier dependencies and requirements. */
  predictedDurationDays: Scalars["Int"]["output"];
  /**
   * The type of a Workflow is one of MINE, REGRELLO, or COMPANY and it indicates where the
   * WorkflowTemplate/Blueprint will be presented in the application. MINE templates are the ones that
   * show up in "My Blueprints" and only the user who created them has access. COMPANY templates are
   * the ones that show up in the "Company Blueprints" section and they are shared with all users in
   * the Workspace as long as they have permission to publish new workflows. REGRELLO scoped templates are not implemented.
   */
  type: WorkflowTemplateType;
  /** Default owner of any workflows kicked off from this workflow template. */
  workflowOwnerParty?: Maybe<Party>;
}

export interface CustomColumnsStateResponse {
  enabledDefaultColumnOptions?: Maybe<DefaultColumnOptions>;
  enabledFields: Array<Field>;
}

export enum CustomFieldDefaultColumnOption {
  BLUEPRINT = "BLUEPRINT",
  FIELD = "FIELD",
  MANAGE = "MANAGE",
  PLUGIN = "PLUGIN",
  UPDATED = "UPDATED",
}

export interface DataObjectCell {
  key: Scalars["String"]["output"];
  value: DataObjectCellValue;
}

export type DataObjectCellValue = DataObjectCellValueString;

export interface DataObjectCellValueString {
  stringValue?: Maybe<Scalars["String"]["output"]>;
}

export type DefaultColumnOptions = TaskDefaultColumnOptions | WorkflowDefaultColumnOptions;

export interface DefaultColumnOptionsInput {
  taskDefaultColumnOptions?: InputMaybe<Array<TaskDefaultColumnOption>>;
  workflowDefaultColumnOptions?: InputMaybe<Array<WorkflowDefaultColumnOption>>;
}

/** DEPRECATED. Use SuccessResponse instead. */
export interface DefaultResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteActionItemResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteActionItemTemplateResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteAutomationRequestsFromActionItemTemplateResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteCommentResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteDetachedFieldInstanceValueResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteDocumentResponse extends Response {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteDocumentVersionResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteFieldResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteIntegrationAuthenticationResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteRegrelloObjectResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteRoleInput {
  id: Scalars["ID"]["input"];
}

export interface DeleteRoleResponse {
  success: Scalars["Boolean"]["output"];
  /**
   * Only appears when `success` is set to false. Contains the blueprints that this role is still
   * used in.
   */
  usedInWorkflowTemplates?: Maybe<Array<WorkflowTemplate>>;
}

export interface DeleteSearchCollectionForDataSourceResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface DeleteTagResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteTagTypeResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteTeamResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteUserResponse extends Response {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteViewInput {
  viewId: Scalars["ID"]["input"];
}

export interface DeleteViewResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteWorkatoIntegrationPackageResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteWorkflowCollaborationResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteWorkflowResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteWorkflowStageResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteWorkflowStageTemplateResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeleteWorkflowTemplateCollaborationResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DeleteWorkflowTemplateResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DeployWorkatoIntegrationPackageResponse {
  success: Scalars["Boolean"]["output"];
}

export interface DevOnlyDeleteAuth0TestUsersResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface DiscoverableBlueprintsInput {
  /** If defined, only blueprints whose name or UUID contains the specified substring will be returned. */
  searchUuidOrName?: InputMaybe<Scalars["String"]["input"]>;
  /** How to sort the resulting blueprints. */
  sort: BlueprintSortInput;
}

export interface DismissNotificationCardResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
}

/**
 * Documents can be tied to one or more Action Items, or one or more Comments.
 * Within a Document we can retrieve all of its DocumentVersions.
 */
export interface Document {
  actionItem?: Maybe<ActionItem>;
  actionItemTemplate?: Maybe<ActionItemTemplate>;
  comment?: Maybe<Comment>;
  currentVersion: DocumentVersion;
  documentType: DocumentType;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  permissions: PermissionsToDocument;
  storageType: DocumentStorageType;
  tags: Array<Tag>;
  versions: Array<DocumentVersion>;
  workflow?: Maybe<BaseWorkflowReference>;
}

export interface DocumentAdvancedFilterInputs {
  actionItemName?: InputMaybe<Scalars["String"]["input"]>;
  createdByPartyIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  tagIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  uploadDateTimeRange?: InputMaybe<TimeRange>;
}

export enum DocumentDefaultColumnOption {
  ADDED_BY = "ADDED_BY",
  DELETE = "DELETE",
  FILE_NAME = "FILE_NAME",
  TAGS = "TAGS",
  TASK = "TASK",
  UPLOAD_DATE = "UPLOAD_DATE",
  WORKFLOW = "WORKFLOW",
}

export enum DocumentStorageType {
  EXTERNAL_LINK = "EXTERNAL_LINK",
  REGRELLO = "REGRELLO",
}

export interface DocumentType {
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
}

/** DocumentVersion can only relate to one Document */
export interface DocumentVersion {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  externalLink?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  uid: Scalars["String"]["output"];
}

export interface DocumentsV2Response {
  documents: Array<Document>;
  /** Total number of records matching the search parameters at request-time. */
  totalCount: Scalars["Int"]["output"];
}

export interface DocusignTemplate {
  externalId: Scalars["String"]["output"];
  groupedInputs: Array<IntegrationAutomationGroupedInputs>;
  inputs: Array<IntegrationAutomationInput>;
  name: Scalars["String"]["output"];
  outputs: Array<IntegrationAutomationOutput>;
}

export interface DownloadBlueprintAsTemplateResponse {
  signedUrl: Scalars["String"]["output"];
}

export enum DueOnClass {
  /** Now <= Due On < Now + 48 HR. This is deprecated as of Nov. 1, 2021. */
  DUE_SOON = "DUE_SOON",
  /** On Time */
  ON_TIME = "ON_TIME",
  /** Due On < Now */
  OVERDUE = "OVERDUE",
}

/** Input fields for updating the due on interval field on an action item template. */
export interface DueOnIntervalInput {
  dueOnIntervalSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * Determines how `dueOnIntervalSeconds` is turned into a Due Date. In the case of WORKING_DAYS, the
   * length of the working hours configured in CalendarSettings will be used to determine the due date.
   * In that case, a day will be defined to be the length of the working hours. In the case of
   * INTERVAL_SECONDS, the `dueOnIntervalSeconds` will be added directly without any adjustments. If
   * not defined, WORKING_DAYS will be used by default if `dueOnIntervalSeconds` is a multiple of the
   * number of seconds in a day. Otherwise, INTERVAL_SECONDS will be used.
   */
  dueOnIntervalType?: InputMaybe<ActionItemTemplateDueOnIntervalType>;
}

export interface EmailSendResponse {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface EnabledWorkflowStatusOverviews {
  completedWorkflows: Scalars["Boolean"]["output"];
  endedWorkflows: Scalars["Boolean"]["output"];
  inProgressWorkflows: Scalars["Boolean"]["output"];
  notStartedWorkflows: Scalars["Boolean"]["output"];
  onTrackWorkflows: Scalars["Boolean"]["output"];
  pausedWorkflows: Scalars["Boolean"]["output"];
  problemsReported: Scalars["Boolean"]["output"];
  workflowsWithExceptions: Scalars["Boolean"]["output"];
  workflowsWithOverdueStages: Scalars["Boolean"]["output"];
}

export interface EnabledWorkflowStatusOverviewsInput {
  completedWorkflows: Scalars["Boolean"]["input"];
  endedWorkflows: Scalars["Boolean"]["input"];
  inProgressWorkflows: Scalars["Boolean"]["input"];
  notStartedWorkflows: Scalars["Boolean"]["input"];
  onTrackWorkflows: Scalars["Boolean"]["input"];
  pausedWorkflows: Scalars["Boolean"]["input"];
  problemsReported: Scalars["Boolean"]["input"];
  workflowsWithExceptions: Scalars["Boolean"]["input"];
  workflowsWithOverdueStages: Scalars["Boolean"]["input"];
}

export interface EndWorkflowResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface ExportBlueprintInput {
  blueprintId: Scalars["ID"]["input"];
}

export interface ExportBlueprintResponse {
  /**
   * Useful for testing purposes to immediatly import the blueprint back into the same workspace. For cross workspace transfer
   * the file must be downloaded and reuploaded in the target workspace using the createBlueprintImportFile endpoint.
   */
  fileUuid: Scalars["String"]["output"];
  /** A signed URL that can be used to download the exported blueprint data. */
  signedUrl: Scalars["String"]["output"];
}

export interface ExportFormField {
  displayOrder: Scalars["Float"]["output"];
  enableFieldPassing: Scalars["Boolean"]["output"];
  formSectionId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  isMultiValued: Scalars["Boolean"]["output"];
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  spectrumFieldId: Scalars["ID"]["output"];
}

export interface ExportFormInstructionSection {
  description: Scalars["String"]["output"];
  displayOrder: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
}

export interface ExportFormSection {
  description: Scalars["String"]["output"];
  displayOrder: Scalars["Float"]["output"];
  fields: Array<ExportFormField>;
  id: Scalars["ID"]["output"];
  instructions: Array<ExportFormInstructionSection>;
  name: Scalars["String"]["output"];
}

export interface ExportFormStructure {
  description: Scalars["String"]["output"];
  formVersionUuid: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  sections: Array<ExportFormSection>;
}

/**
 * Fields are used to define the way that Regrello will store data from the user, in the user's
 * schema. For instance, to store information about a car, you might have fields that represent the
 * car's make, model, and year. Each field has a type, which determines the allowed values or
 * requirements for the field.
 */
export interface Field {
  /**
   * Primarily used for a select-dropdown type of field, it indicates all of the allowed values for a
   * field. Instances of this field will only be able to store values listed here. Validation is done
   * at the time of field instance update or creation.
   */
  allowedValues: Array<FieldAllowedValue>;
  /** If present, the Field was deleted at this timestamp. */
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  /** A human-friendly description of the field. */
  description?: Maybe<Scalars["String"]["output"]>;
  /**
   * Whether this field can only be fulfilled by values meeting the provided restriction.
   * Currently only intended for use in permissions v2.
   */
  fieldRestriction?: Maybe<FieldRestriction>;
  /** The type of the field. See FieldType for more information. */
  fieldType: FieldType;
  /**
   * FieldUnit is used to indicate the unit of measurement for a field. For instance, a field
   * represending USD currency will have a FieldUnit indicating this.
   */
  fieldUnit?: Maybe<FieldUnit>;
  /** A unique identifier for the field. */
  id: Scalars["ID"]["output"];
  /**
   * deprecated. Set to false.
   * @deprecated Feature is no longer used.
   */
  isCategoryField: Scalars["Boolean"]["output"];
  /** Whether or not the field is allowed to hold multiple values. */
  isMultiValued: Scalars["Boolean"]["output"];
  /** A unique name for the field that indicates what type of information it holds. */
  name: Scalars["String"]["output"];
  /** The data type of the field. See PropertyType for more information. */
  propertyType: PropertyType;
  /**
   * Fields are used as a type of reference or wrapper for a full Regrello Object, when that Regrello
   * Object would be used in a Workflow or Task. If this field is
   * populated, then the Field refers to the referenced RegrelloObject.
   */
  regrelloObject?: Maybe<RegrelloObject>;
  /** The scim attribute this field is designated for storing the value of. */
  scimAttributeName?: Maybe<Scalars["String"]["output"]>;
  /** The timestamp at which the Field was last changed. */
  updatedAt?: Maybe<Scalars["Time"]["output"]>;
  /** A convenience aggregation indicating the number of WorkflowTemplates/Blueprints that use this Field. */
  workflowTemplateCounts: Scalars["Int"]["output"];
}

export type FieldAllowedValue = FieldAllowedValueParty | FieldAllowedValueString;

export interface FieldAllowedValueParty {
  displayOrder: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  partyValue: Party;
}

export interface FieldAllowedValueString {
  displayOrder: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  stringValue: Scalars["String"]["output"];
}

export interface FieldAllowedValuesInput {
  allowedValuePartyId?: InputMaybe<Scalars["ID"]["input"]>;
  allowedValueString?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder: Scalars["Float"]["input"];
}

export interface FieldInstance {
  actionItem?: Maybe<ActionItem>;
  actionItemTemplate?: Maybe<ActionItemTemplate>;
  /** Specify changes to the default behavior of the controller type field instance. */
  controllerBehaviorModifier?: Maybe<ControllerBehaviorModifierType>;
  controlsActionItemOrActionItemTemplateField?: Maybe<ActionItemOrActionItemTemplateField>;
  crossWorkflowSourceFieldInstanceValueId?: Maybe<Scalars["ID"]["output"]>;
  /**
   * If Spectrum is enabled and this field instance belongs to a form we will override the field's display order
   * with a calculated display order (combining section display order and form field display order).
   * If Spectrum is not enabled or the field instance does not belong to a form we will just return the saved display order.
   */
  displayOrder?: Maybe<Scalars["Float"]["output"]>;
  field: Field;
  formFieldID?: Maybe<Scalars["ID"]["output"]>;
  formInstanceID?: Maybe<Scalars["ID"]["output"]>;
  id: Scalars["ID"]["output"];
  inputType?: Maybe<FieldInstanceValueInputType>;
  /**
   * Whether the FieldInstance was copied from another one. Useful in certain contexts for knowing
   * whether it existed on an object's template before materialization.
   */
  isCopy?: Maybe<Scalars["Boolean"]["output"]>;
  isMultiValued?: Maybe<Scalars["Boolean"]["output"]>;
  /** Indicates this is a splitter field */
  isSplitter?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * If Spectrum is enabled, this will be the ID of the latest available spectrum-field version for the
   * field backing this field instance. This can be used to discern whether this field instance is
   * showing an outdated version of this field.
   *
   * If Spectrum is not enabled, this will always be null.
   */
  latestAvailableSpectrumFieldVersion?: Maybe<SpectrumFieldVersion>;
  mapsToStandard: Scalars["Boolean"]["output"];
  projection?: Maybe<FieldInstanceProjection>;
  regrelloObjectPropertyId?: Maybe<Scalars["ID"]["output"]>;
  /** Indicates whether to split the task assignees up based on this field instance's values. Must correspond to a party field to be true. */
  shouldSplitAssignees?: Maybe<Scalars["Boolean"]["output"]>;
  /** If Spectrum is enabled we will join the SpectrumFieldVersion to the FieldInstance, including ValidationType and PropertyType */
  spectrumFieldVersion?: Maybe<SpectrumFieldVersion>;
  spectrumMetadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  values: Array<FieldInstanceValue>;
  workflow?: Maybe<Workflow>;
  workflowTemplate?: Maybe<WorkflowTemplate>;
  writtenByActionItem?: Maybe<ActionItem>;
}

export interface FieldInstanceConditionalExpression {
  id: Scalars["ID"]["output"];
  left: FieldInstance;
  leftFieldInstancePropertyID?: Maybe<Scalars["ID"]["output"]>;
  operatorV2: ConditionOperator;
  right: Array<FieldInstance>;
}

export interface FieldInstanceMultiValueDocument extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceMultiValueDocument?: Maybe<Array<FieldInstanceMultiValueDocument>>;
  crossWorkflowSourceFieldInstanceMultiValueDocument?: Maybe<FieldInstanceMultiValueDocument>;
  documentMultiValue: Array<Document>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  sinksFieldInstanceMultiValueDocument: Array<FieldInstanceMultiValueDocument>;
  sourceFieldInstanceMultiValueDocument?: Maybe<FieldInstanceMultiValueDocument>;
  /**
   * To slim down the table field instance objects, only "primitive" data types (strings, ints, float and IDs) are returned
   * in the row cells. Nested objects will not be resolved inline of the FieldInstance, instead they will be resolved
   * at the column level once as auxiliary data. This allows us to deduplicate repeated column data.
   */
  tabularAuxiliaryDataDocument?: Maybe<Array<Document>>;
  tabularValueDocument?: Maybe<Array<FieldInstanceTabularMultiValueDocument>>;
}

export interface FieldInstanceMultiValueFloat extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceMultiValueFloat?: Maybe<Array<FieldInstanceMultiValueFloat>>;
  crossWorkflowSourceFieldInstanceMultiValueFloat?: Maybe<FieldInstanceMultiValueFloat>;
  floatMultiValue: Array<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  sinksFieldInstanceMultiValueFloat: Array<FieldInstanceMultiValueFloat>;
  sourceFieldInstanceMultiValueFloat?: Maybe<FieldInstanceMultiValueFloat>;
  tabularMultiValueFloat?: Maybe<Array<FieldInstanceTabularMultiValueFloat>>;
}

export interface FieldInstanceMultiValueInt extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceMultiValueInt?: Maybe<Array<FieldInstanceMultiValueInt>>;
  crossWorkflowSourceFieldInstanceMultiValueInt?: Maybe<FieldInstanceMultiValueInt>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  intMultiValue: Array<Scalars["Int"]["output"]>;
  sinksFieldInstanceMultiValueInt: Array<FieldInstanceMultiValueInt>;
  sourceFieldInstanceMultiValueInt?: Maybe<FieldInstanceMultiValueInt>;
  tabularMultiValueInt?: Maybe<Array<FieldInstanceTabularMultiValueInt>>;
}

export interface FieldInstanceMultiValueParty extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceMultiValueParty?: Maybe<Array<FieldInstanceMultiValueParty>>;
  crossWorkflowSourceFieldInstanceMultiValueParty?: Maybe<FieldInstanceMultiValueParty>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  partyMultiValue: Array<Party>;
  sinksFieldInstanceMultiValueParty: Array<FieldInstanceMultiValueParty>;
  sourceFieldInstanceMultiValueParty?: Maybe<FieldInstanceMultiValueParty>;
  sourceFieldInstanceMultiValuePartyV2?: Maybe<FieldInstance>;
  /**
   * To slim down the table field instance objects, only "primitive" data types (strings, ints, float and IDs) are returned
   * in the row cells. Nested objects will not be resolved inline of the FieldInstance, instead they will be resolved
   * at the column level once as auxiliary data. This allows us to deduplicate repeated column data.
   */
  tabularAuxiliaryDataParty?: Maybe<Array<Party>>;
  tabularMultiValueParty?: Maybe<Array<FieldInstanceTabularMultiValueParty>>;
}

export interface FieldInstanceMultiValueRegrelloObjectInstance extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceMultiValueRegrelloObjectInstance?: Maybe<
    Array<FieldInstanceMultiValueRegrelloObjectInstance>
  >;
  crossWorkflowSourceFieldInstanceMultiValueRegrelloObjectInstance?: Maybe<FieldInstanceMultiValueRegrelloObjectInstance>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  regrelloObjectInstanceMultiValue: Array<RegrelloObjectInstanceV2>;
  sinksFieldInstanceMultiValueRegrelloObjectInstance: Array<FieldInstanceMultiValueRegrelloObjectInstance>;
  sourceFieldInstanceMultiValueRegrelloObjectInstance?: Maybe<FieldInstanceMultiValueRegrelloObjectInstance>;
  /**
   * To slim down the table field instance objects, only "primitive" data types (strings, ints, float and IDs) are returned
   * in the row cells. Nested objects will not be resolved inline of the FieldInstance, instead they will be resolved
   * at the column level once as auxiliary data. This allows us to deduplicate repeated column data.
   */
  tabularAuxiliaryDataRegrelloObjectInstance?: Maybe<Array<RegrelloObjectInstanceV2>>;
  tabularMultiValueRegrelloObjectInstance?: Maybe<Array<FieldInstanceTabularMultiValueRegrelloObjectInstance>>;
}

export interface FieldInstanceMultiValueString extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceMultiValueString?: Maybe<Array<FieldInstanceMultiValueString>>;
  crossWorkflowSourceFieldInstanceMultiValueString?: Maybe<FieldInstanceMultiValueString>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  sinksFieldInstanceMultiValueString: Array<FieldInstanceMultiValueString>;
  sourceFieldInstanceMultiValueString?: Maybe<FieldInstanceMultiValueString>;
  stringMultiValue: Array<Scalars["String"]["output"]>;
  tabularMultiValueString?: Maybe<Array<FieldInstanceTabularMultiValueString>>;
}

export interface FieldInstanceMultiValueTime extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceMultiValueTime?: Maybe<Array<FieldInstanceMultiValueTime>>;
  crossWorkflowSourceFieldInstanceMultiValueTime?: Maybe<FieldInstanceMultiValueTime>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  sinksFieldInstanceMultiValueTime: Array<FieldInstanceMultiValueTime>;
  sourceFieldInstanceMultiValueTime?: Maybe<FieldInstanceMultiValueTime>;
  tabularMultiValueTime?: Maybe<Array<FieldInstanceTabularMultiValueTime>>;
  timeMultiValue: Array<Scalars["Time"]["output"]>;
}

export interface FieldInstanceProjection {
  selectedRegrelloObjectPropertyIds: Array<Scalars["ID"]["output"]>;
}

export interface FieldInstanceSource {
  actionItemFrozeForReason?: Maybe<ActionItemFrozeForReason>;
  actionItemId?: Maybe<Scalars["ID"]["output"]>;
  actionItemIsSplitter: Scalars["Boolean"]["output"];
  actionItemName?: Maybe<Scalars["String"]["output"]>;
  fieldInstanceId: Scalars["ID"]["output"];
  splitChildData?: Maybe<SplitChildData>;
  stageId?: Maybe<Scalars["ID"]["output"]>;
  stageName?: Maybe<Scalars["String"]["output"]>;
}

export interface FieldInstanceSourcesForActionItem {
  actionItemFieldInstanceSources: Array<FieldInstanceSource>;
}

export interface FieldInstanceSpectrumMetadata {
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
}

export interface FieldInstanceSpectrumMetadataPair {
  fieldInstanceID: Scalars["ID"]["output"];
  spectrumMetadatas: Array<FieldInstanceSpectrumMetadata>;
}

export interface FieldInstanceTabularMultiValueDocument {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value: Array<Scalars["ID"]["output"]>;
}

export interface FieldInstanceTabularMultiValueFloat {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value: Array<Scalars["Float"]["output"]>;
}

export interface FieldInstanceTabularMultiValueInt {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value: Array<Scalars["Int"]["output"]>;
}

export interface FieldInstanceTabularMultiValueParty {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value: Array<Scalars["ID"]["output"]>;
}

export interface FieldInstanceTabularMultiValueRegrelloObjectInstance {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value: Array<Scalars["ID"]["output"]>;
}

export interface FieldInstanceTabularMultiValueString {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value: Array<Scalars["String"]["output"]>;
}

export interface FieldInstanceTabularMultiValueTime {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value: Array<Scalars["Time"]["output"]>;
}

export interface FieldInstanceTabularValueBoolean {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface FieldInstanceTabularValueFloat {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value?: Maybe<Scalars["Float"]["output"]>;
}

export interface FieldInstanceTabularValueInt {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value?: Maybe<Scalars["Int"]["output"]>;
}

export interface FieldInstanceTabularValueParty {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value?: Maybe<Scalars["ID"]["output"]>;
}

export interface FieldInstanceTabularValueString {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
}

export interface FieldInstanceTabularValueTime {
  metadata?: Maybe<Array<FieldInstanceSpectrumMetadata>>;
  /** The row number is 1-index based. */
  rowNumber: Scalars["Int"]["output"];
  value?: Maybe<Scalars["Time"]["output"]>;
}

/**
 * A FieldInstanceValue is the core representation of a field value in Regrello. It is used to
 *   represent the value of a field on a Task/ActionItem or Workflow. It is also used to represent the value of a
 *   field on a WorkflowTemplate/Blueprint.
 */
export interface FieldInstanceValue {
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
}

export interface FieldInstanceValueBoolean extends FieldInstanceValue {
  booleanValue?: Maybe<Scalars["Boolean"]["output"]>;
  crossWorkflowSinksFieldInstanceValueBoolean?: Maybe<Array<FieldInstanceValueBoolean>>;
  crossWorkflowSourceFieldInstanceValueBoolean?: Maybe<FieldInstanceValueBoolean>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  sinksFieldInstanceValueBoolean: Array<FieldInstanceValueBoolean>;
  sourceFieldInstanceValueBoolean?: Maybe<FieldInstanceValueBoolean>;
  tabularValueBoolean?: Maybe<Array<FieldInstanceTabularValueBoolean>>;
}

export interface FieldInstanceValueFloat extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceValueFloat?: Maybe<Array<FieldInstanceValueFloat>>;
  crossWorkflowSourceFieldInstanceValueFloat?: Maybe<FieldInstanceValueFloat>;
  floatValue?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  sinksFieldInstanceValueFloat: Array<FieldInstanceValueFloat>;
  sourceFieldInstanceValueFloat?: Maybe<FieldInstanceValueFloat>;
  tabularValueFloat?: Maybe<Array<FieldInstanceTabularValueFloat>>;
}

/**
 * Enumerates all of the ways that a field may be used in Regrello.
 * REQUESTED fields on a task or workflow are required to be submitted before a task can be submitted
 * or before a workflow can be started.
 * PROVIDED fields are fields that are provided to the user or team that is assigned the task.
 * SUBMITTED fields are fields that are submitted by the user or team that is assigned the task, when
 * referenced in an approval task.
 * INHERITED fields are fields that are inherited from the workflow level or from a previous task.
 * OPTIONAL fields are fields that are not required to be submitted before a task can be submitted, but
 * otherwise they behave like REQUESTED fields.
 * DERIVED fields are fields that are calculated based on other data fields (custom or native).
 */
export enum FieldInstanceValueInputType {
  DERIVED = "DERIVED",
  INHERITED = "INHERITED",
  OPTIONAL = "OPTIONAL",
  PROVIDED = "PROVIDED",
  REQUESTED = "REQUESTED",
  SUBMITTED = "SUBMITTED",
}

export interface FieldInstanceValueInt extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceValueInt?: Maybe<Array<FieldInstanceValueInt>>;
  crossWorkflowSourceFieldInstanceValueInt?: Maybe<FieldInstanceValueInt>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  intValue?: Maybe<Scalars["Int"]["output"]>;
  sinksFieldInstanceValueInt: Array<FieldInstanceValueInt>;
  sourceFieldInstanceValueInt?: Maybe<FieldInstanceValueInt>;
  tabularValueInt?: Maybe<Array<FieldInstanceTabularValueInt>>;
}

export interface FieldInstanceValueParty extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceValueParty?: Maybe<Array<FieldInstanceValueParty>>;
  crossWorkflowSourceFieldInstanceValueParty?: Maybe<FieldInstanceValueParty>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  partyValue?: Maybe<Party>;
  sinksFieldInstanceValueParty: Array<FieldInstanceValueParty>;
  sourceFieldInstanceValueParty?: Maybe<FieldInstanceValueParty>;
  /**
   * To slim down the table field instance objects, only "primitive" data types (strings, ints, float and IDs) are returned
   * in the row cells. Nested objects will not be resolved inline of the FieldInstance, instead they will be resolved
   * at the column level once as auxiliary data. This allows us to deduplicate repeated column data.
   */
  tabularAuxiliaryDataParty?: Maybe<Array<Party>>;
  tabularValueParty?: Maybe<Array<FieldInstanceTabularValueParty>>;
}

export interface FieldInstanceValueString extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceValueString?: Maybe<Array<FieldInstanceValueString>>;
  crossWorkflowSourceFieldInstanceValueString?: Maybe<FieldInstanceValueString>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  sinksFieldInstanceValueString: Array<FieldInstanceValueString>;
  sourceFieldInstanceValueString?: Maybe<FieldInstanceValueString>;
  stringValue?: Maybe<Scalars["String"]["output"]>;
  tabularValueString?: Maybe<Array<FieldInstanceTabularValueString>>;
}

export interface FieldInstanceValueTime extends FieldInstanceValue {
  crossWorkflowSinksFieldInstanceValueTime?: Maybe<Array<FieldInstanceValueTime>>;
  crossWorkflowSourceFieldInstanceValueTime?: Maybe<FieldInstanceValueTime>;
  id: Scalars["ID"]["output"];
  inputType: FieldInstanceValueInputType;
  sinksFieldInstanceValueTime: Array<FieldInstanceValueTime>;
  sourceFieldInstanceValueTime?: Maybe<FieldInstanceValueTime>;
  sourceFieldInstanceValueTimeV2?: Maybe<FieldInstance>;
  tabularValueTime?: Maybe<Array<FieldInstanceTabularValueTime>>;
  timeValue?: Maybe<Scalars["Time"]["output"]>;
}

export interface FieldInstancesAvailableAsSourceValues {
  actionItemFieldInstances: Array<FieldInstance>;
  actionItemTemplateFieldInstances: Array<FieldInstance>;
  workflowFieldInstances: Array<FieldInstance>;
  workflowTemplateFieldInstances: Array<FieldInstance>;
}

export interface FieldInstancesByFields {
  fieldInstances: Array<FieldInstance>;
}

/**
 * A field object is a uniquely identifiable "bag" of properties. It is global in scope, unassociated
 * with any particular user-facing entity (e.g. workflow, action item, or a template thereof). It can
 * be linked with user-facing enties with the help of a FieldObjectUsage record; FieldObjectUsages can then be associated with a given
 * user-facing entity (e.g. a workflow, action item, or template thereof).
 */
export interface FieldObject {
  adHocAdditionsAllowed: Scalars["Boolean"]["output"];
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  description?: Maybe<Scalars["String"]["output"]>;
  fieldObjectProperties: Array<FieldObjectProperty>;
  id: Scalars["ID"]["output"];
  isCategorical: Scalars["Boolean"]["output"];
  isMultiSelect: Scalars["Boolean"]["output"];
  isSelect: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["Time"]["output"];
}

export interface FieldObjectDatum {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  fieldObjectDatumProperties: Array<FieldObjectDatumProperty>;
  id: Scalars["ID"]["output"];
  updatedAt: Scalars["Time"]["output"];
}

export type FieldObjectDatumProperty =
  | FieldObjectDatumPropertyBoolean
  | FieldObjectDatumPropertyDocument
  | FieldObjectDatumPropertyFloat
  | FieldObjectDatumPropertyInt
  | FieldObjectDatumPropertyParty
  | FieldObjectDatumPropertyString
  | FieldObjectDatumPropertyTime;

export interface FieldObjectDatumPropertyBoolean {
  fieldObjectProperty: FieldObjectProperty;
  value?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface FieldObjectDatumPropertyDocument {
  fieldObjectProperty: FieldObjectProperty;
  value?: Maybe<Document>;
}

export interface FieldObjectDatumPropertyFloat {
  fieldObjectProperty: FieldObjectProperty;
  value?: Maybe<Scalars["Float"]["output"]>;
}

export interface FieldObjectDatumPropertyInt {
  fieldObjectProperty: FieldObjectProperty;
  value?: Maybe<Scalars["Int"]["output"]>;
}

export interface FieldObjectDatumPropertyParty {
  fieldObjectProperty: FieldObjectProperty;
  value?: Maybe<Party>;
}

export interface FieldObjectDatumPropertyString {
  fieldObjectProperty: FieldObjectProperty;
  value?: Maybe<Scalars["String"]["output"]>;
}

export interface FieldObjectDatumPropertyTime {
  fieldObjectProperty: FieldObjectProperty;
  value?: Maybe<Scalars["Time"]["output"]>;
}

export interface FieldObjectProperty {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  isRepresentativeField: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  propertyType: PropertyType;
  updatedAt: Scalars["Time"]["output"];
}

/**
 * FieldObjectUsage defines the way that a FieldObjectDatum is used in a user-facing Regrello object: ActionItem, ActionItemTemplate, Workflow, WorkflowTemplate.
 * It is possible for one FieldObjectDatum record to relate to multiple FieldObjectUsages (Supplier record is shared between many action items)
 * and also for multiple FieldObjectDatum records to relate to one FieldObjectUsage (Product field on Action Item holds many Products)
 */
export interface FieldObjectUsage {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  displayOrder: Scalars["Float"]["output"];
  fieldNameOverride?: Maybe<Scalars["String"]["output"]>;
  fieldObject: FieldObject;
  fieldObjectData: Array<FieldObjectDatum>;
  id: Scalars["ID"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  isOutput: Scalars["Boolean"]["output"];
  isReadOnly: Scalars["Boolean"]["output"];
  isRequired: Scalars["Boolean"]["output"];
  source?: Maybe<FieldObjectUsage>;
  updatedAt: Scalars["Time"]["output"];
}

export interface FieldRestriction {
  /**
   * Whether this restriction is for filtering the allowed values by parties which are a member of this
   * role.
   */
  filterByRole?: Maybe<Role>;
  id: Scalars["ID"]["output"];
}

/**
 * FieldTypes indicate the functionality of a Field.
 * DEFAULT fields are used to hold information about a task or workflow.
 * CONTROLLER fields are used to control built-in Regrello fields like Assignees or Due Dates.
 * SYSTEM fields are used to hold information that is not configurable by the user, but that is
 * referencable. Workflow Owner is a good example of a SYSTEM type field. It operates in a Workflow as
 * a field, and can be referenced like a field, but the user cannot re-define it, rename it, change its
 * type, or change validation.
 */
export enum FieldType {
  CONTROLLER = "CONTROLLER",
  DEFAULT = "DEFAULT",
  SYSTEM = "SYSTEM",
}

export interface FieldUnit {
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
  type: FieldUnitType;
}

export enum FieldUnitType {
  CURRENCY = "CURRENCY",
}

export interface FieldsV2Response {
  fields: Array<Field>;
  /** Total number of records matching the search parameters at request-time. */
  totalCount: Scalars["Int"]["output"];
}

export interface FilterByDataObjectKeyClause {
  /** Data object key to filter by. */
  dataObjectKey: Scalars["String"]["input"];
  /** Value of the data object key to filter by. */
  stringValue: Scalars["String"]["input"];
}

export interface FlatfileInfoResponse {
  flatfileIsDevModeDisabled: Scalars["Boolean"]["output"];
  flatfileLicenseKey: Scalars["String"]["output"];
}

/**
 * A top-level container for a user-facing form that collects user input. This entity contains no data
 * directly. Rather, it has a sequence of form versions that each contain mutable data about the form -
 * including its name, description, and contents (e.g., sections, fields).
 */
export interface Form {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  /**
   * An ordered history of versions of this form. The oldest version is first in the list; the
   * newest version is last in the list.
   */
  formVersions: Array<FormVersion>;
  id: Scalars["ID"]["output"];
  /**
   * The latest version of this form. This is available for convenience to facilitate the expected
   * behavior of showing the most recent form version to the user.
   */
  latestFormVersion: FormVersion;
  publishedFromFormID?: Maybe<Scalars["ID"]["output"]>;
  /** The ActionItemTemplates that use any version of this form. */
  usedBy: Array<ActionItemTemplate>;
  uuid: Scalars["UUID"]["output"];
  /** Forms V2 migrated are associated directly to a Workflow and WorkflowTemplate. */
  workflow?: Maybe<Workflow>;
  workflowTemplate?: Maybe<WorkflowTemplate>;
}

/** An instance of a form-constraint condition in a real user-created form? */
export interface FormConstraintCondition {
  displayOrder: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  operator: FormConstraintConditionOperator;
  values: Array<Scalars["String"]["output"]>;
}

/**
 * A group of multiple constrain conditions that can be combined with a logical operator (AND, OR).
 * These groups can be nested to create more complex constraint expressions (e.g., "if A and (B or
 * C)"). The display order is used to determine the order in which the conditions are shown in the UI,
 * but it is not relevant for evaluation because both AND and OR are commutative.
 */
export interface FormConstraintConditionGroup {
  constraints: Array<FormConstraintCondition>;
  displayOrder: Scalars["Float"]["output"];
  groups: Array<FormConstraintConditionGroup>;
  operator: FormConstraintConditionGroupOperator;
}

export interface FormConstraintConditionGroupInput {
  constraints?: InputMaybe<Array<FormConstraintConditionInput>>;
  displayOrder: Scalars["Float"]["input"];
  groups?: InputMaybe<Array<FormConstraintConditionGroupInput>>;
  operator: FormConstraintConditionGroupOperator;
}

/**
 * A connective operator that can be used to combine multiple constraint conditions in a
 * FormConstraintConditionGroup. The AND operator requires all conditions to be satisfied, while the OR
 * operator requires only one condition to be satisfied.
 */
export enum FormConstraintConditionGroupOperator {
  AND = "AND",
  OR = "OR",
}

export interface FormConstraintConditionInput {
  displayOrder: Scalars["Float"]["input"];
  name: Scalars["String"]["input"];
  operator: FormConstraintConditionOperator;
  values: Array<Scalars["String"]["input"]>;
}

/**
 * A single operator for use in a form-constraint expression. Operators can be applied to a "left-side"
 * value (e.g., is the value zero? is the value empty?) and optionally one or more "right-side" values
 * (e.g., is the value equal to any of the following values? does the value contain this value?).
 */
export enum FormConstraintConditionOperator {
  ANY_IS_VALUE_INVALID = "ANY_IS_VALUE_INVALID",
  ANY_IS_VALUE_VALID = "ANY_IS_VALUE_VALID",
  ARRAY_CONTAINS = "ARRAY_CONTAINS",
  ARRAY_CONTAINS_ALL = "ARRAY_CONTAINS_ALL",
  ARRAY_CONTAINS_ONE_OF = "ARRAY_CONTAINS_ONE_OF",
  ARRAY_COUNT_EQUALS = "ARRAY_COUNT_EQUALS",
  ARRAY_EMPTY = "ARRAY_EMPTY",
  ARRAY_EQUALS = "ARRAY_EQUALS",
  ARRAY_NOT_CONTAINS = "ARRAY_NOT_CONTAINS",
  ARRAY_NOT_CONTAINS_ALL = "ARRAY_NOT_CONTAINS_ALL",
  ARRAY_NOT_CONTAINS_ONE_OF = "ARRAY_NOT_CONTAINS_ONE_OF",
  ARRAY_NOT_EMPTY = "ARRAY_NOT_EMPTY",
  ARRAY_NOT_EQUALS = "ARRAY_NOT_EQUALS",
  EQ = "EQ",
  GT = "GT",
  GTE = "GTE",
  IS_NOT_ZERO = "IS_NOT_ZERO",
  IS_ZERO = "IS_ZERO",
  LT = "LT",
  LTE = "LTE",
  NOT = "NOT",
  STRING_CONTAINS = "STRING_CONTAINS",
  STRING_ENDS_WITH = "STRING_ENDS_WITH",
  STRING_IS_NONE_OF = "STRING_IS_NONE_OF",
  STRING_IS_ONE_OF = "STRING_IS_ONE_OF",
  STRING_NOT_CONTAINS = "STRING_NOT_CONTAINS",
  STRING_STARTS_WITH = "STRING_STARTS_WITH",
}

/**
 * A single field in a form, containing an input that the user would eventually be asked to fill out. A
 * form field is versioned and always belongs to a particular form version. A form field can also have
 * effects conditionally applied to it.
 */
export interface FormField {
  createdAt: Scalars["Time"]["output"];
  displayColumn: Scalars["Int"]["output"];
  displayOrder: Scalars["Float"]["output"];
  enableFieldPassing: Scalars["Boolean"]["output"];
  formFieldConstraints: Array<FormFieldConstraint>;
  formSection: FormSection;
  id: Scalars["ID"]["output"];
  isMultiValued: Scalars["Boolean"]["output"];
  spectrumField: SpectrumField;
  spectrumFieldVersion: SpectrumFieldVersion;
  uuid: Scalars["UUID"]["output"];
}

export interface FormFieldConstraint {
  condition: FormConstraintConditionGroup;
  createdAt: Scalars["Time"]["output"];
  formField: FormField;
  formFieldConstraintRule: FormFieldConstraintRule;
  id: Scalars["ID"]["output"];
  uuid: Scalars["UUID"]["output"];
}

/**
 * A side effect that can be applied when a form-field constraint is satisfied. For example, "show a
 * field if...", "raise an exception...", "make a field optional if...".
 */
export interface FormFieldConstraintRule {
  formFieldConstraintRule: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  uuid: Scalars["UUID"]["output"];
}

/**
 * A single instance of a particular form "template". This form instance can be attached to a
 * particular action-item or action-item template and shown to the user.
 */
export interface FormInstance {
  actionItem?: Maybe<ActionItem>;
  actionItemTemplate?: Maybe<ActionItemTemplate>;
  createdAt: Scalars["Time"]["output"];
  form: Form;
  formSectionStatuses: Array<FormSectionStatus>;
  formVersion: FormVersion;
  id: Scalars["ID"]["output"];
  updatedAt: Scalars["Time"]["output"];
  uuid: Scalars["UUID"]["output"];
}

/** An arbitrary metdata pair that stores additional information about a form section. */
export interface FormInstanceSectionMetadata {
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
}

/**
 * A single section in a form. In the simplest case, a form section displays one or more form fields.
 * In more complex cases (not yet implemented in Regrello), a form section can contain an recursive
 * hierarchy of other form sections. A section can also be conditionally shown or hidden based on
 * user-defined conditions.
 */
export interface FormSection {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  /**  The user-facing description for this section.  */
  description: Scalars["String"]["output"];
  /** The display order of the form section within its parent form section. */
  displayOrder: Scalars["Float"]["output"];
  /** An ordered list of form fields within this section. */
  formFields: Array<FormField>;
  /**
   * A list of constraints that are configured for this section, if any. For example, hese contraints
   * could conditionally show or hide this section based on specific conditions.
   */
  formSectionConstraint: Array<FormSectionConstraint>;
  /**
   * An ordered list of nested child sections, if any, within this section. Will be an empty list if
   * this section contains only fields and no nested sections.
   */
  formSections: Array<FormSection>;
  /** The form version to which this section belongs. */
  formVersion: FormVersion;
  id: Scalars["ID"]["output"];
  /**
   * Whether this section contains only instructional text.
   *
   * Note: Instruction text is easier modeled as a section than a field because multiple instances of
   * instruction text can be added to a single form, whereas only one instance of each field can be
   * added to a single form.
   */
  instructionOnly: Scalars["Boolean"]["output"];
  /**
   * The type of a form section. A `TEXT` section represent a section with only text, aka instruction.
   * A `COLUMN` section represents a section that can contain up to 2 fields, displayed in the same
   * row. A `DEFAULT` section is a section that can contain arbitrary number of fields.
   */
  kind: FormSectionKind;
  /**
   * The parent form section, if this section is nested within another section. Will be undefined for
   * the default/top-most/root section in the form.
   */
  parentFormSection?: Maybe<FormSection>;
  /** The user-facing display name for this section. */
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Time"]["output"];
  updatedBy: Party;
  uuid: Scalars["UUID"]["output"];
}

/**
 * A single section-level constraint for a particular form section. This constraint can be used to
 * specify that a section should only be hidden or shown if a certain conditional expression is met.
 * The conditional expression can be arbitrarily complex.
 */
export interface FormSectionConstraint {
  condition: FormConstraintConditionGroup;
  createdAt: Scalars["Time"]["output"];
  formSection: FormSection;
  formSectionConstraintRule: FormSectionConstraintRule;
  id: Scalars["ID"]["output"];
  uuid: Scalars["UUID"]["output"];
}

/**
 * A single rule that can be applied to a form section constraint. This rule can be used to specify
 * that a section should only be hidden or shown if a certain condition is met.
 */
export interface FormSectionConstraintRule {
  formSectionConstraintRule: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  uuid: Scalars["UUID"]["output"];
}

export enum FormSectionKind {
  COLUMN = "COLUMN",
  DEFAULT = "DEFAULT",
  TEXT = "TEXT",
}

export interface FormSectionSpectrumMetadataPair {
  formSectionID: Scalars["ID"]["output"];
  spectrumMetadatas: Array<FormInstanceSectionMetadata>;
}

/** The current status of a single section in a form instance. */
export interface FormSectionStatus {
  formSectionID: Scalars["ID"]["output"];
  metadata: Array<FormInstanceSectionMetadata>;
  status: FormSectionStatusString;
  updatedAt: Scalars["Time"]["output"];
  updatedBy: Party;
}

/**
 * An enumeration of possible form-section statuses. A section can be either not started, in progress,
 * or complete-all based on how many of the section's form fields have been filled out.
 */
export enum FormSectionStatusString {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

/**
 * A single version of a single form, containing all the mutable data about that form from a particular
 * moment in time. A form version is conceptually the "template" or "structure" for the form; it needs
 * to be instantiated into a FormInstance in order to collect user input. A form version contains a
 * hierarchy of form sections, starting with a single root ("default") section.
 */
export interface FormVersion {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  /**
   * The top-level section for this form as of this version. Additional sections may be nested
   * multiple levels deep within this default section.
   */
  defaultFormSection: FormSection;
  /** The user-facing description for this form as of this version. */
  description: Scalars["String"]["output"];
  /**
   * Whether this form, as of this version, is used in a resource that has disabled editing (e.g., a
   * locked workflow). If true, attemped changes to this form will fail.
   */
  editingLocked: Scalars["Boolean"]["output"];
  /**
   * As of this writing on Jun 6, 2024, this `FieldInstance` is only a `FieldInstance` with empty
   * value that contains the necessary information to establish field mapping. It is only associated
   * with the `FormVersion` and is not associated with any of `ActionItem`, `ActionItemTemplate`, or
   * `FormInstance`. It is effectively the `FieldInstance` template just like how we have dummy
   * `fieldInstance` on `ActionItemTemplate`s and `WorkflowTemplate`s.
   */
  fieldInstances: Array<FieldInstance>;
  /** The form that this version is versioning. */
  form: Form;
  id: Scalars["ID"]["output"];
  /** The user-facing display name of the form as of this version. */
  name: Scalars["String"]["output"];
  /** The number of fields in this form as of this version. */
  numberOfFields: Scalars["Int"]["output"];
  /**
   * A list of field names and field IDs that are present in this form version. This list can be
   * checked to prevent these same fields from being added to a task alongside this form (which would
   * be invalid), for example.
   */
  occupiedSpectrumFieldNamesById: Array<OccupiedSpectrumFieldNameById>;
  updatedAt: Scalars["Time"]["output"];
  uuid: Scalars["UUID"]["output"];
}

export interface FormVersionPreviewFormInstanceResponse {
  fieldInstances: Array<FieldInstance>;
  formInstance: FormInstance;
}

export interface GenerateGranularAccessLinkforIntegrationResponse {
  token?: Maybe<Scalars["String"]["output"]>;
}

export interface GenerateOauthStateForIntegrationResponse {
  state: Scalars["String"]["output"];
}

export interface GenerateWorkatoEmbeddedConnectionWidgetJwtResponse {
  jwt: Scalars["String"]["output"];
}

/**
 * A generic representation of a workflow or workflow template. Allows us to write logic only once
 * for building workflow graphs, the workflow Gantt chart, etc.
 *
 * Abstracts away the fact that workflows and workflow templates are modeled a little bit
 * differently from each other on the backend.
 */
export interface GenericWorkflow {
  id: Scalars["ID"]["output"];
  stages: Array<GenericWorkflowStage>;
  startedAt?: Maybe<Scalars["Time"]["output"]>;
}

export interface GenericWorkflowActionItem {
  approvalActionItems: Array<GenericWorkflowActionItem>;
  completedOn?: Maybe<Scalars["Time"]["output"]>;
  dueOn?: Maybe<Scalars["Time"]["output"]>;
  dueOnIntervalSeconds?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  isDueOnControlledByCustomField?: Maybe<Scalars["Boolean"]["output"]>;
  linkedWorkflowProps?: Maybe<LinkedWorkflowProps>;
  name: Scalars["String"]["output"];
  splitChildren: Array<GenericWorkflowActionItem>;
  startAfterActionItemTemplateID?: Maybe<Scalars["ID"]["output"]>;
  startAtFromTemplate?: Maybe<Scalars["Time"]["output"]>;
  startedAt?: Maybe<Scalars["Time"]["output"]>;
  status: WorkflowFrontendTaskStatus;
  type: ActionItemType;
}

export interface GenericWorkflowStage {
  actionItems: Array<GenericWorkflowActionItem>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  startAfterWorkflowStageID?: Maybe<Scalars["ID"]["output"]>;
  startAfterWorkflowStageIDs?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  startAt?: Maybe<Scalars["Time"]["output"]>;
  startOnWorkflowStart: Scalars["Boolean"]["output"];
  startedAt?: Maybe<Scalars["Time"]["output"]>;
  status: WorkflowFrontendStageStatus;
}

export interface GetPartyAccessCodeUuiDsForIntegrationResponse {
  approveUUID?: Maybe<Scalars["String"]["output"]>;
  rejectUUID?: Maybe<Scalars["String"]["output"]>;
  reopenUUID?: Maybe<Scalars["String"]["output"]>;
  replyUUID?: Maybe<Scalars["String"]["output"]>;
  reportExceptionUUID?: Maybe<Scalars["String"]["output"]>;
  resolveExceptionUUID?: Maybe<Scalars["String"]["output"]>;
  submitUUID?: Maybe<Scalars["String"]["output"]>;
  tenantID: Scalars["ID"]["output"];
}

export interface GiveAdminAccessToUserResponse {
  user: User;
}

export interface GiveUserAccessToTenantInputs {
  accessLevel: UserAccessLevel;
  authUserId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  tenantId: Scalars["ID"]["input"];
}

export interface GiveUserAccessToTenantResponse {
  authUser: AuthUser;
}

/**
 * Wrapper for an ID value used in input types to distinguish between fields that should be ignored by
 * the receiving endpoint and fields that should be cleared. If the patch object itself is null, the
 * field is ignored; if the patch object is defined but its value is null, the field is cleared.
 */
export interface IdPatch {
  id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface ImportBlueprintInput {
  fileUuid: Scalars["UUID"]["input"];
}

export interface ImportBlueprintResponse {
  blueprintId: Scalars["ID"]["output"];
}

export enum IndexObjectType {
  ACTION = "action",
  DOC = "doc",
  USER = "user",
  WORKFLOW = "workflow",
  WORKFLOW_TEMPLATE = "workflow_template",
}

export interface InitializeAgentUsersResponse extends Response {
  createdAgentUsers?: Maybe<Array<Scalars["String"]["output"]>>;
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface Integration {
  authentications: Array<IntegrationAuthentication>;
  automations: Array<IntegrationAutomation>;
  createdAt: Scalars["Time"]["output"];
  displayName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  integrationImplementationType: IntegrationImplementationType;
  integrationType: IntegrationType;
}

export interface IntegrationAuthentication {
  createdAt: Scalars["Time"]["output"];
  error?: Maybe<Scalars["String"]["output"]>;
  extraConfig?: Maybe<IntegrationAuthenticationExtraConfig>;
  id: Scalars["ID"]["output"];
  integration: Integration;
  isActive: Scalars["Boolean"]["output"];
  updatedAt: Scalars["Time"]["output"];
  user: User;
}

export type IntegrationAuthenticationExtraConfig = IntegrationAuthenticationExtraConfigSharePoint;

export interface IntegrationAuthenticationExtraConfigSharePoint {
  sharePointSiteName?: Maybe<Scalars["String"]["output"]>;
}

export interface IntegrationAutomation {
  createdAt: Scalars["Time"]["output"];
  displayName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inputs: Array<IntegrationAutomationInput>;
  integration: Integration;
  integrationAutomationType: IntegrationAutomationType;
  outputs: Array<IntegrationAutomationOutput>;
}

export interface IntegrationAutomationGroupedInputs {
  inputGroupName: Scalars["String"]["output"];
  inputs: Array<IntegrationAutomationInput>;
}

export interface IntegrationAutomationInput {
  createdAt: Scalars["Time"]["output"];
  displayName: Scalars["String"]["output"];
  externalId?: Maybe<Scalars["String"]["output"]>;
  fieldInstance?: Maybe<FieldInstance>;
  id: Scalars["ID"]["output"];
  inputGroupName?: Maybe<Scalars["String"]["output"]>;
  isDuplicationAllowed: Scalars["Boolean"]["output"];
  isDynamic: Scalars["Boolean"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  isRequired: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  valueType: PropertyDataType;
}

export interface IntegrationAutomationInstance {
  createdAt: Scalars["Time"]["output"];
  groupedInputs: Array<IntegrationAutomationGroupedInputs>;
  id: Scalars["ID"]["output"];
  /** inputs - contains only ungrouped inputs */
  inputs: Array<IntegrationAutomationInput>;
  integrationAuthentication: IntegrationAuthentication;
  integrationAutomation: IntegrationAutomation;
  outputs: Array<IntegrationAutomationOutput>;
  /** Automation instances associated with automated tasks that are using this integration's automation instance. */
  referencingAutomationInstances?: Maybe<Array<IntegrationAutomationInstance>>;
  updatedAt: Scalars["Time"]["output"];
}

export interface IntegrationAutomationOutput {
  createdAt: Scalars["Time"]["output"];
  displayName: Scalars["String"]["output"];
  externalId?: Maybe<Scalars["String"]["output"]>;
  fieldInstance?: Maybe<FieldInstance>;
  id: Scalars["ID"]["output"];
  isDuplicationAllowed: Scalars["Boolean"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  jsonExpression?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  valueType: PropertyDataType;
}

export enum IntegrationAutomationType {
  BOX_SAVE_FILE_TO_FOLDER = "BOX_SAVE_FILE_TO_FOLDER",
  DOCUSIGN_CREATE_AND_SEND_FOR_SIGNATURE_AND_SAVE_ON_COMPLETED = "DOCUSIGN_CREATE_AND_SEND_FOR_SIGNATURE_AND_SAVE_ON_COMPLETED",
  DOCUSIGN_CREATE_DRAFT_FROM_TEMPLATE = "DOCUSIGN_CREATE_DRAFT_FROM_TEMPLATE",
  DOCUSIGN_SEND_FOR_SIGNATURE_AND_SAVE_ON_COMPLETED = "DOCUSIGN_SEND_FOR_SIGNATURE_AND_SAVE_ON_COMPLETED",
  SHAREPOINT_UPLOAD_FILE_TO_SITE_FOLDER = "SHAREPOINT_UPLOAD_FILE_TO_SITE_FOLDER",
}

export enum IntegrationImplementationType {
  DIRECT = "DIRECT",
  WORKATO = "WORKATO",
}

export enum IntegrationType {
  BOX = "BOX",
  CUSTOM = "CUSTOM",
  DOCUSIGN = "DOCUSIGN",
  DOCUSIGN_DEVELOPER = "DOCUSIGN_DEVELOPER",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  NETSUITE = "NETSUITE",
  ORACLE = "ORACLE",
  SAP = "SAP",
  SHAREPOINT = "SHAREPOINT",
}

export interface IntegrationsByParamsInput {
  integrationAuthenticationId: Scalars["ID"]["input"];
  workflowTemplateType?: InputMaybe<WorkflowTemplateType>;
}

export interface IntegrationsByParamsResponse {
  count: Scalars["Int"]["output"];
}

export interface InternalCreateIntegrationAuthenticationInput {
  accessToken: Scalars["String"]["input"];
  integrationId: Scalars["ID"]["input"];
  scopes?: InputMaybe<Scalars["String"]["input"]>;
}

export interface IsEmailUniqueResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  isUnique: Scalars["Boolean"]["output"];
}

export interface IsWorkflowEligibleToStart extends ValidationResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface IsWorkflowTemplateEligibleToUse extends ValidationResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface Job {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  error?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  progress: Scalars["Int"]["output"];
  relatedObjectId?: Maybe<Scalars["ID"]["output"]>;
  relatedObjectName?: Maybe<Scalars["String"]["output"]>;
  startedAt?: Maybe<Scalars["Time"]["output"]>;
  status: JobStatus;
  type: JobType;
}

export interface JobCreatedResponse {
  job: Job;
}

export enum JobStatus {
  FAILURE = "FAILURE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum JobType {
  CREATE_SYNC_OBJECT_FROM_CSV = "CREATE_SYNC_OBJECT_FROM_CSV",
  UPDATE_SYNC_OBJECT_FROM_CSV = "UPDATE_SYNC_OBJECT_FROM_CSV",
  UPDATE_SYNC_OBJECT_FROM_REST_API = "UPDATE_SYNC_OBJECT_FROM_REST_API",
}

export interface LatestSpectrumFieldVersionsV2ColumnFilter {
  key?: InputMaybe<CustomFieldDefaultColumnOption>;
  value?: InputMaybe<Scalars["String"]["input"]>;
}

export interface LatestSpectrumFieldVersionsV2ColumnFilters {
  connector: ConditionConnective;
  filters: Array<LatestSpectrumFieldVersionsV2ColumnFilter>;
}

export interface LatestSpectrumFieldVersionsV2Params {
  /**
   * Whether to exclude controller fields from the response (e.g., RegrelloTaskAssignees,
   * RegrelloTaskDueOn).
   *
   * Controller fields are used under the hood to make it possible to assign tasks to fields whose
   * values were provided earlier in the workflow.
   */
  excludeControllerFields?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeRoleFields?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Whether to exclude system fields from the response (e.g., Workflow ID, Workflow Owner, Workflow
   * Reference Number).
   *
   * System fields are built-in fields in Regrello that are used for various special purposes.
   */
  excludeSystemFields?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface LatestSpectrumFieldVersionsV2Response {
  fields: Array<SpectrumFieldVersion>;
  /** The total number of records matching the search parameters at request time. */
  totalCount: Scalars["Int"]["output"];
}

export interface Line {
  fieldInstances: Array<FieldInstance>;
  /** Assigned in order of regrelloObjectInstanceId */
  lineNumber: Scalars["Int"]["output"];
  regrelloObjectInstanceId: Scalars["ID"]["output"];
}

export interface LinkedWorkflowProps {
  blueprintPredictedDurationSeconds: Scalars["Float"]["output"];
  endOn?: Maybe<Scalars["String"]["output"]>;
  percentComplete: Scalars["Float"]["output"];
}

/**
 * A LinkedWorkflowReference is a reference to a WorkflowTemplate/Blueprint that
 * contains the fields that need to be accessed from its parent actionItem.
 */
export interface LinkedWorkflowReference {
  /**
   * The observed completion timestamp of the workflow. A workflow is completed when all tasks have
   * been completed or skipped by starting rules/conditions.
   */
  completedAt?: Maybe<Scalars["Time"]["output"]>;
  /** The timestamp at which the workflow was created. */
  createdAt: Scalars["Time"]["output"];
  /** The user who created the workflow, or who was specified by the caller as the workflow creator. */
  createdBy: User;
  /**
   * The number of days the workflow has been delayed according to its stated due dates
   * on tasks and when they were scheduled to start vs when they actually started.
   */
  daysDelayed?: Maybe<Scalars["Int"]["output"]>;
  /** The timestamp at which the workflow was deleted, if it was. If the workflow hasn't been deleted, this field is empty. */
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  /** A human-readable text description of the workflow. */
  description?: Maybe<Scalars["String"]["output"]>;
  /**
   * EndDate is a future-projected end date for the workflow. It is calculated by taking into account
   * stage and task dependencies, as well as the predicted duration of each stage and task.
   */
  endDate?: Maybe<Scalars["Time"]["output"]>;
  /**
   * The timestamp at which the workflow was ended. Ended workflows are not deleted, but they are
   * considered unsuccessful and no longer running. A workflow may never have an endedAt time, if the
   * workflow was never "Ended". "Ending" a workflow is a specific workflow status transition that is
   * different than "Completing" a workflow.
   */
  endedAt?: Maybe<Scalars["Time"]["output"]>;
  /**
   * Custom fields associated with the workflow. These are the workflow-level fields and will not
   * contain any task-level fields.
   */
  fieldInstances: Array<FieldInstance>;
  /** The unique identifier of the workflow. */
  id: Scalars["ID"]["output"];
  /** The name for this workflow. It must be unique. */
  name: Scalars["String"]["output"];
  /** The number of tasks completed divided by the number of tasks in the workflow. */
  percentComplete?: Maybe<Scalars["Float"]["output"]>;
  /**
   * This field returns the current user's permission level for the workflow. It is used by the UI to
   * determine which things should show or not, as well as which buttons should be enabled and which
   * fields may be editable or available.
   */
  permissions: UserPermissionsToWorkflow;
  /** Current scheduling status of the workflow. Possible values are "STARTED", "PAUSED", "COMPLETED", "ENDED". */
  scheduleStatus?: Maybe<WorkflowScheduleStatus>;
  /**
   * An AI-generated summary of the workflow that focuses on execution status and that summarizes by
   * stage. It focuses less on messages and more on the status. Only available to select Regrello workspaces.
   */
  stageSummary?: Maybe<Scalars["String"]["output"]>;
  /** The stages of tasks that are part of the workflow. By default, workflows are executed in stage order. */
  stages: Array<WorkflowStageReference>;
  /**
   * The timestamp at which the workflow was started. This is the same time as when the first stage
   * launched. It can be different from the time that the workflow was created if the workflow was
   * created in a state that could not immediately start.
   */
  startedAt?: Maybe<Scalars["Time"]["output"]>;
  /**
   * The template from which the workflow was created, if applicable. This field will be null for
   * workflows created without a Blueprint. A WorkflowTempalte is also called a Blueprint.
   */
  template?: Maybe<WorkflowTemplateBaseReference>;
  /** The timestamp at which the workflow was last changed. */
  updatedAt: Scalars["Time"]["output"];
}

export interface LoadViewResponse {
  /** total number of workflows ignoring limit and offset */
  count: Scalars["Int"]["output"];
  view: View;
  workflowStatusOverviews: WorkflowStatusOverviews;
  workflows: Array<Workflow>;
}

/** Inputs for mapping a source field instance value to a form field. */
export interface MapFieldInstanceSourceToFormFieldInput {
  /** The destination form field. */
  formFieldId: Scalars["Int"]["input"];
  /** The source field instance value. */
  sourceFieldInstanceValueId?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface MapFieldInstanceToFormFieldInput {
  formFieldID: Scalars["ID"]["input"];
  sourceFieldInstanceValueID?: InputMaybe<Scalars["Int"]["input"]>;
}

export enum MatchLevelEnum {
  FULL = "FULL",
  NONE = "NONE",
  PARTIAL = "PARTIAL",
}

export interface MigrateFromV1Response {
  success: Scalars["Boolean"]["output"];
}

export interface MigrateTenantToV2Input {
  /** Requires confirmation 'Yes, I'm absolutely sure'. */
  destroyV2Bindings?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Yes, I'm absolutely sure I want to do this. */
  yesImSure?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface MigrateToSpectrumFormsV2Input {
  /**
   * Whether to force a new migration job to be started, regardless of whether one is already in
   * progress. This is useful if a previously triggered job got stuck in a bad state and needs to be
   * restarted.
   */
  force?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface MigrateToSpectrumFormsV2Response {
  /**
   * The ID of the job that was created to migrate to Spectrum forms V2. Pass this to
   * `spectrumFormsV2MigrationStatus` to check the status of the job.
   */
  jobID: Scalars["ID"]["output"];
  /** The initial status of the job. */
  status: SpectrumInitializationStatus;
}

export interface MoveActionItemTemplateToWorkflowStageResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface MoveActionItemTemplateToWorkflowStageTemplateResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface Mutation {
  /**
   * Acknowledges Regrello's privacy policy for the current user.
   * This is a requirement for each user at customers for which this privacy check is enabled.
   *
   * Invoking this endpoint more than once will result in a no-op if the initial invocation was successful.
   */
  acknowledgePrivacyPolicy?: Maybe<SuccessResponse>;
  /**
   * Idempotently adds one or more domains to additionally allow for the specified tenant. No-ops if an
   * empty list of domains is provided.
   */
  addAllowedDomainsToTenant: SuccessResponse;
  addAutomationRequestsToActionItemTemplate?: Maybe<AddAutomationRequestsToActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  addFieldInstancesToActionItemTemplate?: Maybe<AddFieldInstancesToActionItemTemplateResponse>;
  addFieldInstancesToWorkflow?: Maybe<AddFieldInstancesToWorkflowResponse>;
  addFieldInstancesToWorkflowTemplate?: Maybe<AddFieldInstancesToWorkflowTemplateResponse>;
  /** Adds a set of parties to a given role. */
  addPartiesToRole: AddPartiesToRoleResponse;
  approveActionItem?: Maybe<ApproveActionItemResponse>;
  archiveTenant?: Maybe<SuccessResponse>;
  bulkCreateUsers: Array<CreateUserResponse>;
  bulkDeleteWorkflows?: Maybe<BulkDeleteWorkflowsResponse>;
  /**
   * Starts many workflows at once from a frontend CSV upload. The workflows will be started sometime
   * shortly after this call, not necessarily by the time the response is returned.
   */
  bulkStartWorkflows?: Maybe<BulkStartWorkflowsResponse>;
  bulkUpdateActionItem?: Maybe<BulkUpdateActionItemResponse>;
  /** Clears the out of office state for the party */
  clearOutOfOffice: ClearOutOfOfficeResponse;
  commentOnActionItem?: Maybe<CommentOnActionItemResponse>;
  configureDeviceVerificationForTenant?: Maybe<SuccessResponse>;
  /** <sharedWorkflowTemplateId> - can be either an ID of a Company-level template or Regrello template. */
  copySharedWorkflowTemplateToMyTemplates?: Maybe<CopySharedWorkflowTemplateToMyTemplatesResponse>;
  copyWorkflowTemplateFromCurrentTenantToDestinationTenant?: Maybe<CopyWorkflowTemplateFromCurrentTenantToDestinationTenantResponse>;
  /** @deprecated Creating standalone action items is no longer supported; created workflows instead. */
  createActionItem?: Maybe<CreateActionItemResponse>;
  createActionItemTemplateV2?: Maybe<CreateActionItemTemplateResponse>;
  createApprovalActionItemTemplate?: Maybe<CreateActionItemTemplateResponse>;
  /**
   * Creates a blueprint import job that will run asynchronously to import a blueprint into the workspace.
   * The job can be checked for status using the blueprintAsyncImportStatus query.
   */
  createBlueprintAsyncImport: BlueprintImportJob;
  /**
   * Creates a blueprint import job that will run asynchronously to import a blueprint into the
   * workspace as a new variant of an existing standard blueprint.
   *
   * The standardWorkflowTemplateId is a reference to a workflowTemplate.ID and should reference the
   * latest version of the standard blueprint.
   *
   * Fails if the specified blueprint is not of type "COMPANY" or does not have variant creation
   * enabled.
   */
  createBlueprintAsyncImportAsNewVariant: BlueprintImportJob;
  /**
   * Creates a blueprint import job that will run asynchronously to import a blueprint into the workspace
   * as a new draft version of an existing blueprint.
   *
   * The workflowTemplateVersionId is a reference to a workflowTemplate.ID and should reference the latest version of the existing blueprint.
   *
   * Fails if the specified blueprint version is not of type "COMPANY".
   */
  createBlueprintAsyncImportAsNewVersion: BlueprintImportJob;
  /**
   * Creates a storage location to upload file for blueprint import. The signedUrl can be used to upload the file
   * and the fileUuid can be passed to importBlueprint. The uploaded file should be originally retrieved via the exportBlueprint endpoint.
   */
  createBlueprintImportFile: CreateBlueprintImportFileResponse;
  /** Create zero or more relations between a subject and a blueprint. */
  createBlueprintRelations: CreateRelationResponse;
  createBlueprintVariant: CreateBlueprintVariantResponse;
  createDocument?: Maybe<CreateDocumentResponse>;
  /** @deprecated Unused by our frontend */
  createDocumentVersion?: Maybe<CreateDocumentVersionResponse>;
  createField?: Maybe<CreateFieldResponse>;
  createFormAndVersion: FormVersion;
  createFormField: FormField;
  createFormInstanceForActionItem: FormInstance;
  createFormInstanceForActionItemTemplate: FormInstance;
  createFormSection: FormSection;
  createFormVersion: FormVersion;
  createFormVersionPreviewFormInstance: FormInstance;
  createIntegrationAuthentication?: Maybe<CreateIntegrationAuthenticationResponse>;
  /**
   * Creates a notification card for a workflow / task. Should only ever be created by service accounts
   * / system calls.
   */
  createNotificationCard?: Maybe<CreateNotificationCardResponse>;
  createOrUpdateFormFieldConstraint: FormFieldConstraint;
  createOrUpdateFormSectionConstraint: FormSectionConstraint;
  createRegrelloObject?: Maybe<JobCreatedResponse>;
  /** Creates a new custom role. */
  createRole: CreateRoleResponse;
  /** Creates a Typesense search collection for a given data source. */
  createSearchCollectionForDataSource: CreateSearchCollectionForDataSourceResponse;
  /**
   * Create a new Service Account user in Regrello. The user will be created in the current workspace.
   * This mutation will return its OAuth Client ID and Client Secret, which will not be shown again.
   */
  createServiceAccountUser?: Maybe<CreateServiceAccountUserResponse>;
  /**
   * Creates a new Spectrum field in this workspace, along with an initial version for that field
   * that will have the provided properties (name, helper text, etc.). You can update this field via
   * the `createSpectrumFieldVersion` mutation.
   */
  createSpectrumFieldAndVersion: SpectrumFieldVersion;
  /**
   * Creates a new version of an existing Spectrum field in this workspace. This is conceptually the
   * same as updating the field (e.g., changing its name).
   *
   * ___Note:___ We version fields so that we can pin a particular version of a field when needed.
   * For example, in a published blueprint, it is useful to pin all field versions so that nothing
   * about the blueprint changes when the fields it uses are updated elsewhere in the system.
   */
  createSpectrumFieldVersion: SpectrumFieldVersion;
  createTag?: Maybe<CreateTagResponse>;
  createTagType?: Maybe<CreateTagTypeResponse>;
  createTeam?: Maybe<CreateTeamResponse>;
  createTenant?: Maybe<CreateTenantResponse>;
  /**
   * Creates a new Typesense collection for the specified tenant(s) with the specified schema name.
   * If the collection already exists, this is a no-op.
   */
  createTenantSearchCollection?: Maybe<SuccessResponse>;
  createUser?: Maybe<CreateUserResponse>;
  createView: CreateViewResponse;
  /**
   * Creates a Connection asset in Workato for a given integration.
   * When called for the very 1st time on the current tenant it will also create a customer's workspace in Workato and deploy an integration package there.
   * Once a connection is created it is in the "Disconnected" state.
   * This mutation returns a connection id which is used to render an embedded iframe where the user fills out all the connection inputs in order to authenticate this connection.
   * Once connection is authenticated its state transitioned into "Connected" and is ready to use.
   */
  createWorkatoIntegrationConnection?: Maybe<CreateWorkatoIntegrationConnectionResponse>;
  /**
   * Creates a WorkatoIntegrationPackage record in auth database.
   * This record has a workatoPackageId reference which is used to deploy a package with the assets (recipes, connections, endpoints) to a customer's workspace in Workato.
   * Only Auth Admin can make this action.
   */
  createWorkatoIntegrationPackage?: Maybe<CreateWorkatoIntegrationPackageResponse>;
  createWorkflow?: Maybe<CreateWorkflowResponse>;
  /** Creates a Workflow from a WorkflowTemplate/Blueprint. */
  createWorkflowFromTemplate?: Maybe<CreateWorkflowResponse>;
  /** Create zero or more relations between a subject and a workflow. */
  createWorkflowRelations: CreateRelationResponse;
  /** @deprecated replaced with createWorkflowStageV2 */
  createWorkflowStage?: Maybe<CreateWorkflowStageResponse>;
  /** @deprecated replaced with createWorkflowStageTemplateV2 */
  createWorkflowStageTemplate?: Maybe<CreateWorkflowStageTemplateResponse>;
  createWorkflowStageTemplateV2?: Maybe<CreateWorkflowStageTemplateResponse>;
  createWorkflowStageV2?: Maybe<CreateWorkflowStageResponse>;
  createWorkflowTemplate?: Maybe<CreateWorkflowTemplateResponse>;
  /**
   * Delete the action item with the given ID. If there is only one action item in its associated action item template,
   * delete the template as well to start dependent action item templates.
   */
  deleteActionItem?: Maybe<DeleteActionItemResponse>;
  deleteActionItemTemplate?: Maybe<DeleteActionItemTemplateResponse>;
  deleteAutomationRequestsFromActionItemTemplate?: Maybe<DeleteAutomationRequestsFromActionItemTemplateResponse>;
  deleteDocument?: Maybe<DeleteDocumentResponse>;
  /** @deprecated Unused by our frontend */
  deleteDocumentVersion?: Maybe<DeleteDocumentVersionResponse>;
  deleteField?: Maybe<DeleteFieldResponse>;
  deleteIntegrationAuthentication?: Maybe<DeleteIntegrationAuthenticationResponse>;
  deleteRegrelloObject?: Maybe<DeleteRegrelloObjectResponse>;
  /** Deletes an existing role. */
  deleteRole: DeleteRoleResponse;
  /** Deletes the Typesense search collection for a given data source. */
  deleteSearchCollectionForDataSource: DeleteSearchCollectionForDataSourceResponse;
  deleteTag?: Maybe<DeleteTagResponse>;
  deleteTagType?: Maybe<DeleteTagTypeResponse>;
  deleteTeam?: Maybe<DeleteTeamResponse>;
  deleteTenantAppCustomDomain?: Maybe<UpdateTenantResponse>;
  deleteTenantEmailSendingDomain?: Maybe<UpdateTenantResponse>;
  /**
   * Deletes the Typesense collection for the specified tenant(s) with the specified schema name.
   * If the collection does not exist, this is a no-op.
   * Ensures this collection is not being used to search.
   */
  deleteTenantSearchCollection?: Maybe<SuccessResponse>;
  deleteUser?: Maybe<DeleteUserResponse>;
  deleteView: DeleteViewResponse;
  /**
   * Deletes a WorkatoIntegrationPackage record.
   * Only Auth Admin can make this action.
   */
  deleteWorkatoIntegrationPackage?: Maybe<DeleteWorkatoIntegrationPackageResponse>;
  deleteWorkflow?: Maybe<DeleteWorkflowResponse>;
  deleteWorkflowCollaboration: DeleteWorkflowCollaborationResponse;
  deleteWorkflowStage?: Maybe<DeleteWorkflowStageResponse>;
  deleteWorkflowStageTemplate?: Maybe<DeleteWorkflowStageTemplateResponse>;
  deleteWorkflowTemplate?: Maybe<DeleteWorkflowTemplateResponse>;
  deleteWorkflowTemplateCollaboration: DeleteWorkflowTemplateCollaborationResponse;
  /**
   * Deploys a WorkatoIntegrationPackage to clients that already have their workspace created in Workato.
   * This should be used to `update` integration package if a fix is needed in deployed package.
   * First `createWorkatoIntegrationPackage` should be used to obtain new `workatoIntegrationPackageId`.
   * Only Auth Admin can make this action.
   */
  deployWorkatoIntegrationPackage?: Maybe<DeployWorkatoIntegrationPackageResponse>;
  devOnlyDeleteAuth0TestUsers?: Maybe<DevOnlyDeleteAuth0TestUsersResponse>;
  /** Sends a Daily Task Alert email to the current user immediately. Intended strictly for testing. */
  devOnly_sendDailyTaskAlertEmailImmediately?: Maybe<EmailSendResponse>;
  /** Sends a Weekly Digest email to the current user immediately. Intended strictly for testing. */
  devOnly_sendWeeklyDigestEmailImmediately?: Maybe<EmailSendResponse>;
  /** Dismisses a notification card. */
  dismissNotificationCard?: Maybe<DismissNotificationCardResponse>;
  /** Downloads a blueprint as a markdown template. */
  downloadBlueprintAsTemplate?: Maybe<DownloadBlueprintAsTemplateResponse>;
  endWorkflow?: Maybe<EndWorkflowResponse>;
  /**
   * Finds or creates users in the current workspace matching the email addresses provided in the
   * inputs. All created and existing users will be returned. This operation will send welcome emails
   * to any newly created users.
   */
  findOrCreateUsers: Array<CreateUserResponse>;
  generateSubmissionPdfForActionItem?: Maybe<CreateDocumentResponse>;
  /** Generates a JWT token to be used for displaying embedded connection widget. */
  generateWorkatoEmbeddedConnectionWidgetJwt?: Maybe<GenerateWorkatoEmbeddedConnectionWidgetJwtResponse>;
  giveAdminAccessToUser?: Maybe<GiveAdminAccessToUserResponse>;
  giveUserAccessToTenant?: Maybe<GiveUserAccessToTenantResponse>;
  /** Imports a blueprint into the workspace. */
  importBlueprint: ImportBlueprintResponse;
  /**
   * Marks all notifications as read for the current user. No-ops on any notifications are already
   * marked as read.
   *
   * ___Note:___ This is labeled as "incubating" because it is not yet clear if this is the final form
   * of the API. When the API settles, the "incubating" prefix will be removed, forcing clients to
   * peruse the final API and update calls accordingly.
   */
  incubating_markAllNotificationsAsRead: NotificationsResponse;
  /**
   * Marks the specified notification as read for the current user. No-ops if the specified notification
   * is already marked as read.
   *
   * ___Note:___ This is labeled as "incubating" because it is not yet clear if this is the final form
   * of the API. When the API settles, the "incubating" prefix will be removed, forcing clients to
   * peruse the final API and update calls accordingly.
   */
  incubating_markNotificationAsRead: NotificationResponse;
  /**
   * Initializes the AI Agent users for AI Agent Task Completion.
   * This is idempotent and can be safely called multiple times in a given tenant.
   * That way if new Agent users are added, they will be initialized.
   */
  initializeAgentUsers: InitializeAgentUsersResponse;
  /**
   * Mutation for internal usage only.
   * It allows to create an IntegrationAuthentication directly by providing an integration and access token,
   * what is useful for API integration tests.
   */
  internalCreateIntegrationAuthentication?: Maybe<CreateIntegrationAuthenticationResponse>;
  mapFieldInstancesToFormFieldsOnFormInstance: FormInstance;
  /**
   * Migrates a tenant to the new permissions v2 schema. Specifically, it updates the state of a v1 tenant
   * to be reflected in its v2 tables.
   *
   * The migration will respect the idempotency rule that "if a change occurred in v2 permissions, but not
   * in v1 permissions, then the change made in v2 will persist". This idempotency rule will be violated
   * if the user specifies `destroyV2Bindings` (with confirmation) in their request payload.
   */
  migrateTenantToPermissionsV2: SuccessResponse;
  /**
   * The mutation that we must run in each workspace immediately after enabling the
   * "remove-forms-page-2024-08" feature flag, and before allowing users to resume their work in that
   * workspace.
   *
   * This mutation kicks off a background job that will asynchronously create a copy at the usage
   * site of each preexisting form. (e.g., blueprint, workflow, running task) and associate the copy
   * to the usage site. It will also delete any orphaned forms that are not associated with any
   * blueprint, workflow, or running task.
   *
   * In a successful run, the job will progress through these statuses:
   * 1. `NOT_STARTED`
   * 2. `IN_PROGRESS_DELETING_ORPHANED_FORMS`
   * 3. `IN_PROGRESS_COPYING_FORM_VERSIONS_TO_BLUEPRINTS`
   * 4. `IN_PROGRESS_COPYING_FORM_VERSIONS_TO_WORKFLOWS`
   * 5. `IN_PROGRESS_VERIFYING`
   * 6. `COMPLETED`
   *
   * In an unsuccessful run, the job will end in the `FAILED` status.
   *
   * Invoke the `spectrumFormsV2MigrationStatus` query periodically to get the current status.
   *
   * This mutation is idempotent and will no-op and return early if there is no work to do.
   *
   * If you run this mutation and the job gets stuck in a bad state, you can pass `force: true` to
   * restart the job from scratch.
   */
  migrateToSpectrumFormsV2?: Maybe<MigrateToSpectrumFormsV2Response>;
  moveActionItemTemplateToWorkflowStage?: Maybe<MoveActionItemTemplateToWorkflowStageResponse>;
  moveActionItemTemplateToWorkflowStageTemplate?: Maybe<MoveActionItemTemplateToWorkflowStageTemplateResponse>;
  /**
   * Endpoint for identifying which parties and roles have the specified permission toward a specific
   * resource. This is useful for internal permissions testing purposes.
   */
  partiesAndRolesWithPermission: Array<PartyOrRole>;
  pauseWorkflow?: Maybe<PauseWorkflowResponse>;
  /**
   * When child workflow is created, but due to input mapping issue some required fields to start are not filled out this
   * endpoint can be used to set the inputs and start the child, thus resolving the exception on the linked workflow task.
   * ID is the linked workflow action item id in the parent workflow.
   */
  provideRequiredLinkedWorkflowFields?: Maybe<UpdateActionItemResponse>;
  /** @deprecated Published forms do not exist in FormsV2 */
  publishForm: FormVersion;
  /** <userId> - deprecated */
  readComment?: Maybe<ReadCommentResponse>;
  recalculateActionItemCountsByPartyIds?: Maybe<RecalculateActionItemCountsResponse>;
  recreateLinkedWorkflow?: Maybe<UpdateActionItemResponse>;
  /** Refreshes the Typesense search collection for a given data source. */
  refreshSearchCollectionForDataSource: RefreshSearchCollectionForDataSourceResponse;
  /** Invalidate the OAuth credentials for the Service Account identified by userId, and generate new credentials. */
  regenerateServiceAccountOAuthCredentials?: Maybe<RegenerateServiceAccountOAuthCredentialsResponse>;
  reindexTenantSearchData?: Maybe<SuccessResponse>;
  rejectActionItem?: Maybe<RejectActionItemResponse>;
  removeAdminAccessFromUser?: Maybe<RemoveAdminAccessFromUserResponse>;
  /**
   * Removes one or more allowed domains on the specified tenant. No-ops if an empty list of domains is
   * provided. Does not error if a domain is already not allowed for the tenant.
   */
  removeAllowedDomainsFromTenant: SuccessResponse;
  removeFieldInstanceFromWorkflow?: Maybe<RemoveFieldInstanceFromWorkflowResponse>;
  removeFieldInstanceFromWorkflowTemplate?: Maybe<RemoveFieldInstanceFromWorkflowTemplateResponse>;
  /**
   * Remove one or more field instances from an action item template.
   * @deprecated use updateActionItemTemplate instead
   */
  removeFieldInstancesFromActionItemTemplateV2?: Maybe<RemoveFieldInstanceFromActionItemTemplateResponse>;
  removeForm: Form;
  removeFormFieldConstraint: RemoveFormFieldConstraintResponse;
  removeFormFieldsFromSection: FormSection;
  removeFormSectionConstraint: RemoveFormSectionConstraintResponse;
  removeFormVersionPreviewFormInstance: FormVersion;
  /** Removes a set of parties from a role. */
  removePartiesFromRole: RemovePartiesFromRoleResponse;
  removeSectionFromForm: FormVersion;
  reopenActionItem?: Maybe<ReopenActionItemResponse>;
  /**
   * Replace SCIM custom attributes with the specified inputs. This operation replaces the existing set of custom
   * attributes to the new ones.
   */
  replaceScimCustomAttributes: SuccessResponse;
  restartAutomatedActionItem?: Maybe<RestartAutomatedActionItemResponse>;
  resumeApiHealthCheck?: Maybe<ResumeApiHealthCheckResponse>;
  resumeDataSyncForRegrelloObject: ResumeDataSyncForRegrelloObjectResponse;
  resumeWorkflow?: Maybe<ResumeWorkflowResponse>;
  runDataSyncForRegrelloObject: RunDataSyncForRegrelloObjectResponse;
  saveFieldInstancesOnActionItemDraft?: Maybe<UpdateFieldInstancesOnActionItemResponse>;
  /** @deprecated Published forms do not exist in FormsV2 */
  savePublishedFormToMyForms: FormVersion;
  saveWorkflowCollaboration: UpdateWorkflowCollaborationResponse;
  saveWorkflowTemplateCollaboration: UpdateWorkflowTemplateCollaborationResponse;
  /** @deprecated Please use updateScimCredentials. */
  scimCredentials: ScimCredentialsResponse;
  sendWeeklyDigestEmail?: Maybe<SendWeeklyDigestResponse>;
  sendWelcomeEmail?: Maybe<SendWelcomeEmailResponse>;
  sendWelcomeToTeamEmail?: Maybe<SendWelcomeToTeamEmailResponse>;
  sendWelcomeToWorkspaceEmail?: Maybe<SendWelcomeToWorkspaceEmailResponse>;
  /** Sets the out of office state for the party based on the provided input */
  setOutOfOffice: SetOutOfOfficeResponse;
  setSpectrumFieldConstraints: Array<SpectrumFieldConstraint>;
  /**
   * Set the desired **explicit** relationships between a subject and resource. Any existing explicit
   * relationships between the two will be removed. If a relationship is derived by implicit means
   * (e.g., a blueprint owner can view a workflow created from their blueprint), then no changes will
   * take place.
   *
   * To remove all relationships from a subject to a resource, simply pass an empty set of relations
   * with the corresponding resource ID.
   *
   * Attempting to create relations between a subject and a published blueprint will fail.
   */
  setWorkflowRelations: SetRelationsResponse;
  /**
   * Set the desired **explicit** relationships between a subject and resource. Any existing explicit
   * relationships between the two will be removed. If a relationship is derived by implicit means
   * (e.g., a blueprint owner can view a workflow created from their blueprint), then no changes will
   * take place.
   *
   * To remove all relationships from a subject to a resource, simply pass an empty set of relations
   * with the corresponding resource ID.
   *
   * Attempting to create relations between a subject and a published blueprint will fail.
   */
  setWorkflowTemplateRelations: SetRelationsResponse;
  shareWorkflowTemplate?: Maybe<ShareWorkflowTemplateResponse>;
  /**
   * Stars the specified action item for the current user. No-ops if the specified action item is
   * already starred for the current user.
   */
  starActionItem: StarEntityResponse;
  /**
   * Stars the specified workflow for the current user. No-ops if the specified workflow is already
   * starred for the current user.
   */
  starWorkflow: StarEntityResponse;
  /**
   * Stars the specified workflow template for the current user. No-ops if the specified workflow
   * template is already starred for the current user.
   */
  starWorkflowTemplate: StarEntityResponse;
  startActionItemTemplate?: Maybe<StartActionItemTemplateResponse>;
  /** Initiates the process to generate a form based on a name and form asset. */
  startAppGenFormJob?: Maybe<AppGenStatusResponse>;
  /** Initiates the process to generate a blueprint based on a process name, industry name, and customer flowcharts. */
  startAppGenJob?: Maybe<AppGenStatusResponse>;
  startWorkflow?: Maybe<StartWorkflowResponse>;
  startWorkflowStage?: Maybe<StartWorkflowStageResponse>;
  stopDataSyncForRegrelloObject: StopDataSyncForRegrelloObjectResponse;
  submitActionItem?: Maybe<SubmitActionItemResponse>;
  switchCurrentUserTenant?: Maybe<SwitchTenantResponse>;
  switchTenant?: Maybe<SwitchTenantResponse>;
  triggerSettingPlannedDueDatesForWorkflow?: Maybe<TriggerAsyncJobResponse>;
  triggerSettingPlannedDueDatesForWorkflowsInAllTenants?: Maybe<TriggerAsyncJobResponse>;
  triggerSettingPlannedDueDatesForWorkflowsInCurrentTenant?: Maybe<TriggerAsyncJobResponse>;
  unDeleteWorkflowTemplate?: Maybe<UnDeleteWorkflowTemplateResponse>;
  unarchiveTenant?: Maybe<SuccessResponse>;
  /**
   * Unstars the specified action item for the current user. No-ops if the specified action item is not
   * currently starred for the current user.
   */
  unstarActionItem: SuccessResponse;
  /**
   * Unstars the specified workflow for the current user. No-ops if the specified workflow is not
   * currently starred for the current user.
   */
  unstarWorkflow: SuccessResponse;
  /**
   * Unstars the specified workflow template for the current user. No-ops if the specified workflow
   * template is not currently starred for the current user.
   */
  unstarWorkflowTemplate: SuccessResponse;
  updateActionItem?: Maybe<UpdateActionItemResponse>;
  updateActionItemAssignees?: Maybe<UpdateActionItemResponse>;
  updateActionItemCc?: Maybe<UpdateActionItemResponse>;
  updateActionItemDescription?: Maybe<UpdateActionItemResponse>;
  updateActionItemDocuments?: Maybe<UpdateActionItemResponse>;
  updateActionItemDueOn?: Maybe<UpdateActionItemResponse>;
  updateActionItemHasException?: Maybe<UpdateActionItemResponse>;
  updateActionItemIsMuted?: Maybe<ActionItem>;
  updateActionItemName?: Maybe<UpdateActionItemResponse>;
  updateActionItemTags?: Maybe<UpdateActionItemResponse>;
  /**
   * Updates an entire action item template. Patch objects in the input are used to determine whether
   * to clear a field or ignore it.
   */
  updateActionItemTemplate?: Maybe<UpdateActionItemTemplateResponse>;
  updateActionItemTemplateApprovers?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateAssignees?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateCc?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateDescription?: Maybe<UpdateActionItemTemplateResponse>;
  updateActionItemTemplateDisplayOrder?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateDocuments?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateDueOn?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateDueOnIntervalSeconds?: Maybe<UpdateActionItemTemplateResponse>;
  updateActionItemTemplateDueOnIntervalSecondsAfterTrigger?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateName?: Maybe<UpdateActionItemTemplateResponse>;
  /** Updates the reject action (the side effect when an action item is rejected) for the specified action-item template. */
  updateActionItemTemplateRejectAction?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateStartAfterActionItemTemplateId?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateStartAt?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateActionItemTemplateTags?: Maybe<UpdateActionItemTemplateResponse>;
  /**
   * Updates the `WorkflowNameTemplateSuffix` column on an `ActionItemTemplate` record. Used to
   * generate the linked workflow's name upon materialization if a naming convention is defined on the
   * linked workflow template.
   * @deprecated adding a suffix to a linked workflow name is no longer supported
   */
  updateActionItemTemplateWorkflowNameTemplateSuffix?: Maybe<UpdateActionItemTemplateResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateApprovalActionItemTemplates?: Maybe<UpdateActionItemTemplateResponse>;
  updateAutomationRequestFieldsMapping?: Maybe<UpdateAutomationRequestFieldsMappingInputResponse>;
  updateAutomationRequestsOnActionItemTemplate?: Maybe<UpdateAutomationRequestsOnActionItemTemplateResponse>;
  updateBlueprintVariantsConditions: UpdateBlueprintVariantConditionsResponse;
  updateCalendarSettings?: Maybe<UpdateCalendarSettingsResponse>;
  updateEnabledColumns?: Maybe<CustomColumnsStateResponse>;
  updateFieldAllowedValues?: Maybe<UpdateFieldResponse>;
  updateFieldInstancesOnActionItem?: Maybe<UpdateFieldInstancesOnActionItemResponse>;
  /** @deprecated use updateActionItemTemplate instead */
  updateFieldInstancesOnActionItemTemplate?: Maybe<UpdateFieldInstancesOnActionItemTemplateResponse>;
  updateFieldInstancesOnActionItemV2?: Maybe<UpdateFieldInstancesOnActionItemResponse>;
  /**
   * Update field instances even if the actionItem is completed. This mutation is intended to only be
   * used internally by Regrello employees to respond to customer requests about updating information
   * that they can no longer update.
   */
  updateFieldInstancesOnCompletedActionItemV2?: Maybe<UpdateFieldInstancesOnActionItemResponse>;
  updateFieldInstancesOnWorkflow?: Maybe<UpdateFieldInstancesOnWorkflowResponse>;
  updateFieldInstancesOnWorkflowTemplate?: Maybe<UpdateFieldInstancesOnWorkflowTemplateResponse>;
  updateFieldName?: Maybe<UpdateFieldResponse>;
  updateFieldUnit?: Maybe<UpdateFieldResponse>;
  updateFormFieldDisplayOrder: FormField;
  updateFormFieldEnableFieldPassing: FormField;
  updateFormFieldFormSection: FormField;
  updateFormFieldInstancesOnFormInstance?: Maybe<UpdateFormFieldInstancesOnFormInstanceResponse>;
  updateFormFieldIsMultiValued: FormField;
  updateFormSection: FormSection;
  updateFormSectionDisplayOrder: FormSection;
  updateFormSectionParentFormSection: FormSection;
  updateFormVersion: FormVersion;
  updateInputsAndOutputsOnAutomatedActionItemTemplate?: Maybe<UpdateInputsAndOutputsOnAutomatedActionItemTemplateResponse>;
  updateInputsOnAutomatedActionItem?: Maybe<UpdateInputsOnAutomatedActionItemResponse>;
  updateIntegrationAuthenticationOnActionItemTemplate?: Maybe<UpdateIntegrationAuthenticationOnActionItemTemplateResponse>;
  /** Updates the feedback sentiment of a notification card. */
  updateNotificationCardFeedbackSentiment?: Maybe<UpdateNotificationCardFeedbackSentimentResponse>;
  updateNotificationSettingsAnyNotificationEmails?: Maybe<UpdateNotificationSettingsResponse>;
  updateNotificationSettingsGlobalActionItemEmails?: Maybe<UpdateNotificationSettingsResponse>;
  updateNotificationSettingsGlobalAssignedTaskEmails?: Maybe<UpdateNotificationSettingsResponse>;
  updateNotificationSettingsGlobalCcdTaskEmails?: Maybe<UpdateNotificationSettingsResponse>;
  updateNotificationSettingsWeeklyDigest?: Maybe<UpdateNotificationSettingsResponse>;
  /** Updates a set of roles from a party. */
  updatePartyRoles: UpdatePartyRolesResponse;
  updateRegrelloObject?: Maybe<JobCreatedResponse>;
  /** Updates an existing role. */
  updateRole: CreateRoleResponse;
  /** Updates role membership. */
  updateRoleMembers: UpdatePartyRolesResponse;
  /**
   * Update SCIM related configuration on the tenant such as enabling/disabling the integration, service account used to
   * generate the access token, permission management, and error notifications.
   */
  updateScimConfiguration: ScimConfigurationResponse;
  updateScimCredentials: ScimCredentialsResponse;
  updateTag?: Maybe<UpdateTagResponse>;
  updateTagType?: Maybe<UpdateTagTypeResponse>;
  updateTeam: UpdateTeamResponse;
  updateTenantAppCustomDomain?: Maybe<UpdateTenantResponse>;
  updateTenantDatabaseUrlSecretName?: Maybe<UpdateTenantResponse>;
  updateTenantDisplayName?: Maybe<UpdateTenantResponse>;
  updateTenantEmailSendingDomain?: Maybe<UpdateTenantResponse>;
  updateTenantNonAdminPermissions?: Maybe<UpdateTenantResponse>;
  /**
   * Sets a custom privacy policy for the specified tenant, to display in the workspace and email
   * communications. Both the link and text must be set or cleared together, provide `nil` input to
   * clear the privacy policy. If `input` is defined but `privacyPolicyText` is not, the default text
   * "Privacy policy" will be used.
   */
  updateTenantPrivacyPolicy?: Maybe<UpdateTenantResponse>;
  /**
   * Sets the Typesense collection schema to update search index changes on.
   * Errors if the collection is currently being used to search.
   */
  updateTenantSearchCollectionToIndex?: Maybe<SuccessResponse>;
  /**
   * Sets the Typesense collection schema to perform searches from.
   * Also verifies that the target collection already was getting indexed on.
   */
  updateTenantSearchCollectionToSearchFrom?: Maybe<SuccessResponse>;
  updateUserAccessLevel?: Maybe<UpdateUserResponse>;
  updateUserAccessRole?: Maybe<UpdateUserResponse>;
  updateUserDefaultViewId?: Maybe<UpdateUserResponse>;
  updateUserFieldInstancesOnActionItemUsingEmails?: Maybe<UpdateFieldInstancesOnActionItemResponse>;
  /**
   * Updates Form Field Instances for People fields through Regrello Lite, enabling users to specify
   * the appropriate Parties through email addresses instead of Party IDs to avoid disclosing
   * existing workspace Parties.
   */
  updateUserFormFieldInstancesOnFormInstanceUsingEmails?: Maybe<UpdateFormFieldInstancesOnFormInstanceResponse>;
  updateUserIsTimeZoneLocked?: Maybe<UpdateUserResponse>;
  updateUserLanguage?: Maybe<UpdateUserResponse>;
  updateUserLocation?: Maybe<UpdateUserResponse>;
  updateUserName?: Maybe<UpdateUserResponse>;
  updateUserPhone?: Maybe<UpdateUserResponse>;
  updateUserSetupCompletedAt?: Maybe<UpdateUserResponse>;
  updateUserTimeZone?: Maybe<UpdateUserResponse>;
  updateView: UpdateViewResponse;
  updateViewName: UpdateViewResponse;
  updateWorkflowDescription?: Maybe<UpdateWorkflowResponse>;
  updateWorkflowName?: Maybe<UpdateWorkflowResponse>;
  updateWorkflowOwner?: Maybe<UpdateWorkflowResponse>;
  updateWorkflowStageDescription?: Maybe<UpdateWorkflowStageResponse>;
  updateWorkflowStageExecutionOrder?: Maybe<UpdateWorkflowStageExecutionOrderResponse>;
  updateWorkflowStageName?: Maybe<UpdateWorkflowStageResponse>;
  updateWorkflowStageRequired?: Maybe<UpdateWorkflowStageResponse>;
  updateWorkflowStageStartAfterSpec?: Maybe<UpdateWorkflowStageResponse>;
  /** @deprecated replaced with updateWorkflowStageStartAfterWorkflowStageIds */
  updateWorkflowStageStartAfterWorkflowStageId?: Maybe<UpdateWorkflowStageResponse>;
  updateWorkflowStageStartAfterWorkflowStageIds?: Maybe<UpdateWorkflowStageResponse>;
  updateWorkflowStageStartAt?: Maybe<UpdateWorkflowStageResponse>;
  updateWorkflowStageStartOnWorkflowStart?: Maybe<UpdateWorkflowStageResponse>;
  updateWorkflowStageStartingConditions?: Maybe<UpdateWorkflowStageResponse>;
  updateWorkflowStageTemplateDescription?: Maybe<UpdateWorkflowStageTemplateResponse>;
  updateWorkflowStageTemplateExecutionOrder?: Maybe<UpdateWorkflowStageTemplateExecutionOrderResponse>;
  updateWorkflowStageTemplateName?: Maybe<UpdateWorkflowStageTemplateResponse>;
  updateWorkflowStageTemplateRequired?: Maybe<UpdateWorkflowStageTemplateResponse>;
  updateWorkflowStageTemplateStartAfterSpec?: Maybe<UpdateWorkflowStageTemplateResponse>;
  /** @deprecated replaced with updateWorkflowStageTemplateStartAfterWorkflowStageTemplateIds */
  updateWorkflowStageTemplateStartAfterWorkflowStageTemplateId?: Maybe<UpdateWorkflowStageTemplateResponse>;
  updateWorkflowStageTemplateStartAfterWorkflowStageTemplateIds?: Maybe<UpdateWorkflowStageTemplateResponse>;
  updateWorkflowStageTemplateStartAt?: Maybe<UpdateWorkflowStageTemplateResponse>;
  updateWorkflowStageTemplateStartOnWorkflowStart?: Maybe<UpdateWorkflowStageTemplateResponse>;
  updateWorkflowStageTemplateStartingConditions?: Maybe<UpdateWorkflowStageTemplateResponse>;
  updateWorkflowSummaries?: Maybe<UpdateWorkflowSummariesResponse>;
  updateWorkflowTags?: Maybe<UpdateWorkflowResponse>;
  updateWorkflowTemplateAutoAdjustDueOn?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkflowTemplateCollaborators?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkflowTemplateCreateViaEmail?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkflowTemplateDescription?: Maybe<UpdateWorkflowTemplateResponse>;
  /**
   * Updates all field instances on this workflow template to point to the latest version of their
   * corresponding Spectrum field. This is useful on published blueprints that have been saved to My
   * Blueprints, because the underlying fields in that workflow template will still be "pinned" to the
   * field versions that were current at the time the blueprint was published.
   *
   * This will fail:
   * - If Spectrum is not enabled.
   * - If the blueprint is published, because fields cannot be modified on published blueprints.
   */
  updateWorkflowTemplateFieldInstanceSpectrumFieldVersionsToLatest?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkflowTemplateIsEditingWorkflowApprovalsRestricted?: Maybe<UpdateWorkflowTemplateResponse>;
  /**
   * Updates whether or not workflows created from this template will be "restricted"; i.e., certain
   * actions on them (and their stages and tasks) will be restricted from happening. In most cases,
   * only workspace owners and admins can override such restrictions.
   */
  updateWorkflowTemplateIsEditingWorkflowsRestricted?: Maybe<UpdateWorkflowTemplateResponse>;
  /**
   * Updates whether or not variant blueprints can be created from this template after publishing it.
   * This setting cannot be turned off in newer versions once enabled and published, otherwise existing
   * variant processes would get disrupted.
   */
  updateWorkflowTemplateIsVariantCreationEnabled?: Maybe<UpdateWorkflowTemplateResponse>;
  /** @deprecated IsVisibilityLimitedForExternalParties setting deprecated */
  updateWorkflowTemplateIsVisibilityLimitedForExternalParties?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkflowTemplateName?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkflowTemplateNameTemplate?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkflowTemplateReferenceNumber?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkflowTemplateTags?: Maybe<UpdateWorkflowTemplateResponse>;
  /**
   * Updates the workflow template's version notes, a user provided field that summarizes the changes
   * made in this template's version. Version notes can only be updated on a template of type "MINE" or
   * "DRAFT" and are cleared when copying a blueprint or updating a blueprint to a new draft version.
   */
  updateWorkflowTemplateVersionNotes?: Maybe<UpdateWorkflowTemplateResponse>;
  /**
   * Creates a new draft version of a blueprint from the specified version ID and returns it. Fails if
   * the specified blueprint version is of type `MINE` or `DRAFT`.
   *
   * The workflowTemplateVersionId is a reference to a workflowTemplate.ID
   */
  updateWorkflowTemplateVersionToNewDraftVersion?: Maybe<UpdateWorkflowTemplateToNewDraftVersionResponse>;
  updateWorkflowTemplateWorkflowOwner?: Maybe<UpdateWorkflowTemplateResponse>;
  updateWorkspaceSettings: WorkspaceSettings;
  /**
   * Verifies a user's email address with a UUID that was only sent to them. The
   * forceVerifyForExistingUser parameter is ignored.
   */
  verifyUserIdentity?: Maybe<VerifyUserIdentityResponse>;
}

export interface MutationAddAllowedDomainsToTenantArgs {
  input: AllowedDomainsInput;
}

export interface MutationAddAutomationRequestsToActionItemTemplateArgs {
  actionItemTemplateId: Scalars["ID"]["input"];
  input: Array<CreateAutomationRequestInput>;
}

export interface MutationAddFieldInstancesToActionItemTemplateArgs {
  actionItemTemplateId: Scalars["ID"]["input"];
  input: Array<InputMaybe<CreateFieldInstanceValueInputs>>;
}

export interface MutationAddFieldInstancesToWorkflowArgs {
  input: Array<InputMaybe<CreateFieldInstanceValueInputs>>;
  workflowId: Scalars["ID"]["input"];
}

export interface MutationAddFieldInstancesToWorkflowTemplateArgs {
  input: Array<InputMaybe<CreateFieldInstanceValueInputs>>;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationAddPartiesToRoleArgs {
  input: Array<AddPartiesToRoleInput>;
}

export interface MutationApproveActionItemArgs {
  createCommentInput?: InputMaybe<CreateCommentInputs>;
  id: Scalars["ID"]["input"];
}

export interface MutationArchiveTenantArgs {
  ignoreSingleTenantUsers: Scalars["Boolean"]["input"];
  tenantId: Scalars["ID"]["input"];
}

export interface MutationBulkCreateUsersArgs {
  input: BulkCreateUsersInput;
}

export interface MutationBulkDeleteWorkflowsArgs {
  input: Array<Scalars["ID"]["input"]>;
}

export interface MutationBulkStartWorkflowsArgs {
  input: BulkStartWorkflowsInputs;
}

export interface MutationBulkUpdateActionItemArgs {
  input: Array<UpdateActionItemInputs>;
}

export interface MutationClearOutOfOfficeArgs {
  partyId: Scalars["ID"]["input"];
}

export interface MutationCommentOnActionItemArgs {
  hasException?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  input: CreateCommentInputs;
}

export interface MutationConfigureDeviceVerificationForTenantArgs {
  deviceVerificationEnabled: Scalars["Boolean"]["input"];
  tenantId: Scalars["ID"]["input"];
}

export interface MutationCopySharedWorkflowTemplateToMyTemplatesArgs {
  sharedWorkflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationCopyWorkflowTemplateFromCurrentTenantToDestinationTenantArgs {
  destinationTenantId: Scalars["ID"]["input"];
  sourceWorkflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationCreateActionItemArgs {
  input: CreateActionItemInputs;
}

export interface MutationCreateActionItemTemplateV2Args {
  apiHealthCheckConfig?: InputMaybe<CreateApiHealthCheckConfigInput>;
  approvalActionItemTemplatesInput?: InputMaybe<Array<CreateApprovalActionItemTemplateInputs>>;
  input: CreateActionItemTemplateInputs;
  isParallel?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface MutationCreateApprovalActionItemTemplateArgs {
  input: CreateApprovalActionItemTemplateInputs;
  parentActionItemTemplateId: Scalars["ID"]["input"];
}

export interface MutationCreateBlueprintAsyncImportArgs {
  input: ImportBlueprintInput;
}

export interface MutationCreateBlueprintAsyncImportAsNewVariantArgs {
  input: ImportBlueprintInput;
  standardWorkflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationCreateBlueprintAsyncImportAsNewVersionArgs {
  input: ImportBlueprintInput;
  workflowTemplateVersionId: Scalars["ID"]["input"];
}

export interface MutationCreateBlueprintRelationsArgs {
  input: Array<CreateBlueprintRelationInput>;
}

export interface MutationCreateBlueprintVariantArgs {
  input: CreateBlueprintVariantInput;
}

export interface MutationCreateDocumentArgs {
  input: CreateDocumentInputs;
}

export interface MutationCreateDocumentVersionArgs {
  input: CreateDocumentVersionInputs;
}

export interface MutationCreateFieldArgs {
  input: CreateFieldInputs;
}

export interface MutationCreateFormAndVersionArgs {
  input: CreateFormAndVersionInput;
}

export interface MutationCreateFormFieldArgs {
  input: CreateFormFieldInput;
}

export interface MutationCreateFormInstanceForActionItemArgs {
  input: CreateFormInstanceForActionItemInput;
}

export interface MutationCreateFormInstanceForActionItemTemplateArgs {
  input: CreateFormInstanceForActionItemTemplateInput;
}

export interface MutationCreateFormSectionArgs {
  input: CreateFormSectionInput;
}

export interface MutationCreateFormVersionArgs {
  input: CreateFormVersionInput;
}

export interface MutationCreateFormVersionPreviewFormInstanceArgs {
  formVersionUUID: Scalars["String"]["input"];
}

export interface MutationCreateIntegrationAuthenticationArgs {
  input: CreateIntegrationAuthenticationInput;
}

export interface MutationCreateNotificationCardArgs {
  input: CreateNotificationCardInput;
}

export interface MutationCreateOrUpdateFormFieldConstraintArgs {
  input: CreateOrUpdateFormFieldConstraintInput;
}

export interface MutationCreateOrUpdateFormSectionConstraintArgs {
  input: CreateOrUpdateFormSectionConstraintInput;
}

export interface MutationCreateRegrelloObjectArgs {
  input: CreateRegrelloObjectInputs;
}

export interface MutationCreateRoleArgs {
  input: CreateRoleInput;
}

export interface MutationCreateSearchCollectionForDataSourceArgs {
  input: CreateSearchCollectionForDataSourceInput;
}

export interface MutationCreateServiceAccountUserArgs {
  input: CreateServiceAccountUserInputs;
}

export interface MutationCreateSpectrumFieldAndVersionArgs {
  input: CreateSpectrumFieldInput;
}

export interface MutationCreateSpectrumFieldVersionArgs {
  input: CreateSpectrumFieldVersionInput;
}

export interface MutationCreateTagArgs {
  input: CreateTagInputs;
}

export interface MutationCreateTagTypeArgs {
  input: CreateTagTypeInputs;
}

export interface MutationCreateTeamArgs {
  input: CreateTeamInput;
}

export interface MutationCreateTenantArgs {
  input: TenantInputs;
}

export interface MutationCreateTenantSearchCollectionArgs {
  allTenants: Scalars["Boolean"]["input"];
  collectionSchemaName: Scalars["String"]["input"];
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface MutationCreateUserArgs {
  input: CreateUserInputs;
}

export interface MutationCreateViewArgs {
  createViewInput: CreateViewInput;
}

export interface MutationCreateWorkatoIntegrationConnectionArgs {
  integrationType: IntegrationType;
}

export interface MutationCreateWorkatoIntegrationPackageArgs {
  input: CreateWorkatoIntegrationPackageInput;
}

export interface MutationCreateWorkflowArgs {
  input: CreateWorkflowInputs;
}

export interface MutationCreateWorkflowFromTemplateArgs {
  input: CreateWorkflowFromTemplateInputs;
}

export interface MutationCreateWorkflowRelationsArgs {
  input: Array<CreateWorkflowRelationInput>;
}

export interface MutationCreateWorkflowStageArgs {
  input: CreateWorkflowStageInputs;
}

export interface MutationCreateWorkflowStageTemplateArgs {
  input: CreateWorkflowStageTemplateInputs;
}

export interface MutationCreateWorkflowStageTemplateV2Args {
  input: CreateWorkflowStageTemplateInputsV2;
}

export interface MutationCreateWorkflowStageV2Args {
  input: CreateWorkflowStageInputsV2;
}

export interface MutationCreateWorkflowTemplateArgs {
  input: CreateWorkflowTemplateInputs;
}

export interface MutationDeleteActionItemArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteActionItemTemplateArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteAutomationRequestsFromActionItemTemplateArgs {
  actionItemTemplateId: Scalars["ID"]["input"];
  ids: Array<Scalars["ID"]["input"]>;
}

export interface MutationDeleteDocumentArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteDocumentVersionArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteFieldArgs {
  fieldId: Scalars["ID"]["input"];
}

export interface MutationDeleteIntegrationAuthenticationArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteRegrelloObjectArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteRoleArgs {
  input: DeleteRoleInput;
}

export interface MutationDeleteSearchCollectionForDataSourceArgs {
  dataSourceId: Scalars["ID"]["input"];
}

export interface MutationDeleteTagArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteTagTypeArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteTeamArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteTenantAppCustomDomainArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteTenantEmailSendingDomainArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteTenantSearchCollectionArgs {
  allTenants: Scalars["Boolean"]["input"];
  collectionSchemaName: Scalars["String"]["input"];
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface MutationDeleteUserArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteViewArgs {
  deleteViewInput: DeleteViewInput;
}

export interface MutationDeleteWorkatoIntegrationPackageArgs {
  workatoIntegrationPackageId: Scalars["ID"]["input"];
}

export interface MutationDeleteWorkflowArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteWorkflowCollaborationArgs {
  partyId: Scalars["ID"]["input"];
  workflowId: Scalars["ID"]["input"];
}

export interface MutationDeleteWorkflowStageArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteWorkflowStageTemplateArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteWorkflowTemplateArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationDeleteWorkflowTemplateCollaborationArgs {
  partyId: Scalars["ID"]["input"];
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationDeployWorkatoIntegrationPackageArgs {
  workatoIntegrationPackageId: Scalars["ID"]["input"];
}

export interface MutationDismissNotificationCardArgs {
  uuid: Scalars["String"]["input"];
}

export interface MutationDownloadBlueprintAsTemplateArgs {
  blueprintId: Scalars["ID"]["input"];
  mode: BlueprintTemplateMode;
}

export interface MutationEndWorkflowArgs {
  id: Scalars["ID"]["input"];
  sendNotificationToAssociatedParties: Scalars["Boolean"]["input"];
}

export interface MutationFindOrCreateUsersArgs {
  input: BulkCreateUsersInput;
}

export interface MutationGenerateSubmissionPdfForActionItemArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationGenerateWorkatoEmbeddedConnectionWidgetJwtArgs {
  origin?: InputMaybe<Scalars["String"]["input"]>;
}

export interface MutationGiveAdminAccessToUserArgs {
  id: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
}

export interface MutationGiveUserAccessToTenantArgs {
  input: GiveUserAccessToTenantInputs;
}

export interface MutationImportBlueprintArgs {
  input: ImportBlueprintInput;
}

export interface MutationIncubatingMarkNotificationAsReadArgs {
  notificationId: Scalars["ID"]["input"];
}

export interface MutationInternalCreateIntegrationAuthenticationArgs {
  input: InternalCreateIntegrationAuthenticationInput;
}

export interface MutationMapFieldInstancesToFormFieldsOnFormInstanceArgs {
  formInstanceUUID: Scalars["UUID"]["input"];
  input: Array<InputMaybe<MapFieldInstanceToFormFieldInput>>;
}

export interface MutationMigrateTenantToPermissionsV2Args {
  input: MigrateTenantToV2Input;
}

export interface MutationMigrateToSpectrumFormsV2Args {
  input: MigrateToSpectrumFormsV2Input;
}

export interface MutationMoveActionItemTemplateToWorkflowStageArgs {
  displayOrder: Scalars["Float"]["input"];
  id: Scalars["ID"]["input"];
  workflowStageId: Scalars["ID"]["input"];
}

export interface MutationMoveActionItemTemplateToWorkflowStageTemplateArgs {
  displayOrder: Scalars["Float"]["input"];
  id: Scalars["ID"]["input"];
  workflowStageTemplateId: Scalars["ID"]["input"];
}

export interface MutationPartiesAndRolesWithPermissionArgs {
  permission: Scalars["String"]["input"];
  resourceID: Scalars["ID"]["input"];
  resourceType: Scalars["String"]["input"];
}

export interface MutationPauseWorkflowArgs {
  id: Scalars["ID"]["input"];
  sendNotificationToAssociatedParties: Scalars["Boolean"]["input"];
}

export interface MutationProvideRequiredLinkedWorkflowFieldsArgs {
  id: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueByIdInputs>>;
}

export interface MutationPublishFormArgs {
  input: PublishFormInput;
}

export interface MutationReadCommentArgs {
  commentId: Scalars["ID"]["input"];
}

export interface MutationRecalculateActionItemCountsByPartyIdsArgs {
  partyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface MutationRecreateLinkedWorkflowArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationRefreshSearchCollectionForDataSourceArgs {
  input: RefreshSearchCollectionForDataSourceInput;
}

export interface MutationRegenerateServiceAccountOAuthCredentialsArgs {
  userId: Scalars["ID"]["input"];
}

export interface MutationReindexTenantSearchDataArgs {
  allTenants: Scalars["Boolean"]["input"];
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface MutationRejectActionItemArgs {
  createCommentInput?: InputMaybe<CreateCommentInputs>;
  id: Scalars["ID"]["input"];
}

export interface MutationRemoveAdminAccessFromUserArgs {
  id: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
}

export interface MutationRemoveAllowedDomainsFromTenantArgs {
  input: AllowedDomainsInput;
}

export interface MutationRemoveFieldInstanceFromWorkflowArgs {
  fieldId: Scalars["ID"]["input"];
  workflowId: Scalars["ID"]["input"];
}

export interface MutationRemoveFieldInstanceFromWorkflowTemplateArgs {
  fieldId: Scalars["ID"]["input"];
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationRemoveFieldInstancesFromActionItemTemplateV2Args {
  input: RemoveFieldInstancesFromActionItemTemplateInput;
}

export interface MutationRemoveFormArgs {
  formUUID: Scalars["UUID"]["input"];
}

export interface MutationRemoveFormFieldConstraintArgs {
  formFieldConstraintUUID: Scalars["String"]["input"];
}

export interface MutationRemoveFormFieldsFromSectionArgs {
  input: RemoveFormFieldsInput;
}

export interface MutationRemoveFormSectionConstraintArgs {
  formSectionConstraintUUID: Scalars["String"]["input"];
}

export interface MutationRemoveFormVersionPreviewFormInstanceArgs {
  formVersionUUID: Scalars["String"]["input"];
}

export interface MutationRemovePartiesFromRoleArgs {
  input: Array<RemovePartiesFromRoleInput>;
}

export interface MutationRemoveSectionFromFormArgs {
  formSectionUUID: Scalars["UUID"]["input"];
}

export interface MutationReopenActionItemArgs {
  createCommentInput?: InputMaybe<CreateCommentInputs>;
  id: Scalars["ID"]["input"];
}

export interface MutationReplaceScimCustomAttributesArgs {
  input: ScimCustomAttributeInputs;
}

export interface MutationRestartAutomatedActionItemArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationResumeApiHealthCheckArgs {
  apiHealthCheckConfigId: Scalars["Int"]["input"];
}

export interface MutationResumeDataSyncForRegrelloObjectArgs {
  regrelloObjectId: Scalars["ID"]["input"];
}

export interface MutationResumeWorkflowArgs {
  id: Scalars["ID"]["input"];
  shiftStartAndDueDatesBySeconds?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface MutationRunDataSyncForRegrelloObjectArgs {
  regrelloObjectId: Scalars["ID"]["input"];
}

export interface MutationSaveFieldInstancesOnActionItemDraftArgs {
  id: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueByIdInputs>>;
  userFieldsInput?: InputMaybe<Array<InputMaybe<SaveUserFieldInstanceOnActionItemDraftEmailsInput>>>;
}

export interface MutationSavePublishedFormToMyFormsArgs {
  input: SavePublishedFormToMyFormsInput;
}

export interface MutationSaveWorkflowCollaborationArgs {
  accessRoleId?: InputMaybe<Scalars["ID"]["input"]>;
  partyId: Scalars["ID"]["input"];
  workflowId: Scalars["ID"]["input"];
}

export interface MutationSaveWorkflowTemplateCollaborationArgs {
  accessRoleId?: InputMaybe<Scalars["ID"]["input"]>;
  partyId: Scalars["ID"]["input"];
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationScimCredentialsArgs {
  tenantId: Scalars["ID"]["input"];
}

export interface MutationSendWeeklyDigestEmailArgs {
  partyId: Scalars["ID"]["input"];
}

export interface MutationSendWelcomeEmailArgs {
  userID: Scalars["ID"]["input"];
}

export interface MutationSendWelcomeToTeamEmailArgs {
  teamID: Scalars["ID"]["input"];
  userID: Scalars["ID"]["input"];
}

export interface MutationSendWelcomeToWorkspaceEmailArgs {
  tenantID: Scalars["ID"]["input"];
  userID: Scalars["ID"]["input"];
}

export interface MutationSetOutOfOfficeArgs {
  input: SetOutOfOfficeInput;
  partyId: Scalars["ID"]["input"];
}

export interface MutationSetSpectrumFieldConstraintsArgs {
  input: Array<CreateSpectrumFieldConstraintInput>;
  spectrumFieldVersionUUID: Scalars["UUID"]["input"];
}

export interface MutationSetWorkflowRelationsArgs {
  input: SetWorkflowRelationInput;
}

export interface MutationSetWorkflowTemplateRelationsArgs {
  input: SetWorkflowTemplateRelationInput;
}

export interface MutationShareWorkflowTemplateArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationStarActionItemArgs {
  actionItemId: Scalars["ID"]["input"];
}

export interface MutationStarWorkflowArgs {
  workflowId: Scalars["ID"]["input"];
}

export interface MutationStarWorkflowTemplateArgs {
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationStartActionItemTemplateArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationStartAppGenFormJobArgs {
  documentVersionID: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationStartAppGenJobArgs {
  documentVersionIDs: Array<Scalars["ID"]["input"]>;
  industry: Scalars["String"]["input"];
  process: Scalars["String"]["input"];
}

export interface MutationStartWorkflowArgs {
  input: StartWorkflowInput;
}

export interface MutationStartWorkflowStageArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationStopDataSyncForRegrelloObjectArgs {
  regrelloObjectId: Scalars["ID"]["input"];
}

export interface MutationSubmitActionItemArgs {
  createCommentInput?: InputMaybe<CreateCommentInputs>;
  fieldInstanceSubmissionPayloads?: InputMaybe<Array<UpdateFieldInstanceValueInputsByFieldId>>;
  id: Scalars["ID"]["input"];
}

export interface MutationSwitchCurrentUserTenantArgs {
  tenantId: Scalars["ID"]["input"];
}

export interface MutationSwitchTenantArgs {
  input: SwitchTenantInputs;
}

export interface MutationTriggerSettingPlannedDueDatesForWorkflowArgs {
  workflowId: Scalars["ID"]["input"];
}

export interface MutationUnDeleteWorkflowTemplateArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationUnarchiveTenantArgs {
  tenantId: Scalars["ID"]["input"];
}

export interface MutationUnstarActionItemArgs {
  actionItemId: Scalars["ID"]["input"];
}

export interface MutationUnstarWorkflowArgs {
  workflowId: Scalars["ID"]["input"];
}

export interface MutationUnstarWorkflowTemplateArgs {
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemArgs {
  input: UpdateActionItemInputs;
}

export interface MutationUpdateActionItemAssigneesArgs {
  assigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemCcArgs {
  ccPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemDescriptionArgs {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemDocumentsArgs {
  documentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemDueOnArgs {
  dueOn?: InputMaybe<Scalars["Time"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemHasExceptionArgs {
  hasException: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemIsMutedArgs {
  id: Scalars["ID"]["input"];
  isMuted: Scalars["Boolean"]["input"];
}

export interface MutationUpdateActionItemNameArgs {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationUpdateActionItemTagsArgs {
  id: Scalars["ID"]["input"];
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface MutationUpdateActionItemTemplateArgs {
  id: Scalars["ID"]["input"];
  input: UpdateActionItemTemplateInputs;
}

export interface MutationUpdateActionItemTemplateApproversArgs {
  approverPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateAssigneesArgs {
  assigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateCcArgs {
  ccPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateDescriptionArgs {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateDisplayOrderArgs {
  displayOrder: Scalars["Float"]["input"];
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateDocumentsArgs {
  documentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateDueOnArgs {
  dueOn?: InputMaybe<Scalars["Time"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateDueOnIntervalSecondsArgs {
  dueOnIntervalSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  dueOnIntervalType?: InputMaybe<ActionItemTemplateDueOnIntervalType>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateDueOnIntervalSecondsAfterTriggerArgs {
  dueOnIntervalSecondsAfterTrigger?: InputMaybe<ActionItemDueOnIntervalTrigger>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateActionItemTemplateNameArgs {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationUpdateActionItemTemplateRejectActionArgs {
  id: Scalars["ID"]["input"];
  rejectActionInput: RejectActionInput;
}

export interface MutationUpdateActionItemTemplateStartAfterActionItemTemplateIdArgs {
  id: Scalars["ID"]["input"];
  startAfterActionItemTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface MutationUpdateActionItemTemplateStartAtArgs {
  id: Scalars["ID"]["input"];
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface MutationUpdateActionItemTemplateTagsArgs {
  id: Scalars["ID"]["input"];
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface MutationUpdateActionItemTemplateWorkflowNameTemplateSuffixArgs {
  id: Scalars["ID"]["input"];
  workflowNameTemplateSuffix?: InputMaybe<Scalars["String"]["input"]>;
}

export interface MutationUpdateApprovalActionItemTemplatesArgs {
  approvalActionItemTemplates?: InputMaybe<Array<ApprovalActionItemTemplate>>;
  id: Scalars["ID"]["input"];
  isParallel?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface MutationUpdateAutomationRequestFieldsMappingArgs {
  input: UpdateAutomationRequestFieldsMappingInput;
}

export interface MutationUpdateAutomationRequestsOnActionItemTemplateArgs {
  actionItemTemplateId: Scalars["ID"]["input"];
  healthCheckConfig?: InputMaybe<UpdateApiHealthCheckConfigInput>;
  input: Array<UpdateAutomationRequestInput>;
}

export interface MutationUpdateBlueprintVariantsConditionsArgs {
  input: UpdateBlueprintVariantConditionsInput;
}

export interface MutationUpdateCalendarSettingsArgs {
  input: UpdateCalendarSettingsInput;
}

export interface MutationUpdateEnabledColumnsArgs {
  columnCustomizationTableSpecifier: ColumnCustomizationTableSpecifier;
  enabledDefaultColumnOptions: DefaultColumnOptionsInput;
  enabledFieldIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface MutationUpdateFieldAllowedValuesArgs {
  allowedValues: Array<FieldAllowedValuesInput>;
  fieldId: Scalars["ID"]["input"];
}

export interface MutationUpdateFieldInstancesOnActionItemArgs {
  actionItemId: Scalars["ID"]["input"];
  fieldId: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueInputs>>;
}

export interface MutationUpdateFieldInstancesOnActionItemTemplateArgs {
  actionItemTemplateId: Scalars["ID"]["input"];
  fieldId: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueInputs>>;
}

export interface MutationUpdateFieldInstancesOnActionItemV2Args {
  actionItemId: Scalars["ID"]["input"];
  auditHistoryId?: InputMaybe<Scalars["Int"]["input"]>;
  fieldId: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueInputs>>;
}

export interface MutationUpdateFieldInstancesOnCompletedActionItemV2Args {
  actionItemId: Scalars["ID"]["input"];
  fieldId: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueInputs>>;
}

export interface MutationUpdateFieldInstancesOnWorkflowArgs {
  fieldId: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueInputs>>;
  workflowId: Scalars["ID"]["input"];
}

export interface MutationUpdateFieldInstancesOnWorkflowTemplateArgs {
  fieldId: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueInputs>>;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface MutationUpdateFieldNameArgs {
  fieldId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationUpdateFieldUnitArgs {
  input: UpdateFieldUnitInputs;
}

export interface MutationUpdateFormFieldDisplayOrderArgs {
  input: UpdateFormFieldDisplayOrderInput;
}

export interface MutationUpdateFormFieldEnableFieldPassingArgs {
  input: UpdateFormFieldEnableFieldPassingInput;
}

export interface MutationUpdateFormFieldFormSectionArgs {
  input: UpdateFormFieldFormSectionInput;
}

export interface MutationUpdateFormFieldInstancesOnFormInstanceArgs {
  auditHistoryID?: InputMaybe<Scalars["Int"]["input"]>;
  formInstanceUUID: Scalars["UUID"]["input"];
  input: Array<InputMaybe<UpdateFieldInstanceValueByIdInputs>>;
}

export interface MutationUpdateFormFieldIsMultiValuedArgs {
  input: UpdateFormFieldIsMultiValuedInput;
}

export interface MutationUpdateFormSectionArgs {
  input: UpdateFormSectionInput;
}

export interface MutationUpdateFormSectionDisplayOrderArgs {
  input: UpdateFormSectionDisplayOrderInput;
}

export interface MutationUpdateFormSectionParentFormSectionArgs {
  input: UpdateFormSectionParentFormSectionInput;
}

export interface MutationUpdateFormVersionArgs {
  input: UpdateFormVersionInput;
}

export interface MutationUpdateInputsAndOutputsOnAutomatedActionItemTemplateArgs {
  actionItemTemplateId: Scalars["ID"]["input"];
  paramsToUpdateOutputs?: InputMaybe<Array<ParamsToUpdateIntegrationAutomationOutput>>;
  paramsToUpsertGroupedInputs?: InputMaybe<Array<ParamsToUpsertIntegrationAutomationGroupedInput>>;
  paramsToUpsertInputs?: InputMaybe<Array<ParamsToUpsertIntegrationAutomationInput>>;
  paramsToUpsertOutputs?: InputMaybe<Array<ParamsToUpsertIntegrationAutomationOutput>>;
}

export interface MutationUpdateInputsOnAutomatedActionItemArgs {
  id: Scalars["ID"]["input"];
  paramsToUpdateInputs: Array<ParamsToUpdateIntegrationAutomationInput>;
}

export interface MutationUpdateIntegrationAuthenticationOnActionItemTemplateArgs {
  actionItemTemplateId: Scalars["ID"]["input"];
  integrationAuthenticationId: Scalars["ID"]["input"];
}

export interface MutationUpdateNotificationCardFeedbackSentimentArgs {
  feedbackSentiment?: InputMaybe<NotificationCardFeedbackSentiment>;
  uuid: Scalars["String"]["input"];
}

export interface MutationUpdateNotificationSettingsAnyNotificationEmailsArgs {
  isAnyNotificationEmailEnabled: Scalars["Boolean"]["input"];
}

export interface MutationUpdateNotificationSettingsGlobalActionItemEmailsArgs {
  isGlobalActionItemEmailEnabled: Scalars["Boolean"]["input"];
  unmuteAll?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface MutationUpdateNotificationSettingsGlobalAssignedTaskEmailsArgs {
  isGlobalAssignedTaskEmailEnabled: Scalars["Boolean"]["input"];
  unmuteAll?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface MutationUpdateNotificationSettingsGlobalCcdTaskEmailsArgs {
  isGlobalCcdTaskEmailEnabled: Scalars["Boolean"]["input"];
  unmuteAll?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface MutationUpdateNotificationSettingsWeeklyDigestArgs {
  isWeeklyDigestEnabled: Scalars["Boolean"]["input"];
}

export interface MutationUpdatePartyRolesArgs {
  input: UpdatePartyRolesInput;
}

export interface MutationUpdateRegrelloObjectArgs {
  id: Scalars["ID"]["input"];
  input: UpdateRegrelloObjectInputs;
}

export interface MutationUpdateRoleArgs {
  input: UpdateRoleInput;
}

export interface MutationUpdateRoleMembersArgs {
  input: Array<UpdateRoleMembersInput>;
}

export interface MutationUpdateScimConfigurationArgs {
  input: UpdateScimConfigurationInput;
}

export interface MutationUpdateTagArgs {
  input: UpdateTagInputs;
}

export interface MutationUpdateTagTypeArgs {
  id: Scalars["ID"]["input"];
  input: UpdateTagTypeInputs;
}

export interface MutationUpdateTeamArgs {
  id: Scalars["ID"]["input"];
  input: UpdateTeamInput;
}

export interface MutationUpdateTenantAppCustomDomainArgs {
  appCustomDomain: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateTenantDatabaseUrlSecretNameArgs {
  dbUrlSecretName?: InputMaybe<Scalars["String"]["input"]>;
  tenantId: Scalars["ID"]["input"];
}

export interface MutationUpdateTenantDisplayNameArgs {
  displayName: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateTenantEmailSendingDomainArgs {
  emailSendingDomain: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateTenantNonAdminPermissionsArgs {
  input: UpdateTenantNonAdminPermissionsInput;
}

export interface MutationUpdateTenantPrivacyPolicyArgs {
  input?: InputMaybe<UpdateTenantPrivacyPolicyInput>;
  tenantId: Scalars["ID"]["input"];
}

export interface MutationUpdateTenantSearchCollectionToIndexArgs {
  allTenants: Scalars["Boolean"]["input"];
  collectionSchemaName: Scalars["String"]["input"];
  isIndexingEnabled: Scalars["Boolean"]["input"];
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface MutationUpdateTenantSearchCollectionToSearchFromArgs {
  allTenants: Scalars["Boolean"]["input"];
  collectionSchemaName: Scalars["String"]["input"];
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface MutationUpdateUserAccessLevelArgs {
  accessLevel: UserAccessLevel;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateUserAccessRoleArgs {
  accessRoleId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateUserDefaultViewIdArgs {
  defaultViewId?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateUserFieldInstancesOnActionItemUsingEmailsArgs {
  actionItemId: Scalars["ID"]["input"];
  draft: Scalars["Boolean"]["input"];
  fieldId: Scalars["ID"]["input"];
  fieldInstanceId: Scalars["ID"]["input"];
  input: Array<InputMaybe<UpdateUserFieldInstanceOnActionItemUsingEmailsInputs>>;
}

export interface MutationUpdateUserFormFieldInstancesOnFormInstanceUsingEmailsArgs {
  auditHistoryID?: InputMaybe<Scalars["Int"]["input"]>;
  draft: Scalars["Boolean"]["input"];
  fieldInstanceValueID: Scalars["ID"]["input"];
  formFieldID: Scalars["ID"]["input"];
  formInstanceUUID: Scalars["UUID"]["input"];
  input: Array<InputMaybe<UpdateUserFormFieldInstancesOnFormInstanceUsingEmailsInputs>>;
}

export interface MutationUpdateUserIsTimeZoneLockedArgs {
  id: Scalars["ID"]["input"];
  isTimeZoneLocked: Scalars["Boolean"]["input"];
}

export interface MutationUpdateUserLanguageArgs {
  id: Scalars["ID"]["input"];
  language: UserLanguage;
}

export interface MutationUpdateUserLocationArgs {
  id: Scalars["ID"]["input"];
  location?: InputMaybe<UserLocation>;
}

export interface MutationUpdateUserNameArgs {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationUpdateUserPhoneArgs {
  id: Scalars["ID"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
}

export interface MutationUpdateUserSetupCompletedAtArgs {
  id: Scalars["ID"]["input"];
  isCompleted: Scalars["Boolean"]["input"];
}

export interface MutationUpdateUserTimeZoneArgs {
  id: Scalars["ID"]["input"];
  timeZone?: InputMaybe<Scalars["String"]["input"]>;
}

export interface MutationUpdateViewArgs {
  updateViewInput: UpdateViewInput;
}

export interface MutationUpdateViewNameArgs {
  name: Scalars["String"]["input"];
  viewId: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowDescriptionArgs {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowNameArgs {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationUpdateWorkflowOwnerArgs {
  id: Scalars["ID"]["input"];
  ownerPartyId: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowStageDescriptionArgs {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowStageExecutionOrderArgs {
  executionOrder: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowStageNameArgs {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationUpdateWorkflowStageRequiredArgs {
  id: Scalars["ID"]["input"];
  required: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowStageStartAfterSpecArgs {
  connective?: InputMaybe<ConditionConnective>;
  id: Scalars["ID"]["input"];
  workflowStageIds: Array<Scalars["ID"]["input"]>;
}

export interface MutationUpdateWorkflowStageStartAfterWorkflowStageIdArgs {
  id: Scalars["ID"]["input"];
  startAfterWorkflowStageId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface MutationUpdateWorkflowStageStartAfterWorkflowStageIdsArgs {
  id: Scalars["ID"]["input"];
  input?: InputMaybe<UpdateWorkflowStageStartAfterWorkflowStageIdsInputs>;
  startAfterWorkflowStageIds: Array<Scalars["ID"]["input"]>;
}

export interface MutationUpdateWorkflowStageStartAtArgs {
  id: Scalars["ID"]["input"];
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface MutationUpdateWorkflowStageStartOnWorkflowStartArgs {
  id: Scalars["ID"]["input"];
  startOnWorkflowStart: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowStageStartingConditionsArgs {
  id: Scalars["ID"]["input"];
  input: UpdateConditionGroupInputs;
}

export interface MutationUpdateWorkflowStageTemplateDescriptionArgs {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowStageTemplateExecutionOrderArgs {
  executionOrder: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowStageTemplateNameArgs {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationUpdateWorkflowStageTemplateRequiredArgs {
  id: Scalars["ID"]["input"];
  required: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowStageTemplateStartAfterSpecArgs {
  connective?: InputMaybe<ConditionConnective>;
  id: Scalars["ID"]["input"];
  workflowStageTemplateIds: Array<Scalars["ID"]["input"]>;
}

export interface MutationUpdateWorkflowStageTemplateStartAfterWorkflowStageTemplateIdArgs {
  id: Scalars["ID"]["input"];
  startAfterWorkflowStageTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface MutationUpdateWorkflowStageTemplateStartAfterWorkflowStageTemplateIdsArgs {
  id: Scalars["ID"]["input"];
  startAfterWorkflowStageTemplateIds: Array<Scalars["ID"]["input"]>;
}

export interface MutationUpdateWorkflowStageTemplateStartAtArgs {
  id: Scalars["ID"]["input"];
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface MutationUpdateWorkflowStageTemplateStartOnWorkflowStartArgs {
  id: Scalars["ID"]["input"];
  startOnWorkflowStart: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowStageTemplateStartingConditionsArgs {
  id: Scalars["ID"]["input"];
  input: UpdateConditionGroupInputs;
}

export interface MutationUpdateWorkflowSummariesArgs {
  customizePrompt?: InputMaybe<Scalars["String"]["input"]>;
  since?: InputMaybe<Scalars["Time"]["input"]>;
  workflowId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface MutationUpdateWorkflowTagsArgs {
  id: Scalars["ID"]["input"];
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface MutationUpdateWorkflowTemplateAutoAdjustDueOnArgs {
  id: Scalars["ID"]["input"];
  isAutoAdjustDueOnEnabled: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowTemplateCollaboratorsArgs {
  collaboratorPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowTemplateCreateViaEmailArgs {
  id: Scalars["ID"]["input"];
  input?: InputMaybe<UpdateWorkflowTemplateCreateViaEmailInputs>;
}

export interface MutationUpdateWorkflowTemplateDescriptionArgs {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowTemplateFieldInstanceSpectrumFieldVersionsToLatestArgs {
  id: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowTemplateIsEditingWorkflowApprovalsRestrictedArgs {
  id: Scalars["ID"]["input"];
  isRestricted: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowTemplateIsEditingWorkflowsRestrictedArgs {
  id: Scalars["ID"]["input"];
  isRestricted: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowTemplateIsVariantCreationEnabledArgs {
  id: Scalars["ID"]["input"];
  isEnabled: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowTemplateIsVisibilityLimitedForExternalPartiesArgs {
  id: Scalars["ID"]["input"];
  isLimited: Scalars["Boolean"]["input"];
}

export interface MutationUpdateWorkflowTemplateNameArgs {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}

export interface MutationUpdateWorkflowTemplateNameTemplateArgs {
  id: Scalars["ID"]["input"];
  input?: InputMaybe<UpdateNameTemplateInputs>;
}

export interface MutationUpdateWorkflowTemplateReferenceNumberArgs {
  id: Scalars["ID"]["input"];
  referenceNumberPrefix?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumberStartingValue?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface MutationUpdateWorkflowTemplateTagsArgs {
  id: Scalars["ID"]["input"];
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface MutationUpdateWorkflowTemplateVersionNotesArgs {
  id: Scalars["ID"]["input"];
  versionNotes?: InputMaybe<Scalars["String"]["input"]>;
}

export interface MutationUpdateWorkflowTemplateVersionToNewDraftVersionArgs {
  workflowTemplateVersionId: Scalars["ID"]["input"];
}

export interface MutationUpdateWorkflowTemplateWorkflowOwnerArgs {
  id: Scalars["ID"]["input"];
  workflowOwnerPartyId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface MutationUpdateWorkspaceSettingsArgs {
  settings: UpdateWorkspaceSettingsInput;
}

export interface MutationVerifyUserIdentityArgs {
  authUserEmail?: InputMaybe<Scalars["String"]["input"]>;
  forceVerifyForExistingUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}

export enum MyActionItemsContext {
  BY_PARTY = "ByParty",
  CC = "Cc",
  DIRECTLY_TO_PARTY = "DirectlyToParty",
  TO_OR_BY_PARTY = "ToOrByParty",
  TO_PARTY = "ToParty",
}

export interface MyActionItemsInput {
  /**
   * How to query for action items.
   *
   * For example, `ToParty` indicates that the query should return
   * action items assigned **to** the party pointed to by ID `partyId`.
   * On the other hand, `ByParty` indicates that the query should return
   * items assigned **by** the party pointed to by ID `partyId`.
   */
  context?: InputMaybe<MyActionItemsContext>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** Filter to returned action items meeting all specified criteria. */
  modifiers?: InputMaybe<Array<MyActionItemsModifier>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** ID of the party to retrieve action items for. */
  partyId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<TaskDefaultColumnOption>;
  sortOrder?: InputMaybe<SortOrder>;
}

export enum MyActionItemsModifier {
  /** @deprecated replaced with ShowOnlyOnTrack */
  HIDE_OVERDUE = "HideOverdue",
  SHOW_ALL = "ShowAll",
  SHOW_APPROVAL_TASKS = "ShowApprovalTasks",
  /** @deprecated replaced with ShowAll */
  SHOW_COMPLETED = "ShowCompleted",
  SHOW_ONLY_COMPLETED = "ShowOnlyCompleted",
  SHOW_ONLY_ENDED = "ShowOnlyEnded",
  SHOW_ONLY_EXCEPTION = "ShowOnlyException",
  SHOW_ONLY_IN_PROGRESS = "ShowOnlyInProgress",
  /** Show only action items that are muted by the querying user. */
  SHOW_ONLY_MUTED = "ShowOnlyMuted",
  SHOW_ONLY_ON_TRACK = "ShowOnlyOnTrack",
  SHOW_ONLY_OVERDUE = "ShowOnlyOverdue",
  SHOW_ONLY_PAUSED = "ShowOnlyPaused",
  SHOW_STANDARD_TASKS = "ShowStandardTasks",
}

export interface MyActionItemsResponse {
  actionItems: Array<ActionItem>;
  totalCount: Scalars["Int"]["output"];
}

/**
 * A naming convention to specify how the names of objects created from an object template will be
 * generated on create and update.
 */
export interface NameTemplate {
  /**
   * The field IDs referenced in the string template. Used for validation and improving update
   * performance.
   */
  fieldIds: Array<Scalars["ID"]["output"]>;
  /**
   * The actual template for the naming convention. Field references must be specified in the string
   * using the format `{{.fieldId#}}` or `{{.fieldId#.regrelloObjectId#}}` for synced objects,
   * replacing '#' with the appropriate ID.
   */
  stringTemplate: Scalars["String"]["output"];
}

/** A naming convention with a suffix to be appended at the end of the generated name. */
export interface NameTemplateWithSuffix {
  /**
   * The field IDs referenced in the string template. Used for validation and improving update
   * performance.
   */
  fieldIds: Array<Scalars["ID"]["output"]>;
  /**
   * The actual template for the naming convention. Field references must be specified in the string
   * using the format `{{.fieldId#}}` or `{{.fieldId#.regrelloObjectId#}}` for synced objects,
   * replacing '#' with the appropriate ID.
   */
  stringTemplate: Scalars["String"]["output"];
  /** Optional suffix that will be appended to the generated name. */
  suffix?: Maybe<Scalars["String"]["output"]>;
}

export enum NonAdminPeopleAndTeamsDefaultColumnOption {
  COMPLETED = "COMPLETED",
  EXCEPTION = "EXCEPTION",
  LAST_ACTIVE = "LAST_ACTIVE",
  MANAGE = "MANAGE",
  MEMBERS = "MEMBERS",
  NAME = "NAME",
  ON_TRACK = "ON_TRACK",
  OVERDUE = "OVERDUE",
  OWNED_WORKFLOWS = "OWNED_WORKFLOWS",
  TEAM_ADMIN = "TEAM_ADMIN",
  USER_TEAMS = "USER_TEAMS",
}

export interface NonAdminPeopleAndTeamsTableResponse {
  parties: Array<PartyWithActionItemCount>;
  totalCount: Scalars["Int"]["output"];
}

/** A notification for the current user about recent system activity that may be interesting to them. */
export interface Notification {
  /** The party whose action triggered the notification, if any. */
  actor?: Maybe<Party>;
  /** The time at which the notification was created. */
  createdAt: Scalars["Time"]["output"];
  /** The unique identifier for the notification. */
  id: Scalars["ID"]["output"];
  /** Whether the notification has not yet been read by the user. */
  isUnread: Scalars["Boolean"]["output"];
  /** The action item that the notification is about, if any. */
  referencedActionItem?: Maybe<ActionItemReference>;
  /** The action item template that the notification is about, if any. */
  referencedActionItemTemplate?: Maybe<ActionItemTemplateReference>;
  /** The comment that the notification is about, if any. */
  referencedComment?: Maybe<CommentReference>;
  /** The workflow that the notification is about, if any. */
  referencedWorkflow?: Maybe<BaseWorkflowReference>;
  /** The workflow template that the notification is about, if any. */
  referencedWorkflowTemplate?: Maybe<WorkflowTemplateBaseReference>;
  /** The type of the notification, indicating what happened. */
  type: NotificationType;
}

export interface NotificationCard {
  actionItem?: Maybe<ActionItem>;
  body: Scalars["String"]["output"];
  comment?: Maybe<Comment>;
  createdAt: Scalars["Time"]["output"];
  dismissedAt?: Maybe<Scalars["Time"]["output"]>;
  /** The sentiment of a user's feedback on a NotificationCard. E.g., positive or negative. */
  feedbackSentiment?: Maybe<NotificationCardFeedbackSentiment>;
  id: Scalars["ID"]["output"];
  /**
   * Whether or not user-provided-feedback should be allowed for the notification card. See the
   * `feedbackSentiment` field on the NotificationCard type.
   */
  isFeedbackEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type: NotificationCardType;
  /**
   * Unique identifier for the NotificationCard in the context of the workflow. If a duplicate is
   * found, the existing card will be dismissed when the new one gets created. This is to allow an
   * external system like AI Monitor the ability to deterministically replace cards without creating
   * new ones.
   */
  uniqueIdentifier?: Maybe<Scalars["String"]["output"]>;
  uuid: Scalars["String"]["output"];
  workflow?: Maybe<Workflow>;
}

export enum NotificationCardFeedbackSentiment {
  NEGATIVE = "NEGATIVE",
  POSITIVE = "POSITIVE",
}

export enum NotificationCardType {
  PREDICTIVE_COMMENT_PROBLEM = "PREDICTIVE_COMMENT_PROBLEM",
  WORKFLOW_DELAY_50_PERCENT = "WORKFLOW_DELAY_50_PERCENT",
  WORKFLOW_SUMMARY = "WORKFLOW_SUMMARY",
}

export interface NotificationCardsFilterInputs {
  /** ID of the workflow to retrieve NotificationCards for. */
  workflowId: Scalars["ID"]["input"];
}

export interface NotificationCardsResponse {
  /** The total number of NotificationCards matching the filter criteria. */
  count: Scalars["Int"]["output"];
  /** The total number of `NotificationCardTypePredictiveCommentProblem` matching the filter criteria. */
  countOfPredictiveCommentProblemCards: Scalars["Int"]["output"];
  /** The total number of `NotificationCardTypeWorkflowDelay50Percent` matching the filter criteria. */
  countOfWorkflowDelay50PercentCards: Scalars["Int"]["output"];
  /** The NotificationCards matching the filter criteria. */
  notificationCards: Array<NotificationCard>;
  /** The workflow that the NotificationCards are associated with. */
  workflow?: Maybe<Workflow>;
}

export interface NotificationResponse {
  notification: Notification;
}

export interface NotificationSettings {
  id: Scalars["ID"]["output"];
  isAnyNotificationEmailEnabled: Scalars["Boolean"]["output"];
  isDailyDigestEmailEnabled: Scalars["Boolean"]["output"];
  isGlobalActionItemEmailEnabled: Scalars["Boolean"]["output"];
  isGlobalAssignedTaskEmailEnabled: Scalars["Boolean"]["output"];
  isGlobalCcdTaskEmailEnabled: Scalars["Boolean"]["output"];
  isWeeklyDigestEmailEnabled: Scalars["Boolean"]["output"];
  party: Party;
}

/**
 * Indicates the type of notification being sent to the user. This can be used by the client to
 * determine how to phrase the notification message shown to the user.
 */
export enum NotificationType {
  /** Indicates that the current user has been mentioned in a comment on an action item. */
  ACTION_ITEM_MENTION = "ACTION_ITEM_MENTION",
  /** Indicates that the an approval action item has been rejected in a workflow. */
  ACTION_ITEM_REJECTED = "ACTION_ITEM_REJECTED",
  /** Indicates that a workflow has been created from a pertinent workflow template. */
  WORKFLOW_CREATED_FROM_TEMPLATE = "WORKFLOW_CREATED_FROM_TEMPLATE",
  /** Indicates that a new version of a pertinent workflow template has been created. */
  WORKFLOW_TEMPLATE_VERSION_CREATED = "WORKFLOW_TEMPLATE_VERSION_CREATED",
}

export interface NotificationsResponse {
  notifications: Array<Notification>;
}

export interface OAuth2ConfigInput {
  accessTokenUrl: Scalars["String"]["input"];
  clientId: Scalars["String"]["input"];
  clientSecret?: InputMaybe<Scalars["String"]["input"]>;
  grantType: OAuthGrantType;
  location: OAuthCredentialsLocation;
  scope?: InputMaybe<Scalars["String"]["input"]>;
}

export enum OAuthCredentialsLocation {
  BASIC_AUTH_HEADER = "BASIC_AUTH_HEADER",
  BODY = "BODY",
}

export enum OAuthGrantType {
  CLIENT_CREDENTIALS = "CLIENT_CREDENTIALS",
}

export interface OAuthIdpConfiguration {
  /**
   * Applicable for oauth2 authorization type only, the allowed audience claim to validate service account managed by
   * customer's IdP.
   */
  allowedAudienceClaim?: Maybe<Scalars["String"]["output"]>;
  /**
   * Applicable for oauth2 authorization type only, the allowed issuer claim to validate service account managed by
   * customer's IdP.
   */
  allowedIssuerClaim?: Maybe<Scalars["String"]["output"]>;
  /**
   * Applicable for oauth2 authorization type only, the allowed sub claim to validate service account managed by customer's
   * IdP.
   */
  allowedSubClaim?: Maybe<Scalars["String"]["output"]>;
  /** Applicable for oauth2 authorization type only, the jwks endpoint to validate access token generated by customer's IdP. */
  jwksEndpoint?: Maybe<Scalars["String"]["output"]>;
}

export interface OAuthIdpConfigurationInput {
  /**
   * Applicable for oauth2 authorization type only, the allowed audience claim to validate service account managed by
   * customer's IdP.
   */
  allowedAudienceClaim?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Applicable for oauth2 authorization type only, the allowed issuer claim to validate service account managed by
   * customer's IdP.
   */
  allowedIssuerClaim?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Applicable for oauth2 authorization type only, the allowed sub claim to validate service account managed by customer's
   * IdP.
   */
  allowedSubClaim?: InputMaybe<Scalars["String"]["input"]>;
  /** Applicable for oauth2 authorization type only, the jwks endpoint to validate access token generated by customer's IdP. */
  jwksEndpoint?: InputMaybe<Scalars["String"]["input"]>;
}

export interface OccupiedSpectrumFieldNameById {
  spectrumFieldId: Scalars["ID"]["output"];
  spectrumFieldVersionName: Scalars["String"]["output"];
}

/** An OnlineParty is a party that is currently online on the workflow template page or idle. */
export interface OnlineParty {
  party: Party;
  status: OnlinePartyStatus;
}

export enum OnlinePartyStatus {
  /** Indicates that the party is online and actively looking at the resource in question. */
  ACTIVE = "ACTIVE",
  /** Indicates that the party is online but dormant and/or not actively looking at the resource in question. */
  IDLE = "IDLE",
}

export interface Organization {
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
}

export interface OutOfOfficeDelegationEvent {
  createdAt: Scalars["Time"]["output"];
  /** information about delegations if any */
  delegateValues?: Maybe<OutOfOfficeDelegationEventDelegateValues>;
  id: Scalars["ID"]["output"];
  outOfOfficeEvent: OutOfOfficeEvent;
}

export interface OutOfOfficeDelegationEventDelegateValues {
  delegates: Array<Party>;
  isCcdOnDelegatedTask: Scalars["Boolean"]["output"];
}

export interface OutOfOfficeEvent {
  /** contains information about delegates if set */
  delegationSettings?: Maybe<OutOfOfficeEventDelegationSettings>;
  endAt?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  /** calculated value true iff startAt < current time < endAt (nil endAt <==> infinite time in future) */
  isActive: Scalars["Boolean"]["output"];
  party: Party;
  startAt: Scalars["Time"]["output"];
}

export interface OutOfOfficeEventDelegationSettings {
  approvalTaskDelegates: Array<Party>;
  nonApprovalTaskDelegates: Array<Party>;
  /** Information about role-specific delegates, if set */
  roleDelegations: Array<OutOfOfficeEventRoleDelegation>;
  shouldCcOnDelegatedTasks: Scalars["Boolean"]["output"];
}

export interface OutOfOfficeEventRoleDelegation {
  delegateParties: Array<Party>;
  role: RoleReference;
}

export interface PaginatedWorkflowTemplatesInput {
  /** Whether to include deleted workflow templates in the response. */
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Whether to include workflow templates that are variants (i.e., they have a defined
   * `standardBlueprintUuid`).
   */
  includeVariants?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Upper limit of how many workflow templates to return. If not defined, a default value of 25 will
   * be used. A max value of 100 will be used if the provided limit exceeds the max.
   */
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** How many workflow templates in the filtered results to skip before the templates to return. */
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * If defined, only workflow templates to which the user has at least one of the specified
   * permissions will be returned.
   */
  permissions?: InputMaybe<Array<WorkflowTemplateAction>>;
  /** If defined, only workflow templates whose name contains the specified substring will be returned. */
  search?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * If defined, only workflow templates whose name or blueprint UUID contains the specified substring
   * will be returned.
   */
  searchUuidOrName?: InputMaybe<Scalars["String"]["input"]>;
  /** If defined, only workflow templates of the specified types will be returned. */
  types?: InputMaybe<Array<WorkflowTemplateType>>;
  /** If defined, only workflow templates that have the specified automations will be returned. */
  usingAutomationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** If defined, only workflow templates that have the specified fields will be returned. */
  usingFieldIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Optional ID of a specific workflow template to return. */
  workflowTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface PaginatedWorkflowTemplatesResponse {
  totalCount: Scalars["Int"]["output"];
  workflowTemplates: Array<WorkflowTemplate>;
}

export enum PaginationType {
  LIMIT_OFFSET = "LIMIT_OFFSET",
}

export interface ParamsToCreateIntegrationAutomationGroupedInput {
  inputGroupName: Scalars["String"]["input"];
  inputs: Array<ParamsToCreateIntegrationAutomationInput>;
}

export interface ParamsToCreateIntegrationAutomationInput {
  displayName: Scalars["String"]["input"];
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  fieldInstanceId?: InputMaybe<Scalars["ID"]["input"]>;
  isDynamic: Scalars["Boolean"]["input"];
  isHidden: Scalars["Boolean"]["input"];
  isRequired: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
  valueType: PropertyDataType;
}

export interface ParamsToCreateIntegrationAutomationOutput {
  displayName: Scalars["String"]["input"];
  fieldId?: InputMaybe<Scalars["ID"]["input"]>;
  /** <fieldInstanceId> is DEPRECATED. Use <fieldId> instead. */
  fieldInstanceId?: InputMaybe<Scalars["ID"]["input"]>;
  isHidden: Scalars["Boolean"]["input"];
  jsonExpression?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  valueType: PropertyDataType;
}

export interface ParamsToUpdateIntegrationAutomationInput {
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  /** We allow the user to map a field instance later so <fieldInstanceId> can be null when updating a task. */
  fieldInstanceId?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
}

export interface ParamsToUpdateIntegrationAutomationOutput {
  fieldId?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
  jsonExpression?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ParamsToUpsertIntegrationAutomationGroupedInput {
  inputGroupName: Scalars["String"]["input"];
  upsertParams: Array<ParamsToUpsertIntegrationAutomationInput>;
}

export interface ParamsToUpsertIntegrationAutomationInput {
  /**
   * Example use case when need to use <createParams>: "[DocuSign] Create draft from template" task.
   *
   * When "DocuSign template" input is changed - a new set of "isDynamic=true" inputs is retrieved.
   * Those inputs correspond to the fields from a selected DocuSign template.
   * In this case, when updating an automated task template we need to replace the old inputs with the new ones.
   * New inputs must be supplied via <createParams>. Old inputs are deleted automatically.
   */
  createParams?: InputMaybe<ParamsToCreateIntegrationAutomationInput>;
  /** Example use case when need to use <updateParams>: user assigned a new field instance to an input. */
  updateParams?: InputMaybe<ParamsToUpdateIntegrationAutomationInput>;
}

export interface ParamsToUpsertIntegrationAutomationOutput {
  createParams?: InputMaybe<ParamsToCreateIntegrationAutomationOutput>;
  updateParams?: InputMaybe<ParamsToUpdateIntegrationAutomationOutput>;
}

export interface ParentActionItemsAndStagesInWorkflowInput {
  dependingOnActionItemTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnWorkflowStageId?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnWorkflowStageIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workflowId: Scalars["ID"]["input"];
}

export interface ParentActionItemsAndStagesInWorkflowResponse {
  actionItemTemplates?: Maybe<Array<ActionItemTemplate>>;
  workflowStages?: Maybe<Array<WorkflowStage>>;
}

export interface ParentActionItemsAndStagesInWorkflowTemplateInput {
  dependingOnActionItemTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnWorkflowStageTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnWorkflowStageTemplateIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface ParentActionItemsAndStagesInWorkflowTemplateResponse {
  actionItemTemplates?: Maybe<Array<ActionItemTemplate>>;
  workflowStageTemplates?: Maybe<Array<WorkflowStageTemplate>>;
}

/**
 * A party is a common entity type that Users have and that Teams have. It is used as the primary
 * assignable unit in Regrello. When tasks are submitted or when certain permissions are evaluated,
 * the Party is the entity in consideration and not the User. A Party has either a User or a Team but
 * not both. There is a unique Party associated with every individual User or Team.
 */
export interface Party {
  id: Scalars["ID"]["output"];
  /** Roles that the party has been assigned. */
  roles: Array<Role>;
  team?: Maybe<Team>;
  user?: Maybe<User>;
}

/** Either a party or a role. Only one can be active at a time. */
export interface PartyOrRole {
  party?: Maybe<Party>;
  role?: Maybe<Role>;
}

/** Either role ID **OR** party ID should be supplied. Never both. */
export interface PartyOrRoleId {
  partyId?: InputMaybe<Scalars["ID"]["input"]>;
  roleId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface PartyOrRoleViewCollaboration {
  /** The type of access in this collaboration */
  accessRole: AccessRole;
  /** The party this collaboration is for, if any. Exactly one of party or role should be defined. */
  party?: Maybe<Party>;
  /** The role this collaboration is for, if any. Exactly one of party or role should be defined. */
  role?: Maybe<Role>;
}

export interface PartyRelationBinding {
  party: Party;
  relation: ResourceRelation;
}

export interface PartyWithActionItemCount {
  numCompleted: Scalars["Int"]["output"];
  numEnded: Scalars["Int"]["output"];
  numException: Scalars["Int"]["output"];
  numInProgress: Scalars["Int"]["output"];
  numOnTrack: Scalars["Int"]["output"];
  numOverdue: Scalars["Int"]["output"];
  numOwnedWorkflows: Scalars["Int"]["output"];
  numPaused: Scalars["Int"]["output"];
  numTotal: Scalars["Int"]["output"];
  party: Party;
  /** Permissions about the party in question. */
  permissions?: Maybe<PartyWithActionItemCountPermissions>;
}

export interface PartyWithActionItemCountPermissions {
  /**
   * Whether the user can view the tasks belonging to the described party. This
   * is asserted when the querying party is a team admin of the target party, or
   * if the querying party is a workspace admin.
   */
  canViewTasks: Scalars["Boolean"]["output"];
}

export interface PartyWithHomePageAggregations {
  completedActions: Scalars["Int"]["output"];
  completedWorkflows: Scalars["Int"]["output"];
  dueSoonActions: Scalars["Int"]["output"];
  exceptionActions: Scalars["Int"]["output"];
  exceptionWorkflows: Scalars["Int"]["output"];
  lastUpdated?: Maybe<Scalars["Time"]["output"]>;
  numberOfUnreadComments: Scalars["Int"]["output"];
  openActions: Scalars["Int"]["output"];
  openWorkflows: Scalars["Int"]["output"];
  overdueActions: Scalars["Int"]["output"];
  overdueWorkflows: Scalars["Int"]["output"];
  party: Party;
}

export interface PauseWorkflowResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export enum PeopleDefaultColumnOption {
  ADDED_BY = "ADDED_BY",
  ALTERNATE_EMAILS = "ALTERNATE_EMAILS",
  DATE_ADDED = "DATE_ADDED",
  LAST_ACTIVE = "LAST_ACTIVE",
  PERMISSION = "PERMISSION",
  ROLES = "ROLES",
  TYPE = "TYPE",
  USER = "USER",
}

export interface Permission {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  type?: Maybe<PermissionLevel>;
  /** The user to whom the permission is applied. There will be a special user for Everyone. */
  user?: Maybe<User>;
}

export enum PermissionLevel {
  OWN = "OWN",
  READ = "READ",
  WRITE = "WRITE",
}

export interface PermissionedCreateMenuContentFlags {
  showCreateActionItemButton: Scalars["Boolean"]["output"];
  /** @deprecated This button is no longer offered on the client side. */
  showCreateFieldButton: Scalars["Boolean"]["output"];
  /** @deprecated This button is no longer offered on the client side. */
  showCreateFormButton: Scalars["Boolean"]["output"];
  /** @deprecated This button is no longer offered on the client side. */
  showCreatePeopleButton: Scalars["Boolean"]["output"];
  /** @deprecated This button is no longer offered on the client side. */
  showCreateRoleButton: Scalars["Boolean"]["output"];
  /** @deprecated This button is no longer offered on the client side. */
  showCreateTagButton: Scalars["Boolean"]["output"];
  /** @deprecated This button is no longer offered on the client side. */
  showCreateTeamButton: Scalars["Boolean"]["output"];
  showCreateTemplateButton: Scalars["Boolean"]["output"];
  showCreateWorkflowButton: Scalars["Boolean"]["output"];
  showCreateWorkflowFromScratch: Scalars["Boolean"]["output"];
}

export interface PermissionedNavigationFlags {
  createMenuContentFlags: PermissionedCreateMenuContentFlags;
  sidebarContentFlags: PermissionedSidebarContentFlags;
}

export interface PermissionedSidebarContentFlags {
  adminPage: Scalars["Boolean"]["output"];
  assignedTasksPage: Scalars["Boolean"]["output"];
  ccdTasksPage: Scalars["Boolean"]["output"];
  /** @deprecated This link is no longer offered in Regrello. */
  designDocs: Scalars["Boolean"]["output"];
  documentsPage: Scalars["Boolean"]["output"];
  homePage: Scalars["Boolean"]["output"];
  peopleAndTeamsPage: Scalars["Boolean"]["output"];
  starredPage: Scalars["Boolean"]["output"];
  starredSection: Scalars["Boolean"]["output"];
  templatesSection: TemplatesSidebarSectionFlags;
}

export interface PermissionsToActionItem {
  canAcknowledge: Scalars["Boolean"]["output"];
  canApprove: Scalars["Boolean"]["output"];
  canComment: Scalars["Boolean"]["output"];
  canComplete: Scalars["Boolean"]["output"];
  canDelete: Scalars["Boolean"]["output"];
  canDiscover: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
  canEditApprovers: Scalars["Boolean"]["output"];
  canEditAssignees: Scalars["Boolean"]["output"];
  canEditAssignor: Scalars["Boolean"]["output"];
  canEditAutomationInputs: Scalars["Boolean"]["output"];
  canEditCC: Scalars["Boolean"]["output"];
  canEditDescription: Scalars["Boolean"]["output"];
  canEditDocuments: Scalars["Boolean"]["output"];
  canEditDueOn: Scalars["Boolean"]["output"];
  canEditName: Scalars["Boolean"]["output"];
  canEditTags: Scalars["Boolean"]["output"];
  canMute: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canRecreateLinkedWorkflow: Scalars["Boolean"]["output"];
  canReject: Scalars["Boolean"]["output"];
  canRemoveExistingAssignees: Scalars["Boolean"]["output"];
  canReopen: Scalars["Boolean"]["output"];
  canReportException: Scalars["Boolean"]["output"];
  canRequireAcknowledge: Scalars["Boolean"]["output"];
  canRequireApproval: Scalars["Boolean"]["output"];
}

export interface PermissionsToActionItemTemplate {
  canDelete: Scalars["Boolean"]["output"];
  canDiscover: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
  canEditAdvancedSettings: Scalars["Boolean"]["output"];
  canEditApprovals: Scalars["Boolean"]["output"];
  canEditApprovers: Scalars["Boolean"]["output"];
  canEditAssignees: Scalars["Boolean"]["output"];
  canEditAutomationInputs: Scalars["Boolean"]["output"];
  canEditAutomationOutputs: Scalars["Boolean"]["output"];
  canEditCc: Scalars["Boolean"]["output"];
  canEditDescription: Scalars["Boolean"]["output"];
  canEditDisplayOrder: Scalars["Boolean"]["output"];
  canEditDocuments: Scalars["Boolean"]["output"];
  canEditDueOn: Scalars["Boolean"]["output"];
  canEditInheritedFields: Scalars["Boolean"]["output"];
  canEditLinkedBlueprint: Scalars["Boolean"]["output"];
  canEditName: Scalars["Boolean"]["output"];
  canEditRejectAction: Scalars["Boolean"]["output"];
  canEditRequestedFields: Scalars["Boolean"]["output"];
  canEditStartConditions: Scalars["Boolean"]["output"];
  canEditTags: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canRemoveExistingAssignees: Scalars["Boolean"]["output"];
  canRemoveRequestedFields: Scalars["Boolean"]["output"];
  canStart: Scalars["Boolean"]["output"];
}

export interface PermissionsToDocument {
  canDelete: Scalars["Boolean"]["output"];
}

export interface PermissionsToWorkflowStage {
  canAddAutomatedTask: Scalars["Boolean"]["output"];
  canDelete: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
  canStart: Scalars["Boolean"]["output"];
}

/**
 * PropertyTypes indicate the type of data that a field can hold. STRING fields can hold text, INT and
 * FLOAT fields can hold numbers, etc.
 */
export enum PropertyDataType {
  BOOLEAN = "BOOLEAN",
  DOCUMENT_ID = "DOCUMENT_ID",
  FLOAT = "FLOAT",
  INT = "INT",
  NAME_TEMPLATE = "NAME_TEMPLATE",
  PARTY_ID = "PARTY_ID",
  REGRELLO_OBJECT_INSTANCE_ID = "REGRELLO_OBJECT_INSTANCE_ID",
  STRING = "STRING",
  TIME = "TIME",
}

/**
 * A PropertyType is used to indicate the type of data that a field can hold. It may also imply how a
 * field should be used. STRING fields can hold text, INT and FLOAT fields can hold numbers, etc. Every Field must have a PropertyType.
 */
export interface PropertyType {
  /** Enumeration of the data type of the PropertyType */
  dataType: PropertyDataType;
  /** A unique identifier for the PropertyType. */
  id: Scalars["ID"]["output"];
  /** Each PropertyType must have a unique name. Regrello comes with pre-defined PropertyTypes for most situations. */
  name: Scalars["String"]["output"];
}

export interface PublishFormInput {
  formUUID: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}

export interface Query {
  /**
   * List all available accessRoles by their Scope. The IDs of each AccessRole may be used in mutations that add
   * Workflow Collaborators or that create new Users or Service Accounts.
   */
  accessRoles: Array<AccessRole>;
  /** Retrieve a singular action item, if the user has view permissions to it. */
  actionItem: ActionItem;
  actionItemTemplates: Array<ActionItemTemplate>;
  actionItemTemplatesAvailableAsStartAfterDependency: Array<ActionItemTemplate>;
  /** @deprecated superceded by myActionItems */
  actionItems: Array<ActionItem>;
  activityAggregations?: Maybe<ActivityAggregation>;
  /** The People table under the Admin tab */
  adminPeopleTable: AdminPeopleTableResponse;
  /** Use this for the numbers in the tabs of the action item inbox screen, and the view ephemeral tab screen */
  aggregateActionItemsByCategory?: Maybe<AggregateActionItemsByCategoryResponse>;
  /**
   * Use this to return the tab names for each tag type, and the number that should be displayed in each tab
   * It also returns the table for a given tab (tag type) if requested
   */
  aggregateHomePage?: Maybe<AggregateHomePageResponse>;
  aggregateHomePageByTeam?: Maybe<AggregateHomePageByTeamResponse>;
  /** Displays the table for home page, Individuals tab */
  aggregateHomePageByUser?: Maybe<AggregateHomePageByUserResponse>;
  /**
   * Use this to return the tab names for each tag type, and the number that should be displayed in each tab
   * It also returns the table for a given tab (tag type) if requested
   */
  aggregateHomePageTab?: Maybe<AggregateHomePageTabResponse>;
  aggregateWorkflowDocuments?: Maybe<AggregateWorkflowDocumentsResponse>;
  aggregateWorkflowReportForTag?: Maybe<AggregateWorkflowReportForTagResponse>;
  aggregateWorkflowTemplateDocuments?: Maybe<AggregateWorkflowTemplateDocumentsResponse>;
  /** Retrieves similar task IDs for a given task ID. */
  aiAgentLearningSimilarActionItems: AiAgentLearningSimilarActionItemsResponse;
  /** List allowed domains for the current tenant */
  allowedDomainsForTenant: Array<Scalars["String"]["output"]>;
  apiHealthCheckResults?: Maybe<ApiHealthCheckResultsResponse>;
  approvalAttemptsForActionItem?: Maybe<Array<ApprovalAttempt>>;
  /** Find standalone automation templates that can be used to create automated tasks */
  automationTemplates: AutomationTemplatesResponse;
  /** Checks the status of a blueprint import. */
  blueprintAsyncImportStatus: BlueprintImportJob;
  /**
   * Returns all non-draft versions (i.e., workflow templates of type `COMPANY` and `PREVIOUS_VERSION`)
   * of a blueprint as specified by the ID of the latest published version. Requires read permissions
   * on the latest published version. The returned versions are ordered by version number in descending
   * order.
   */
  blueprintVersions: BlueprintVersionsResponse;
  boxFolders: Array<BoxFolder>;
  bulkStartWorkflowsJobStatus?: Maybe<BulkStartWorkflowsJobStatusResponse>;
  calendarSettings: CalendarSettings;
  controllerFields: Array<Field>;
  currentTenant?: Maybe<Tenant>;
  currentUser?: Maybe<User>;
  customColumnsState?: Maybe<CustomColumnsStateResponse>;
  /**
   * Returns base references for all distinct blueprints (workflow templates of type "COMPANY" and
   * "MINE") the requesting user can discover (i.e., see their name and ID but no internal data). Such
   * blueprints include any the user explicitly has at least read permissions on and the blueprints of
   * any workflows the user has access to, or all blueprints if the user is an admin.
   */
  discoverableBlueprints: Array<WorkflowTemplateBaseReference>;
  documentTypes: Array<DocumentType>;
  documentsV2: DocumentsV2Response;
  docusignDraftFieldInstances: Array<FieldInstance>;
  docusignTemplates: Array<DocusignTemplate>;
  /** Exports blueprint data in a form that can be later imported into a new workspace. */
  exportBlueprint: ExportBlueprintResponse;
  fieldInstanceSourcesForActionItem?: Maybe<FieldInstanceSourcesForActionItem>;
  fieldInstancesAvailableAsSourceValuesInWorkflow?: Maybe<FieldInstancesAvailableAsSourceValues>;
  fieldInstancesAvailableAsSourceValuesInWorkflowTemplate?: Maybe<FieldInstancesAvailableAsSourceValues>;
  fieldInstancesForFormInstance: Array<FieldInstance>;
  fieldUnits: Array<FieldUnit>;
  fields: Array<Field>;
  /**
   * Query for all fields in Regrello. A Field is the schema definition for a FieldInstance. It defines
   * the validation and type of data that will be stored in a FieldInstance.
   */
  fieldsV2: FieldsV2Response;
  /**
   * Flatfile (the 3rd-party service we use to upload CSVs to bulk-start workflows from a given
   * workflow template) has subpar security posture for its V2 "Portal" offering, such that we can't
   * authenticate an upload job with a token of any kind. This means that if our license key lives on
   * the frontend, anyone who inspects our client code will be able to upload potentially malevolent
   * data to our Flatfile account, which increases our surface area for security exploits.
   *
   * As a safeguard, we store the Flatfile license key on the backend and offer this query to return
   * that and other Flatfile-specific information. This ensures that only authenticated users will ever
   * be able to see these values, but it still doesn't prevent a bad actor with an account from
   * uploading data willy-nilly to Flatfile, or from sharing that key with someone else.
   *
   * If we continue to use Flatfile, we hope to move to their V3 "Embed" offering as soon as it can
   * support the more powerful feature set that V2 offers. As of this writing (May 4, 2022), V3 cannot
   * support our needs by itself, so we're stuck on the less secure V2.
   *
   * For more information, talk to Derek Hanson or Chris Lewis.
   */
  flatfileInfo?: Maybe<FlatfileInfoResponse>;
  formFieldConstraintRules: Array<FormFieldConstraintRule>;
  formFieldsAppearingBeforeFormField?: Maybe<Array<FormField>>;
  formFieldsAppearingBeforeFormSection?: Maybe<Array<FormField>>;
  formInstancesForActionItem?: Maybe<ActionItemWithFormInstancesResponse>;
  formInstancesForActionItemTemplate?: Maybe<ActionItemTemplateWithFormInstancesResponse>;
  formSectionConstraintRules: Array<FormSectionConstraintRule>;
  formVersionPreviewFormInstance?: Maybe<FormVersionPreviewFormInstanceResponse>;
  forms: Array<Form>;
  formsForWorkflow: Array<Form>;
  formsForWorkflowTemplate: Array<Form>;
  generateGranularAccessLinkforIntegration?: Maybe<GenerateGranularAccessLinkforIntegrationResponse>;
  /** Generates an oauth state for a current user and a given integration */
  generateOauthStateForIntegration?: Maybe<GenerateOauthStateForIntegrationResponse>;
  /**
   * Returns the status of a spectrum form generation job with the given UUID.
   * @deprecated use getAppGenFormJobStatusV2
   */
  getAppGenFormJobStatus?: Maybe<AppGenStatusResponse>;
  /** Returns the status of a spectrum form generation job with the given UUID. */
  getAppGenFormJobStatusV2?: Maybe<AppGenStatusResponse>;
  /**
   * Returns the status of a blueprint generation job with the given UUID.
   * @deprecated use getAppGenJobStatusV2
   */
  getAppGenJobStatus?: Maybe<AppGenStatusResponse>;
  /** Returns the status of a blueprint generation job with the given UUID. */
  getAppGenJobStatusV2?: Maybe<AppGenStatusResponse>;
  getPartyAccessCodeUUIDsForIntegration?: Maybe<GetPartyAccessCodeUuiDsForIntegrationResponse>;
  /** @deprecated Please use getScimCredentialsV2. */
  getScimCredentials: ScimCredentialsResponse;
  getUserViews: Array<View>;
  /** Returns `true` if the current user has a non-null value in the `privacy_acknowledged_at` column. */
  hasCurrentUserAcknowledgedPrivacyPolicy: Scalars["Boolean"]["output"];
  /** A map of the number of unread notifications for each tenant that the current user is a member of. */
  incubating_notificationCountsByTenant: Array<TenantNotificationCount>;
  /**
   * A list of all notifications for the current user, including both all unread and some unread
   * notifications. Will be sorted in descending chronological order (i.e., newest to oldest).
   *
   * ___Note:___ This is labeled as "incubating" because it is not yet clear if this is the final form
   * of the API. When the API settles, the "incubating" prefix will be removed, forcing clients to
   * peruse the final API and update calls accordingly.
   */
  incubating_notifications: NotificationsResponse;
  integrations?: Maybe<Array<Integration>>;
  /** Retrieve company blueprints that reference a given integration connection. */
  integrationsByParams: IntegrationsByParamsResponse;
  /** Checks if the email exists and belongs to any user in the workspace. */
  isEmailUnique: IsEmailUniqueResponse;
  jobs: Array<Job>;
  latestFormVersion: FormVersion;
  latestSpectrumFieldVersionsV2: LatestSpectrumFieldVersionsV2Response;
  listDetachedFieldInstances: Array<FieldInstance>;
  /**
   * The inputs required for loading table data.
   * The inputs require viewport information so that the data is correctly paginated and to limit the quantity of data
   * being fetched and returned.
   *
   * The viewport size requested in the input is not guaranteed to be the size of the data that is returned.
   * The backend reserves the right to limit the pagination window to return fewer rows than requested.
   */
  loadTable?: Maybe<TabularResponse>;
  /**
   * If ViewStateInput is null then the view is loaded based on the stored data otherwise it is loaded based on the
   * ViewStateInput
   *
   * Any input timestamp should be expressed in UTC.
   */
  loadView: LoadViewResponse;
  /**
   * Find action items that intersect between the initiating party and the requested party.
   *
   * This may include standalone tasks assigned to or from the given party, or tasks that
   * the current user may have view permissions over due to workflow-level permissions or
   * otherwise.
   */
  myActionItems: MyActionItemsResponse;
  myTeams: Array<Team>;
  /** The Non-admin people and teams table */
  nonAdminPeopleAndTeamsTable: NonAdminPeopleAndTeamsTableResponse;
  /** Retrieves NotificationCards matching the filter criteria. */
  notificationCards?: Maybe<NotificationCardsResponse>;
  notificationSettings?: Maybe<NotificationSettings>;
  organizations: Array<Organization>;
  /**
   * Search across all workflow templates in the system that the requesting user has access to.
   *
   * If limit is unspecified, all workflow templates (following `offset`) are returned. If `offset`
   * is unspecified, the default value is zero.
   */
  paginatedWorkflowTemplates: PaginatedWorkflowTemplatesResponse;
  /**
   * Search across all workflow templates in the system that the requesting user has access to,
   * filtered by the specified inputs.
   */
  paginatedWorkflowTemplatesV2?: Maybe<PaginatedWorkflowTemplatesResponse>;
  /**
   * Retrieve all action item templates and stages in a workflow that start before a given action item
   * template and or stage
   */
  parentActionItemsAndStagesInWorkflow?: Maybe<ParentActionItemsAndStagesInWorkflowResponse>;
  /**
   * Retrieve all action item templates and stages in a workflow template that start before a given
   * action item template and or stage template
   */
  parentActionItemsAndStagesInWorkflowTemplate?: Maybe<ParentActionItemsAndStagesInWorkflowTemplateResponse>;
  /**
   * Fetch all parties, optionally filtered by ID or by a search string. The search string will do a
   * partial match on Name or Email.
   */
  parties: Array<Party>;
  permissionedNavigationFlags: PermissionedNavigationFlags;
  /**
   * Similar to the workflowTemplates query, but filters the results by workflow templates that the
   * user has at least one of the specified permissions to. The result is always filtered by blueprints
   * the current user can read.
   */
  permissionedWorkflowTemplates: Array<WorkflowTemplate>;
  propertyTypes: Array<PropertyType>;
  regrelloObjectInstances?: Maybe<RegrelloObjectInstancesResponse>;
  regrelloObjects: Array<RegrelloObject>;
  /**
   * Retrieve all relations held by all subjects on a specific resource. For instance, a party
   * may be given "co-owner" access to a blueprint with ID 2. In this case, querying for relations
   * on blueprint ID 2 will return a list with one element, representing this binding.
   */
  relations: RelationsQueryResponse;
  retrieveApiHealthCheckResultsCsvDownloadUrl?: Maybe<RetrieveApiHealthCheckResultsCsvDownloadUrlResponse>;
  retrieveDataExporterCsvDownloadUrl?: Maybe<RetrieveDataExporterCsvDownloadUrlResponse>;
  retrieveDocumentDownloadUrl?: Maybe<RetrieveDocumentDownloadUrlResponse>;
  retrieveRegrelloObjectSyncCsvReportDownloadUrl?: Maybe<RetrieveRegrelloObjectSyncCsvReportDownloadUrlResponse>;
  retrieveTaskViewCsvDownloadUrl?: Maybe<RetrieveTaskViewCsvDownloadUrlResponse>;
  retrieveViewCsvDownloadUrl?: Maybe<RetrieveViewCsvDownloadUrlResponse>;
  retrieveWorkflowTemplateBlankCsvDownloadUrl?: Maybe<RetrieveWorkflowTemplateBlankCsvDownloadUrlResponse>;
  retrieveWorkflowsCsvDownloadUrl?: Maybe<RetrieveWorkflowsCsvDownloadUrlResponse>;
  /**
   * Returns roles against a name query string. Endpoint prohibited to external users because they
   * should not be able to read all roles in the workspace.
   */
  roleSelectorQueryResults: RoleSelectorQueryResultsResponse;
  roles: RolesQueryResponse;
  /**
   * Returns the SCIM configuration settings for the service-account user in the current tenant. This is available to users
   * of all permission levels.
   */
  scimConfiguration: ScimConfigurationResponse;
  scimCredentialsV2: ScimCredentialsResponse;
  /**
   * List all custom attributes in the tenant
   * @deprecated Please use scimCustomAttributesV2.
   */
  scimCustomAttributes?: Maybe<Array<ScimCustomAttribute>>;
  scimCustomAttributesV2?: Maybe<Array<ScimCustomAttribute>>;
  /** @deprecated Please use scimLatestSyncEventHistoryV2. */
  scimLatestSyncEventHistory: ScimSyncEventHistoryResponse;
  scimLatestSyncEventHistoryV2: ScimSyncEventHistoryResponse;
  search: Array<SearchResult>;
  /** Searches a data source for a given term using keyword search. */
  searchDataSourceKeyword: SearchDataSourceResponse;
  /** Searches a data source for a given term using semantic search. */
  searchDataSourceSemantic: SearchDataSourceResponse;
  /** Typesense search. */
  searchV2: Array<SearchResult>;
  /** Typesense search featuring MultiSearch. */
  searchV3: SearchResponse;
  /**
   * Retrieve a list of folders from a SharePoint site.
   * <parentFolder> is an encoded path to parent folder.
   * If no <parentFolder> provided then root folders are returned.
   * Provide <parentFolder> in order to retrieve subfolders.
   * <parentFolder> example: "%2Fsites%2FAlexTest%2FShared%20Documents"
   */
  sharePointFolders: Array<SharePointFolder>;
  spectrumFieldConstraints: Array<SpectrumFieldConstraint>;
  spectrumFieldValidationTypes: Array<SpectrumFieldValidationType>;
  /** Returns all versions of the specified Spectrum Field, in ascending chronological order. */
  spectrumFieldVersions: Array<SpectrumFieldVersion>;
  /**
   * Returns the status of the Spectrum field initialization job in the current workspace. This job
   * can be started via the `initializeSpectrum` mutation.
   */
  spectrumInitializationStatus?: Maybe<SpectrumInitializationStatusResponse>;
  spectrumValueConstraints: Array<SpectrumValueConstraint>;
  /** List SQL Instances for creating new tenants */
  sqlInstances: Array<Scalars["String"]["output"]>;
  /** Returns all content that the current user has starred. */
  stars: StarsResponse;
  tagTypes: Array<TagType>;
  /** @deprecated Use tagsV2 instead */
  tags: Array<Tag>;
  tagsV2: TagsV2Response;
  /**
   * Returns whether the current tenant has configured task 'Requested information' to show first in
   * task-detail views, before any 'Inherited' information. If this is not provided, the default is to
   * show 'Inherited' information first.
   */
  taskInformationOrderSetting: TaskInformationOrderSettingResponse;
  /**
   * Returns a short list of the most relevant teams that match the provided search criteria, sorted in
   * descending order of relevance.
   */
  teamSelectorQueryResults?: Maybe<TeamSelectorQueryResultsResponse>;
  teams: Array<Team>;
  tenantIdFromActionItemUuid: TenantIdFromActionItemUuidResponse;
  tenants: Array<Tenant>;
  testAutomationRequests: Array<TestAutomationResponse>;
  /** Get user audit history. */
  userAuditHistory?: Maybe<Array<UserAuditHistory>>;
  /**
   * Returns a short list of the most relevant users that match the provided search criteria, sorted in
   * descending order of relevance.
   */
  userSelectorQueryResults?: Maybe<UserSelectorQueryResultsResponse>;
  /** Query for all visible users, optionally filtered by ID to find a single user. */
  users: Array<User>;
  /** Validates that the blueprint is exportable. Can be called before attempting export. */
  validateCanExportBlueprint: ValidateCanExportBlueprintResponse;
  /** Validate that the input is importable. Can be called before attempting import. */
  validateCanImportBlueprint: ValidateCanImportBlueprintResponse;
  /**
   * Validates that the specified Workflow Template can be published with its current name. Fails if
   * the template is not of type "MINE" or "DRAFT".
   */
  validateCanPublishWorkflowTemplateName: ValidateCanPublishWorkflowTemplateNameResponse;
  /** <validateWorkflow> query is deprecated. Use Workflow's <isEligibleToStart> field instead. */
  validateWorkflow?: Maybe<ValidateWorkflowResponse>;
  validateWorkflowName?: Maybe<ValidateWorkflowNameResponse>;
  validateWorkflowStageStartAfterWorkflowStageIds?: Maybe<UpdateWorkflowStageStartAfterWorkflowStageIdsResponse>;
  validateWorkflowTemplateCreateViaEmail?: Maybe<ValidateWorkflowTemplateCreateViaEmailResponse>;
  /**
   * For a standard blueprint version, returns the latest published version of
   * associated variants.
   */
  variantBlueprints: Array<VariantBlueprintReference>;
  viewFields: ViewFieldsResponse;
  workatoIntegrationConnections: Array<WorkatoIntegrationConnection>;
  /**
   * Retrieve WorkatoIntegrationPackage records.
   * Only Auth Admin has access to its data.
   */
  workatoIntegrationPackages: Array<WorkatoIntegrationPackage>;
  /**
   * Returns the parties that are participating in a given workflow, with grouped counts by task
   * involvement.
   */
  workflowParticipations: Array<WorkflowParticipation>;
  workflowStageTemplatesAvailableAsStartAfterDependency: Array<WorkflowStageTemplate>;
  workflowStagesAvailableAsStartAfterDependency: Array<WorkflowStage>;
  /**
   * Retrieve all action item template field instances in a workflow template that are inheritable
   * (i.e., available to select as source values).
   */
  workflowTemplateAggregateActionItemTemplateInheritableFieldInstances?: Maybe<WorkflowTemplateInheritableFieldInstances>;
  /**
   * Retrieves a list of column identifiers for the columns that will appear in the CSV fetched via
   * `retrieveWorkflowTemplateBlankCsvDownloadUrl`, in the same order. This can be used to parse CSV
   * uploads on the client and map columns back to the native fields and custom fields present on the
   * workflow template in question.
   */
  workflowTemplateBlankCsvColumns?: Maybe<WorkflowTemplateBlankCsvColumnsResponse>;
  /**
   * Returns the parties that are participating in a given workflow template, with grouped counts by
   * task involvement.
   */
  workflowTemplateParticipations: Array<WorkflowTemplateParticipation>;
  workflowTemplates: Array<WorkflowTemplate>;
  /**
   * Used to find workflow templates that cannot be linked to from a given workflow template since doing so would cause a cycle. Returns the workflow template IDs.
   * @deprecated Cycles are now allowed, so this is a noop.
   */
  workflowTemplatesUnavailableToLinkFromWorkflowTemplate: Array<Scalars["ID"]["output"]>;
  /**
   * Returns the set of workflow templates that have been shared with a given role, or otherwise use
   * a given role.
   */
  workflowTemplatesUsingRole: WorkflowTemplatesUsingRoleResponse;
  /**
   * Query for a workflow by ID. It is not recommended to call this without an ID, particularly with
   * many workflow details requested.
   */
  workflows: Array<Workflow>;
  workflowsByName: Array<Workflow>;
  workspaceSettings: WorkspaceSettings;
}

export interface QueryAccessRolesArgs {
  scope: AccessRoleScope;
}

export interface QueryActionItemArgs {
  input: ActionItemInput;
}

export interface QueryActionItemTemplatesArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  integrationId?: InputMaybe<Scalars["ID"]["input"]>;
  onlyStandaloneTemplates?: InputMaybe<Scalars["Boolean"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  types?: InputMaybe<Array<ActionItemType>>;
}

export interface QueryActionItemTemplatesAvailableAsStartAfterDependencyArgs {
  currentActionItemTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  currentWorkflowStageId?: InputMaybe<Scalars["ID"]["input"]>;
  currentWorkflowStageTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryActionItemsArgs {
  assignedByPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  assignedDirectlyTo?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  assignedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  associatedPartyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  ccPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  dueOnClass?: InputMaybe<DueOnClass>;
  dueOnGreaterThan?: InputMaybe<Scalars["Time"]["input"]>;
  dueOnLessThan?: InputMaybe<Scalars["Time"]["input"]>;
  filterConnective?: InputMaybe<ConditionConnective>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  needsApprovalBy?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  notAssociatedPartyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  notStatus?: InputMaybe<ActionItemStatus>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  showCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  showOnlyHasException?: InputMaybe<Scalars["Boolean"]["input"]>;
  showOnlyMuted?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionItemStatus>;
  tagId?: InputMaybe<Scalars["ID"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryActivityAggregationsArgs {
  allowListDomains?: InputMaybe<Array<Scalars["String"]["input"]>>;
  denyListDomains?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startDate: Scalars["Time"]["input"];
}

export interface QueryAdminPeopleTableArgs {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<SortOrder>;
}

export interface QueryAggregateActionItemsByCategoryArgs {
  associatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  checkboxFilters?: InputMaybe<Array<ActionItemCheckboxFilter>>;
  dueOnClass?: InputMaybe<DueOnClass>;
  dueOnGreaterThan?: InputMaybe<Scalars["Time"]["input"]>;
  dueOnLessThan?: InputMaybe<Scalars["Time"]["input"]>;
  showCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  tagId?: InputMaybe<Scalars["ID"]["input"]>;
  viewingPartyId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryAggregateHomePageArgs {
  associatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  notAssociatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  tagTypeId: Scalars["ID"]["input"];
}

export interface QueryAggregateHomePageByTeamArgs {
  associatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  filterConnective?: InputMaybe<ConditionConnective>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  notAssociatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface QueryAggregateHomePageByUserArgs {
  associatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  filterConnective?: InputMaybe<ConditionConnective>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  notAssociatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface QueryAggregateHomePageTabArgs {
  associatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  notAssociatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  tagTypeId: Scalars["ID"]["input"];
}

export interface QueryAggregateWorkflowDocumentsArgs {
  showCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflowId?: InputMaybe<Scalars["ID"]["input"]>;
  workflowReferenceNumber?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryAggregateWorkflowReportForTagArgs {
  tagFilters?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  tagId: Scalars["ID"]["input"];
  workflowTemplateIds: Array<Scalars["ID"]["input"]>;
}

export interface QueryAggregateWorkflowTemplateDocumentsArgs {
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface QueryAiAgentLearningSimilarActionItemsArgs {
  input: AiAgentLearningSimilarActionItemsInput;
}

export interface QueryApiHealthCheckResultsArgs {
  apiHealthCheckConfigId: Scalars["ID"]["input"];
}

export interface QueryApprovalAttemptsForActionItemArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryAutomationTemplatesArgs {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryBlueprintAsyncImportStatusArgs {
  importUUID: Scalars["UUID"]["input"];
}

export interface QueryBlueprintVersionsArgs {
  input: BlueprintVersionsInput;
}

export interface QueryBoxFoldersArgs {
  folderId?: InputMaybe<Scalars["String"]["input"]>;
  integrationAuthenticationId: Scalars["ID"]["input"];
}

export interface QueryBulkStartWorkflowsJobStatusArgs {
  jobId: Scalars["ID"]["input"];
}

export interface QueryControllerFieldsArgs {
  fieldId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryCustomColumnsStateArgs {
  columnCustomizationTableSpecifier: ColumnCustomizationTableSpecifier;
  tableType: TableType;
}

export interface QueryDiscoverableBlueprintsArgs {
  input: DiscoverableBlueprintsInput;
}

export interface QueryDocumentTypesArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryDocumentsV2Args {
  advancedFilterInputs: DocumentAdvancedFilterInputs;
  associatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  showCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  sortBy?: InputMaybe<DocumentDefaultColumnOption>;
  sortOrder?: InputMaybe<SortOrder>;
}

export interface QueryDocusignDraftFieldInstancesArgs {
  workflowId?: InputMaybe<Scalars["ID"]["input"]>;
  workflowTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryDocusignTemplatesArgs {
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  includeHiddenInputs?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeHiddenOutputs?: InputMaybe<Scalars["Boolean"]["input"]>;
  integrationAutomationInstanceId: Scalars["ID"]["input"];
}

export interface QueryExportBlueprintArgs {
  input: ExportBlueprintInput;
}

export interface QueryFieldInstanceSourcesForActionItemArgs {
  actionItemId: Scalars["ID"]["input"];
}

export interface QueryFieldInstancesAvailableAsSourceValuesInWorkflowArgs {
  currentWorkflowStageId?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnActionItemTemplateID?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnWorkflowStageID?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnWorkflowStageIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workflowId: Scalars["ID"]["input"];
}

export interface QueryFieldInstancesAvailableAsSourceValuesInWorkflowTemplateArgs {
  currentWorkflowTemplateStageId?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnActionItemTemplateID?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnWorkflowTemplateStageID?: InputMaybe<Scalars["ID"]["input"]>;
  dependingOnWorkflowTemplateStageIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface QueryFieldInstancesForFormInstanceArgs {
  formInstanceUUID: Scalars["String"]["input"];
}

export interface QueryFieldUnitsArgs {
  fieldUnitType?: InputMaybe<FieldUnitType>;
}

export interface QueryFieldsArgs {
  columnCustomizationTableSpecifier?: InputMaybe<ColumnCustomizationTableSpecifier>;
  fieldId?: InputMaybe<Scalars["ID"]["input"]>;
  fieldTypes?: InputMaybe<Array<InputMaybe<FieldType>>>;
}

export interface QueryFieldsV2Args {
  columnCustomizationTableSpecifier?: InputMaybe<ColumnCustomizationTableSpecifier>;
  fieldId?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<CustomFieldDefaultColumnOption>;
  sortOrder?: InputMaybe<SortOrder>;
}

export interface QueryFormFieldsAppearingBeforeFormFieldArgs {
  formFieldUUID: Scalars["String"]["input"];
}

export interface QueryFormFieldsAppearingBeforeFormSectionArgs {
  formSectionUUID: Scalars["String"]["input"];
}

export interface QueryFormInstancesForActionItemArgs {
  actionItemUUID: Scalars["UUID"]["input"];
}

export interface QueryFormInstancesForActionItemTemplateArgs {
  actionItemTemplateUUID: Scalars["UUID"]["input"];
}

export interface QueryFormVersionPreviewFormInstanceArgs {
  formVersionUUID: Scalars["String"]["input"];
}

export interface QueryFormsArgs {
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface QueryFormsForWorkflowArgs {
  workflowID: Scalars["ID"]["input"];
}

export interface QueryFormsForWorkflowTemplateArgs {
  workflowTemplateID: Scalars["ID"]["input"];
}

export interface QueryGenerateGranularAccessLinkforIntegrationArgs {
  actionItemUuid?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryGenerateOauthStateForIntegrationArgs {
  integrationId: Scalars["ID"]["input"];
}

export interface QueryGetAppGenFormJobStatusArgs {
  jobUUID: Scalars["String"]["input"];
}

export interface QueryGetAppGenFormJobStatusV2Args {
  jobUUID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryGetAppGenJobStatusArgs {
  jobUUID: Scalars["String"]["input"];
}

export interface QueryGetAppGenJobStatusV2Args {
  jobUUID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryGetPartyAccessCodeUuiDsForIntegrationArgs {
  actionItemUuid: Scalars["String"]["input"];
  partyID: Scalars["ID"]["input"];
}

export interface QueryGetScimCredentialsArgs {
  tenantId: Scalars["ID"]["input"];
}

export interface QueryIntegrationsArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryIntegrationsByParamsArgs {
  inputs: IntegrationsByParamsInput;
}

export interface QueryIsEmailUniqueArgs {
  email: Scalars["String"]["input"];
}

export interface QueryJobsArgs {
  createdByUserId?: InputMaybe<Scalars["ID"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  statuses?: InputMaybe<Array<JobStatus>>;
  types?: InputMaybe<Array<JobType>>;
}

export interface QueryLatestFormVersionArgs {
  formUUID: Scalars["String"]["input"];
}

export interface QueryLatestSpectrumFieldVersionsV2Args {
  filters?: InputMaybe<LatestSpectrumFieldVersionsV2ColumnFilters>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  params?: InputMaybe<LatestSpectrumFieldVersionsV2Params>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<CustomFieldDefaultColumnOption>;
  sortOrder?: InputMaybe<SortOrder>;
}

export interface QueryListDetachedFieldInstancesArgs {
  detachedFieldInstanceValueId?: InputMaybe<Scalars["ID"]["input"]>;
  fieldId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryLoadTableArgs {
  tabularFieldInstanceValueHeaderIds: Array<Scalars["ID"]["input"]>;
  tabularViewport: TabularViewport;
}

export interface QueryLoadViewArgs {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  viewId: Scalars["ID"]["input"];
  viewState?: InputMaybe<ViewStateInput>;
}

export interface QueryMyActionItemsArgs {
  input: MyActionItemsInput;
}

export interface QueryNonAdminPeopleAndTeamsTableArgs {
  isTeamsQuery: Scalars["Boolean"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  sortBy: NonAdminPeopleAndTeamsDefaultColumnOption;
  sortOrder: SortOrder;
  teamId?: InputMaybe<Scalars["ID"]["input"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
}

export interface QueryNotificationCardsArgs {
  filters: NotificationCardsFilterInputs;
}

export interface QueryOrganizationsArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryPaginatedWorkflowTemplatesArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  permissions?: InputMaybe<Array<WorkflowTemplateAction>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  types?: InputMaybe<Array<WorkflowTemplateType>>;
  usingAutomationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  usingFieldIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface QueryPaginatedWorkflowTemplatesV2Args {
  input: PaginatedWorkflowTemplatesInput;
}

export interface QueryParentActionItemsAndStagesInWorkflowArgs {
  input: ParentActionItemsAndStagesInWorkflowInput;
}

export interface QueryParentActionItemsAndStagesInWorkflowTemplateArgs {
  input: ParentActionItemsAndStagesInWorkflowTemplateInput;
}

export interface QueryPartiesArgs {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryPermissionedNavigationFlagsArgs {
  userId: Scalars["ID"]["input"];
}

export interface QueryPermissionedWorkflowTemplatesArgs {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  permissions?: InputMaybe<Array<WorkflowTemplateAction>>;
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  types?: InputMaybe<Array<WorkflowTemplateType>>;
  usingFieldIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface QueryRegrelloObjectInstancesArgs {
  fieldInstanceId?: InputMaybe<Scalars["ID"]["input"]>;
  filters?: InputMaybe<Array<RegrelloObjectInstanceSearchFilterInput>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  queryV2?: InputMaybe<Scalars["Boolean"]["input"]>;
  regrelloObjectId: Scalars["ID"]["input"];
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  sortByPropertyId?: InputMaybe<Scalars["Int"]["input"]>;
  sortOrder?: InputMaybe<SortOrder>;
  totalCountOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface QueryRegrelloObjectsArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  objectTypes?: InputMaybe<Array<RegrelloObjectType>>;
}

export interface QueryRelationsArgs {
  input: RelationsQueryInput;
}

export interface QueryRetrieveApiHealthCheckResultsCsvDownloadUrlArgs {
  apiHealthCheckConfigId: Scalars["ID"]["input"];
}

export interface QueryRetrieveDataExporterCsvDownloadUrlArgs {
  dateTimeFormat?: InputMaybe<CsvExportDateTimeFormat>;
}

export interface QueryRetrieveDocumentDownloadUrlArgs {
  documentVersionId: Scalars["ID"]["input"];
}

export interface QueryRetrieveRegrelloObjectSyncCsvReportDownloadUrlArgs {
  regrelloObjectSyncEventId: Scalars["ID"]["input"];
}

export interface QueryRetrieveTaskViewCsvDownloadUrlArgs {
  dateTimeFormat?: InputMaybe<CsvExportDateTimeFormat>;
  viewId: Scalars["ID"]["input"];
  viewState?: InputMaybe<ViewStateInput>;
}

export interface QueryRetrieveViewCsvDownloadUrlArgs {
  dateTimeFormat?: InputMaybe<CsvExportDateTimeFormat>;
  isDataExporter: Scalars["Boolean"]["input"];
  viewId: Scalars["ID"]["input"];
  viewState?: InputMaybe<ViewStateInput>;
}

export interface QueryRetrieveWorkflowTemplateBlankCsvDownloadUrlArgs {
  dateTimeFormat?: InputMaybe<CsvExportDateTimeFormat>;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface QueryRetrieveWorkflowsCsvDownloadUrlArgs {
  dateTimeFormat?: InputMaybe<CsvExportDateTimeFormat>;
  workflowIds: Array<Scalars["ID"]["input"]>;
}

export interface QueryRoleSelectorQueryResultsArgs {
  input?: InputMaybe<RoleSelectorQueryResultsInput>;
}

export interface QueryRolesArgs {
  input: RolesQueryInput;
}

export interface QueryScimCustomAttributesArgs {
  tenantId: Scalars["ID"]["input"];
}

export interface QueryScimLatestSyncEventHistoryArgs {
  tenantId: Scalars["ID"]["input"];
}

export interface QuerySearchArgs {
  associatedPartyId?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  term: Scalars["String"]["input"];
}

export interface QuerySearchDataSourceKeywordArgs {
  input: SearchDataSourceInput;
}

export interface QuerySearchDataSourceSemanticArgs {
  input: SearchDataSourceInput;
}

export interface QuerySearchV2Args {
  input: SearchInput;
}

export interface QuerySearchV3Args {
  input: SearchInput;
}

export interface QuerySharePointFoldersArgs {
  integrationAuthenticationId: Scalars["ID"]["input"];
  parentFolder?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QuerySpectrumFieldVersionsArgs {
  spectrumFieldUUID: Scalars["UUID"]["input"];
}

export interface QuerySpectrumInitializationStatusArgs {
  jobID: Scalars["ID"]["input"];
}

export interface QueryTagTypesArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface QueryTagsArgs {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryTagsV2Args {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<TagDefaultColumnOption>;
  sortOrder?: InputMaybe<SortOrder>;
}

export interface QueryTeamSelectorQueryResultsArgs {
  input?: InputMaybe<TeamSelectorQueryResultsInput>;
}

export interface QueryTeamsArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryTenantIdFromActionItemUuidArgs {
  actionItemUuid: Scalars["String"]["input"];
}

export interface QueryTenantsArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface QueryTestAutomationRequestsArgs {
  inputs: Array<TestAutomationRequestInput>;
}

export interface QueryUserAuditHistoryArgs {
  input: UserAuditHistoryQueryInput;
}

export interface QueryUserSelectorQueryResultsArgs {
  input?: InputMaybe<UserSelectorQueryResultsInput>;
}

export interface QueryUsersArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface QueryValidateCanExportBlueprintArgs {
  input: ExportBlueprintInput;
}

export interface QueryValidateCanImportBlueprintArgs {
  input: ImportBlueprintInput;
}

export interface QueryValidateCanPublishWorkflowTemplateNameArgs {
  id: Scalars["ID"]["input"];
}

export interface QueryValidateWorkflowArgs {
  id: Scalars["ID"]["input"];
  startImmediately: Scalars["Boolean"]["input"];
}

export interface QueryValidateWorkflowNameArgs {
  name: Scalars["String"]["input"];
}

export interface QueryValidateWorkflowStageStartAfterWorkflowStageIdsArgs {
  id: Scalars["ID"]["input"];
  startAfterWorkflowStageIds: Array<Scalars["ID"]["input"]>;
}

export interface QueryValidateWorkflowTemplateCreateViaEmailArgs {
  email: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
}

export interface QueryVariantBlueprintsArgs {
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface QueryViewFieldsArgs {
  viewId: Scalars["ID"]["input"];
  viewState?: InputMaybe<ViewStateInput>;
}

export interface QueryWorkatoIntegrationConnectionsArgs {
  integrationType?: InputMaybe<IntegrationType>;
}

export interface QueryWorkatoIntegrationPackagesArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  packageType?: InputMaybe<WorkatoIntegrationPackageType>;
  workatoPackageId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryWorkflowParticipationsArgs {
  input: WorkflowParticipationInput;
}

export interface QueryWorkflowStageTemplatesAvailableAsStartAfterDependencyArgs {
  currentWorkflowStageTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface QueryWorkflowStagesAvailableAsStartAfterDependencyArgs {
  currentWorkflowStageId?: InputMaybe<Scalars["ID"]["input"]>;
  workflowId: Scalars["ID"]["input"];
}

export interface QueryWorkflowTemplateAggregateActionItemTemplateInheritableFieldInstancesArgs {
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface QueryWorkflowTemplateBlankCsvColumnsArgs {
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface QueryWorkflowTemplateParticipationsArgs {
  input: WorkflowTemplateParticipationInput;
}

export interface QueryWorkflowTemplatesArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  permissions?: InputMaybe<Array<WorkflowTemplateAction>>;
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  types?: InputMaybe<Array<WorkflowTemplateType>>;
  usingAutomationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  usingFieldIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface QueryWorkflowTemplatesUnavailableToLinkFromWorkflowTemplateArgs {
  id: Scalars["ID"]["input"];
}

export interface QueryWorkflowTemplatesUsingRoleArgs {
  input: WorkflowTemplatesUsingRoleInput;
}

export interface QueryWorkflowsArgs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
}

export interface QueryWorkflowsByNameArgs {
  name: Scalars["String"]["input"];
}

export interface ReadCommentResponse extends Response {
  comment?: Maybe<Comment>;
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface RecalculateActionItemCountsResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface RefreshSearchCollectionForDataSourceInput {
  /** ID of the data source to refresh the search collection for. */
  dataSourceId: Scalars["ID"]["input"];
}

export interface RefreshSearchCollectionForDataSourceResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  job: Job;
}

/** The response type for the regenerateServiceAccountOAuthCredentials mutation. */
export interface RegenerateServiceAccountOAuthCredentialsResponse extends Response {
  /** The OAuth Client ID. */
  clientId?: Maybe<Scalars["String"]["output"]>;
  /** The OAuth Client Secret. It will only be shown here, and it will not be shown again. */
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  /** In the case of an unexpected error, information about the error will be returned in this string. */
  error?: Maybe<Scalars["String"]["output"]>;
  /** The user that owns these credentials, identified by the userId input. */
  user?: Maybe<User>;
}

export interface RegrelloObject {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  /**
   * Relevant for objects of type=SYNC_OBJECT.
   * Contains a datetime of the latest successful data sync.
   */
  dataUpdatedAt?: Maybe<Scalars["Time"]["output"]>;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  properties: Array<RegrelloObjectProperty>;
  syncConfig?: Maybe<RegrelloObjectSyncConfig>;
  syncEvents: Array<RegrelloObjectSyncEvent>;
  totalCount: Scalars["Int"]["output"];
  type: RegrelloObjectType;
  updatedAt: Scalars["Time"]["output"];
}

export interface RegrelloObjectSyncEventsArgs {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface RegrelloObjectChangeEvent {
  changes: Array<RegrelloObjectSyncInstanceChange>;
  createdAt: Scalars["Time"]["output"];
  createdBy?: Maybe<Party>;
}

export interface RegrelloObjectInstanceAuditHistory {
  fields: Array<RegrelloObjectInstanceAuditHistoryDelta>;
  line: Line;
}

export interface RegrelloObjectInstanceAuditHistoryDelta {
  addedRecords: Array<AuditHistoryAddedRecord>;
  addedRecordsV2: Array<AuditHistoryRecord>;
  collectionAdd?: Maybe<Scalars["String"]["output"]>;
  collectionRemove?: Maybe<Scalars["String"]["output"]>;
  field?: Maybe<Field>;
  fieldName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  removedRecords: Array<AuditHistoryRemovedRecord>;
  removedRecordsV2: Array<AuditHistoryRecord>;
  valueAfter?: Maybe<Scalars["String"]["output"]>;
  valueBefore?: Maybe<Scalars["String"]["output"]>;
}

export interface RegrelloObjectInstanceSearchFilterInput {
  regrelloObjectPropertyId: Scalars["ID"]["input"];
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
}

export interface RegrelloObjectInstanceSearchInputs {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  propertyFilters: Array<RegrelloObjectInstanceSearchFilterInput>;
  regrelloObjectId: Scalars["ID"]["input"];
}

export interface RegrelloObjectInstanceV2 {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  dataObjectCells: Array<DataObjectCell>;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
}

export interface RegrelloObjectInstancesAuditHistory {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  event: AuditHistoryEvent;
  fields: Array<RegrelloObjectInstanceAuditHistory>;
  id: Scalars["ID"]["output"];
}

export interface RegrelloObjectInstancesResponse {
  hasMore: Scalars["Boolean"]["output"];
  regrelloObject: RegrelloObject;
  regrelloObjectInstances: Array<RegrelloObjectInstanceV2>;
  totalCount: Scalars["Int"]["output"];
}

export interface RegrelloObjectProperty {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  dataObjectKey: Scalars["String"]["output"];
  displayName?: Maybe<Scalars["String"]["output"]>;
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  isPrimaryKey?: Maybe<Scalars["Boolean"]["output"]>;
  propertyType?: Maybe<PropertyType>;
  updatedAt: Scalars["Time"]["output"];
}

export interface RegrelloObjectPropertyInput {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  originalKeyName: Scalars["String"]["input"];
  propertyTypeId?: InputMaybe<Scalars["ID"]["input"]>;
}

export enum RegrelloObjectSource {
  CSV = "CSV",
  REST_API = "REST_API",
  SNOWFLAKE = "SNOWFLAKE",
}

export interface RegrelloObjectSyncConfig {
  createdAt: Scalars["Time"]["output"];
  httpRequestConfig?: Maybe<AutomationRequest>;
  isSyncEnabled: Scalars["Boolean"]["output"];
  scheduledJob?: Maybe<ScheduledJob>;
  source: RegrelloObjectSource;
  updatedAt: Scalars["Time"]["output"];
}

export interface RegrelloObjectSyncEvent {
  action: SyncEventAction;
  dataSourceUrl?: Maybe<Scalars["String"]["output"]>;
  document?: Maybe<Document>;
  error?: Maybe<Scalars["String"]["output"]>;
  errorType?: Maybe<RegrelloObjectSyncEventErrorType>;
  finishedAt?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  rowsCreated: Scalars["Int"]["output"];
  rowsDeleted: Scalars["Int"]["output"];
  rowsUpdated: Scalars["Int"]["output"];
  startedAt: Scalars["Time"]["output"];
  status: SyncEventStatus;
  triggerType?: Maybe<RegrelloObjectSyncEventTriggerType>;
  triggeredBy: User;
}

export enum RegrelloObjectSyncEventErrorType {
  INVALID_DATA = "INVALID_DATA",
  REST_API_CLIENT_ERROR = "REST_API_CLIENT_ERROR",
  REST_API_SERVER_ERROR = "REST_API_SERVER_ERROR",
  UNSUPPORTED_DATA_SHAPE = "UNSUPPORTED_DATA_SHAPE",
}

export enum RegrelloObjectSyncEventTriggerType {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export interface RegrelloObjectSyncInstanceChange {
  event?: Maybe<AuditHistoryEvent>;
  regrelloObjectId: Scalars["Int"]["output"];
  regrelloObjectInstanceId: Scalars["Int"]["output"];
  valueAfter?: Maybe<Array<DataObjectCell>>;
  valueBefore?: Maybe<Array<DataObjectCell>>;
}

export enum RegrelloObjectType {
  MULTILINE_PO = "MULTILINE_PO",
  SYNC_OBJECT = "SYNC_OBJECT",
}

export interface RejectAction {
  createdAt: Scalars["Time"]["output"];
  entity: RejectActionEntity;
  id: Scalars["ID"]["output"];
}

export interface RejectActionEndWorkflowInput {
  /** The id of the workflow to end. */
  workflowId: Scalars["ID"]["input"];
}

export type RejectActionEntity =
  | RejectActionEntityEndWorkflow
  | RejectActionEntityReopenActionItem
  | RejectActionEntityReportException
  | RejectActionEntityRestartStage
  | RejectActionEntityRestartWorkflow;

export interface RejectActionEntityEndWorkflow {
  workflow?: Maybe<Workflow>;
}

export interface RejectActionEntityReopenActionItem {
  actionItemTemplate: ActionItemTemplate;
}

export interface RejectActionEntityReportException {
  actionItemTemplate: ActionItemTemplate;
}

export interface RejectActionEntityRestartStage {
  workflowStage?: Maybe<WorkflowStage>;
  workflowStageTemplate?: Maybe<WorkflowStageTemplate>;
}

export interface RejectActionEntityRestartWorkflow {
  workflow?: Maybe<Workflow>;
}

export interface RejectActionInput {
  endWorkflowParams?: InputMaybe<RejectActionEndWorkflowInput>;
  /**
   * Only one of the following should be specified, according to the type above. Contains the
   * information needed carry out the specific post-reject action.
   */
  reopenActionItemParams?: InputMaybe<RejectActionReopenActionItemInput>;
  restartStageParams?: InputMaybe<RejectActionRestartStageInput>;
  restartWorkflowParams?: InputMaybe<RejectActionRestartWorkflowInput>;
  /** Specifies the post-reject action to perform. */
  type: RejectActionType;
}

export interface RejectActionItemResponse {
  actionItem?: Maybe<ActionItem>;
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface RejectActionReopenActionItemInput {
  /** The id of the action item template to reopen. */
  actionItemTemplateId: Scalars["ID"]["input"];
}

export interface RejectActionRestartStageInput {
  /** The id of the stage to restart. */
  workflowStageId?: InputMaybe<Scalars["ID"]["input"]>;
  /** The id of the stage template to restart. */
  workflowStageTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface RejectActionRestartWorkflowInput {
  /** The id of the workflow to restart. */
  workflowId: Scalars["ID"]["input"];
}

export enum RejectActionType {
  END_WORKFLOW = "END_WORKFLOW",
  REOPEN_ACTION_ITEM = "REOPEN_ACTION_ITEM",
  REPORT_EXCEPTION = "REPORT_EXCEPTION",
  RESTART_STAGE = "RESTART_STAGE",
  RESTART_WORKFLOW = "RESTART_WORKFLOW",
}

/**
 * A relation binding is a directed, labeled edge from one subject to a resource.
 * The RelationBinding union represents a each possible subject from which said
 * edge may be drawn.
 *
 * A single relation on a resource may expand into a more complete set of discrete
 * permissions.
 */
export type RelationBinding = PartyRelationBinding | RoleRelationBinding;

export interface RelationWorkflowInput {
  /** The relation of this role to the object. For example, 'owner', 'viewer', etc */
  relation: WorkflowRelationType;
  /** The id of the workflow to which the role relation is being added. If unspecified, applies to all workflows. */
  workflowId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface RelationWorkflowTemplateInput {
  /** The relation of this role to the object. For example, 'owner', 'viewer', etc */
  relation: WorkflowTemplateRelationType;
  /** The id of the workflow template to which the role relation is being added. If unspecified, applies to all workflow templates. */
  workflowTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface RelationWorkspaceInput {
  /** The relation of this role to the workspace. For example, 'can_publish', 'admin', etc */
  relation: WorkspaceRelationType;
}

export interface RelationsQueryInput {
  resourceId: Scalars["ID"]["input"];
  type: RelationsQueryType;
}

export interface RelationsQueryResponse {
  relations: Array<RelationBinding>;
}

export enum RelationsQueryType {
  WORKFLOW = "WORKFLOW",
  WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE",
}

export interface RemoveAdminAccessFromUserResponse {
  user: User;
}

export interface RemoveFieldInstanceFromActionItemTemplateResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface RemoveFieldInstanceFromWorkflowResponse {
  workflow: Workflow;
}

export interface RemoveFieldInstanceFromWorkflowTemplateResponse {
  workflowTemplate: WorkflowTemplate;
}

export interface RemoveFieldInstancesFromActionItemTemplateInput {
  /** The id of the action item template to remove field instances from. */
  actionItemTemplateId: Scalars["ID"]["input"];
  /** Input needed to remove any inherited field instances on the action item template. */
  inheritedFieldInstancesToRemove?: InputMaybe<Array<RemoveInheritedFieldInstancesInput>>;
  /** Input needed to remove any requested field instances on the action item template. */
  requestedFieldInstancesToRemove?: InputMaybe<Array<RemoveRequestedFieldInstancesInput>>;
}

export interface RemoveFormFieldConstraintResponse {
  status: Scalars["String"]["output"];
}

export interface RemoveFormFieldsInput {
  formFieldUUIDs: Array<Scalars["String"]["input"]>;
  formSectionUUID: Scalars["String"]["input"];
}

export interface RemoveFormInstanceResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface RemoveFormSectionConstraintResponse {
  status: Scalars["String"]["output"];
}

export interface RemoveInheritedFieldInstancesInput {
  /** The id of the field that the desired field instance to remove belongs to. */
  fieldId: Scalars["ID"]["input"];
  /**
   * The id of the source field instance from which this inherited field instance's value originates
   * from.
   */
  sourceFieldInstanceId: Scalars["ID"]["input"];
}

export interface RemovePartiesFromRoleInput {
  partyIds: Array<Scalars["ID"]["input"]>;
  roleId: Scalars["ID"]["input"];
}

export interface RemovePartiesFromRoleResponse {
  success: Scalars["Boolean"]["output"];
}

export interface RemoveRequestedFieldInstancesInput {
  fieldId: Scalars["ID"]["input"];
}

export enum ReopenActionItemReason {
  MANUAL = "MANUAL",
  REJECTED = "REJECTED",
}

export interface ReopenActionItemResponse {
  actionItem?: Maybe<ActionItem>;
  error?: Maybe<Scalars["String"]["output"]>;
}

/**
 * A relation held on a specific resource, with an optional condition that must be met
 * for the relation to take effect.
 *
 * To retrieve the subject's workspace relations, use the permissionedSidebarContentFlags
 * query.
 */
export type ResourceRelation = WorkflowResourceRelation | WorkflowTemplateResourceRelation;

export interface Response {
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface RestartAutomatedActionItemResponse {
  actionItem?: Maybe<ActionItem>;
}

export enum RestrictedActionConfig {
  WORKFLOW_APPROVALS = "WORKFLOW_APPROVALS",
}

export enum ResultTypeEnum {
  ACTION_ITEM = "ACTION_ITEM",
  DOCUMENT = "DOCUMENT",
  TAG = "TAG",
  USER = "USER",
  WORKFLOW = "WORKFLOW",
  WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE",
}

export interface ResumeApiHealthCheckResponse {
  apiHealthCheckConfig: ApiHealthCheckConfig;
}

export interface ResumeDataSyncForRegrelloObjectResponse {
  regrelloObjectSyncConfig: RegrelloObjectSyncConfig;
}

export interface ResumeWorkflowResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface RetrieveApiHealthCheckResultsCsvDownloadUrlResponse {
  signedUrl: Scalars["String"]["output"];
}

export interface RetrieveDataExporterCsvDownloadUrlResponse {
  signedUrl: Scalars["String"]["output"];
}

export interface RetrieveDocumentDownloadUrlResponse {
  signedUrl?: Maybe<Scalars["String"]["output"]>;
}

export interface RetrieveRegrelloObjectSyncCsvReportDownloadUrlResponse {
  signedUrl: Scalars["String"]["output"];
}

export interface RetrieveTaskViewCsvDownloadUrlResponse {
  signedUrl: Scalars["String"]["output"];
}

export interface RetrieveViewCsvDownloadUrlResponse {
  signedUrl: Scalars["String"]["output"];
}

export interface RetrieveWorkflowTemplateBlankCsvDownloadUrlResponse {
  signedUrl?: Maybe<Scalars["String"]["output"]>;
}

export interface RetrieveWorkflowsCsvDownloadUrlResponse {
  signedUrl: Scalars["String"]["output"];
}

export interface Role {
  /** Whether this role gives its members the ability to invite new users to the workspace. */
  canInviteUsersToWorkspace: Scalars["Boolean"]["output"];
  createdAt: Scalars["Time"]["output"];
  /** The user who made this role, if it is not a system role. */
  createdByUser?: Maybe<User>;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  isSystem: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parties: Array<Party>;
  /** The SCIM ID of this role, if it is a SCIM role. */
  scimId?: Maybe<Scalars["String"]["output"]>;
  /** The spectrum field version corresponding to this role */
  spectrumFieldVersion?: Maybe<SpectrumFieldVersion>;
  updatedAt: Scalars["Time"]["output"];
}

export interface RoleReference {
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
}

export interface RoleRelationBinding {
  relation: ResourceRelation;
  role: Role;
}

export interface RoleSelectorQueryResultsInput {
  /** The query text to search for. */
  query: Scalars["String"]["input"];
  /** An optional list of party ids to prioritize in the search results. */
  roleIdsToPrioritize?: InputMaybe<Array<Scalars["Int"]["input"]>>;
}

export interface RoleSelectorQueryResultsResponse {
  /**
   * An array of the most relevant roles matching the provided search criteria, sorted in descending
   * order of relevance. Up to 25 results will be returned.
   */
  roles: Array<Role>;
}

export enum RolesDefaultColumnOption {
  ADDED_BY = "ADDED_BY",
  DATE_ADDED = "DATE_ADDED",
  NAME = "NAME",
  PEOPLE_ASSIGNED = "PEOPLE_ASSIGNED",
  SCIM_SYNC = "SCIM_SYNC",
}

export interface RolesQueryInput {
  /** Exclude SCIM roles from the output. */
  excludeScim?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** If supplied, includes aggregate statistics about the roles in the output. */
  includeCounts?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter to only system roles or custom roles. */
  isSystem?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  partyIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** String to search for roles like */
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  /** Column to sort the given roles by. */
  sortBy?: InputMaybe<RolesDefaultColumnOption>;
  sortOrder?: InputMaybe<SortOrder>;
}

export interface RolesQueryResponse {
  roles: Array<RolesQueryRole>;
  totalCount: Scalars["Int"]["output"];
}

export interface RolesQueryRole {
  /**
   * The number of members that are in the role. Only set if `includeCounts` is
   * set in the input for the `roles` query.
   */
  memberCount?: Maybe<Scalars["Int"]["output"]>;
  role: Role;
  /**
   * The number of workflow templates that the role is currently used in. Only
   * set if `includeCounts` is set in the input for the `roles` query.
   */
  usedInWorkflowTemplates?: Maybe<Scalars["Int"]["output"]>;
}

export interface RunDataSyncForRegrelloObjectResponse {
  jobId: Scalars["ID"]["output"];
  regrelloObjectSyncConfig: RegrelloObjectSyncConfig;
}

export interface SavePublishedFormToMyFormsInput {
  formUUID: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}

/** Used for saving user fields from regrello lite. */
export interface SaveUserFieldInstanceOnActionItemDraftEmailsInput {
  emails?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fieldId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
}

export interface SavedField {
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface ScheduledJob {
  createdAt: Scalars["Time"]["output"];
  errorNotificationConfig?: Maybe<ScheduledJobErrorNotificationConfig>;
  id: Scalars["ID"]["output"];
  interval: ScheduledJobInterval;
  lastRunAt?: Maybe<Scalars["Time"]["output"]>;
  nextRunAt: Scalars["Time"]["output"];
  /** Time with time zone. Format: "13:00:00+03" */
  runAt?: Maybe<Scalars["String"]["output"]>;
  type: ScheduledJobType;
  updatedAt: Scalars["Time"]["output"];
}

export interface ScheduledJobErrorNotificationConfig {
  createdAt: Scalars["Time"]["output"];
  id: Scalars["ID"]["output"];
  notificationType: ScheduledJobErrorNotificationType;
  notifyParties: Array<Party>;
  updatedAt: Scalars["Time"]["output"];
}

export enum ScheduledJobErrorNotificationType {
  ONLY_ADMINS = "ONLY_ADMINS",
  PROVIDED_PARTIES = "PROVIDED_PARTIES",
}

export enum ScheduledJobInterval {
  DAILY = "DAILY",
  HOURLY = "HOURLY",
}

export enum ScheduledJobType {
  API_HEALTH_CHECK = "API_HEALTH_CHECK",
  UPDATE_REGRELLO_OBJECT_VIA_REST_API = "UPDATE_REGRELLO_OBJECT_VIA_REST_API",
}

export enum ScimAuthorizationType {
  ACCESS_TOKEN = "ACCESS_TOKEN",
  OAUTH2_IDP = "OAUTH2_IDP",
}

export interface ScimConfigurationResponse {
  /** The authorization type for the SCIM integration e.g. access token or oauth2 */
  authorizationType?: Maybe<ScimAuthorizationType>;
  /** Whether this workspace has any manager fields synced from SCIM. */
  hasManagers: Scalars["Boolean"]["output"];
  /** Controls whether error notifications are turned on or not. */
  isErrorNotificationEnabled: Scalars["Boolean"]["output"];
  /**
   * Controls whether a user's permission level should be managed via SCIM groups.
   * This field should be deprecated when FeatureFlagScimPermissions202404 feature flag is enabled.
   */
  managePermissions: Scalars["Boolean"]["output"];
  /** If false, notify all admins by default. If true, notify a specific subset of admins. */
  notifySpecificParties: Scalars["Boolean"]["output"];
  /** The configuration for oauth2 by IdP authorization type. */
  oAuthIDPConfig?: Maybe<OAuthIdpConfiguration>;
  /** The specific parties to notify when NotifySpecificParties is true. Only available for admins. */
  parties: Array<Party>;
  /** The user id of the service account user for the tenant. If nil, indicates scim is not configured. */
  scimUserId?: Maybe<Scalars["ID"]["output"]>;
}

export interface ScimCredentialsResponse {
  clientId?: Maybe<Scalars["String"]["output"]>;
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  scimEndpoint: Scalars["String"]["output"];
  /** The user id of the service account user created */
  userId: Scalars["ID"]["output"];
}

export interface ScimCustomAttribute {
  attributeName: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  scimAttributeName: Scalars["String"]["output"];
}

export interface ScimCustomAttributeInput {
  attributeName: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  scimAttributeName: Scalars["String"]["input"];
}

export interface ScimCustomAttributeInputs {
  scimCustomAttributes: Array<ScimCustomAttributeInput>;
}

export interface ScimSyncEventHistoryResponse {
  endTime: Scalars["String"]["output"];
  errorMessage?: Maybe<Scalars["String"]["output"]>;
}

export interface ScimUserDetails {
  /**
   * Whether or not the user is currently being provisioned via scim. Scim must be enabled for the
   * tenant, the user must have scim id, and must have been added to the workspace by the current scim
   * integration.
   */
  isActivelyScimProvisioned: Scalars["Boolean"]["output"];
  /**
   * If this user was added to the workspace via SCIM at some point in the past. True if this user's
   * CreatedBy is related to one or more scim configurations.
   */
  isAddedViaScim: Scalars["Boolean"]["output"];
  /**
   * Whether or not the user's permissions should be managed via SCIM.
   * This field should be deprecated when FeatureFlagScimPermissions202404 feature flag is enabled.
   */
  managePermissions: Scalars["Boolean"]["output"];
}

export interface SearchDataSourceInput {
  /** ID of the data source to search. */
  dataSourceId: Scalars["ID"]["input"];
  /** Filter by clauses to apply to the search. */
  filterByDataObjectKeys?: InputMaybe<Array<FilterByDataObjectKeyClause>>;
  /** Maximum number of results to return. */
  maxResults?: InputMaybe<Scalars["Int"]["input"]>;
  /** The term to search for. */
  query: Scalars["String"]["input"];
  /**
   * The data object keys to search on. If not provided, all data object keys will be searched on. The
   * order of the provided keys influences the order of the matches.
   */
  queryByDataObjectKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface SearchDataSourceResponse {
  regrelloObject: RegrelloObject;
  regrelloObjectInstances: Array<RegrelloObjectInstanceV2>;
  /**
   * The total number of results matching the search criteria. This will always be the same as the page
   * size for semantic search.
   */
  totalCount: Scalars["Int"]["output"];
}

export interface SearchInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** @deprecated no longer have tag page where tags filter was used */
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  term: Scalars["String"]["input"];
  type?: InputMaybe<IndexObjectType>;
}

export interface SearchResponse {
  metrics: SearchResultMetrics;
  results: Array<SearchResult>;
}

export interface SearchResult {
  actionItemName?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  ownerName?: Maybe<Scalars["String"]["output"]>;
  snippets: Array<Snippet>;
  status?: Maybe<Scalars["String"]["output"]>;
  taskName?: Maybe<Scalars["String"]["output"]>;
  type: ResultTypeEnum;
  updatedAt?: Maybe<Scalars["Time"]["output"]>;
  uri: Scalars["String"]["output"];
  workflowName?: Maybe<Scalars["String"]["output"]>;
  workflowTemplateType?: Maybe<Scalars["String"]["output"]>;
}

export interface SearchResultMetrics {
  actionItemsFound: Scalars["Int"]["output"];
  documentsFound: Scalars["Int"]["output"];
  totalFound: Scalars["Int"]["output"];
  usersFound: Scalars["Int"]["output"];
  workflowTemplatesFound: Scalars["Int"]["output"];
  workflowsFound: Scalars["Int"]["output"];
}

export interface SendDailyDigestResponse {
  success: Scalars["Boolean"]["output"];
}

export interface SendWeeklyDigestResponse {
  success: Scalars["Boolean"]["output"];
}

export interface SendWelcomeEmailResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface SendWelcomeToTeamEmailResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface SendWelcomeToWorkspaceEmailResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface SetOutOfOfficeInput {
  /** settings about delegations if any set */
  delegationSettings?: InputMaybe<SetOutOfOfficeInputDelegationSettings>;
  endAt?: InputMaybe<Scalars["Time"]["input"]>;
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface SetOutOfOfficeInputDelegationSettings {
  approvalTaskDelegatesPartyIds: Array<Scalars["ID"]["input"]>;
  nonApprovalTaskDelegatesPartyIds: Array<Scalars["ID"]["input"]>;
  /** Settings about role-specific delegations */
  roleDelegations?: InputMaybe<Array<SetOutOfOfficeRoleDelegationInput>>;
  /** Should be set iff nonApprovalTaskDelegatesPartyIds and/or approvalTaskDelegatesPartyIds has values */
  shouldCcOnDelegatedTasks: Scalars["Boolean"]["input"];
}

export interface SetOutOfOfficeResponse {
  currentOutOfOfficeEvent?: Maybe<OutOfOfficeEvent>;
}

export interface SetOutOfOfficeRoleDelegationInput {
  delegatePartyIds: Array<Scalars["ID"]["input"]>;
  roleId: Scalars["ID"]["input"];
}

export interface SetRelationsResponse {
  /** Whether the relations were set for the subject successfully. */
  success: Scalars["Boolean"]["output"];
}

export interface SetWorkflowRelationInput {
  partyOrRoleId: PartyOrRoleId;
  /** The exact set of workflow relations desired for the subject. Pass an empty array to remove all relations. */
  relations: Array<WorkflowRelationType>;
  /** The ID of the workflow towards which the role relations are being updated. */
  workflowId: Scalars["ID"]["input"];
}

export interface SetWorkflowTemplateRelationInput {
  partyOrRoleId: PartyOrRoleId;
  /** The exact set of workflow template relations desired for the subject. Pass an empty array to remove all relations. */
  relations: Array<WorkflowTemplateRelationType>;
  /** The ID of the workflow template towards which the role relations are being updated. */
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface SharePointFolder {
  name: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
}

export interface ShareWorkflowTemplateResponse {
  workflowTemplate: WorkflowTemplate;
}

export interface SkeletonField {
  allowedValues: Array<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  inputType: FieldInstanceValueInputType;
  isMultiValued: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  propertyType: PropertyDataType;
  type: FieldType;
  validationType: Scalars["String"]["output"];
}

export interface SkeletonForm {
  description?: Maybe<Scalars["String"]["output"]>;
  fields: Array<SkeletonField>;
  name: Scalars["String"]["output"];
}

export interface SkeletonStage {
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  tasks: Array<SkeletonTask>;
}

export interface SkeletonTask {
  assignee: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  form?: Maybe<SkeletonForm>;
  name: Scalars["String"]["output"];
  taskType: ActionItemType;
}

export interface Snippet {
  fieldName: Scalars["String"]["output"];
  matchLevel: MatchLevelEnum;
  value: Scalars["String"]["output"];
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * A thin wrapper around a legacy custom Field. This entity is versioned via SpectrumFieldVersion. This
 * was introduced to extend the existing Field model without modifying it, since legacy Fields will
 * continue to be used until Spectrum is permanently enabled.
 */
export interface SpectrumField {
  createdAt: Scalars["Time"]["output"];
  id: Scalars["ID"]["output"];
  uuid: Scalars["UUID"]["output"];
}

/**
 * A single pre-configured value that can be used within a particular field. Currently only used in
 * Select and Multi-Select fields.
 */
export interface SpectrumFieldAllowedValue {
  createdAt: Scalars["Time"]["output"];
  displayOrder: Scalars["Float"]["output"];
  documentIDValue?: Maybe<Scalars["Int"]["output"]>;
  floatValue?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  intValue?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Use PartyValue instead */
  partyIDValue?: Maybe<Scalars["Int"]["output"]>;
  partyValue?: Maybe<Party>;
  spectrumFieldVersion: SpectrumFieldVersion;
  stringValue?: Maybe<Scalars["String"]["output"]>;
  uuid: Scalars["UUID"]["output"];
}

export interface SpectrumFieldConfiguration {
  createdAt: Scalars["Time"]["output"];
  id: Scalars["ID"]["output"];
  spectrumFieldValidationTypeConfiguration: SpectrumFieldValidationTypeConfiguration;
  spectrumFieldVersion: SpectrumFieldVersion;
  uuid: Scalars["UUID"]["output"];
}

/**
 * A restriction on the permitted inputs for a particular field. This is more flexible and powerful
 * than SpectrumFieldAllowedValue: SpectrumFieldAllowedValue can specify a list of values from which
 * the user can choose, while SpectrumFieldConstraint can do things like "require a numeric value to be
 * greater than 3" or "disallow links in a particular document field".
 */
export interface SpectrumFieldConstraint {
  constraintArgs: Array<Scalars["String"]["output"]>;
  createdAt: Scalars["Time"]["output"];
  id: Scalars["ID"]["output"];
  spectrumFieldVersion: SpectrumFieldVersion;
  spectrumValueConstraint: SpectrumValueConstraint;
  uuid: Scalars["UUID"]["output"];
}

/**
 * A single conceptual field type for a Spectrum field (e.g., Email, Phone, Signature, Multi-Select).
 * In practice, this is a superset of the field types suppored in legacy custom Fields.
 */
export interface SpectrumFieldValidationType {
  id: Scalars["ID"]["output"];
  uuid: Scalars["UUID"]["output"];
  validationType: Scalars["String"]["output"];
}

export interface SpectrumFieldValidationTypeConfiguration {
  configurationDescription: Scalars["String"]["output"];
  configurationName: Scalars["String"]["output"];
  createdAt: Scalars["Time"]["output"];
  id: Scalars["ID"]["output"];
  spectrumFieldValidationType: SpectrumFieldValidationType;
  uuid: Scalars["UUID"]["output"];
}

/**
 * A single version of a single Spectrum field, containing all the mutable data about that field from a
 * particular moment in time. A Spectrum-field version is conceptually the "template" or "structure"
 * for the Spectrum field; it needs to be instantiated via a FormInstance or a standalone
 * FieldInstanceValue in order to collect user input.
 */
export interface SpectrumFieldVersion {
  /**
   * The pre-configured values from which the user can choose when filling out this field, as of this
   * field version. Will be present only for Select and Multi-select fields.
   */
  allowedValues: Array<SpectrumFieldAllowedValue>;
  /** The time at which this version was created. */
  createdAt: Scalars["Time"]["output"];
  /** The description for this Spectrum field as of this field version. */
  description: Scalars["String"]["output"];
  /**
   * The legacy Field that this Spectrum field wraps as of this version.
   *
   * Note: In practice, this will always be defined.
   */
  field?: Maybe<Field>;
  /**
   * The constraints that are imposed on this field as of this field version. For example, a number
   * field might have a constraint that requires the value to be greater than or equal to 3.
   */
  fieldConstraints: Array<SpectrumFieldConstraint>;
  /** The units for this field as of this field version. Currently relevant only for Currency fields. */
  fieldUnit?: Maybe<FieldUnit>;
  /** The helper text for this Spectrum field as of this field version. */
  helperText: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /**
   * The unchanging internal name for this Spectrum field. This is used for certain behind-the-scenes
   * use cases and can typically be ignored.
   */
  internalName: Scalars["String"]["output"];
  /** The name of this Spectrum field as of this field version. */
  name: Scalars["String"]["output"];
  /**
   * The data type of this field's value as of this field version. This is not exactly one-to-one
   * with the types of fields available. For example, both Text fields and Select fields can have a
   * String property type.
   */
  propertyType: PropertyType;
  /** The Spectrum field that this version is versioning. */
  spectrumField: SpectrumField;
  uuid: Scalars["UUID"]["output"];
  /**
   * The user-facing type of this field as of this field version, allowing for different validation
   * of different field types that may otherwise use the same property type under the hood. For
   * example, Phone Number and Email fields have different validation types that allow their values
   * to be validated as phone numbers or email addresses, respectively.
   */
  validationType: SpectrumFieldValidationType;
  /**
   * The human-interpretable, auto-incrementing ID of this version for this Spectrum Field.
   * The first version of this field is always 1, and each subsequent version increments by 1.
   */
  versionNumber: Scalars["Int"]["output"];
}

export enum SpectrumInitializationJobType {
  /**
   * The job type for the Forms V2 Migration job, which migrates all workspace-level forms
   * to belong to blueprints where they are used—or deletes them if they are not used by
   * any blueprint.
   */
  FORMS_V2_MIGRATION = "FORMS_V2_MIGRATION",
  /**
   * The job type for the Spectrum Initialization job, which migrates all workspace fields
   * to associate them with a Spectrum field and version.
   */
  SPECTRUM_INIT = "SPECTRUM_INIT",
}

export enum SpectrumInitializationStatus {
  /**
   * Indicates that the job has completed all of its work and that the workspace is ready to use
   * Spectrum.
   */
  COMPLETED = "COMPLETED",
  /** Indicates that the job has failed to complete its work. This is a terminal state. */
  FAILED = "FAILED",
  /**
   * Indicates that the job is currently creating full copies from preexisting form versions and
   * associating the copies to each blueprint that is using it.
   */
  IN_PROGRESS_COPYING_FORM_VERSIONS_TO_BLUEPRINTS = "IN_PROGRESS_COPYING_FORM_VERSIONS_TO_BLUEPRINTS",
  /**
   * Indicates that the job is currently creating full copies from preexisting form versions and
   * associating the copies to each workflow that is using it.
   */
  IN_PROGRESS_COPYING_FORM_VERSIONS_TO_WORKFLOWS = "IN_PROGRESS_COPYING_FORM_VERSIONS_TO_WORKFLOWS",
  /**
   * Indicates that the job is currently deleting orphaned forms that are not associated with
   * any blueprint, workflow, or running task.
   */
  IN_PROGRESS_DELETING_ORPHANED_FORMS = "IN_PROGRESS_DELETING_ORPHANED_FORMS",
  /**
   * Indicates that the job is currently creating Spectrum fields for each legacy Field in the
   * current workspace.
   */
  IN_PROGRESS_FIELDS = "IN_PROGRESS_FIELDS",
  /**
   * Indicates that the job is currently creating Spectrum field versions for each field-instance
   * value in the current workspace.
   */
  IN_PROGRESS_FIELD_INSTANCE_VALUES = "IN_PROGRESS_FIELD_INSTANCE_VALUES",
  /**
   * When running `initializeSpectrum`, this status indicates that the job is currently verifying that
   * all Spectrum fields are in a good state.
   *
   * When running `migrateToSpectrumFormsV2`, this status indicates that the job is currently verifying that
   * all forms are in a good state.
   */
  IN_PROGRESS_VERIFYING = "IN_PROGRESS_VERIFYING",
  /** Indicates that the job has not yet started. */
  NOT_STARTED = "NOT_STARTED",
}

export interface SpectrumInitializationStatusResponse {
  /**
   * An error message, if the job has failed. This will be empty if the job is still in progress or
   * has completed successfully.
   */
  error?: Maybe<Scalars["String"]["output"]>;
  /**
   * The job type for the requested job ID. This job type is used to identify what migration the
   * job is executing.
   */
  jobType: SpectrumInitializationJobType;
  /**
   * The current status of the Spectrum field initialization job in this workspace. This job is
   * created via the `initializeSpectrum` mutation.
   */
  status: SpectrumInitializationStatus;
}

/**
 * A restriction that can be imposed on a particular Spectrum field or Spectrum validation type. For
 * example, you could use this to specify that a text field's value must be at least 3 characters long
 * in order to be valid.
 */
export interface SpectrumValueConstraint {
  createdAt: Scalars["Time"]["output"];
  id: Scalars["ID"]["output"];
  spectrumFieldPropertyType?: Maybe<PropertyType>;
  spectrumFieldValidationType?: Maybe<SpectrumFieldValidationType>;
  uuid: Scalars["UUID"]["output"];
  valueConstraintRule: Scalars["String"]["output"];
}

export interface SplitChildData {
  splitAssignee?: Maybe<Party>;
  splitSplitterFieldInstance?: Maybe<FieldInstance>;
  splitterParentActionItemId: Scalars["ID"]["output"];
  splitterParentActionItemName: Scalars["String"]["output"];
}

export interface SplitConfigurationInput {
  /**
   * Whether or not this field instance controls the splitting of an action item template. Should only
   * be true when creating field instances on action item templates.
   */
  isSplitterFieldInstance?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * If the field instance should split the assignees per task when creating split children. Should
   * only be true when this field instance's field has a Party property type.
   */
  shouldSplitAssignees?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface StageStartConfig {
  startAfterWorkflowStagesConfig?: InputMaybe<StartAfterWorkflowStageConfigInputs>;
  startAt?: InputMaybe<Scalars["Time"]["input"]>;
  startingConditions?: InputMaybe<UpdateConditionGroupInputs>;
  /**
   * The type of stage-start trigger.
   *
   * - If `DEFAULT` or `WORKFLOW_START`, then no other properties should be provided.
   * - If `TIME`, then only `startAt` must be provided.
   * - If `WORKFLOW_CONDITIONS` or `START_AFTER_STAGES_CONDITIONS`, then `startingConditions` must be provided.
   * - If `START_AFTER_STAGES` or `START_AFTER_STAGES_CONDITIONS`, then `startAfterWorkflowStagesConfig ` must be provided.
   */
  type: StageStartType;
}

/** Indicates how a workflow stage should start. */
export enum StageStartType {
  /** Immediately after the previous stage is completed. */
  DEFAULT = "DEFAULT",
  /** When certain stages are completed or skipped. */
  START_AFTER_STAGES = "START_AFTER_STAGES",
  /** When certain stages are completed or skipped and if configured field conditions are met. */
  START_AFTER_STAGES_CONDITIONS = "START_AFTER_STAGES_CONDITIONS",
  /** At a certain timestamp. */
  TIME = "TIME",
  /** As soon as the workflow starts and if configured field conditions are met. */
  WORKFLOW_CONDITIONS = "WORKFLOW_CONDITIONS",
  /** As soon as the workflow starts. */
  WORKFLOW_START = "WORKFLOW_START",
}

export interface StandaloneApprovalActionItemInput {
  /** The post-reject action configuration. */
  rejectActionInput?: InputMaybe<RejectActionInput>;
}

/**
 * A base reference for a standard blueprint that exposes only the necessary info for working with
 * variant blueprints in a client.
 */
export interface StandardBlueprintBaseReference {
  blueprintUuid: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  fieldInstances: Array<FieldInstance>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  permissions: UserPermissionsToWorkflowTemplate;
}

export interface Star {
  createdAt: Scalars["Time"]["output"];
  entity: StarredEntity;
  id: Scalars["ID"]["output"];
}

export interface StarEntityResponse {
  star?: Maybe<Star>;
}

export type StarredEntity = StarredEntityActionItem | StarredEntityWorkflow | StarredEntityWorkflowTemplate;

export interface StarredEntityActionItem {
  actionItem: ActionItem;
}

export interface StarredEntityWorkflow {
  workflow: Workflow;
}

export interface StarredEntityWorkflowTemplate {
  workflowTemplate: WorkflowTemplate;
}

export interface StarsResponse {
  stars: Array<Star>;
}

export interface StartActionItemTemplateResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface StartAfterWorkflowStageConfigInputs {
  /**
   * Whether this stage should start after _all_ stages in `workflowStageIds` finish (`ConditionConnective.AND`)
   * or after _any_ stage in `workflowStageIds` finishes (`ConditionConnective.OR`).
   */
  connective: ConditionConnective;
  workflowStageIds: Array<Scalars["ID"]["input"]>;
}

export interface StartAfterWorkflowStageSpec {
  connective: ConditionConnective;
  workflowStages: Array<WorkflowStage>;
}

export interface StartAfterWorkflowStageTemplateSpec {
  connective: ConditionConnective;
  workflowStageTemplates: Array<WorkflowStageTemplate>;
}

export interface StartWorkflowInput {
  id: Scalars["ID"]["input"];
  startImmediately: Scalars["Boolean"]["input"];
}

export interface StartWorkflowResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  workflow: Workflow;
}

export interface StartWorkflowStageResponse {
  workflowStage: WorkflowStage;
}

export interface StopDataSyncForRegrelloObjectResponse {
  regrelloObjectSyncConfig: RegrelloObjectSyncConfig;
}

/**
 * Wrapper for a string value used in input types to distinguish between fields that should be ignored
 * by the receiving endpoint and fields that should be cleared. If the patch object itself is null, the
 * field is ignored; if the patch object is defined but its value is null, the field is cleared.
 */
export interface StringPatch {
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SubmitActionItemResponse {
  actionItem?: Maybe<ActionItem>;
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface SuccessResponse {
  success: Scalars["Boolean"]["output"];
}

export interface SwitchTenantInputs {
  authUserId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
}

export interface SwitchTenantResponse {
  authUser: AuthUser;
}

export enum SyncEventAction {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export enum SyncEventStatus {
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

export interface SyncInstancePropertyValue {
  propertyId: Scalars["ID"]["input"];
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SyncObjectInstanceExternalKey {
  values: Array<SyncInstancePropertyValue>;
}

export enum TableType {
  TASK_TABLE = "TASK_TABLE",
  WORKFLOW_TABLE = "WORKFLOW_TABLE",
}

export interface TabularFieldInstance {
  fieldInstanceId: Scalars["ID"]["output"];
  values: Array<FieldInstanceValue>;
}

export interface TabularResponse {
  tableValues: Array<TabularFieldInstance>;
}

/**
 * The viewport configuration is used to paginate the tabular data into the requested chunk sizes.
 * Viewport requests are not guaranteed to match with the size of data returned; the backend reserves the right
 * to limit the pagination window to return fewer rows than requested.
 */
export interface TabularViewport {
  /** The upper bound of rows to return. It is an error to request rows with a rowLimit ≤ 0. */
  rowLimit: Scalars["Int"]["input"];
  /** Rows are 1-indexed. It is an error to request a row ≤ 0. */
  rowOffset: Scalars["Int"]["input"];
}

export interface Tag {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  tagType: TagType;
}

export enum TagDefaultColumnOption {
  CREATED_BY = "CREATED_BY",
  CREATED_DATE = "CREATED_DATE",
  MANAGE = "MANAGE",
  NAME = "NAME",
  PARENT_TAG = "PARENT_TAG",
}

export interface TagType {
  backgroundColor: Scalars["String"]["output"];
  borderColor: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  tags?: Maybe<Array<Tag>>;
}

export interface TagTypeWithCount {
  tagCount: Scalars["Int"]["output"];
  tagType: TagType;
}

export interface TagWithHomePageAggregations {
  completedWorkflows: Scalars["Int"]["output"];
  dueSoonActions: Scalars["Int"]["output"];
  exceptionActions: Scalars["Int"]["output"];
  exceptionWorkflows: Scalars["Int"]["output"];
  lastUpdated?: Maybe<Scalars["Time"]["output"]>;
  numberOfUnreadComments: Scalars["Int"]["output"];
  openActions: Scalars["Int"]["output"];
  openWorkflows: Scalars["Int"]["output"];
  overdueActions: Scalars["Int"]["output"];
  overdueWorkflows: Scalars["Int"]["output"];
  tag: Tag;
}

export interface TagsV2Response {
  tags: Array<Tag>;
  totalCount: Scalars["Int"]["output"];
}

export interface TaskCounts {
  assignedTo: Scalars["Int"]["output"];
  ccd: Scalars["Int"]["output"];
}

export enum TaskDefaultColumnOption {
  APPROVERS = "APPROVERS",
  ASSIGNED_BY = "ASSIGNED_BY",
  ASSIGNED_TO = "ASSIGNED_TO",
  DUE_DATE = "DUE_DATE",
  IMPORTANCE = "IMPORTANCE",
  STARTED_AT = "STARTED_AT",
  STATUS = "STATUS",
  SUBJECT = "SUBJECT",
  TAGS = "TAGS",
  TASK_EXCEPTION = "TASK_EXCEPTION",
  TASK_LAST_UPDATED = "TASK_LAST_UPDATED",
  TASK_WORKFLOW_NAME = "TASK_WORKFLOW_NAME",
}

export interface TaskDefaultColumnOptions {
  taskDefaultColumnOptions: Array<TaskDefaultColumnOption>;
}

export interface TaskInformationOrderSettingResponse {
  /**
   * Whether the current tenant has configured task 'Requested information' to show first in
   * task-detail views, before any 'Inherited' information. By default, this is false in each tenant.
   */
  isTaskInformationReversed: Scalars["Boolean"]["output"];
}

/** Action Items */
export enum TaskStatusChangeReason {
  ANOTHER_TASK_REJECTED = "ANOTHER_TASK_REJECTED",
  AUTOMATED_TASK_COMPLETED = "AUTOMATED_TASK_COMPLETED",
  AUTOMATED_TASK_FAILED = "AUTOMATED_TASK_FAILED",
  AUTOMATED_TASK_STARTED = "AUTOMATED_TASK_STARTED",
  CONDITION_REEVALUATED = "CONDITION_REEVALUATED",
  LINKED_WORKFLOW_DELETED = "LINKED_WORKFLOW_DELETED",
  LINKED_WORKFLOW_ENDED = "LINKED_WORKFLOW_ENDED",
  LINKED_WORKFLOW_FAILED_TO_CREATE = "LINKED_WORKFLOW_FAILED_TO_CREATE",
  LINKED_WORKFLOW_FAILED_TO_START = "LINKED_WORKFLOW_FAILED_TO_START",
  LINKED_WORKFLOW_MISSING_INFORMATION_TO_START = "LINKED_WORKFLOW_MISSING_INFORMATION_TO_START",
  LINKED_WORKFLOW_MISSING_REQUIRED_OUTPUT = "LINKED_WORKFLOW_MISSING_REQUIRED_OUTPUT",
  LINKED_WORKFLOW_PROBLEM_REPORTED = "LINKED_WORKFLOW_PROBLEM_REPORTED",
  LINKED_WORKFLOW_STATE_CHANGE = "LINKED_WORKFLOW_STATE_CHANGE",
  MANUAL = "MANUAL",
  REJECTED = "REJECTED",
  STAGE_RESTARTED = "STAGE_RESTARTED",
  STAGE_STARTED = "STAGE_STARTED",
  WORKFLOW_ENDED = "WORKFLOW_ENDED",
  WORKFLOW_PAUSED = "WORKFLOW_PAUSED",
  WORKFLOW_RESTARTED = "WORKFLOW_RESTARTED",
  WORKFLOW_STARTED = "WORKFLOW_STARTED",
}

export interface Team {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  domain: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  party: Party;
  permissions?: Maybe<UserPermissionsToTeam>;
  representingUser?: Maybe<User>;
  teamMembers: Array<TeamMember>;
  type: TeamType;
}

export interface TeamMember {
  isAdmin: Scalars["Boolean"]["output"];
  user: User;
}

/** Filters the results of a team selector query serverside. */
export interface TeamSelectorFilters {
  /** An optional parameter to filter by team type. */
  type?: InputMaybe<TeamType>;
}

export interface TeamSelectorQueryResultsInput {
  /** An optional parameter to filter the results by. See TeamSelectorFilters for more information */
  filters?: InputMaybe<TeamSelectorFilters>;
  /** An optional list of party ids to prioritize in the search results. */
  partyIdsToPrioritize?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** The query text to search for. */
  query: Scalars["String"]["input"];
  /**
   * An optional list of role IDs to filter the results by. If provided, only users belonging to one or
   * more of these roles will be included. If provided and no results are found, then filtering based
   * on role ID will be removed, and isRoleFilteringApplied will be set to false in the response.
   */
  roleIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface TeamSelectorQueryResultsResponse {
  /** If role filtering is applied, the list of roles. */
  filteringByRoles?: Maybe<Array<Role>>;
  /**
   * True if the results were filtered to teams belonging to the supplied list of role IDs in the
   * input.
   */
  isRoleFilteringApplied: Scalars["Boolean"]["output"];
  /**
   * An array of the most relevant teams matching the provided search criteria, sorted in descending
   * order of relevance. Up to 25 results will be returned.
   */
  teams: Array<Team>;
}

export enum TeamType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export interface TemplatesSidebarSectionFlags {
  blueprints: Scalars["Boolean"]["output"];
  companyTemplatesPage: Scalars["Boolean"]["output"];
  draftBlueprintsPage: Scalars["Boolean"]["output"];
  fieldsPage: Scalars["Boolean"]["output"];
  formsPage: Scalars["Boolean"]["output"];
  myTemplatesPage: Scalars["Boolean"]["output"];
  sharedBlueprintsPage: Scalars["Boolean"]["output"];
  tagsPage: Scalars["Boolean"]["output"];
}

export interface Tenant {
  customDomain?: Maybe<Scalars["String"]["output"]>;
  dbUrlSecretName?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  /** The domain at which this tenant is configured to receive email. */
  emailDomain: Scalars["String"]["output"];
  /** An ID that is unique across environments, necessary for some metrics tracking. */
  globallyUniqueMetricsId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  permissionAllowNonAdminUserToCreateUsers: Scalars["Boolean"]["output"];
  permissionAllowNonAdminUserToInviteNonDomain: Scalars["Boolean"]["output"];
  permissionAllowNonAdminUsersToEditAssignees: Scalars["Boolean"]["output"];
  /** The user id of the service account user currently configured for the scim integration, if any. */
  scimUserId?: Maybe<Scalars["ID"]["output"]>;
  uuid: Scalars["String"]["output"];
  uuidShort: Scalars["String"]["output"];
}

export interface TenantIdFromActionItemUuidResponse {
  tenantId: Scalars["ID"]["output"];
}

export interface TenantInputs {
  customerName: Scalars["String"]["input"];
  dbInstance: Scalars["String"]["input"];
  dbUrlSecretName?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  users?: InputMaybe<Array<CreateUserInputs>>;
}

export interface TenantNotificationCount {
  /** The number of unread notifications for the tenant. */
  notificationCount: Scalars["Int"]["output"];
  /** The unique identifier for the tenant. */
  tenantId: Scalars["ID"]["output"];
}

export enum TenantUserRole {
  /** Admin (scoped only to one tenant) */
  ADMIN = "ADMIN",
  APPROVER = "APPROVER",
  ASSIGNEE = "ASSIGNEE",
  ASSIGNOR = "ASSIGNOR",
  CC = "CC",
  WORKFLOW_COLLABORATOR = "WORKFLOW_COLLABORATOR",
}

export interface TestAutomationRequestInput {
  authorizationType: AuthorizationType;
  formDataParams?: InputMaybe<Array<AutomationRequestParamInputV1>>;
  headerParams?: InputMaybe<Array<AutomationRequestParamInputV1>>;
  /**
   * Should be supplied when testing an already created automation.
   * We just return a redacted version of token/oath2Config.clientSecret to the frontend so
   * by having automation request id the backend is able to obtain the token/oauth2Config data from the given automation request
   * and construct the proper request.
   */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  jsonDataParams?: InputMaybe<Array<AutomationRequestParamInputV1>>;
  method: AutomationRequestMethod;
  oauth2Config?: InputMaybe<OAuth2ConfigInput>;
  queryParams?: InputMaybe<Array<AutomationRequestParamInputV1>>;
  responseJsonExtractorConfigInputs?: InputMaybe<Array<AutomationResponseJsonExtractorConfigInput>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
}

export interface TestAutomationResponse {
  extractedJsonResponseData: Array<AutomationExtractedJsonResponseData>;
  requestMethod: AutomationRequestMethod;
  requestUrl: Scalars["String"]["output"];
  responseBody?: Maybe<Scalars["String"]["output"]>;
  responseStatus: Scalars["Int"]["output"];
  retriesNum: Scalars["Int"]["output"];
}

export interface Thumbnail {
  id: Scalars["ID"]["output"];
  path: Scalars["String"]["output"];
}

/**
 * Wrapper for a time value used in input types to distinguish between fields that should be ignored by
 * the receiving endpoint and fields that should be cleared. If the patch object itself is null, the
 * field is ignored; if the patch object is defined but its value is null, the field is cleared.
 */
export interface TimePatch {
  timeValue?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface TimeRange {
  end?: InputMaybe<Scalars["Time"]["input"]>;
  start?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface TriggerAsyncJobResponse {
  success: Scalars["Boolean"]["output"];
}

export interface UnDeleteWorkflowTemplateResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface UpdateApiHealthCheckConfigInput {
  healthCheckRequestInput: UpdateAutomationRequestInput;
  scheduledJobInput: UpdateScheduledJobInput;
}

export interface UpdateActionItemInputs {
  assigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  ccPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  clearDueOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  dueOn?: InputMaybe<Scalars["Time"]["input"]>;
  hasException?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  isMuted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface UpdateActionItemResponse {
  actionItem?: Maybe<ActionItem>;
}

/** Inputs for updating field instances on an object. */
export interface UpdateActionItemTemplateFieldInstanceValuesInputs {
  /** Array of field instances to remove, as specified by their IDs. */
  fieldInstanceValuesToRemove?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Array of field instances to create or update. */
  fieldInstanceValuesToUpsert?: InputMaybe<Array<UpsertFieldInstanceValueInputs>>;
}

/** Inputs for removing, creating, and updating form instances on an action item template. */
export interface UpdateActionItemTemplateFormInstanceInputs {
  /** Array of form instances to remove, as specified by their UUIDs. */
  formInstanceUuidsToRemove?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  /** Array of form instances to create or update. */
  formInstancesToUpsert?: InputMaybe<Array<UpsertFormInstanceInputs>>;
}

/** Inputs for updating multiple fields and objects on an action item template at once. */
export interface UpdateActionItemTemplateInputs {
  /**
   * If not defined, the action item template's AI agent backup assignees will not be updated. To clear
   * the AI agent backup assignees, provide an empty array.
   */
  aiAgentBackupAssigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * If not defined, the action item template's assignees will not be updated. To clear the assignees,
   * provide an empty array.
   */
  assigneePartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * If not defined, the action item template's CCs will not be updated. To clear the CCs, provide an
   * empty array.
   */
  ccPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * ID of the workflow template from which this action item template creates a workflow. As this field
   * is required for action item templates of type LINKED_WORKFLOW, it cannot be cleared for such
   * templates. If not defined, this field will not be updated. If defined, the action item template
   * must be of type LINKED_WORKFLOW, otherwise the operation will fail.
   */
  createsWorkflowFromWorkflowTemplateId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * If not defined, the action item template's description will not be updated. To clear the
   * description, provide a patch object with a null or empty value.
   */
  description?: InputMaybe<StringPatch>;
  /**
   * If not defined, the action item template's documents will not be updated. To clear the documents,
   * provide an empty array.
   */
  documentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * If not defined, the action item template's due date will not be updated. To clear the due date,
   * provide a patch object with a null value.
   */
  dueOn?: InputMaybe<TimePatch>;
  /**
   * If not defined, the action item template's due on interval seconds will not be updated. To clear
   * the due on interval seconds, provide an input object with a null value.
   */
  dueOnIntervalInput?: InputMaybe<DueOnIntervalInput>;
  /** Inputs for removing, adding, and updating field instances on the action item template. */
  fieldInstanceValueInputs?: InputMaybe<UpdateActionItemTemplateFieldInstanceValuesInputs>;
  /** Inputs for adding and/or removing form instances on the action item template. */
  formInstanceInputs?: InputMaybe<UpdateActionItemTemplateFormInstanceInputs>;
  /**
   * Inputs for updating the action item template's legacy approvals. If not defined, the approvals
   * will not be updated. To clear the approvals, provide an empty array.
   */
  legacyApprovalActionItemTemplates?: InputMaybe<Array<ApprovalActionItemTemplate>>;
  /**
   * Since all action item templates must have a name, if this field is not provided the name will not
   * be updated.
   */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * The action item template's post-reject action configuration. As this field is required for action
   * item templates of type APPROVAL, it cannot be cleared for such templates. If not defined, this
   * field will not be updated. If defined, the action item template must be of type APPROVAL,
   * otherwise the operation will fail.
   */
  rejectActionInput?: InputMaybe<RejectActionInput>;
  /**
   * If not defined, the action item template's start after action item template ID will not be
   * updated. To clear this ID, provide a patch object with a null value.
   */
  startAfterActionItemTemplateId?: InputMaybe<IdPatch>;
  /**
   * If not defined, the action item template's start date will not be updated. To clear the start
   * date, provide a patch object with a null value.
   */
  startAt?: InputMaybe<TimePatch>;
  /**
   * If not defined, the action item template's tags will not be updated. To clear the tags, provide an
   * empty array.
   */
  tagIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface UpdateActionItemTemplateResponse {
  actionItemTemplate?: Maybe<ActionItemTemplate>;
}

export interface UpdateAutomationRequestFieldsMappingInput {
  formDataParams?: InputMaybe<Array<AutomationRequestParamMappingInput>>;
  headerParams?: InputMaybe<Array<AutomationRequestParamMappingInput>>;
  id: Scalars["ID"]["input"];
  jsonDataParams?: InputMaybe<Array<AutomationRequestParamMappingInput>>;
  queryParams?: InputMaybe<Array<AutomationRequestParamMappingInput>>;
}

export interface UpdateAutomationRequestFieldsMappingInputResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface UpdateAutomationRequestInput {
  authorizationType: AuthorizationType;
  createNewUsersFromResponse?: InputMaybe<Scalars["Boolean"]["input"]>;
  formDataParams?: InputMaybe<Array<UpsertAutomationRequestParamInputV1>>;
  headerParams?: InputMaybe<Array<UpsertAutomationRequestParamInputV1>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  jsonDataParams?: InputMaybe<Array<UpsertAutomationRequestParamInputV1>>;
  method: AutomationRequestMethod;
  oauth2Config?: InputMaybe<OAuth2ConfigInput>;
  paginationConfig?: InputMaybe<UpdateAutomationRequestPaginationConfigInput>;
  queryParams?: InputMaybe<Array<UpsertAutomationRequestParamInputV1>>;
  responseJsonExtractorConfigInputs?: InputMaybe<Array<AutomationResponseJsonExtractorConfigInput>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
}

export interface UpdateAutomationRequestPaginationConfigInput {
  initialOffset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  paginationType: PaginationType;
}

export interface UpdateAutomationRequestsOnActionItemTemplateResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface UpdateBlueprintVariantConditionsInput {
  conditionGroup: UpdateConditionGroupInputs;
  variantWorkflowId: Scalars["ID"]["input"];
}

export interface UpdateBlueprintVariantConditionsResponse {
  variant: WorkflowTemplate;
}

export interface UpdateCalendarSettingsInput {
  isRemoveWeekendsEnabled: Scalars["Boolean"]["input"];
  workDayEndSecond?: InputMaybe<Scalars["Int"]["input"]>;
  workDayStartSecond?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface UpdateCalendarSettingsResponse {
  calendarSettings: CalendarSettings;
}

export interface UpdateCommentInputs {
  documentIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  text?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UpdateCommentResponse {
  comment?: Maybe<Comment>;
  error?: Maybe<Scalars["String"]["output"]>;
}

export interface UpdateConditionGroupInputs {
  conditionGroups?: InputMaybe<Array<UpdateConditionGroupInputs>>;
  /** Starting conditions for the stage. */
  conditions?: InputMaybe<Array<InputMaybe<UpdateStartingConditionsInputs>>>;
  connective: ConditionConnective;
  id?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface UpdateDetachedFieldInstanceResponse {
  fieldInstance: FieldInstance;
}

export interface UpdateFieldInstanceValueByIdInputs {
  booleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  documentMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  fieldId?: InputMaybe<Scalars["ID"]["input"]>;
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  formFieldID?: InputMaybe<Scalars["ID"]["input"]>;
  formInstanceID?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
  inputType?: InputMaybe<FieldInstanceValueInputType>;
  intValue?: InputMaybe<Scalars["Int"]["input"]>;
  partyMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  partyValue?: InputMaybe<Scalars["ID"]["input"]>;
  regrelloObjectInstanceV2IdMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Updates the requested row number for the field instance value.
   *
   * It is an error to omit this field on a tabular field instance value.
   * It is an error to provide a row number that cannot exist (i.e. ≤ 0 or > maxRowSize for the table)
   */
  rowNumber?: InputMaybe<Scalars["Int"]["input"]>;
  stringMultiValue?: InputMaybe<Array<Scalars["String"]["input"]>>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
  timeValue?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface UpdateFieldInstanceValueInputs {
  booleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Specify changes to the default behavior of the controller type field instance. */
  controllerBehaviorModifier?: InputMaybe<ControllerBehaviorModifierType>;
  controlsActionItemOrActionItemTemplateField?: InputMaybe<ActionItemOrActionItemTemplateField>;
  crossWorkflowSourceFieldInstanceValueId?: InputMaybe<Scalars["ID"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  documentMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  inputType: FieldInstanceValueInputType;
  intValue?: InputMaybe<Scalars["Int"]["input"]>;
  isMultiValued?: InputMaybe<Scalars["Boolean"]["input"]>;
  partyMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  partyValue?: InputMaybe<Scalars["ID"]["input"]>;
  regrelloObjectInstanceV2IdMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  selectedRegrelloObjectPropertyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sourceFieldInstanceValueId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Details for configuring a splitter field instance, which when attached to an action item template,
   * creates a split task.
   */
  splitConfigurationInput?: InputMaybe<SplitConfigurationInput>;
  stringMultiValue?: InputMaybe<Array<Scalars["String"]["input"]>>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
  timeValue?: InputMaybe<Scalars["Time"]["input"]>;
}

/** Updates any relevant field instance(s) on a Regrello task. */
export interface UpdateFieldInstanceValueInputsByFieldId {
  /** Inputs to update instances of the given field. */
  updateFieldInstanceValueInputs?: InputMaybe<Array<UpdateFieldInstanceValueInputs>>;
  /** Update user fields with emails instead of standard fields. */
  updateUserFieldInstancesOnActionItemUsingEmails?: InputMaybe<
    Array<UpdateUserFieldInstanceOnActionItemUsingEmailsInputs>
  >;
}

export interface UpdateFieldInstancesOnActionItemResponse {
  actionItem: ActionItem;
  auditHistoryID: Scalars["ID"]["output"];
}

export interface UpdateFieldInstancesOnActionItemTemplateResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface UpdateFieldInstancesOnWorkflowResponse {
  workflow: Workflow;
}

export interface UpdateFieldInstancesOnWorkflowTemplateResponse {
  workflowTemplate: WorkflowTemplate;
}

export interface UpdateFieldResponse {
  field: Field;
}

export interface UpdateFieldUnitInputs {
  fieldId: Scalars["ID"]["input"];
  fieldUnitId: Scalars["ID"]["input"];
}

export interface UpdateFormFieldDisplayOrderInput {
  displayColumn?: InputMaybe<Scalars["Int"]["input"]>;
  displayOrder: Scalars["Float"]["input"];
  formFieldUUID: Scalars["String"]["input"];
}

export interface UpdateFormFieldEnableFieldPassingInput {
  enableFieldPassing: Scalars["Boolean"]["input"];
  formFieldUUID: Scalars["String"]["input"];
}

export interface UpdateFormFieldFormSectionInput {
  displayColumn?: InputMaybe<Scalars["Int"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  formFieldUUID: Scalars["String"]["input"];
  formSectionUUID: Scalars["String"]["input"];
}

export interface UpdateFormFieldInstancesOnFormInstanceResponse {
  auditHistoryID: Scalars["Int"]["output"];
  /**
   * The updated spectrumMetadata for each field in the form instance. This indicates (among possible
   * other things) whether a field should be shown or hidden based on the most recent form update.
   *
   * Including this in the response allows clients to performantly hide or reveal fields without
   * having to refetch this information via a separate query after the fact.
   */
  fieldInstanceSpectrumMetadatas: Array<FieldInstanceSpectrumMetadataPair>;
  formInstance: FormInstance;
  /**
   * The updated spectrumMetadata for each section in the form instance. This indicates (among possible
   * other things) whether a section should be shown or hidden based on the most recent form update.
   *
   * Including this in the response allows clients to performantly hide or reveal sections without
   * having to refetch this information via a separate query after the fact.
   */
  formSectionSpectrumMetadatas: Array<FormSectionSpectrumMetadataPair>;
  gruleFailed: Scalars["Boolean"]["output"];
}

export interface UpdateFormFieldIsMultiValuedInput {
  formFieldUUID: Scalars["String"]["input"];
  isMultiValued: Scalars["Boolean"]["input"];
}

export interface UpdateFormSectionDisplayOrderInput {
  displayOrder: Scalars["Float"]["input"];
  formSectionUUID: Scalars["String"]["input"];
}

export interface UpdateFormSectionInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  formSectionUUID: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UpdateFormSectionParentFormSectionInput {
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  formSectionUUID: Scalars["String"]["input"];
  parentFormSectionUUID: Scalars["String"]["input"];
}

export interface UpdateFormVersionInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  formVersionUUID: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}

export enum UpdateHasExceptionReason {
  AUTOMATED_TASK = "AUTOMATED_TASK",
  MANUAL = "MANUAL",
  REJECTED = "REJECTED",
}

export interface UpdateInputsAndOutputsOnAutomatedActionItemTemplateResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface UpdateInputsOnAutomatedActionItemResponse {
  actionItem: ActionItem;
}

export interface UpdateIntegrationAuthenticationOnActionItemTemplateResponse {
  actionItemTemplate: ActionItemTemplate;
}

export interface UpdateNameTemplateInputs {
  fieldIds: Array<Scalars["ID"]["input"]>;
  stringTemplate: Scalars["String"]["input"];
}

export interface UpdateNotificationCardFeedbackSentimentResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  notificationCard?: Maybe<NotificationCard>;
}

export interface UpdateNotificationSettingsResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  notificationSettings: NotificationSettings;
  success: Scalars["Boolean"]["output"];
}

export interface UpdatePartyRolesInput {
  accessLevel?: InputMaybe<UserAccessLevel>;
  partyId: Scalars["ID"]["input"];
  roleIdsToAdd?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  roleIdsToRemove?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}

export interface UpdatePartyRolesResponse {
  success: Scalars["Boolean"]["output"];
}

export interface UpdateRegrelloObjectInputs {
  automationRequestInput?: InputMaybe<UpdateAutomationRequestInput>;
  documentId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  properties: Array<RegrelloObjectPropertyInput>;
  scheduledJobInput?: InputMaybe<UpdateScheduledJobInput>;
}

export interface UpdateRoleInput {
  id: Scalars["ID"]["input"];
  params: CreateRoleInput;
}

export interface UpdateRoleMembersInput {
  partyIdsToAdd?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  partyIdsToRemove?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  roleId: Scalars["ID"]["input"];
}

export interface UpdateScheduledJobErrorNotificationConfigInput {
  notifyPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  type: ScheduledJobErrorNotificationType;
}

export interface UpdateScheduledJobInput {
  errorNotificationConfig?: InputMaybe<UpdateScheduledJobErrorNotificationConfigInput>;
  interval: ScheduledJobInterval;
  /** Time with time zone. Format: "13:00:00+03" */
  runAt?: InputMaybe<Scalars["String"]["input"]>;
  type: ScheduledJobType;
}

export interface UpdateScimConfigurationInput {
  /** The authorization type for the SCIM integration e.g. access token or oauth2 */
  authorizationType?: InputMaybe<ScimAuthorizationType>;
  /** Whether scim provisioning is turned on or not. */
  isEnabled: Scalars["Boolean"]["input"];
  /** Controls whether error notifications are turned on or not. */
  isErrorNotificationEnabled: Scalars["Boolean"]["input"];
  /**
   * Controls whether a user's permission level should be managed via SCIM groups.
   * This field should be deprecated when FeatureFlagScimPermissions202404 feature flag is enabled.
   */
  managePermissions: Scalars["Boolean"]["input"];
  /** If false, notify all admins by default. If true, notify a specific subset of admins. */
  notifySpecificParties: Scalars["Boolean"]["input"];
  /** The configuration for oauth2 by IdP authorization type. */
  oauthIDPConfig?: InputMaybe<OAuthIdpConfigurationInput>;
  /** The specific party ids to notify when NotifySpecificParties is true. */
  partyIds: Array<Scalars["ID"]["input"]>;
  /** The user id of the service account user created for scim. */
  scimUserId: Scalars["ID"]["input"];
}

export interface UpdateStartingConditionsInputs {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  leftFieldInstancePropertyID?: InputMaybe<Scalars["ID"]["input"]>;
  leftFieldInstanceValueID: Scalars["ID"]["input"];
  operatorV2: ConditionOperator;
  rightBooleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  rightDocumentIDValue?: InputMaybe<Scalars["ID"]["input"]>;
  /** @deprecated this was never actually used */
  rightFieldInstanceValueID?: InputMaybe<Scalars["ID"]["input"]>;
  rightFloatMultiValue?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  rightFloatValue?: InputMaybe<Scalars["Float"]["input"]>;
  rightIntMultiValue?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  rightIntValue?: InputMaybe<Scalars["Int"]["input"]>;
  rightPartyIDMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  rightPartyIDValue?: InputMaybe<Scalars["ID"]["input"]>;
  rightStringMultiValue?: InputMaybe<Array<Scalars["String"]["input"]>>;
  rightStringValue?: InputMaybe<Scalars["String"]["input"]>;
  rightTimeMultiValue?: InputMaybe<Array<Scalars["Time"]["input"]>>;
  rightTimeValue?: InputMaybe<Scalars["Time"]["input"]>;
}

export interface UpdateTagInputs {
  name: Scalars["String"]["input"];
  tagId: Scalars["ID"]["input"];
  tagTypeId: Scalars["ID"]["input"];
}

export interface UpdateTagResponse {
  tag: Tag;
}

export interface UpdateTagTypeInputs {
  backgroundColor?: InputMaybe<Scalars["String"]["input"]>;
  borderColor?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UpdateTagTypeResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  tagType?: Maybe<TagType>;
}

export interface UpdateTeamInput {
  email: Scalars["String"]["input"];
  memberIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  name: Scalars["String"]["input"];
  teamAdminIds: Array<Scalars["ID"]["input"]>;
}

export interface UpdateTeamResponse {
  team: Team;
}

export interface UpdateTenantNonAdminPermissionsInput {
  permissionAllowNonAdminUserToCreateUsers?: InputMaybe<Scalars["Boolean"]["input"]>;
  permissionAllowNonAdminUsersToEditAssignees?: InputMaybe<Scalars["Boolean"]["input"]>;
  tenantId: Scalars["ID"]["input"];
}

/** Inputs for setting a tenant's custom privacy policy. */
export interface UpdateTenantPrivacyPolicyInput {
  privacyPolicyText?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUrl: Scalars["String"]["input"];
}

export interface UpdateTenantResponse {
  tenant: Tenant;
}

export interface UpdateUserFieldInstanceOnActionItemUsingEmailsInputs {
  email: Scalars["String"]["input"];
  /** Currently unused. */
  fieldInstanceId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface UpdateUserFormFieldInstancesOnFormInstanceUsingEmailsInputs {
  email: Scalars["String"]["input"];
}

export interface UpdateUserResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
}

export interface UpdateViewInput {
  columns?: InputMaybe<Array<ViewColumnInput>>;
  enabledWorkflowStatusOverviews?: InputMaybe<EnabledWorkflowStatusOverviewsInput>;
  name: Scalars["String"]["input"];
  /** This field is deprecated with RBAC-M6 and does not have an effect. */
  sharedToWorkspace: Scalars["Boolean"]["input"];
  sharedWithPartyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  sharedWithRoleIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  showCompleted: Scalars["Boolean"]["input"];
  showWorkflowStatusOverview: Scalars["Boolean"]["input"];
  sortColumnFieldId?: InputMaybe<Scalars["ID"]["input"]>;
  sortColumnOption?: InputMaybe<WorkflowDefaultColumnOption>;
  sortColumnRegrelloObjectPropertyId?: InputMaybe<Scalars["ID"]["input"]>;
  sortOrder: SortOrder;
  viewBaseFilter: ViewBaseFilter;
  viewId: Scalars["ID"]["input"];
}

export interface UpdateViewResponse {
  view: View;
}

export interface UpdateWorkflowCollaborationResponse {
  workflowCollaboration: WorkflowCollaboration;
}

export interface UpdateWorkflowResponse {
  workflow: Workflow;
}

export interface UpdateWorkflowStageExecutionOrderResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface UpdateWorkflowStageResponse {
  workflowStage: WorkflowStage;
}

export interface UpdateWorkflowStageStartAfterWorkflowStageIdsInputs {
  startStageIfApplicable: Scalars["Boolean"]["input"];
}

export interface UpdateWorkflowStageStartAfterWorkflowStageIdsResponse {
  workflowStageStatus: WorkflowStageStatus;
}

export interface UpdateWorkflowStageTemplateExecutionOrderResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface UpdateWorkflowStageTemplateResponse {
  workflowStageTemplate?: Maybe<WorkflowStageTemplate>;
}

export interface UpdateWorkflowSummariesResponse {
  success: Scalars["Boolean"]["output"];
}

export interface UpdateWorkflowTemplateCollaborationResponse {
  workflowTemplateCollaboration: WorkflowTemplateCollaboration;
}

export interface UpdateWorkflowTemplateCreateViaEmailInputs {
  createViaEmailContactEmail?: InputMaybe<Scalars["String"]["input"]>;
  isCreateViaEmailEnabled: Scalars["Boolean"]["input"];
  isCreateViaEmailExternalUserEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface UpdateWorkflowTemplateResponse {
  workflowTemplate: WorkflowTemplate;
}

export interface UpdateWorkflowTemplateToNewDraftVersionResponse {
  workflowTemplate: WorkflowTemplate;
}

export interface UpdateWorkspaceSettingsInput {
  emailLogo?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  sidebarBackgroundColor?: InputMaybe<Scalars["String"]["input"]>;
  sidebarForegroundColor?: InputMaybe<Scalars["String"]["input"]>;
  /** Should be a stringified boolean: 'true' or 'false' */
  taskInformationOrderReversed?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UpsertAutomationRequestParamInputV1 {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  input: AutomationRequestParamInputV1;
}

/**
 * Inputs for updating or creating a field instance on an object. If an ID is provided, the
 * corresponding field instance value record will be updated, if not then a record will be created.
 */
export interface UpsertFieldInstanceValueInputs {
  /**
   * Boolean value for the field instance. The referenced field should be single-valued and be of
   * property data type BOOLEAN.
   */
  booleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Specify changes to the default behavior of the controller type field instance. */
  controllerBehaviorModifier?: InputMaybe<ControllerBehaviorModifierType>;
  /** Native field on a task whose value is derived in part from this field instance. */
  controlsActionItemOrActionItemTemplateField?: InputMaybe<ActionItemOrActionItemTemplateField>;
  /** Field instance in a different workflow context from which this field instance inherits its value. */
  crossWorkflowSourceFieldInstanceValueId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Index specifying the order in which this field instance is rendered amongst other field instances
   * on the same object.
   */
  displayOrder?: InputMaybe<Scalars["Float"]["input"]>;
  /**
   * Array of document IDs for the field instance. The referenced field should be multi-valued and be
   * of property data type DOCUMENT_ID.
   */
  documentMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** The field this field instance is created from. */
  fieldId: Scalars["ID"]["input"];
  /**
   * Float value for the field instance. The referenced field should be single-valued and be of
   * property data type FLOAT.
   */
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** The field instance to update. If null, a field instance value will be created instead. */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Mode by which the field instance's value is derived. */
  inputType: FieldInstanceValueInputType;
  /**
   * Int value for the field instance. The referenced field should be single-valued and be of property
   * data type INT.
   */
  intValue?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * Whether the field instance supports multiple values. Normally, `field.isMultiValued` determines if
   * a given field instance accepts multiple values. But in some cases, such as split tasks and synced
   * object field instances, `fieldInstance.isMultiValued` can override the field's multiplicity.
   */
  isMultiValued?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Array of party IDs for the field instance. The referenced field should be multi-valued and be of
   * property data type PARTY_ID.
   */
  partyMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Party ID value for the field instance. The referenced field should be single-valued and be of
   * property data type PARTY_ID.
   */
  partyValue?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Array of Regrello object IDs for the field instance. The referenced field should be of property
   * data type REGRELLO_OBJECT_INSTANCE_ID.
   */
  regrelloObjectInstanceV2IdMultiValue?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * Array of Regrello object property IDs that represents the subset of properties on the Regrello
   * object that this field instance references and displays to the user when rendered.
   */
  selectedRegrelloObjectPropertyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Field instance from which this field instance inherits its value. */
  sourceFieldInstanceValueId?: InputMaybe<Scalars["ID"]["input"]>;
  /** Specify a specific field version to update the field instance to point to. */
  spectrumFieldVersionId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Details for configuring a splitter field instance, which when attached to an action item template,
   * creates a split task.
   */
  splitConfigurationInput?: InputMaybe<SplitConfigurationInput>;
  /**
   * Array of string values for the field instance. The referenced field should be multi-valued and be
   * of property data type STRING.
   */
  stringMultiValue?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * String value for the field instance. The referenced field should be single-valued and be of
   * property data type STRING.
   */
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Time value for the field instance. The referenced field should be single-valued and be of property
   * data type TIME.
   */
  timeValue?: InputMaybe<Scalars["Time"]["input"]>;
}

/**
 * Inputs for creating or updating a form instance on an object. Exactly one of `formVersionUuid` or
 * `formInstanceUuid` needs to be defined or the update operation will fail and roll back.
 */
export interface UpsertFormInstanceInputs {
  /** Source field instance values to map to form fields on the form instance. */
  fieldInstanceSources?: InputMaybe<Array<MapFieldInstanceSourceToFormFieldInput>>;
  /** If defined, the specified form instance will be updated. */
  formInstanceUuid?: InputMaybe<Scalars["UUID"]["input"]>;
  /** If defined, the specified form version will be used to create a form instance on the object. */
  formVersionUuid?: InputMaybe<Scalars["UUID"]["input"]>;
}

/** A User that can log in to Regrello */
export interface User {
  /** The user's UserAccessLevel, see the type definition for more information. */
  accessLevel: UserAccessLevel;
  /** The user's AccessRole determines which resources or operations they are allowed to view or execute in Regrello. */
  accessRole?: Maybe<AccessRole>;
  /** List of the alternate emails for this user's auth user. */
  alternateEmails: Array<Scalars["String"]["output"]>;
  /**
   * A list of audit history records indicating write or edit events on the user itself. This is only
   * accessible to users with an Admin or Owner role, otherwise it will return an empty list.
   */
  auditHistory: Array<UserAuditHistory>;
  /**
   * The AuthUser is the user that is used to authenticate with Regrello and it exists outside of the
   * scope of the workspace because users can have access to multiple workspaces.
   */
  authUser?: Maybe<AuthUser>;
  /** The user's current OAuth clientId. This is only relevant for users whose userType is SERVICE_ACCOUNT. */
  clientId?: Maybe<Scalars["String"]["output"]>;
  /** A timestamp indicating when the user was created. */
  createdAt: Scalars["Time"]["output"];
  /**
   * Returns the user who created this user. Occasionally this can be blank if the user was created
   * during system initialization.
   */
  createdBy?: Maybe<User>;
  /** Current out of office state for the user */
  currentOutOfOfficeEvent?: Maybe<OutOfOfficeEvent>;
  /** DefaultViewId determines which view will load by default on the Home screen. */
  defaultViewId?: Maybe<Scalars["ID"]["output"]>;
  /**
   * Whether or not the user has been soft-deleted. When users are deleted in Regrello, they persist in
   * the database and are noted as inactive wherever they appear in the application.
   */
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  /** The user's email domain. */
  domain: Scalars["String"]["output"];
  /**
   * The user's primary email address known by this workspace. Note that the AuthUser does map multiple
   * email addresses if they exist for each user.
   */
  email: Scalars["String"]["output"];
  /**
   * Any field instances that belong to this user's party. Mostly relevant for storing SCIM-provisioned
   * details such as username.
   */
  fieldInstances: Array<FieldInstance>;
  /** An ID that is unique across environments and tenants, necessary for some metrics tracking. */
  globallyUniqueMetricsId: Scalars["String"]["output"];
  /** A user's primary key identifier in Regrello. */
  id: Scalars["ID"]["output"];
  /** Indicates whether the user has an admin role. */
  isAdmin: Scalars["Boolean"]["output"];
  /** AuthAdmin is a Regrello-internal permission level that grants a user the ability to administer all workspaces. */
  isAuthAdmin: Scalars["Boolean"]["output"];
  /**
   * Auth Workspace Creators are a Regrello-internal permission level that grants a user the ability to
   * create new workspaces.
   */
  isAuthWorkspaceCreator: Scalars["Boolean"]["output"];
  /** Indicates whether the user has muted all of their notifications. */
  isMuted: Scalars["Boolean"]["output"];
  /** Indicates whether the user has finished creating their account after they were invited to Regrello. */
  isPendingSignUp: Scalars["Boolean"]["output"];
  /** Indicates whether the user is an employee of Regrello. */
  isRegrelloEmployee: Scalars["Boolean"]["output"];
  /**
   * This field determines whether or not a user's timezone is locked. If a user's timezone is locked,
   * it must first be unlocked before it may be updated.
   */
  isTimeZoneLocked: Scalars["Boolean"]["output"];
  /** Indicates whether the user's email identity has been verified. */
  isVerified: Scalars["Boolean"]["output"];
  /** An optional user language setting. */
  language?: Maybe<UserLanguage>;
  /**
   * A timestamp indicating the last moment a user was observed by Regrello to be active in the
   * application.
   */
  lastActive?: Maybe<Scalars["Time"]["output"]>;
  /** The user's location, see the type definition for more information. */
  location?: Maybe<UserLocation>;
  /** The name by which the user is known to other users. */
  name?: Maybe<Scalars["String"]["output"]>;
  /**
   * Organization is currently derivative of the user's email domain and it generally indicates the
   * company that the user works for. It exists in this form so that it
   * is possible for Regrello to be aware of Organization membership per-user.
   */
  organization?: Maybe<Organization>;
  /** The party for this user, see Party type for more information. */
  party: Party;
  /** A set of permissions towards the workspace the user holds. Only available for Permissions V2. */
  permissions: UserPermissionsToWorkspace;
  /** Phone is the user's business phone number, although it is currently not used by the Regrello application directly. */
  phone?: Maybe<Scalars["String"]["output"]>;
  /** The scim-related information about this user. */
  scimDetails: ScimUserDetails;
  /** A timestamp indicating when the user completed initial app setup. */
  setupCompletedAt?: Maybe<Scalars["Time"]["output"]>;
  /** Teams that this user is a member of. */
  teams: Array<Team>;
  /**
   * Returns the user's current workspace or tenant. Note that this always returns the *querying*
   * user's workspace, not necessary the target user's workspace. The reason for this is that this
   * field is only intended for use by the currently querying user. We do not intend for users to view
   * the tenant that someone else is currently in.
   */
  tenant: Tenant;
  /** TimeZone is determined by querying the user's browser whenever they access the Regrello application. */
  timeZone?: Maybe<Scalars["String"]["output"]>;
  /** The user's type, see the type definition for more information. */
  userType: UserType;
}

/**
 * A property of a user, AccessLevel is either internal or external and it determines a basic level of read access to
 * internal resources, such as the lists of users and teams. For instance, external users are only
 * allowed to become aware of the existence of other users they have worked with previously on one
 * or more tasks.
 */
export enum UserAccessLevel {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export interface UserAuditHistory {
  createdAt: Scalars["Time"]["output"];
  createdBy: Party;
  event: AuditHistoryEvent;
  fields: Array<UserAuditHistoryDelta>;
  id: Scalars["ID"]["output"];
}

export interface UserAuditHistoryDelta {
  collectionAdd?: Maybe<Scalars["String"]["output"]>;
  collectionRemove?: Maybe<Scalars["String"]["output"]>;
  fieldName: Scalars["String"]["output"];
  valueAfter?: Maybe<Scalars["String"]["output"]>;
  valueBefore?: Maybe<Scalars["String"]["output"]>;
}

export interface UserAuditHistoryQueryInput {
  limit?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["ID"]["input"]>;
  scimHistoryOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId: Scalars["ID"]["input"];
}

export enum UserLanguage {
  DE = "de",
  EN = "en",
  ES = "es",
  FR = "fr",
  NL = "nl",
  ZH = "zh",
}

/**
 * Location is currently a deprecated feature. In a previous version of Regrello, it was used to
 *   determine whether a user was in China or not.
 */
export enum UserLocation {
  CHINA = "CHINA",
  GLOBAL = "GLOBAL",
}

export interface UserPermissionsToCustomFields {
  canCreate: Scalars["Boolean"]["output"];
  canDelete: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
}

export interface UserPermissionsToTag {
  canDelete: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
}

export interface UserPermissionsToTeam {
  canDelete: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
}

export interface UserPermissionsToView {
  canDelete: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
}

export interface UserPermissionsToWorkflow {
  /** @deprecated use permissionsToWorkflowOwner instead */
  CanEditOwner: Scalars["Boolean"]["output"];
  canAddAutomatedTask: Scalars["Boolean"]["output"];
  /** Only rely on this permission with RBAC V2 enabled. */
  canAddLinkedWorkflowTask: Scalars["Boolean"]["output"];
  canDelete: Scalars["Boolean"]["output"];
  /** Can edit aspects of the workflow excluding those covered by canEditData and CanEditOwner. */
  canEdit: Scalars["Boolean"]["output"];
  /** Can edit the workflow's name, description, tags, and workflow level fields. */
  canEditData: Scalars["Boolean"]["output"];
  canEditExistingAccess: Scalars["Boolean"]["output"];
  canEditExistingFields: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canReorderStages: Scalars["Boolean"]["output"];
  canStart: Scalars["Boolean"]["output"];
  permissionsToWorkflowOwner: UserPermissionsToWorkflowOwner;
}

export interface UserPermissionsToWorkflowOwner {
  canEdit: Scalars["Boolean"]["output"];
  /**
   * Whether this workflow's owner is locked and cannot be edited. For a linked workflow controlled by a
   * parent workflow this field is true if the parent workflow passes data to set the child workflows owner.
   * If this is true even users with the owner.canEdit permission are disallowed from editing the owner field.
   */
  isLocked: Scalars["Boolean"]["output"];
}

export interface UserPermissionsToWorkflowTemplate {
  CanEditWorkflowOwner: Scalars["Boolean"]["output"];
  CanOverrideDefaultWorkflowOwner: Scalars["Boolean"]["output"];
  /** Only rely on this permission with RBAC V2 enabled. */
  canAddAutomatedTask: Scalars["Boolean"]["output"];
  /** Only rely on this permission with RBAC V2 enabled. */
  canAddLinkedWorkflowTask: Scalars["Boolean"]["output"];
  canCopy: Scalars["Boolean"]["output"];
  canCreateVariantsOf: Scalars["Boolean"]["output"];
  /** Only meant to be utilized with Permissions V2 */
  canCreateWorkflowsFrom: Scalars["Boolean"]["output"];
  canDelete: Scalars["Boolean"]["output"];
  /**
   * Whether the user can download the blueprint for later creation via bulk upload.
   *
   * Only meant to be utilized with Permissions V2.
   */
  canDownload: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
  canEditVariantConditions: Scalars["Boolean"]["output"];
  canExport: Scalars["Boolean"]["output"];
  canLockWorkflow: Scalars["Boolean"]["output"];
  canPublish: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canUpdateToNewVersion: Scalars["Boolean"]["output"];
  /** Only meant to be utilized with Permissions V2 */
  canViewTabAbout: Scalars["Boolean"]["output"];
  /** Only meant to be utilized with Permissions V2 */
  canViewTabAccess: Scalars["Boolean"]["output"];
  /** Only meant to be utilized with Permissions V2 */
  canViewTabData: Scalars["Boolean"]["output"];
  /** Only meant to be utilized with Permissions V2 */
  canViewTabDocuments: Scalars["Boolean"]["output"];
}

export interface UserPermissionsToWorkspace {
  canAssignRoles: Scalars["Boolean"]["output"];
  canCreateRolesWithInvite: Scalars["Boolean"]["output"];
  canCreateTags: Scalars["Boolean"]["output"];
  customFieldPermissions: UserPermissionsToCustomFields;
  permissionedNavigationFlags: PermissionedNavigationFlags;
  tagPermissions: UserPermissionsToTag;
}

export interface UserSelectorQueryResultsInput {
  /** An optional flag to only return admin users in the search results. */
  adminOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * An optional set used in permissions v2 to limit the results. See UserSelectorRoleBasedFilter
   * for more.
   */
  filters?: InputMaybe<Array<UserSelectorRoleBasedFilter>>;
  /** An optional list of party ids to prioritize in the search results. */
  partyIdsToPrioritize?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** The query text to search for. */
  query: Scalars["String"]["input"];
  /**
   * An optional list of role IDs to filter the results by. If provided, only users belonging to one or
   * more of these roles will be included. If provided and no results are found, then filtering based
   * on role ID will be removed, and isRoleFilteringApplied will be set to false in the response.
   */
  roleIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /**
   * An optional list of user types to omit from the results. In some places, user types like AI Agents
   * should not be shown and the front-end needs to specify when and where.
   */
  userTypesToOmit?: InputMaybe<Array<UserType>>;
}

export interface UserSelectorQueryResultsResponse {
  /** If role filtering is applied, the list of roles. */
  filteringByRoles?: Maybe<Array<Role>>;
  /**
   * True if the results were filtered to users belonging to the supplied list of role IDs in the
   * input.
   */
  isRoleFilteringApplied: Scalars["Boolean"]["output"];
  /**
   * An array of the most relevant users matching the provided search criteria, sorted in descending
   * order of relevance. Up to 25 results will be returned.
   */
  users: Array<User>;
}

/** Filters the results of a user selector query serverside. */
export enum UserSelectorRoleBasedFilter {
  /** Limit results to external users (according to their access level) */
  EXTERNAL_ONLY = "EXTERNAL_ONLY",
  /** Limit results to internal users (according to their access level) */
  INTERNAL_ONLY = "INTERNAL_ONLY",
  /**
   * Return only parties with at least one internal system role. That is, parties with
   * "Creator", "Publisher", or "Admin".
   */
  INTERNAL_ROLES_ONLY = "INTERNAL_ROLES_ONLY",
}

/**
 * The user's type. This is used to determine whether the user is a service account user or not.
 *   Default users and Service Account users have a different security context.
 */
export enum UserType {
  AI_AGENT_ACCOUNT = "AI_AGENT_ACCOUNT",
  DEFAULT = "DEFAULT",
  REGRELLO_INTERNAL_SERVICE_ACCOUNT = "REGRELLO_INTERNAL_SERVICE_ACCOUNT",
  SCIM_SERVICE_ACCOUNT = "SCIM_SERVICE_ACCOUNT",
  SERVICE_ACCOUNT = "SERVICE_ACCOUNT",
}

/**
 * Status of a user's email verification. True if the user has clicked on the link in the verification email and
 * verified their email, false otherwise.
 */
export interface UserVerificationStatus {
  email: Scalars["String"]["output"];
  isVerified: Scalars["Boolean"]["output"];
}

export interface ValidateCanExportBlueprintResponse {
  canExport: Scalars["Boolean"]["output"];
}

export interface ValidateCanImportBlueprintResponse {
  /**
   * The blueprint's extracted, parsed, and validated JSON content converted to a string. This not a
   * specific type or fragment because the file import is JSON, and the exporter GraphQL fragments are
   * not accessible by the front-end. The front-end will be able to extract the data it needs back out
   * for field mapping and other user-interactive purposes.
   *
   * TODO (anthony): For hardening, Adrian and Anthony will be migrating most of this to part of the
   * import process to have field mapping natively supported.
   */
  blueprintJson: Scalars["String"]["output"];
  canImport: Scalars["Boolean"]["output"];
}

export interface ValidateCanPublishWorkflowTemplateNameResponse {
  /**
   * False if there exists a Workflow Template of type "COMPANY" with the same name and different
   * Blueprint UUID as the specified Workflow Template, and true otherwise.
   */
  canPublishWorkflowTemplateName: Scalars["Boolean"]["output"];
  /**
   * Defined if `canPublishWorkflowTemplateName` is false and the user has permission to view the
   * existing workflow template.
   */
  existingTemplateId?: Maybe<Scalars["ID"]["output"]>;
}

export enum ValidateCreateViaEmailErrorType {
  /**
   * A published blueprint in a different workspace shares the same email address. This is a blocking
   * error and attempting to save such an email on the current blueprint will result in an error.
   */
  CROSS_WORKSPACE_EMAIL_CLASH = "CROSS_WORKSPACE_EMAIL_CLASH",
  /**
   * A published blueprint in the same workspace shares the same email address. This is a non-blocking
   * error, but if the current blueprint is published it will take over the email address and turn off
   * creating workflows via email for the existing published blueprint.
   */
  SAME_WORKSPACE_EMAIL_CLASH = "SAME_WORKSPACE_EMAIL_CLASH",
}

export interface ValidateWorkflowNameResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  /**
   * If a Workflow with the supplied name is already exist
   * we return it, so the FE can display the link to this Workflow.
   */
  existingWorkflow?: Maybe<Workflow>;
  success: Scalars["Boolean"]["output"];
}

export interface ValidateWorkflowResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface ValidateWorkflowTemplateCreateViaEmailResponse {
  errorType?: Maybe<ValidateCreateViaEmailErrorType>;
  existingSameWorkspaceReference?: Maybe<WorkflowTemplateBaseReference>;
  /** @deprecated rely on the presence of 'errorType' to determine if there was a validation error */
  success: Scalars["Boolean"]["output"];
}

export interface ValidationResponse {
  code?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface VariantBlueprintReference {
  conditionGroup?: Maybe<ConditionalExpressionGroup>;
  /**
   * For a variant blueprint of type COMPANY. If there is a work in progress new draft version this
   * will include a reference to it.
   */
  draftVersionWorkflowTemplate?: Maybe<WorkflowTemplateBaseReference>;
  isCompliant: Scalars["Boolean"]["output"];
  workflowTemplate: WorkflowTemplateBaseReference;
}

export interface VariantWorkflowTemplateData {
  /** The conditions for this variant to be selected */
  conditionGroup?: Maybe<ConditionalExpressionGroup>;
  /**
   * True if the variant is compliant with respect to the latest published
   * standard blueprint version and false otherwise.
   * A variant is compliant if all task level fields in the standard blueprint
   * are present in the variant, and if all required workflow level fields
   * in the variant are present in the standard blueprint.
   */
  isCompliant: Scalars["Boolean"]["output"];
  /**
   * Fields that are missing in a variant in order to become compliant
   * with respect to the latest published standard blueprint version
   * @deprecated Use missingFieldsFromStandardV2 instead.
   */
  missingFieldsFromStandard: Array<Field>;
  /**
   * Fields that are missing in a variant in order to become compliant
   * with respect to the latest published standard blueprint version
   */
  missingFieldsFromStandardV2: Array<SpectrumFieldVersion>;
  /** Returns the latest version (aka workflowTemplate) of the standard blueprint */
  standardBlueprint: StandardBlueprintBaseReference;
}

export interface VariantWorkflowTemplateDataReference {
  conditionGroup?: Maybe<ConditionalExpressionGroup>;
  standardBlueprint: WorkflowTemplateBaseReference;
}

export interface VerifyUserIdentityResponse extends Response {
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
}

export interface VersionedWorkflowTemplate {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  current: WorkflowTemplate;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  draft?: Maybe<WorkflowTemplate>;
  id: Scalars["ID"]["output"];
  versions: Array<WorkflowTemplate>;
}

export interface View {
  /** @deprecated No longer supported as of RBAC m6, use partyOrRoleCollaborations instead */
  collaborations: Array<ViewCollaboration>;
  columns: Array<ViewColumn>;
  createdByUser: User;
  enabledWorkflowStatusOverviews?: Maybe<EnabledWorkflowStatusOverviews>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  partyOrRoleCollaborations: Array<PartyOrRoleViewCollaboration>;
  permissions?: Maybe<UserPermissionsToView>;
  /** @deprecated No longer supported as of RBAC m6 */
  sharedToWorkspace: Scalars["Boolean"]["output"];
  showCompleted: Scalars["Boolean"]["output"];
  showWorkflowStatusOverview: Scalars["Boolean"]["output"];
  sortColumnFieldId?: Maybe<Scalars["ID"]["output"]>;
  sortColumnOption?: Maybe<WorkflowDefaultColumnOption>;
  sortColumnRegrelloObjectPropertyId?: Maybe<Scalars["ID"]["output"]>;
  sortOrder: SortOrder;
  viewBaseFilter: ViewBaseFilter;
  viewType: ViewType;
}

export enum ViewBaseFilter {
  ALL_WORKFLOWS = "ALL_WORKFLOWS",
  MY_WORKFLOWS = "MY_WORKFLOWS",
}

export interface ViewCollaboration {
  accessRole: AccessRole;
  party: Party;
}

export interface ViewColumn {
  columnWidth?: Maybe<Scalars["Int"]["output"]>;
  defaultColumnOption?: Maybe<WorkflowDefaultColumnOption>;
  displayOrder: Scalars["Float"]["output"];
  field?: Maybe<Field>;
  /** DEPRICATED: select fieldId through the field itself instead */
  fieldId?: Maybe<Scalars["ID"]["output"]>;
  filterConnective?: Maybe<ConditionConnective>;
  filters: Array<ViewFilter>;
  id: Scalars["ID"]["output"];
  regrelloObjectProperty?: Maybe<RegrelloObjectProperty>;
}

export interface ViewColumnInput {
  columnWidth?: InputMaybe<Scalars["Int"]["input"]>;
  defaultColumnOption?: InputMaybe<WorkflowDefaultColumnOption>;
  displayOrder: Scalars["Float"]["input"];
  fieldId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Describes whether the filters should be AND'ed or OR'd together. If omitted, the filters will be
   * AND'ed together.
   */
  filterConnective?: InputMaybe<ConditionConnective>;
  filters?: InputMaybe<Array<ViewFilterInput>>;
  /** regrelloObjectPropertyId is used to filter workflows by Synced Object's property */
  regrelloObjectPropertyId?: InputMaybe<Scalars["ID"]["input"]>;
  /** if ID is not provided will create the column on the BE */
  viewColumnId?: InputMaybe<Scalars["ID"]["input"]>;
}

export interface ViewFieldsResponse {
  fields: Array<Field>;
}

export interface ViewFilter {
  modifier?: Maybe<ViewFilterModifier>;
  operator: ConditionOperator;
  value?: Maybe<Scalars["String"]["output"]>;
  /** list filters will have values instead of value */
  values?: Maybe<Array<Scalars["String"]["output"]>>;
}

export interface ViewFilterInput {
  /** Setting that modifies filtering logic. */
  modifier?: InputMaybe<ViewFilterModifier>;
  operator: ConditionOperator;
  value?: InputMaybe<Scalars["String"]["input"]>;
  /** for list filter type should pass to values instead of value */
  values?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** if ID is not provided will create the filter on the BE */
  viewFilterId?: InputMaybe<Scalars["ID"]["input"]>;
}

export enum ViewFilterModifier {
  /**
   * Only gets applied to the blueprint ID filter. Determines whether to include workflow templates
   * that are variants of the given bluerpint IDs when filtering on home.
   */
  INCLUDE_VARIANTS = "INCLUDE_VARIANTS",
}

export interface ViewPropertiesResponse {
  properties: Array<RegrelloObjectProperty>;
}

export interface ViewStateInput {
  columns?: InputMaybe<Array<ViewColumnInput>>;
  enabledWorkflowStatusOverviews?: InputMaybe<EnabledWorkflowStatusOverviewsInput>;
  showCompleted: Scalars["Boolean"]["input"];
  showWorkflowStatusOverview: Scalars["Boolean"]["input"];
  sortColumnFieldId?: InputMaybe<Scalars["ID"]["input"]>;
  sortColumnOption?: InputMaybe<WorkflowDefaultColumnOption>;
  sortColumnRegrelloObjectPropertyId?: InputMaybe<Scalars["ID"]["input"]>;
  sortOrder: SortOrder;
  viewBaseFilter: ViewBaseFilter;
}

export enum ViewType {
  ALL_WORKFLOWS = "ALL_WORKFLOWS",
  DEFAULT = "DEFAULT",
  MY_WORKFLOWS = "MY_WORKFLOWS",
}

export interface WeeklyUserSummary {
  date: Scalars["Time"]["output"];
  numberOfDailyActiveUsers: Scalars["Int"]["output"];
  numberOfUsers: Scalars["Int"]["output"];
  numberOfUsersWithActivity: Scalars["Int"]["output"];
}

export interface WeeklyWorkflowSummary {
  date: Scalars["Time"]["output"];
  workflowsRunning: Scalars["Int"]["output"];
}

export interface WorkatoIntegrationConnection {
  authorizationError?: Maybe<Scalars["String"]["output"]>;
  authorizationStatus?: Maybe<Scalars["String"]["output"]>;
  integrationType: IntegrationType;
  name: Scalars["String"]["output"];
  workatoId: Scalars["ID"]["output"];
}

export interface WorkatoIntegrationPackage {
  createdAt: Scalars["Time"]["output"];
  id: Scalars["ID"]["output"];
  type: WorkatoIntegrationPackageType;
  version: Scalars["Int"]["output"];
  workatoPackageId: Scalars["String"]["output"];
}

export enum WorkatoIntegrationPackageType {
  SHAREPOINT = "SHAREPOINT",
}

/**
 * A workflow is the core building block of Regrello. It orchestrates tasks and groups of stages that
 *   are used to complete a business process. Workflows are created from WorkflowTemplates.
 */
export interface Workflow {
  /** The date the workflow actually completed on. */
  actualEndOn?: Maybe<Scalars["Time"]["output"]>;
  /** The current projected end date of the workflow. */
  adjustedDueOn?: Maybe<Scalars["Time"]["output"]>;
  /** FieldInstances grouped by field for convenience. */
  aggregatedFieldInstances: Array<FieldInstancesByFields>;
  /**
   * Whether the workflow has been configured to pull its due dates ahead or delay them based on
   * completion times of earlier tasks.
   */
  autoAdjustDueOn: Scalars["Boolean"]["output"];
  /** A reference to a few details about this workflow's blueprint, if any. */
  blueprint?: Maybe<WorkflowTemplateBaseReference>;
  /**
   * The parties (teams or users) that are allowed to view the workflow, and the level of access they
   * have. Indicates whether each party has read or write access, and whether they are allowed to see
   * workflow level details or just task level details. Collaborations are essentially the same as
   * collaborators (above), but with more information.
   */
  collaborations: Array<WorkflowCollaboration>;
  /** The parties (teams or users) that are allowed to view the workflow. */
  collaborators: Array<Party>;
  /**
   * The observed completion timestamp of the workflow. A workflow is completed when all tasks have
   * been completed or skipped by starting rules/conditions.
   */
  completedAt?: Maybe<Scalars["Time"]["output"]>;
  /** Similar to `plannedDueOn`, but recalculated whenever stages are skipped due to their starting conditions. */
  conditionalDueOn?: Maybe<Scalars["Time"]["output"]>;
  /** The timestamp at which the workflow was created. */
  createdAt: Scalars["Time"]["output"];
  /** The user who created the workflow, or who was specified by the caller as the workflow creator. */
  createdBy: User;
  /**
   * Relevant if the workflow is created as a "Linked" workflow to another workflow. This field will
   * link to the task in the other workflow that created this workflow.
   */
  createdByActionItem?: Maybe<WorkflowCreatedByActionItemReference>;
  /** Holds information about whether and when the current user may have starred this workflow. */
  currentUserStar?: Maybe<Star>;
  /**
   * The number of days the workflow has been delayed according to its stated due dates
   * on tasks and when they were scheduled to start vs when they actually started.
   */
  daysDelayed?: Maybe<Scalars["Int"]["output"]>;
  /** The timestamp at which the workflow was deleted, if it was. If the workflow hasn't been deleted, this field is empty. */
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  /** A human-readable text description of the workflow. */
  description?: Maybe<Scalars["String"]["output"]>;
  /**
   * EndDate is a future-projected end date for the workflow. It is calculated by taking into account
   * stage and task dependencies, as well as the predicted duration of each stage and task.
   */
  endDate?: Maybe<Scalars["Time"]["output"]>;
  /**
   * The timestamp at which the workflow was ended. Ended workflows are not deleted, but they are
   * considered unsuccessful and no longer running. A workflow may never have an endedAt time, if the
   * workflow was never "Ended". "Ending" a workflow is a specific workflow status transition that is
   * different than "Completing" a workflow.
   */
  endedAt?: Maybe<Scalars["Time"]["output"]>;
  /**
   * Custom fields associated with the workflow. These are the workflow-level fields and will not
   * contain any task-level fields.
   */
  fieldInstances: Array<FieldInstance>;
  /** A graph-like representation of the workflow. */
  graph: WorkflowGraph;
  /**
   * Indicates whether or not a problem has been reported with the workflow. In the schema, problems
   * are referred to as Exceptions.
   */
  hasException: Scalars["Boolean"]["output"];
  /** The unique identifier of the workflow. */
  id: Scalars["ID"]["output"];
  /**
   * A validation field that indicates to the caller if the workflow is allowed to start immediately.
   * Depending on the workflow's blueprint configuration, it may require information it does not yet
   * have in order to start.
   */
  isEligibleToStart: IsWorkflowEligibleToStart;
  /**
   * Whether the `Workflow` is locked, which means that certain actions (configuration dependent) are
   * restricted from being performed on the workflow. The actual restrictions are reflected in the
   * workflow's `permissions` object.
   */
  isLocked: Scalars["Boolean"]["output"];
  /**
   * Whether or not the workflow owner has decided to allow external parties who are assigned tasks in
   * the workflow to see workflow details.
   * This setting is deprecated and will be set to true for all workflows.
   * A value of True will hide the workflow details from any external assignees.
   */
  isVisibilityLimitedForExternalParties: Scalars["Boolean"]["output"];
  /**
   * Lines are a deprecated feature. They were previously used to group things like order line items into workflows.
   * @deprecated This feature has been removed and will be deleted soon.
   */
  lines: Array<Line>;
  /** The name for this workflow. It must be unique. */
  name: Scalars["String"]["output"];
  /** The naming convention/template with which the workflow's name is crafted. */
  nameTemplate?: Maybe<NameTemplateWithSuffix>;
  /** A list of AI-generated notifications describing the workflow and potential issues detected. */
  notificationCards?: Maybe<NotificationCardsResponse>;
  /**
   * Returns the number of open action items for this workflow. For use on the home page. Open action
   * items are those that are not completed.
   */
  numOpenActionItems: Scalars["Int"]["output"];
  /**
   * Number of comments within the workflow that the current user has not read. Reading a comment is
   * done in a browser by viewing it for a long enough period of time. Warning: this is somewhat
   * computationally intensive, so leaving it out is recommended unless it is needed.
   */
  numberOfUnreadComments: Scalars["Int"]["output"];
  /** Returns the full list of open tasks in the workflow as a list. Note not performant when queried for on a slice of workflows, should only be queried for on a single workflow */
  openActionItems: Array<ActionItem>;
  /** The party who is set as the current workflow owner. */
  owner: Party;
  /** The number of tasks completed divided by the number of tasks in the workflow. */
  percentComplete?: Maybe<Scalars["Float"]["output"]>;
  /**
   * This field returns the current user's permission level for the workflow. It is used by the UI to
   * determine which things should show or not, as well as which buttons should be enabled and which
   * fields may be editable or available.
   */
  permissions: UserPermissionsToWorkflow;
  /** The date the workflow was initially expected to be due on. */
  plannedDueOn?: Maybe<Scalars["Time"]["output"]>;
  /**
   * The incremental reference number if the workflow has been created from a blueprint that was
   * configured with a custom Workflow Reference Number. Used in conjunction with
   * referenceNumberPrefix.
   */
  referenceNumber?: Maybe<Scalars["Int"]["output"]>;
  /**
   * The prefix of the reference number if the workflow has been created from a blueprint that was
   * configured with a custom Workflow Reference Number. Used in conjunction with referenceNumber.
   */
  referenceNumberPrefix?: Maybe<Scalars["String"]["output"]>;
  /** A list of audit history records indicating workflow scheduleStatus transitions. */
  scheduleHistory: Array<WorkflowScheduleHistory>;
  /** Current scheduling status of the workflow. Possible values are "STARTED", "PAUSED", "COMPLETED", "ENDED". */
  scheduleStatus?: Maybe<WorkflowScheduleStatus>;
  /**
   * An AI-generated summary of the workflow that focuses on execution status and that summarizes by
   * stage. It focuses less on messages and more on the status. Only available to select Regrello workspaces.
   */
  stageSummary?: Maybe<Scalars["String"]["output"]>;
  /** The stages of tasks that are part of the workflow. By default, workflows are executed in stage order. */
  stages: Array<WorkflowStage>;
  /**
   * The timestamp at which the workflow was started. This is the same time as when the first stage
   * launched. It can be different from the time that the workflow was created if the workflow was
   * created in a state that could not immediately start.
   */
  startedAt?: Maybe<Scalars["Time"]["output"]>;
  /** An AI-generated summary of the workflow's messages. Only available to select Regrello workspaces. */
  summary?: Maybe<Scalars["String"]["output"]>;
  /** The timestamp at which the workflow's summary was last updated. */
  summaryUpdatedAt?: Maybe<Scalars["Time"]["output"]>;
  /**
   * Tags associated with the workflow. Tags come from the Blueprint/WorkflowTemplate or they can be manually added to
   * individual workflows.
   */
  tags: Array<Tag>;
  /** Returns the "most urgent" action item by status for this workflow. For use on the home page. order is exception, overdue, ontime action items, tie broken by due date */
  topOpenActionItem?: Maybe<ActionItem>;
  /**
   * The ID of the top workflow in the chain of parent workflows that the workflow is a part of.
   * If the workflow does not have a parent workflow, this will be the same as the workflow ID.
   */
  topWorkflowId: Scalars["ID"]["output"];
  /** The timestamp at which the workflow was last changed. */
  updatedAt: Scalars["Time"]["output"];
  /** The last party (team or user) to update a workflow's state. */
  updatedBy: Party;
}

/**
 * A workflow is the core building block of Regrello. It orchestrates tasks and groups of stages that
 *   are used to complete a business process. Workflows are created from WorkflowTemplates.
 */
export interface WorkflowIsEligibleToStartArgs {
  startImmediately: Scalars["Boolean"]["input"];
}

/**
 * A workflow is the core building block of Regrello. It orchestrates tasks and groups of stages that
 *   are used to complete a business process. Workflows are created from WorkflowTemplates.
 */
export interface WorkflowOpenActionItemsArgs {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

/** A WorkflowCollaboration record indicates the level of access that the specified party has to the workflow. */
export interface WorkflowCollaboration {
  /** The accessRole that the corresponding Party has on the workflow. See AccessRole for more information. */
  accessRole: AccessRole;
  /**
   * Whether the collaboration was copied from the workflow template this workflow was created from or
   * was newly added after creation. Useful for determining what edit actions are available to users if
   * the workflow is locked.
   */
  isFromWorkflowTemplate: Scalars["Boolean"]["output"];
  /** The party that has access to the workflow. See Party definition. */
  party: Party;
}

export interface WorkflowCreatedByActionItemReference {
  /** Reference to workflow the createdByActionItem is in */
  createdByActionItemWorkflow: BaseWorkflowReference;
  /**
   * Is the actionItem that created this workflow still linking directly to the workflow, or is the link severed.
   * The link is active if the createdByActionItem is not deleted, and still has its LinkedWorkflowID set to the workflow id
   */
  isActivelyLinkedToWorkflow: Scalars["Boolean"]["output"];
  isCreatedByActionItemWorkflowDeleted: Scalars["Boolean"]["output"];
  isParentWorkflowEnded: Scalars["Boolean"]["output"];
  isParentWorkflowPaused: Scalars["Boolean"]["output"];
}

export enum WorkflowDefaultColumnOption {
  /** Actual end date is set when a workflow finishes. */
  ACTUAL_END_DATE = "ACTUAL_END_DATE",
  /** The blueprint UUID of the workflow template, if any, the workflow was created from. */
  BLUEPRINT_UUID = "BLUEPRINT_UUID",
  /**
   * Like PLANNED_END_DATE, but shows the end date that skips any stages whose starting conditions were
   * not met.
   */
  CONDITIONAL_PLANNED_END_DATE = "CONDITIONAL_PLANNED_END_DATE",
  /** This column represents the workflow creator. The original CREATED_BY column was repurposed to represent the workflow owner. */
  CREATED_BY_V2 = "CREATED_BY_V2",
  DELAYED = "DELAYED",
  /**
   * Deprecated: The actual end date of the workflow if and only if the workflow has ended, otherwise
   * the estimated end date of the workflow. This is a legacy column and has been supplanted by
   * ESTIMATED_END_DATE and ACTUAL_END_DATE, whose meanings are clearer.
   */
  END_DATE = "END_DATE",
  /** Estimated end date will adjust as the workflow runs. */
  ESTIMATED_END_DATE = "ESTIMATED_END_DATE",
  MESSAGES = "MESSAGES",
  NUM_OPEN_ACTION_ITEMS = "NUM_OPEN_ACTION_ITEMS",
  NUM_OVERDUE_ACTION_ITEMS = "NUM_OVERDUE_ACTION_ITEMS",
  /**
   * This column also represents the workflow owner. This column was created to support passing the
   * owner by party id instead of user id, because teams can also be workflow owners.
   */
  OWNER_PARTY = "OWNER_PARTY",
  PENDING_TASKS = "PENDING_TASKS",
  PERCENT_COMPLETE = "PERCENT_COMPLETE",
  /** PLANNED_END_DATE shows the original end date calculated when the workflow started. */
  PLANNED_END_DATE = "PLANNED_END_DATE",
  STAGE_STATUS = "STAGE_STATUS",
  START_DATE = "START_DATE",
  /**
   * The ID of the top workflow in the chain of parent workflows that the workflow is a part of. If the
   * workflow does not have a parent workflow, this will be the same as the workflow ID.
   */
  TOP_WORKFLOW_ID = "TOP_WORKFLOW_ID",
  WORKFLOW_AI_MONITOR = "WORKFLOW_AI_MONITOR",
  WORKFLOW_DAYS_DELAYED = "WORKFLOW_DAYS_DELAYED",
  WORKFLOW_EXCEPTION = "WORKFLOW_EXCEPTION",
  WORKFLOW_ID = "WORKFLOW_ID",
  WORKFLOW_LAST_UPDATED = "WORKFLOW_LAST_UPDATED",
  WORKFLOW_NAME = "WORKFLOW_NAME",
  WORKFLOW_TAGS = "WORKFLOW_TAGS",
  WORKFLOW_TEMPLATE_NAME = "WORKFLOW_TEMPLATE_NAME",
  /**
   * The version number of the workflow template the workflow was created from. Null if the workflow
   * was created from scratch or from a template of type "MINE" or "DRAFT".
   */
  WORKFLOW_TEMPLATE_VERSION_NUMBER = "WORKFLOW_TEMPLATE_VERSION_NUMBER",
  WORKFLOW_UNREAD_MESSAGES = "WORKFLOW_UNREAD_MESSAGES",
}

export interface WorkflowDefaultColumnOptions {
  workflowDefaultColumnOptions: Array<WorkflowDefaultColumnOption>;
}

export enum WorkflowFrontendStageStatus {
  APPROVAL_NOT_STARTED = "APPROVAL_NOT_STARTED",
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
  COMPLETED_LATE = "COMPLETED_LATE",
  ENDED = "ENDED",
  EXCEPTION = "EXCEPTION",
  IN_PROGRESS_ON_TRACK = "IN_PROGRESS_ON_TRACK",
  IN_PROGRESS_OVERDUE = "IN_PROGRESS_OVERDUE",
  LINKED_WORKFLOW_DELAYED = "LINKED_WORKFLOW_DELAYED",
  LINKED_WORKFLOW_IN_PROGRESS = "LINKED_WORKFLOW_IN_PROGRESS",
  LINKED_WORKFLOW_NOT_STARTED = "LINKED_WORKFLOW_NOT_STARTED",
  LINKED_WORKFLOW_PAUSED = "LINKED_WORKFLOW_PAUSED",
  LINKED_WORKFLOW_PROBLEM = "LINKED_WORKFLOW_PROBLEM",
  NOT_STARTED = "NOT_STARTED",
  NOT_STARTED_DELAYED = "NOT_STARTED_DELAYED",
  PAUSED = "PAUSED",
  REJECTED = "REJECTED",
  SKIPPED = "SKIPPED",
}

export enum WorkflowFrontendTaskStatus {
  APPROVAL_NOT_STARTED = "APPROVAL_NOT_STARTED",
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
  COMPLETED_LATE = "COMPLETED_LATE",
  ENDED = "ENDED",
  EXCEPTION = "EXCEPTION",
  IN_PROGRESS_ON_TRACK = "IN_PROGRESS_ON_TRACK",
  IN_PROGRESS_OVERDUE = "IN_PROGRESS_OVERDUE",
  LINKED_WORKFLOW_DELAYED = "LINKED_WORKFLOW_DELAYED",
  LINKED_WORKFLOW_ENDED = "LINKED_WORKFLOW_ENDED",
  LINKED_WORKFLOW_IN_PROGRESS = "LINKED_WORKFLOW_IN_PROGRESS",
  LINKED_WORKFLOW_NOT_STARTED = "LINKED_WORKFLOW_NOT_STARTED",
  LINKED_WORKFLOW_PAUSED = "LINKED_WORKFLOW_PAUSED",
  LINKED_WORKFLOW_PROBLEM = "LINKED_WORKFLOW_PROBLEM",
  NOT_STARTED = "NOT_STARTED",
  NOT_STARTED_DELAYED = "NOT_STARTED_DELAYED",
  PAUSED = "PAUSED",
  REJECTED = "REJECTED",
  SKIPPED = "SKIPPED",
}

/**
 * Graph representation of a workflow. Allows us to write logic only once
 * for building workflow graphs, the workflow Gantt chart, etc.
 */
export interface WorkflowGraph {
  allActionItems: Array<WorkflowGraphActionItem>;
  allStages: Array<WorkflowGraphStage>;
  headlessStages: Array<WorkflowGraphStage>;
  rootStages: Array<WorkflowGraphStage>;
  workflow: GenericWorkflow;
}

export interface WorkflowGraphActionItem {
  actionItem: GenericWorkflowActionItem;
  id: Scalars["ID"]["output"];
  next: Array<WorkflowGraphActionItem>;
  previous: Array<WorkflowGraphActionItem>;
  stageId: Scalars["ID"]["output"];
}

export interface WorkflowGraphStage {
  headlessActionItems: Array<WorkflowGraphActionItem>;
  id: Scalars["ID"]["output"];
  next: Array<WorkflowGraphStage>;
  previous: Array<WorkflowGraphStage>;
  rootActionItems: Array<WorkflowGraphActionItem>;
  stage: GenericWorkflowStage;
}

export interface WorkflowParticipation {
  assignedParty: PartyOrRole;
  resolvedParties: Array<Party>;
  taskCounts: TaskCounts;
}

export interface WorkflowParticipationInput {
  workflowId: Scalars["ID"]["input"];
}

export interface WorkflowReference {
  stageExecutionOrder: Scalars["Int"]["output"];
  stageId: Scalars["ID"]["output"];
  stageName: Scalars["String"]["output"];
  workflowId: Scalars["ID"]["output"];
  workflowName: Scalars["String"]["output"];
  workflowPermissions: UserPermissionsToWorkflow;
}

export enum WorkflowRelationType {
  COMMENT = "COMMENT",
  EDIT = "EDIT",
}

export interface WorkflowResourceRelation {
  conditionGroup?: Maybe<Scalars["ID"]["output"]>;
  /**
   * Whether the relation is locked, and cannot be deleted. This will only
   * apply when querying on a workflow.
   */
  isLocked?: Maybe<Scalars["Boolean"]["output"]>;
  relation: WorkflowRelationType;
}

export enum WorkflowRuntimeStatus {
  COMPLETED = "COMPLETED",
  ENDED = "ENDED",
  EXCEPTION = "EXCEPTION",
  NOT_STARTED = "NOT_STARTED",
  OPEN = "OPEN",
  OVERDUE = "OVERDUE",
  PAUSED = "PAUSED",
}

export interface WorkflowScheduleHistory {
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  status: WorkflowScheduleStatus;
}

/**
 * Enumerates all of the statuses that a Workflow can have. A workflow can be started, paused,
 *   completed, or ended. A workflow is started when it is first created. A workflow is paused when
 *   it is manually paused by a user. A workflow is completed when all of its tasks are completed.
 *   A workflow is ended when it is manually ended by a user.
 */
export enum WorkflowScheduleStatus {
  COMPLETED = "COMPLETED",
  ENDED = "ENDED",
  PAUSED = "PAUSED",
  STARTED = "STARTED",
}

export interface WorkflowStage {
  actionItemTemplates: Array<ActionItemTemplate>;
  /** @deprecated previously we would always set this to true */
  autoStart?: Maybe<Scalars["Boolean"]["output"]>;
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  /**
   * The order in which the stage should be executed.
   * Min value: 1
   */
  executionOrder: Scalars["Int"]["output"];
  frozeForReason?: Maybe<WorkflowStageFrozeForReason>;
  hasException: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  initiatedBy?: Maybe<Party>;
  /**
   * Whether the `WorkflowStage` is locked, which means that certain actions (configuration dependent)
   * are restricted from being performed on the stage. The actual restrictions are reflected in the
   * stage's `permissions` object.
   */
  isLocked: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  permissions: PermissionsToWorkflowStage;
  projectedStartAt?: Maybe<Scalars["Time"]["output"]>;
  required: Scalars["Boolean"]["output"];
  startAfterSpec?: Maybe<StartAfterWorkflowStageSpec>;
  startAfterWorkflowStage?: Maybe<WorkflowStage>;
  startAfterWorkflowStages?: Maybe<Array<WorkflowStage>>;
  /**
   * startAt determines when a WorkflowStage will start. If set, the stage will ignore conditions and
   * previous stages. It will simply start at the time specified.
   */
  startAt?: Maybe<Scalars["Time"]["output"]>;
  startOnWorkflowStart: Scalars["Boolean"]["output"];
  /** startedAt records the time that a workflow stage was started in the past */
  startedAt?: Maybe<Scalars["Time"]["output"]>;
  startingConditions?: Maybe<ConditionalExpressionGroup>;
  status: WorkflowStageStatus;
  updatedAt: Scalars["Time"]["output"];
  workflow: Workflow;
}

export enum WorkflowStageFrozeForReason {
  ENDED = "ENDED",
  INACTIVE = "INACTIVE",
  PAUSED = "PAUSED",
}

export interface WorkflowStageNamesWithAggregations {
  exceptionWorkflows: Scalars["Int"]["output"];
  openWorkflows: Scalars["Int"]["output"];
  overdueWorkflows: Scalars["Int"]["output"];
  stageName: Scalars["String"]["output"];
}

export interface WorkflowStageReference {
  hasException: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  status: WorkflowStageStatus;
}

export enum WorkflowStageStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  FUTURE = "FUTURE",
  INACTIVE = "INACTIVE",
  OVERDUE = "OVERDUE",
  SKIPPED = "SKIPPED",
}

export interface WorkflowStageTemplate {
  actionItemTemplates: Array<ActionItemTemplate>;
  /** @deprecated previously we would always set this to true */
  autoStart?: Maybe<Scalars["Boolean"]["output"]>;
  createdAt: Scalars["Time"]["output"];
  createdBy: User;
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  /**
   * The order in which the stage should be executed.
   * Min value: 1
   */
  executionOrder: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
  startAfterSpec?: Maybe<StartAfterWorkflowStageTemplateSpec>;
  startAfterWorkflowStageTemplate?: Maybe<WorkflowStageTemplate>;
  startAfterWorkflowStageTemplates?: Maybe<Array<WorkflowStageTemplate>>;
  startAt?: Maybe<Scalars["Time"]["output"]>;
  startOnWorkflowStart: Scalars["Boolean"]["output"];
  startingConditions?: Maybe<ConditionalExpressionGroup>;
  updatedAt: Scalars["Time"]["output"];
}

export interface WorkflowStatusOverviews {
  completedWorkflows: Scalars["Int"]["output"];
  endedWorkflows: Scalars["Int"]["output"];
  inProgressWorkflows: Scalars["Int"]["output"];
  notStartedWorkflows: Scalars["Int"]["output"];
  onTrackWorkflows: Scalars["Int"]["output"];
  pausedWorkflows: Scalars["Int"]["output"];
  problemsReported: Scalars["Int"]["output"];
  workflowsWithExceptions: Scalars["Int"]["output"];
  workflowsWithOverdueStages: Scalars["Int"]["output"];
}

/**
 * A WorkflowTemplate is also called a Blueprint. It is a template that is used to create Workflows.
 * When the blueprint versions flag is on these objects only represent a
 * single version of a blueprint.
 */
export interface WorkflowTemplate {
  /**
   * Whether workflows created off this template will adjust due dates of subsequent tasks based on
   * tasks finishing earlier or later than allocated.
   */
  autoAdjustDueOn?: Maybe<Scalars["Boolean"]["output"]>;
  /** UUID of the blueprint. This is the same across many versions. */
  blueprintUuid: Scalars["String"]["output"];
  /** Similar to Collaborators, but it also includes the level of access that each Party has. */
  collaborations: Array<WorkflowTemplateCollaboration>;
  /**
   * Collaborators is the list of Parties that will have access to each workflow created from this
   * WorkflowTemplate/Blueprint.
   */
  collaborators: Array<Party>;
  /** If the WorkflowTemplate was created from another WorkflowTemplate, this field will point to it. */
  copiedFromWorkflowTemplate?: Maybe<WorkflowTemplate>;
  /** Contact email for create via email for workflows created from this template. Users who send any email to this address will create and start the workflow. */
  createViaEmailContactEmail?: Maybe<Scalars["String"]["output"]>;
  /** The timestamp at which the WorkflowTemplate/Blueprint was created. */
  createdAt: Scalars["Time"]["output"];
  /** The user who created/owns the WorkflowTemplate/Blueprint. */
  createdBy: User;
  /** Holds information about whether and when the current user may have starred this workflow template. */
  currentUserStar?: Maybe<Star>;
  /**
   * The timestamp at which the WorkflowTemplate/Blueprint was deleted, if it was. If not, then this
   * field will be blank. Deleted WorkflowTemplates/Blueprints are typically filtered out of most all
   * views in the application.
   */
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  /** A human-readable text description of the WorkflowTemplate/Blueprint. */
  description?: Maybe<Scalars["String"]["output"]>;
  /**
   * For a workflowTemplate of type "COMPANY" or "PREVIOUS_VERSION". If there is a work in progress new
   * draft version this will include a reference to it.
   */
  draftVersionWorkflowTemplate?: Maybe<WorkflowTemplateBaseReference>;
  /** A list of all of the Workflow level fields that will be created when the WorkflowTemplate/Blueprint is used to create a Workflow. */
  fieldInstances: Array<FieldInstance>;
  /** A graph-like representation of the workflow template. */
  graph: WorkflowGraph;
  id: Scalars["ID"]["output"];
  /** Whether workflows can be created from this template via email. */
  isCreateViaEmailEnabled: Scalars["Boolean"]["output"];
  /** Whether workflows can be created from this template by external users via email */
  isCreateViaEmailExternalUserEnabled: Scalars["Boolean"]["output"];
  /** Whether workflows created from this template have their approval tasks restricted from edits. */
  isEditingWorkflowApprovalsRestricted: Scalars["Boolean"]["output"];
  /**
   * Whether workflows created from this template will be "restricted"; i.e., certain actions on them
   * (and their stages and tasks) will be restricted from happening. In most cases, only workspace
   * owners and admins can override such restrictions.
   */
  isEditingWorkflowsRestricted: Scalars["Boolean"]["output"];
  /** Indicates whether the current user can create a blueprint using this workflow, and if not, why not. */
  isEligibleToUse: IsWorkflowTemplateEligibleToUse;
  /**
   * isValidToUse is deprecated. Use isEligibleToUse instead.
   * @deprecated Use isEligibleToUse instead.
   */
  isValidToUse: Scalars["Boolean"]["output"];
  /**
   * Setting which controls if variants can be created from this workflow template. It is always false
   * for variant workflow templates themselves.
   */
  isVariantCreationEnabled: Scalars["Boolean"]["output"];
  /**
   * Whether variants can be created from the latest published version of this workflow template. False
   * if there is no published version.
   */
  isVariantCreationEnabledOnPublishedVersion: Scalars["Boolean"]["output"];
  /**
   * Whether or not the created workflow will restrict access to any assigned external parties. This setting
   * is deprecated and will be set to true for all Workflow Templates. This is
   * explained in more detail on the Workflow type.
   */
  isVisibilityLimitedForExternalParties: Scalars["Boolean"]["output"];
  /** The name of the WorkflowTemplate/Blueprint. */
  name: Scalars["String"]["output"];
  /** The naming convention/template with which the WorkflowTemplate/Blueprint's name is crafted. */
  nameTemplate?: Maybe<NameTemplate>;
  /** A count of the length of the stages list. */
  numberOfStages: Scalars["Int"]["output"];
  /**
   * A list of online parties and their status (active/idle) in the workflow template.
   * The current user is always excluded from this list.
   */
  onlineParties: Array<OnlineParty>;
  /** The level of access that the current user has on the WorkflowTemplate/Blueprint. */
  permissions?: Maybe<UserPermissionsToWorkflowTemplate>;
  /** A sum of the durations of all tasks, taking into account thier dependencies and requirements. */
  predictedDurationDays: Scalars["Int"]["output"];
  /**
   * The timestamp at which the WorkflowTemplate/Blueprint was published. Null if the template is of
   * type `MINE` or `DRAFT`.
   */
  publishedAt?: Maybe<Scalars["Time"]["output"]>;
  /**
   * The party who published the WorkflowTemplate/Blueprint. This will be null if the template is of
   * type `MINE` or `DRAFT`.
   */
  publishedBy?: Maybe<Party>;
  /** Prefix to the workflow IDs kicked off from this blueprint. */
  referenceNumberPrefix?: Maybe<Scalars["String"]["output"]>;
  /**
   * Minimum value of the incremental index of the workflow IDs
   * kicked off from this blueprint.
   */
  referenceNumberStartingValue?: Maybe<Scalars["Int"]["output"]>;
  /** Lists each stage template in the WorkflowTemplate/Blueprint. */
  stageTemplates: Array<WorkflowStageTemplate>;
  /**
   * A list of the tags that are associated with the WorkflowTemplate/Blueprint. Each workflow created
   * from this Blueprint will automatically inherit its tags.
   */
  tags: Array<Tag>;
  /**
   * The type of a Workflow is one of MINE, REGRELLO, or COMPANY and it indicates where the
   * WorkflowTemplate/Blueprint will be presented in the application. MINE templates are the ones that
   * show up in "My Blueprints" and only the user who created them has access. COMPANY templates are
   * the ones that show up in the "Company Blueprints" section and they are shared with all users in
   * the Workspace as long as they have permission to publish new workflows. REGRELLO scoped templates are not implemented.
   */
  type: WorkflowTemplateType;
  /** The timestamp at which the WorkflowTemplate/Blueprint was last changed. */
  updatedAt: Scalars["Time"]["output"];
  /**
   * The most recent Party (user/team) to update the WorkflowTemplate/Blueprint. In practice, this will
   * always be the createdBy User because only the author can modify a WorkflowTemplate/Blueprint.
   * Non-creators need to first save and re-publish.
   */
  updatedBy: Party;
  variantData?: Maybe<VariantWorkflowTemplateData>;
  /**
   * Optional user provided notes summarizing the changes made to this version of the workflow
   * template. Version notes can only be updated on a template of type "MINE" or "DRAFT" and are
   * cleared when copying a blueprint or updating a blueprint to a new version.
   */
  versionNotes?: Maybe<Scalars["String"]["output"]>;
  /**
   * Workflow templates of type COMPANY or PREVIOUS_VERSION will have a version
   * number indicating which iteration of the blueprint it is.
   */
  versionNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Default owner of any workflows kicked off from this workflow template. */
  workflowOwnerParty?: Maybe<Party>;
}

/**
 * These are actions you can perform towards a workflow template. Should be a 1-1 mapping to
 * opa.ActionSchemaJson, but we can't expose those to the client currently because they contain special
 * characters.
 */
export enum WorkflowTemplateAction {
  CREATE_WORKFLOWS_FROM = "CREATE_WORKFLOWS_FROM",
}

/**
 * Base reference for a blueprint, containing only non-sensitive data for clients that don't
 * necessarily have permission to read the rest of the blueprint details.
 */
export interface WorkflowTemplateBaseReference {
  blueprintUuid: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  /**
   * Setting which controls if variants can be created for this workflow template. It is always false
   * for variant workflow templates themselves.
   */
  isVariantCreationEnabled: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  permissions: UserPermissionsToWorkflowTemplate;
  /** Null if the workflow template is not a variant. */
  variantData?: Maybe<VariantWorkflowTemplateDataReference>;
  /** Represents which iteration of the Blueprint this version is. 1-indexed in order of `createdAt`. */
  versionNumber?: Maybe<Scalars["Int"]["output"]>;
}

export interface WorkflowTemplateBlankCsvColumnsResponse {
  /**
   * A list of identifying information about each column in the downloadable CSV, in the order that the
   * columns appear in the CSV file.
   */
  columnIdentifiers: Array<BulkStartWorkflowsCsvColumnIdentifier>;
}

export interface WorkflowTemplateCollaboration {
  accessRole: AccessRole;
  party: Party;
}

export interface WorkflowTemplateInheritableFieldInstances {
  fieldInstances: Array<FieldInstance>;
}

export interface WorkflowTemplateParticipation {
  assignedParty: PartyOrRole;
  resolvedParties: Array<Maybe<Party>>;
  taskCounts: TaskCounts;
}

export interface WorkflowTemplateParticipationInput {
  workflowTemplateId: Scalars["ID"]["input"];
}

export interface WorkflowTemplateReference {
  stageTemplateExecutionOrder: Scalars["Int"]["output"];
  stageTemplateId: Scalars["ID"]["output"];
  stageTemplateName: Scalars["String"]["output"];
  workflowTemplateId: Scalars["ID"]["output"];
  workflowTemplateName: Scalars["String"]["output"];
  workflowTemplatePermissions: UserPermissionsToWorkflowTemplate;
}

export enum WorkflowTemplateRelationType {
  CREATE_VARIANT = "CREATE_VARIANT",
  DRAFT_EDIT = "DRAFT_EDIT",
  /** @deprecated Field no longer supported */
  DRAFT_TO_SHARED_START_WORKFLOW = "DRAFT_TO_SHARED_START_WORKFLOW",
  /** @deprecated Field no longer supported */
  DRAFT_TO_SHARED_VIEW = "DRAFT_TO_SHARED_VIEW",
  DRAFT_VIEW = "DRAFT_VIEW",
  SHARED_START_WORKFLOW = "SHARED_START_WORKFLOW",
  /** @deprecated Field no longer supported */
  SHARED_TO_DRAFT_EDIT = "SHARED_TO_DRAFT_EDIT",
  /** @deprecated Field no longer supported */
  SHARED_TO_DRAFT_VIEW = "SHARED_TO_DRAFT_VIEW",
  SHARED_VIEW = "SHARED_VIEW",
  WORKFLOW_COMMENT = "WORKFLOW_COMMENT",
  WORKFLOW_EDIT = "WORKFLOW_EDIT",
}

export interface WorkflowTemplateResourceRelation {
  conditionGroup?: Maybe<Scalars["ID"]["output"]>;
  relation: WorkflowTemplateRelationType;
}

/**
 * Enumerates all of the types of WorkflowTemplates/Blueprints. REGRELLO templates are not yet
 * implemented. COMPANY templates are created by customers and are shared within a Workspace to users
 * that have permission to create workflows. MINE templates are those that appear in the My Templates,
 * or Draft Blueprints, section of Regrello and have not yet been published. DRAFT templates also exist
 * in the Draft Blueprints section of Regrello but were created from an existing COMPANY or PREVIOUS_VERSION
 * template. PREVIOUS_VERSION templates represent the older versions of a COMPANY template and can be
 * updated to DRAFT to create a new version of a COMPANY template.
 */
export enum WorkflowTemplateType {
  COMPANY = "COMPANY",
  DRAFT = "DRAFT",
  MINE = "MINE",
  PREVIOUS_VERSION = "PREVIOUS_VERSION",
  REGRELLO = "REGRELLO",
}

export interface WorkflowTemplatesUsingRoleInput {
  /** ID of the role to find workflow templates for. */
  roleId: Scalars["ID"]["input"];
}

export interface WorkflowTemplatesUsingRoleResponse {
  /** Workflow templates that the requested role is involved in. */
  workflowTemplates: Array<WorkflowTemplate>;
}

export interface WorkflowWithAggregations {
  endAt?: Maybe<Scalars["Time"]["output"]>;
  exceptionActions: Scalars["Int"]["output"];
  openActions: Scalars["Int"]["output"];
  overdueActions: Scalars["Int"]["output"];
  startAt?: Maybe<Scalars["Time"]["output"]>;
  workflow: Workflow;
}

export enum WorkspaceRelationType {
  ADMIN = "ADMIN",
  CREATE = "CREATE",
  /** Only permits the user to invite others to the workspace. */
  INVITE = "INVITE",
  PUBLISH = "PUBLISH",
}

export interface WorkspaceSetting {
  id: Scalars["ID"]["output"];
  type: WorkspaceSettingValueType;
  updatedAt: Scalars["Time"]["output"];
  updatedBy?: Maybe<Party>;
  value?: Maybe<Scalars["String"]["output"]>;
}

export enum WorkspaceSettingValueType {
  COLOR_HEX = "COLOR_HEX",
  IMAGE_DATA_URL = "IMAGE_DATA_URL",
}

export interface WorkspaceSettings {
  emailLogo: WorkspaceSetting;
  logo: WorkspaceSetting;
  sidebarBackgroundColor: WorkspaceSetting;
  sidebarForegroundColor: WorkspaceSetting;
  taskInformationOrderReversed: WorkspaceSetting;
}
