import { ApolloError } from "@apollo/client";
import { t } from "@lingui/macro";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  AccessRoleUserScope,
  NonAdminPeopleAndTeamsTableQueryDocument,
  type TeamFields,
  TeamSelectorQueryResultsQueryDocument,
  useCreateTeamMutation,
  useUpdateTeamMutation,
} from "@regrello/graphql-api";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { updateTeamInCache } from "../../../../../state/apolloCacheAccessors";
import type { SubmitHandler } from "../../../../../types/form";
import { getGraphQlErrorCode, RegrelloGraphQlErrorCode } from "../../../../../utils/errorUtils";
import { refetchWithLatestVariablesByDocument } from "../../../../../utils/graphqlUtils";
import { useErrorHandler } from "../../../../../utils/hooks/useErrorHandler";
import { RegrelloFormDialogName } from "../../../../../utils/sentryScopeUtils";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { RegrelloFormDialog } from "../../../../atoms/dialog/RegrelloFormDialog";
import { useToastMessageQueue } from "../../../../molecules/toast/useToastMessageQueue";
import { ConfigureTeamForm, FrontendTeamType } from "./ConfigureTeamForm";

export interface ConfigureTeamDialogProps {
  defaultNameValue?: string;
  isOpen: boolean;
  team?: TeamFields;
  onClose: () => void;
  onSubmit?: (newTeam: TeamFields) => void;
}

export const ConfigureTeamDialog = React.memo<ConfigureTeamDialogProps>(function ConfigureTeamDialogFn({
  defaultNameValue,
  isOpen,
  team,
  onClose,
  onSubmit,
}) {
  const { currentUser } = useUser();
  const { showToast } = useToastMessageQueue();
  const { handleError } = useErrorHandler();

  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!isOpen) {
      setSubmissionError(undefined);
    }
  }, [isOpen]);

  const [createTeamAsync] = useCreateTeamMutation({
    update: (cache, mutationResult) => {
      // (clewis): Put the team in the cache so that other pages that the user may be currently
      // looking at can refresh if they need to. See: https://app.regrello.com/workflow/2422
      if (mutationResult.data?.createTeam?.team != null) {
        updateTeamInCache(cache, mutationResult.data.createTeam.team);
      }
    },
    // (krashanoff): Refetch results for the team selector after creation, in case the user is
    // creating a team on the same page as they plan to assign them to an action item, for example.
    refetchQueries: refetchWithLatestVariablesByDocument([
      TeamSelectorQueryResultsQueryDocument,
      NonAdminPeopleAndTeamsTableQueryDocument,
    ]),
  });

  const [updateTeamAsync] = useUpdateTeamMutation({
    refetchQueries: refetchWithLatestVariablesByDocument([
      TeamSelectorQueryResultsQueryDocument,
      NonAdminPeopleAndTeamsTableQueryDocument,
    ]),
  });

  const handleSubmitInternal: SubmitHandler<ConfigureTeamForm.Fields> = useCallback(
    async (data) => {
      const isCreating = team == null;
      const createOrUpdateTeamAsync = isCreating
        ? async () => {
            const resp = await createTeamAsync({
              variables: ConfigureTeamForm.createCreateMutationPayloadFromFormValues(data),
              update: (cache, { data: newTeamData }) => {
                if (newTeamData?.createTeam?.team != null) {
                  updateTeamInCache(cache, newTeamData.createTeam.team);
                }
              },
            });
            return resp.data?.createTeam?.team;
          }
        : async () => {
            const resp = await updateTeamAsync({
              variables: ConfigureTeamForm.createUpdateMutationPayloadFromFormValues(team.id, data),
              update: (cache, { data: newTeamData }) => {
                if (newTeamData?.updateTeam?.team != null) {
                  updateTeamInCache(cache, newTeamData.updateTeam.team);
                }
              },
            });

            // (krashanoff): I'm not sure why this needs to be done, but Apollo will only return one
            // error in this case, which can be mistakenly coerced at runtime to an array of errors.
            // So, we manually propagate it.
            if (resp.errors != null) {
              // eslint-disable-next-line @typescript-eslint/only-throw-error
              throw resp.errors;
            }
            return resp.data?.updateTeam?.team;
          };

      const teamName = data.name;
      try {
        const respTeam = await createOrUpdateTeamAsync();
        if (respTeam == null) {
          handleError(t`Empty error message`, {
            toastMessage: isCreating
              ? t`${teamName} could not be added to this workspace. Try again.`
              : t`Failed to update team. Please try again, or contact a Regrello admin if you continue to see this error.`,
          });
          return false;
        }

        onSubmit?.(respTeam);
        showToast({
          content: isCreating ? t`${teamName} has been added to this workspace.` : t`${teamName} has been updated.`,
          intent: "success",
        });

        return true;
      } catch (requestError) {
        if (requestError instanceof ApolloError) {
          const graphQlErrorCode = getGraphQlErrorCode(requestError);
          switch (graphQlErrorCode) {
            case RegrelloGraphQlErrorCode.TEAM_EMAIL_ALREADY_EXISTS: {
              handleError(requestError);
              const email = data.email;
              setSubmissionError(t`Team could not be created. A team or user with email "${email}" already exists.`);
              return false;
            }
            case RegrelloGraphQlErrorCode.TEAM_NAME_ALREADY_EXISTS:
              handleError(requestError);
              setSubmissionError(t`Team could not be created. A team with name "${teamName}" already exists.`);
              return false;
            default: // (krashanoff): Unchecked case to fall through to default handler.
          }
        }

        handleError(requestError as Error, {
          toastMessage: isCreating
            ? t`${teamName} could not be added to this workspace. Try again.`
            : t`Failed to update team. Please try again, or contact a Regrello admin if you continue to see this error.`,
        });
        return false;
      }
    },
    [createTeamAsync, handleError, onSubmit, showToast, team, updateTeamAsync],
  );

  const defaultValues = useMemo<ConfigureTeamForm.Fields>(() => {
    const result = ConfigureTeamForm.getDefaultValues(team);
    if (currentUser.accessRole?.userScope === AccessRoleUserScope.EXTERNAL) {
      result.teamType = FrontendTeamType.EXTERNAL;
    }
    if (defaultNameValue != null) {
      result.name = defaultNameValue;
    }
    return result;
  }, [currentUser.accessRole?.userScope, defaultNameValue, team]);

  return (
    <RegrelloFormDialog
      dataTestId={DataTestIds.CONFIGURE_TEAM_DIALOG}
      defaultValues={defaultValues}
      description={team == null ? <p>{t`Create a team to collaborate on tasks and workflows.`}</p> : null}
      error={submissionError}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmitInternal}
      scopeNameForSentry={RegrelloFormDialogName.CONFIGURE_TEAM}
      showRequiredInstruction={true}
      submitButtonText={team != null ? t`Save` : t`Add team`}
      title={team != null ? t`Edit team` : t`Add team`}
    >
      {(form) => (
        <ConfigureTeamForm.Component
          context={team == null ? ConfigureTeamForm.Context.CREATE : ConfigureTeamForm.Context.EDIT}
          form={form}
        />
      )}
    </RegrelloFormDialog>
  );
});
