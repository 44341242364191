import { t } from "@lingui/macro";
import { EMPTY_STRING, type WithDataTestId } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { useCallback, useState } from "react";

import { RegrelloDialogV2 } from "../../atoms/dialogV2/RegrelloDialogV2";
import { RegrelloIcon, type RegrelloIconName } from "../../atoms/icons/RegrelloIcon";
import { RegrelloIconStyler } from "../../atoms/icons/RegrelloIconStyler";
import { RegrelloTypography } from "../../atoms/typography/RegrelloTypography";
import { RegrelloButtonBar } from "../buttonBar/RegrelloButtonBar";

type ConfirmationDialogButtonOption = "cancel" | "secondary" | "confirm" | undefined;

export interface RegrelloConfirmationDialogProps extends WithDataTestId {
  /**
   * An icon to display at the start of the cancel button.
   */
  cancelIcon?: React.ReactElement;

  /**
   * The text to display in the cancel button.
   *
   * @default "Cancel"
   */
  cancelText?: string;

  /**
   * The semantic intent the button should convey.
   *
   * @default "primary"
   */
  confirmIntent?: "primary" | "danger";

  /**
   * An icon to display at the start of the confirm button.
   */
  confirmIcon?: React.ReactElement;

  /**
   * The text to display in the confirm button.
   *
   * @default "Confirm"
   */
  confirmText?: string;

  /** The body text to display in the dialog. Can be arbitrary React content. */
  content: React.ReactNode;

  /** Class name applied to the content container, useful for changing the max-width */
  contentClassName?: string;

  /**
   * An icon to show before the dialog content.
   *
   * @default "alert-outline"
   */
  icon?: RegrelloIconName | React.ReactElement;

  /**
   * Whether the cancel button is hidden.
   *
   * ___Warning:___ Please avoid using this prop, because it violates the core objective of this
   * component: to allow the user to either confirm an action _or_ cancel out of it. If you
   * find yourself needing this prop, you should really use `RegrelloAlert` instead.
   */
  isCancelButtonHidden?: boolean;

  /**
   * Whether the confirmation CTA is disabled.
   *
   * @default false
   */
  isConfirmDisabled?: boolean;

  /**
   * Whether the icon is hidden.
   *
   * @default false
   */
  isIconHidden?: boolean;

  /** Whether the dialog is currently open. */
  isOpen: boolean;

  /**
   * Whether the async action is currently in progress. When `true`, the confirm button will
   * display a loading spinner.
   *
   * The `useConfirmationDialog` hook can help you manage this flag.
   */
  isSubmitting?: boolean;

  /** Callback invoked when the dialog wants to close. */
  onClose: () => void;

  /**
   * Callback invoked when the confirm button is clicked. This will ___not___ automatically close
   * the dialog. If running an async operation in response, you may consider passing
   * `isSubmitting=true` while the operation is in progress. Regardless, you should pass
   * `isOpen=false` when you're ready to close the dialog.
   *
   * The `useConfirmationDialog` hook can help you manage all this state.
   */
  onConfirm: () => void;

  /**
   * Callback invoked when the the secondary button is clicked. The dialog renders a button on the
   * starting side at the bottom when this callback is supplied.
   */
  onSecondaryButtonClick?: () => void;

  /**
   * The text to display in the secondary button.
   *
   * @default "Save and close"
   */
  secondaryButtonText?: string;

  /** The text to display in the dialog title. */
  title: string;
}

/** A dialog that asks the user to confirm an action before proceeding, or to cancel. */
export const RegrelloConfirmationDialog = React.memo(function RegrelloConfirmationDialogFn({
  cancelIcon,
  cancelText = t`Cancel`,
  confirmIcon,
  confirmIntent = "primary",
  confirmText = t`Confirm`,
  content,
  contentClassName,
  dataTestId,
  // (hchen): A exclamation mark with a circle around it.
  icon = "alert-outline",
  isCancelButtonHidden = false,
  isConfirmDisabled = false,
  isIconHidden = false,
  isOpen,
  isSubmitting,
  onClose,
  onConfirm,
  onSecondaryButtonClick,
  secondaryButtonText = t`Save and close`,
  title,
}: RegrelloConfirmationDialogProps) {
  const renderIcon = useCallback(() => {
    // Use "block" instead of "inline-block" to eliminate excessive line height in some icons.
    return typeof icon === "string" ? (
      // (hchen): We don't have an icon size of 44x44, so I picked `large` to make it look as close
      // to the visual design as possible.
      <RegrelloIcon iconName={icon} size="large" />
    ) : (
      <RegrelloIconStyler size="large">{icon}</RegrelloIconStyler>
    );
  }, [icon]);

  // TODO (clewis): IMO this mechanism is no good because it doesn't reset when the dialog unmounts.
  const [clickedButton, setClickedButton] = useState<ConfirmationDialogButtonOption>(undefined);

  const handleClickInternal = useCallback(
    (sourceButton: ConfirmationDialogButtonOption) => {
      setClickedButton(sourceButton);
      switch (sourceButton) {
        case "cancel": {
          onClose();
          return;
        }
        case "confirm": {
          onConfirm();
          return;
        }
        case "secondary":
          return onSecondaryButtonClick?.();
        default:
          return null;
      }
    },
    [onClose, onConfirm, onSecondaryButtonClick],
  );

  return (
    <RegrelloDialogV2 contentClassName={contentClassName} dataTestId={dataTestId} onClose={onClose} open={isOpen}>
      <div className="flex">
        {!isIconHidden && <div className="mr-4">{renderIcon()}</div>}
        <div>
          {/* (hchen): We don't have a font size of 20, so I picked `h4` to make it look as close to the visual design as possible. */}
          <RegrelloTypography className="mb-2" dataTestId={DataTestIds.CONFIRMATION_DIALOG_TITLE} variant="h5">
            {title}
          </RegrelloTypography>
          <div data-testid={DataTestIds.CONFIRMATION_DIALOG_CONTENT}>{content}</div>
        </div>
      </div>
      <div className="flex justify-end">
        <RegrelloButtonBar
          additionalEndSideButtonProps={
            !isCancelButtonHidden
              ? [
                  {
                    children: cancelText ?? EMPTY_STRING,
                    dataTestId: DataTestIds.CONFIRMATION_DIALOG_CANCEL_BUTTON,
                    key: "cancel",
                    startIcon: cancelIcon,
                    loading: isSubmitting && clickedButton === "cancel",
                    // eslint-disable-next-line lingui/no-unlocalized-strings
                    onClick: () => handleClickInternal("cancel"),
                  },
                ]
              : undefined
          }
          additionalStartSideButtonProps={
            onSecondaryButtonClick != null
              ? [
                  {
                    children: secondaryButtonText ?? EMPTY_STRING,
                    key: "secondary",
                    loading: isSubmitting && clickedButton === "secondary",
                    // eslint-disable-next-line lingui/no-unlocalized-strings
                    onClick: () => handleClickInternal("secondary"),
                    type: "submit",
                  },
                ]
              : undefined
          }
          confirmButtonProps={{
            children: confirmText,
            dataTestId: DataTestIds.CONFIRMATION_DIALOG_CONFIRM_BUTTON,
            disabled: isConfirmDisabled,
            intent: confirmIntent,
            loading: isSubmitting && clickedButton === "confirm",
            startIcon: confirmIcon,
            // eslint-disable-next-line lingui/no-unlocalized-strings
            onClick: () => handleClickInternal("confirm"),
          }}
        />
      </div>
    </RegrelloDialogV2>
  );
});
