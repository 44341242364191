import { EMPTY_STRING } from "@regrello/core-utils";
import { RegrelloField, RegrelloInput, type RegrelloInputProps, type RegrelloSize } from "@regrello/ui-core";
import React, { useCallback, useEffect, useId, useState } from "react";

import { getBoundedValue } from "./_internal/getBoundedValue";
import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldEmptyValueNone } from "./_internal/RegrelloFormFieldEmptyValueNone";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";
import { getSelfContainedFormInternal } from "./_internal/selfContainedForm/getSelfContainedFormInternal";

export interface RegrelloFormFieldNumberProps
  extends RegrelloFormFieldBaseProps<number | string>,
    Pick<RegrelloInputProps, "autoFocus" | "onBlur" | "onChange" | "inputRef" | "placeholder"> {
  /**
   * If provided, this will decorate the number form field with the currency symbol.
   */
  currencySymbol?: string;

  /**
   * Whether to hide the form field error icon and message. Added (potentially temporarily depending
   * on Design System updates) for Conditional Branching to support the new error state for stage
   * start date forms.
   */
  isErrorMessageHidden?: boolean;

  /**
   * The minimum value to enforce. If `max` is provided and the user types a value greater than
   * `max`, the field will immediately replace the value with the `max` value.
   */
  max?: number;

  /**
   * The minimum value to enforce. If `min` is provided and the user types a value less that `min`,
   * the field will immediately replace the value with the `min` value.
   */
  min?: number;

  /**
   * If provided, will show an 'x' button in the input that invokes this callback when clicked.
   * Expected usage thereafter is for the callback to clear the input's value.
   */
  onClearClick?: () => void;

  /**
   * The size of the input field.
   *
   * @default RegrelloSize.LARGE
   */
  size?: RegrelloSize;

  /** The current field value. Pass an empty string to indicate that the field is empty. */
  value: number | string;
}

export const RegrelloFormFieldNumber = React.memo(function RegrelloFormFieldNumberFn({
  className,
  currencySymbol,
  dataTestId,
  error,
  isErrorMessageHidden = false,
  isDeleted,
  isDefaultMarginsOmitted,
  helperText,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  isEmphasized,
  isLabelVerticallyCentered,
  isRequiredAsteriskShown,
  labelPlacement,
  label,
  labelWidth,
  max,
  min,
  onBlur,
  onChange: propsOnChange,
  selfContainedForm,
  size,
  value,
  variant = "default",
  ...textFieldProps
}: RegrelloFormFieldNumberProps) {
  const id = useId();
  const hasError = error != null;

  if (max != null && min != null && max < min) {
    throw new Error(`Invalid props: max (${max}) cannot be less than min (${min})`);
  }

  const [valueInternal, setValueInternal] = useState(value);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueInternal(event.currentTarget.value);
      propsOnChange?.(event);
    },
    [propsOnChange],
  );

  const onBlurInternal = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (value != null && value.toString().length > 0) {
        setValueInternal(getBoundedValue(value.toString(), min, max));
      }
      onBlur?.(event);
    },
    [max, min, onBlur, value],
  );

  // (clewis): When in controlled mode, keep the internal value in sync with the incoming value when
  // the incoming value changes.
  useEffect(() => {
    setValueInternal(value);
  }, [value]);

  if (variant === "spectrum" && selfContainedForm == null) {
    return (
      <RegrelloField
        dataTestId={dataTestId}
        deleted={isDeleted}
        description={infoTooltipText}
        errorMessage={error}
        helperText={typeof helperText === "string" ? helperText : undefined}
        htmlFor={id}
        label={typeof label === "string" ? label : EMPTY_STRING}
        name={textFieldProps.name ?? EMPTY_STRING}
        required={isRequiredAsteriskShown}
      >
        {() => {
          return (
            <RegrelloInput
              {...textFieldProps}
              autoFocus={textFieldProps.autoFocus || selfContainedForm != null}
              id={id}
              intent={hasError ? "danger" : isEmphasized ? "warning" : undefined}
              onBlur={onBlurInternal}
              onChange={onChange}
              size={size}
              startElement={
                currencySymbol != null
                  ? {
                      type: "nonInteractive",
                      element: <span>{currencySymbol}</span>,
                    }
                  : undefined
              }
              value={valueInternal}
            />
          );
        }}
      </RegrelloField>
    );
  }

  return (
    <RegrelloFormFieldLayout
      className={className}
      dataTestId={dataTestId}
      error={isErrorMessageHidden ? undefined : error}
      helperText={helperText}
      htmlFor={id}
      infoTooltipIconName={infoTooltipIconName}
      infoTooltipText={infoTooltipText}
      infoTooltipVariant={infoTooltipVariant}
      isDefaultMarginsOmitted={isDefaultMarginsOmitted}
      isDeleted={isDeleted}
      isLabelVerticallyCentered={isLabelVerticallyCentered}
      isRequiredAsteriskShown={isRequiredAsteriskShown}
      label={label}
      labelPlacement={labelPlacement}
      labelWidth={labelWidth}
      selfContainedForm={getSelfContainedFormInternal(selfContainedForm, (savedValue) =>
        savedValue == null || savedValue === EMPTY_STRING ? <RegrelloFormFieldEmptyValueNone /> : savedValue,
      )}
      variant={variant}
    >
      <RegrelloInput
        {...textFieldProps}
        autoFocus={textFieldProps.autoFocus || selfContainedForm != null}
        id={id}
        intent={hasError ? "danger" : isEmphasized ? "warning" : undefined}
        onBlur={onBlurInternal}
        onChange={onChange}
        size={size}
        startElement={
          currencySymbol != null
            ? {
                type: "nonInteractive",
                element: <span>{currencySymbol}</span>,
              }
            : undefined
        }
        value={valueInternal}
      />
    </RegrelloFormFieldLayout>
  );
});
