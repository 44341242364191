import * as TabsPrimitive from "@radix-ui/react-tabs";
import { clsx, KeyNames, type WithClassName } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { type ReactNode } from "react";

const RegrelloTabsRoot = TabsPrimitive.Root;

const RegrelloTabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={clsx(
      "flex",
      "items-center",
      "shrink-0",
      "gap-4",
      "px-5",
      "overflow-x-auto",
      "pt-0",
      // (dosipiuk): top padding is here (on larger viewports), so selection outline does not hide
      // beneth the content on the top.
      "sm:pt-1.5",
      className,
    )}
    {...props}
  />
));
RegrelloTabsList.displayName = TabsPrimitive.List.displayName;

const RegrelloTabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={clsx(
      "h-11", // on mobile
      "sm:h-8", // on larger viewports
      [
        "inline-flex",
        "items-center",
        "justify-center",
        "whitespace-nowrap",

        "text-textMuted",

        "transition-all",

        "disabled:pointer-events-none",
        "disabled:opacity-50",

        // (clewis): Use border instead of shadow, else the focus outline will wipe away the active
        // tab's shadow on focus.
        "border-transparent",
        "border-b-[3px]",

        "data-[state=active]:text-textDefault",
        "data-[state=active]:border-textDefault", // (clewis): Same as text color.

        "focus-visible:outline-2",
        "focus-visible:outline-primary-solid",
        "focus-visible:outline-offset-3",
        "focus-visible:shadow-none",
      ],
      className,
    )}
    data-testid={DataTestIds.PAGE_TAB_ITEM}
    {...props}
  />
));
RegrelloTabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const RegrelloTabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={clsx(
      "min-h-0 overflow-auto data-[state=active]:grow",
      // (clewis): Don't show a focus outline when you click on a tab panel.
      "focus-visible:outline-none",
      className,
    )}
    tabIndex={-1}
    {...props}
  />
));
RegrelloTabsContent.displayName = TabsPrimitive.Content.displayName;

export interface RegrelloTabsTab {
  content?: React.ReactNode;
  id: string;
  isDefault?: boolean;
  label: React.ReactNode;
}

export type RegrelloTabsProps = Pick<TabsPrimitive.TabsProps, "onValueChange" | "value" | "activationMode"> &
  WithClassName & {
    /**
     * Classes to be added to content container.
     */
    contentClassName?: string;

    /**
     * Tabs configuration. Must provide `id` and `label`. Optionally provide `content`.
     */
    tabs: RegrelloTabsTab[];

    /**
     * Classes to be added to every trigger.
     */
    triggerClassName?: string;

    /**
     * Classes to be added to trigger list container.
     */
    triggerListClassName?: string;

    /**
     * Content to be displayed at the end of trigger list container.
     */
    triggerListEndContent?: ReactNode;
  };

function handleBlur(event: React.KeyboardEvent<HTMLButtonElement>) {
  if (event.key === KeyNames.ESCAPE) {
    event.currentTarget.blur();
  }
}

/**
 *  Group content together with this accessible tabs component.
 *  Requires tabs definition with `id`, `label` and optional `content`
 */
const RegrelloTabs = ({
  activationMode,
  className,
  contentClassName,
  onValueChange,
  tabs,
  triggerClassName,
  triggerListClassName,
  triggerListEndContent,
  value,
}: RegrelloTabsProps) => {
  const defaultValue = tabs.find((tab) => tab.isDefault === true) || tabs[0];

  return (
    <RegrelloTabsRoot
      activationMode={activationMode}
      className={clsx("h-full flex flex-col relative", className)}
      defaultValue={defaultValue.id}
      onValueChange={onValueChange}
      value={value}
    >
      {/* (clewis): Set the tab list's z-index higher than the content, so tab focus outlines will show in full. */}
      <RegrelloTabsList className={clsx("z-1", triggerListClassName)}>
        {tabs.map((tab) => (
          <RegrelloTabsTrigger key={tab.id} className={triggerClassName} onKeyUp={handleBlur} value={tab.id}>
            {tab.label}
          </RegrelloTabsTrigger>
        ))}
        {triggerListEndContent ? <div className="ml-auto">{triggerListEndContent}</div> : null}
      </RegrelloTabsList>
      {tabs.map((tab) =>
        tab.content != null ? (
          <RegrelloTabsContent key={tab.id} className={contentClassName} value={tab.id}>
            {tab.content}
          </RegrelloTabsContent>
        ) : null,
      )}
    </RegrelloTabsRoot>
  );
};

RegrelloTabs.displayName = TabsPrimitive.Trigger.displayName;

export { RegrelloTabs, RegrelloTabsContent, RegrelloTabsList, RegrelloTabsRoot, RegrelloTabsTrigger };
