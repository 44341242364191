import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";

const DEBOUNCE_DELAY_MS = 100;

export namespace useDebouncedElementSize {
  export interface Args<T extends HTMLElement> {
    /** A ref to the element to observe. */
    elementRef: React.MutableRefObject<T | null>;
  }

  /** The observed width. Will be -1 initially. */
  export type Return = number;
}

/**
 * Hook that uses a ResizeObserver to emit the width of a specified element after a debounced delay.
 * This is useful for rerendering the element strictly after a resize has finished, which improves
 * rendering performance.
 *
 * ___Note:___ If you simply want to re-render the current component when the size changes, just do
 * the following (i.e., don't store the returned value in a variable):
 *
 * ```
 * useDebouncedElementSize({ elementRef: myElementRef });
 * ```
 */
export function useDebouncedElementSize<T extends HTMLElement>({
  elementRef,
}: useDebouncedElementSize.Args<T>): useDebouncedElementSize.Return {
  const [width, setWidth] = useState<number>(-1);

  const debouncedSetRootWidthKey = useMemo(() => {
    return debounce((newWidth: number) => {
      setWidth(newWidth);
    }, DEBOUNCE_DELAY_MS);
  }, []);

  useEffect(() => {
    if (elementRef.current == null) {
      return;
    }
    const rootResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        debouncedSetRootWidthKey(entry.contentRect.width);
      }
    });
    rootResizeObserver.observe(elementRef.current);

    return () => {
      rootResizeObserver.disconnect();
      debouncedSetRootWidthKey.cancel();
    };
  }, [debouncedSetRootWidthKey, elementRef]);

  return width;
}
