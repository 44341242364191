import { useAtomValue } from "jotai";
import React, { useCallback, useEffect, useRef } from "react";

import { RegrelloRestApiService } from "../services/RegrelloRestApiService";
import { fpsPerformanceReportUserAtom } from "../state/applicationState";
import { useWindowFocus } from "./hooks/useWindowFocus";

/**
 * A value that is unreasonably high to be a valid FPS value. This is used to indicate that the
 * previous sample was invalid.
 */
const UNREALISTICALLY_HIGH_FPS_VALUE = 999;

/** Threshold at which to report slow performance to the back-end for logging. */
const FPS_LOG_THRESHOLD = 15;

/** How long to wait between slow FPS reports to get sent to the back-end. */
const FPS_REPORT_THROTTLE_INTERVAL_MS = 10 * 1000;

/** How long to wait between samples. */
const FPS_SAMPLE_INTERVAL_MS = 1000;

/** How long to store samples before the window is analyzed and cleared. */
const FPS_SAMPLE_WINDOW_PERIOD_MS = 10 * 1000;

/** How much each new incoming sample affects the sliding average weight. */
const SLIDING_AVERAGE_SAMPLE_WEIGHT = 0.5;

/**
 * This component measures the FPS of the application, and sends a report to the
 * `FrontendSlowdownReportHandler` endpoint if they drop below the configured threshold. This
 * component should only be mounted once, at the root of the application.
 */
export const MonitorAndReportFpsPerformance = React.memo(function MonitorAndReportFpsPerformanceFn() {
  const { isWindowFocused } = useWindowFocus();
  const fpsPerformanceReportUser = useAtomValue(fpsPerformanceReportUserAtom);
  const previousAnimationFrameTimeRef = useRef<number>(0);
  const previousReportTimeMsRef = useRef<number>(0);
  const previousSampleWindowTimeMsRef = useRef<number>(0);
  const lastSampleTimeMsRef = useRef<number>(0);
  const requestRef = useRef<number>();
  const sampleBuffer = useRef<number[]>([]);
  const slidingAverage = useRef<number>(60);

  /** Makes an API call to the back-end with the necessary report data. */
  const sendLowFpsReport = useCallback(
    (fpsValue: number) => {
      const now = performance.now();

      // Skip reporting if the throttle interval has not elapsed.
      if (
        previousReportTimeMsRef.current != null &&
        now - previousReportTimeMsRef.current < FPS_REPORT_THROTTLE_INTERVAL_MS
      ) {
        return;
      }

      // No need to `await` since it's a fire-and-forget call.
      void RegrelloRestApiService.reportFpsPerformance({
        userEmail: fpsPerformanceReportUser.userEmail,
        tenantName: fpsPerformanceReportUser.tenantName,
        fps: fpsValue,
      });

      previousReportTimeMsRef.current = now;
    },
    [fpsPerformanceReportUser.tenantName, fpsPerformanceReportUser.userEmail],
  );

  /** Calculates the FPS, schedules the next animation frame, and report it if necessary. */
  const handleAnimationFrame = useCallback(
    (time: number) => {
      // If enough time has elapsed to take another sample, save this sample.
      if (time - lastSampleTimeMsRef.current >= FPS_SAMPLE_INTERVAL_MS) {
        const delta = time - previousAnimationFrameTimeRef.current;
        const fps = 1000 / delta;
        slidingAverage.current =
          fps * SLIDING_AVERAGE_SAMPLE_WEIGHT + slidingAverage.current * (1 - SLIDING_AVERAGE_SAMPLE_WEIGHT);
        sampleBuffer.current.push(slidingAverage.current);
        lastSampleTimeMsRef.current = time;
      }

      // If enough time has elapsed, check the sample buffer for the lowest value and report it if
      // necessary.
      if (time - previousSampleWindowTimeMsRef.current >= FPS_SAMPLE_WINDOW_PERIOD_MS) {
        // Once window duration has elapsed, process it and if necessary, schedule report call.
        const lowestFpsValue = sampleBuffer.current.reduce(
          (lowest, fpsValue) => Math.min(lowest, fpsValue),
          UNREALISTICALLY_HIGH_FPS_VALUE,
        );
        if (lowestFpsValue != null && lowestFpsValue < FPS_LOG_THRESHOLD) {
          sendLowFpsReport(lowestFpsValue);
        }
        sampleBuffer.current = [];
        previousSampleWindowTimeMsRef.current = time;
      }

      previousAnimationFrameTimeRef.current = time;
      requestRef.current = requestAnimationFrame(handleAnimationFrame);
    },
    [sendLowFpsReport],
  );

  // Make a new requestAnimationFrame listener and schedule the first one to kick it off.
  useEffect(() => {
    if (!isWindowFocused) {
      return;
    }
    requestRef.current = requestAnimationFrame(handleAnimationFrame);

    return () => {
      if (requestRef.current == null) {
        return;
      }

      cancelAnimationFrame(requestRef.current);
    };
  }, [handleAnimationFrame, isWindowFocused]);

  return null;
});
