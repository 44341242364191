import { captureException } from "@sentry/react";

/** This is the tags API that we send to Sentry. Keep in sync with logger.go on the backend. */
export type RegrelloSentryTags = {
  errorCode?: string;
  errorMessage: string;
  errorType: "system" | "validation" | "unknown";
  isApolloError: boolean;
  isAuth0Error: boolean;

  /** The GraphQL operation name that the error happened in. */
  operationName?: string;
};

/** Logs an error with some default values and structure to Sentry. */
export function captureExceptionWithDefaults(
  error: Error,
  options: Partial<{
    tags: Partial<RegrelloSentryTags>;
    extra: Record<string, unknown>;
  }> = {},
) {
  captureException(error, {
    tags: {
      errorType: "system",
      errorMessage: error.message,
      ...options.tags,
    },
  });
}
